import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { routeByLoanSubType, useLoanStore, useLoanSubmissionRoutes } from '@roc/feature-loans';
import { DraftLoan } from '@roc/feature-types';
import { useHistory } from 'react-router';
import { LoanSubType, isNil } from '@roc/feature-utils';
import { useState } from 'react';
import { useOneToolRoutes } from '@roc/feature-one-tool';


export const LoanApplicationDetailsCardActionsButton = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const loanId = loanDetails?.loanId;
  const { push } = useHistory();
  const { loanSubmissionRoutesConfig } = useLoanSubmissionRoutes();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const { oneToolRoutesConfig } = useOneToolRoutes();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const mapDraftLoan = async () => {
    loanStore.loanDetails = {
      ...loanDetails,
      loanApplicationId: Number(loanId)
    }
  }

  const handleContinue = async () => {
    mapDraftLoan()
    await loanStore.fetchQuoteByLoanId(loanId);

    if (isNil(loanStore?.quoteId) || loanDetails?.loanSubType === LoanSubType.FIX_AND_FLIP_PRO) {
      loanStore.continueDraftLoan(loanStore.loanDetails as DraftLoan);
      handleMenuClose();
      push(
        loanSubmissionRoutesConfig.submitloan.children[
          routeByLoanSubType[loanDetails?.loanSubType]
        ].url
      );
    } else {
      handleMenuClose();
      const route = oneToolRoutesConfig[routeByLoanSubType[loanDetails?.loanSubType]];
      push(route(loanStore?.quoteId).url);
    }
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={handleContinue}
      testId={`continue-loan-application-${loanId}`}
    >
      Continue Submission
    </Button >
  );
});
