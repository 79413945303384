import { PersonAddOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { CustomAlert } from '../../../components/customAlert';
import { CustomButton } from '../../../components/customButton';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { QuoteStep } from '../../../utils/constants';

export const NoBorrowerAlert = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();

  return (
    <CustomAlert
      severity="error"
      variant="outlined"
      action={
        <CustomButton
          size="medium"
          theme="red"
          startIcon={<PersonAddOutlined />}
          testId="edit-borrower"
          onClick={() =>
            quoteTypeStore.goToStep(QuoteStep.BORROWER_INFORMATION)
          }
        >
          ADD BORROWER
        </CustomButton>
      }
    >
      <AlertTitle>No Borrower</AlertTitle>
      <ul>
        <li>
          This quote is based on the information provided and may change if the
          borrower's credit or experience differs.
        </li>
        <li>
          Please note that quotes cannot be converted into loan submissions
          without a qualified borrower.
        </li>
      </ul>
    </CustomAlert>
  );
});
