import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { BorrowerInformationAccordionSummary } from '../../borrowerInformation/components/borrowerInformationAccordion';
import { OneToolCardBorder } from '../../common/oneToolCard';
import { SearchExistingEntityCard } from './searchExistingEntityCard';

export const SearchExistingEntity = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const { borrowersStore } = quoteTypeStore;
  const borrowerFormStores = borrowersStore.borrowerFormStores.filter(
    borrowerFormStore => !!borrowerFormStore.form.fields.firstName.value
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchExistingEntityCard />
      </Grid>
      {borrowerFormStores.map(borrowerFormStore => (
        <Grid item xs={12}>
          <OneToolCardBorder>
            <BorrowerInformationAccordionSummary
              borrowerFormStore={borrowerFormStore}
            />
          </OneToolCardBorder>
        </Grid>
      ))}
    </Grid>
  );
});
