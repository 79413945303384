import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { RoundedBackgroundIcon } from '../../components/roundedBackgroundIcon';
import { FORM_MAX_WIDTH } from '../../utils/constants';

const useStyles = makeStyles(theme => ({
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#F1F2F3',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

interface OneToolCardProps {
  children?: ReactNode;
  disabled?: boolean;
  maxWidth?: any;
  icon?: ReactNode;
  title?: string;
  actions?: ReactNode;
}

export const OneToolCard = (props: OneToolCardProps) => {
  const { children, disabled, maxWidth, icon, title, actions } = props;
  const classes = useStyles();

  return (
    <OneToolCardBorder disabled={disabled}>
      <Box maxWidth={maxWidth} marginX={'auto'}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <OneToolCardHeader icon={icon} title={title} actions={actions} />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </OneToolCardBorder>
  );
};

export const OneToolCardBorder = ({
  children,
  disabled = false,
  className = undefined,
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.paper, disabled && classes.disabled, className)}
      elevation={0}
    >
      {children}
    </Paper>
  );
};

export const OneToolCardHeader = ({ icon, title, actions }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
      <Box display="flex" alignItems="center">
        <RoundedBackgroundIcon Icon={icon} iconColor="#5E5E5E" />
        <Typography
          variant="h4"
          style={{ color: 'black', paddingLeft: '16px', fontSize: '24px' }}
        >
          {title}
        </Typography>
      </Box>
      <Box>{actions}</Box>
    </Box>
  );
};
