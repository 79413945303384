import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  textStyle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20.02px',
    letterSpacing: '0.25px',
    color: '#333333',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  row: {
    border: '0.5px solid #E4E8EB',
    marginTop: '8px',
    padding: '8px',
  },
  arrowIcon: {
    cursor: 'pointer',
    marginRight: '8px',
    transition: 'transform 0.3s ease',
  },
  textRight: {
    textAlign: 'right',
    '& .MuiChip-root': {
      margin: '-15px 0',
      height: '30px',
    },
  },
  headerColumn: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  headerRow: {
    borderBottom: '2px solid #E4E8EB',
  },
});

type ExpandableTableProps = {
  title: string,
  data: any,
  hasTwoColumns: boolean,
  column1Title?: string,
  column2Title?: string,
}

const ExpandableTable = ({ title, data, hasTwoColumns, column1Title, column2Title }: ExpandableTableProps) => {
  const classes = useStyles();

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    setExpandedRows(prevState => {
      const state = {...prevState};
      (data || []).forEach((row,i) => {
        if(row.expanded){
          state[i] = true;
        }
      })
      return state;
    });
  },[data])

  const handleRowClick = (rowIndex: string) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  const renderSubrows = (subrows: any[], parentIndex: string) => {
    return subrows.map((subrow, subindex) => {
      const subrowIndex = `${parentIndex}-${subindex}`;

      return (
        <Grid container key={subrowIndex} spacing={2} className={`${classes.row}`}>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center", paddingLeft: 24 }}>
            {subrow.subrows && (
              <svg
                onClick={() => handleRowClick(subrowIndex)}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className={classes.arrowIcon}
                style={{
                  marginLeft: '-8px',
                  transform: expandedRows[subrowIndex]
                    ? "rotate(90deg)"
                    : "rotate(0)",
                }}
              >
                <g id="menu-right-outline">
                  <path
                    id="Vector"
                    d="M9 6H10.5L16.5 12L10.5 18H9V6ZM13.67 12L11 9.33V14.67L13.67 12Z"
                    fill={
                      expandedRows[subrowIndex] ? "#D9D9D9" : "#B1B1B1"
                    }
                  />
                </g>
              </svg>
            )}
            <Typography className={classes.textStyle}>{subrow.left}</Typography>
          </Grid>
          {hasTwoColumns && subrow.right && subrow.right.column1 && subrow.right.column2 && (
            <>
              <Grid item xs={3}>
                <Typography className={classes.textStyle}>{subrow.right.column1}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.textStyle}>{subrow.right.column2}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography className={`${classes.textStyle} ${classes.textRight}`}>
              {subrow.right && typeof subrow.right !== "object" ? subrow.right : ""}
            </Typography>
          </Grid>
          {/* Render nested subrows if expanded */}
          {expandedRows[subrowIndex] && subrow.subrows && renderSubrows(subrow.subrows, subrowIndex)}
        </Grid>
      );
    });
  };

  return (
    <Box style={{ marginLeft: '-24px', marginRight: '-24px' }}>
      <Grid container style={{ backgroundColor: "#E4E8EB", padding: "16px" }}>
        <Grid item xs={6}>
          <Typography
            className={classes.textStyle}
            style={{ color: "#6D6D6D" }}
          >
            {title}
          </Typography>
        </Grid>
        {hasTwoColumns && (
          <Grid item xs={6} spacing={2} style={{ display: 'contents' }} className={classes.headerRow}>
            <Grid item xs={3}>
              <Typography
                className={`${classes.textStyle} ${classes.headerColumn}`}
                style={{ color: "#6D6D6D", textAlign: 'left' }}
              >
                {column1Title}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={`${classes.textStyle} ${classes.headerColumn}`}
                style={{ color: "#6D6D6D", textAlign: 'left' }}
              >
                {column2Title}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <div style={{
        marginRight: 8,
        marginLeft: 8,
        marginTop: '-8px'
      }}>
        {data.map((row, index) => {
          const rowIndex = `${index}`;

          return (
            <Grid container key={rowIndex} spacing={2} className={classes.row}>
              <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                {row.subrows && (
                  <svg
                    onClick={() => handleRowClick(rowIndex)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className={classes.arrowIcon}
                    style={{
                      marginLeft: '-8px',
                      transform: expandedRows[rowIndex]
                        ? "rotate(90deg)"
                        : "rotate(0)",
                    }}
                  >
                    <g id="menu-right-outline">
                      <path
                        id="Vector"
                        d="M9 6H10.5L16.5 12L10.5 18H9V6ZM13.67 12L11 9.33V14.67L13.67 12Z"
                        fill={
                          expandedRows[rowIndex] ? "#D9D9D9" : "#B1B1B1"
                        }
                      />
                    </g>
                  </svg>
                )}
                <Typography className={classes.textStyle}>{row.left}</Typography>
              </Grid>
              {hasTwoColumns && row.right && row.right.column1 && row.right.column2 && (
                <>
                  <Grid item xs={3}>
                    <Typography className={classes.textStyle}>{row.right.column1}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.textStyle}>{row.right.column2}</Typography>
                  </Grid>
                </>
              )}
              {!hasTwoColumns && <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography className={`${classes.textStyle} ${classes.textRight}`}>
                  {row.right && (typeof row.right !== "object" || React.isValidElement(row.right)) ? row.right : ""}
                </Typography>
              </Grid>}
              {/* Render subrows when parent row is expanded */}
              {expandedRows[rowIndex] &&
                row.subrows &&
                renderSubrows(row.subrows, rowIndex)}
            </Grid>
          );
        })}
      </div>
    </Box>
  );
};

export default ExpandableTable;
