import { SelectField } from "@roc/ui";
import { ICellEditor, ICellEditorParams, ICellEditorComp, ColDef } from "ag-grid-community";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { useTaxCertificateStore } from "../hooks/useTaxCertificateStore";

const useStyles = makeStyles((theme: Theme) => ({
  selectField: {
    borderRadius: '0',
    '& .MuiSelect-root': {
      paddingTop: '11px',
      paddingBottom: '11px',
      fontSize: '14px',
    },
  },
}));

export const StatusEditorPaymentApprobal = forwardRef<ICellEditor, ICellEditorParams>((props, ref) => {
  const { value } = props;
  const { taxCertificateStore } = useTaxCertificateStore();
  const [editableValue, setEditableValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return editableValue;
    },
  }));

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <SelectField
      options={[
        { label: 'PE', value: 'PE' },
        { label: 'RJ', value: 'RJ' },
        { label: 'DP', value: 'DP' },
        { label: 'PY', value: 'PY' },
        { label: 'PP', value: 'PP' },
        { label: 'PC', value: 'PC' },
      ]}
      testId={'foundation'}
      value={editableValue}
      onChange={async (value) => {
        const success = await taxCertificateStore.updatePropertyTaxStatus(props?.data?.id, value);
        if (success) {
          setEditableValue(value);
        }
      }}
      fullWidth
      variant="outlined"
      className={classes.selectField}
    />
  );
});
