import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Work, WorkOutline } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { FORM_MAX_WIDTH, SelectEntityOption } from '../../../utils/constants';
import { OneToolCard } from '../../common/oneToolCard';

const useStyles = makeStyles(theme => ({
  button: {
    width: '358px',
    margin: '0 auto',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2),' +
      '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  },
}));

interface CardProps {
  option: SelectEntityOption;
  onSelect: (option: SelectEntityOption) => void;
}

export const SelectEntityOptionCard = observer(
  ({ option, onSelect }: CardProps) => {
    const classes = useStyles();
    return (
      <OneToolCard
        maxWidth={FORM_MAX_WIDTH}
        icon={WorkOutline}
        title={'Choose from the options below.'}
      >
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <Button
              testId="search-existing"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => onSelect(SelectEntityOption.SEARCH_ENTITY)}
            >
              Search an Existing Entity
            </Button>
          </Grid>
          <Grid item>
            <Button
              testId="search-existing"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => onSelect(SelectEntityOption.ADD_ENTITY)}
            >
              Add Entity
            </Button>
          </Grid>
          <Grid item>
            <Button
              testId="search-existing"
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => onSelect(SelectEntityOption.NO_ENTITY)}
            >
              I don't have an entity yet
            </Button>
          </Grid>
        </Grid>
      </OneToolCard>
    );
  }
);
