import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import {
  Box,
  Grid as MuiGrid,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
  SortModelItem,
  GridApi,
  IGetRowsParams,
  IDatasource,
  RowSelectedEvent,
} from 'ag-grid-community';
import {
  AgGridColumnGroupProps,
  AgGridColumnProps,
  AgGridReact,
} from 'ag-grid-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PaginationBar } from '../paginationBar/paginationBar';
import { CheckboxHeaderComponent } from './checkboxHeaderComponent';
import clsx from 'clsx';

export enum SelectMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum SelectSide {
  LEFT = 'left',
  RIGHT = 'right',
}

const getCheckBoxColumn = (enableHeaderSelect, pinned) => {
  return {
    headerComponent: enableHeaderSelect ? CheckboxHeaderComponent : undefined,
    checkboxSelection: true,
    maxWidth: 50,
    minWidth: 50,
    pinned: pinned,

  }
}

export type GridColumnProps = AgGridColumnProps | AgGridColumnGroupProps;

export type GridRowSelectedEvent = RowSelectedEvent;

export type GridProps = {
  toolbar?: ReactNode;
  columns: GridColumnProps[];
  rows: any[];
  frameworkComponents?: any;
  onSortChanged?: (params: any) => void;
  onFilterChanged?: (params: any) => void;
  isLoading?: boolean;
  isError?: boolean;
  selectMode?: SelectMode;
  onRowSelected?: (event: GridRowSelectedEvent) => void;
  domLayout?: string;
  className?: string;
  rowHeight?: number;
  showFilters?: boolean;
  sortModel?: object;
  filterModel?: object;
  paginationData?: any;
  setPageNumber?: (page: number) => void;
  datasource?: IDatasource;
  showPagination?: boolean;
  paginationBarClassName?: string;
  selectSide?: SelectSide;
  suppressRowClickSelection?: boolean;
  getRowClass?: (params: any) => string;
  pinnedBottomRowData?: any[];
  headerCheckboxSelection?: boolean;
  gridReady?: (params: any) => void;
  pinnedTopRowData?: any[];
  checkBoxColumnPinned?: any;
  enablePaginationSelectPage?: boolean;
  rowModelType?: string;
  rowData?: any;
};

export type Sort = SortModelItem;

const useGridStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& .ag-root-wrapper': {
      borderColor: '#ddd',
      '& .ag-header-row': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: '500',
        fontFamily: theme.typography.h1.fontFamily ?? 'inherit',
      },
      '& .ag-tooltip': {
        whiteSpace: 'pre',
        border: 0,
        backgroundColor: 'rgba(97, 97, 97, 0.9)',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
        padding: '4px 8px',
        fontSize: '14px',
        lineHeight: `1.4em`,
        wordWrap: 'break-word',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& .grid-header-tooltip-icon': {
        width: '15px',
        height: '15px',
        lineHeight: '15px',
        borderRadius: '9px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        marginLeft: theme.spacing(0.5),
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: '400',
      },
      '& .ag-cell-value': {
        display: 'block',
        width: '100%',
      },
    },
  },
  loadingOverlay: {
    padding: theme.spacing(1),
    border: '2px solid #444',
    background: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    position: 'relative',
    top: '33px', // to align with custom pagination bar
  },
}));

const useAnchorStyles = makeStyles((theme: Theme) => ({
  container: {
    color: theme.palette.primary.dark,
  },
  anchor: {
    color: theme.palette.primary.dark,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const useActionStyles = makeStyles((theme: Theme) => ({
  actionButton: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

export const LinkColumn = ({ value, url, onClick }) => {
  const { palette } = useTheme();
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Link style={{ color: palette.primary.dark }} to={url} onClick={onClick}>
        <strong>{value}</strong>
      </Link>
    </div>
  );
};

export const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const DollarColumn = (value) => {
  const { palette } = useTheme();
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <span>{(value != null && value != 0) ? `$${numberWithCommas(value)}` : '--'}</span>
    </div>
  );
};

export const TextColumn = params => {
  const { palette } = useTheme();
  return (
    <Box component="span" style={{ color: palette.primary.dark }}>
      <strong>{params.value}</strong>
    </Box>
  );
};

export const TextColumnWithOnClick = params => {
  const { palette } = useTheme();
  return (
    <Box component="span" style={{ color: palette.primary.dark, cursor: 'pointer' }}>
      <strong onClick={params.onClick}>{params.value}</strong>
    </Box>
  );
};

export const AnchorColumn = params => {
  const styles = useAnchorStyles();
  return (
    <Box component="span" className={styles.container}>
      <a className={styles.anchor} href={params.href}>
        {params.value}
      </a>
    </Box>
  );
};

export const ActionsColumn = params => {
  const styles = useActionStyles();
  return (
    <Box>
      {params.actions.map((action, index) => (
        <IconButton
          key={index}
          onClick={action.onClick}
          className={styles.actionButton}
        >
          {action.icon}
        </IconButton>
      ))}
    </Box>
  );
};

export const tooltipHeaderTemplate = `
  <div class="ag-cell-label-container" role="presentation">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>
      <div ref="eLabel" class="ag-header-cell-label" role="presentation">
          <span ref="eText" class="ag-header-cell-text"></span>
          <span class="grid-header-tooltip-icon">?</span>
          <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
          <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
          <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
          <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
          <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
      </div>
  </div>
`;
interface GridDatasource extends IDatasource {
  rows: any[];
  setRows(rows: any[], gridApi: any): void;
}

class Datasource implements GridDatasource {
  rows: any[];

  setRows(rows, gridApi) {
    if (gridApi) {
      this.rows = rows;
      gridApi.refreshInfiniteCache();
    }
  }

  getRows(params: IGetRowsParams) {
    if (this.rows?.length > 0) {
      params.successCallback([...this.rows], this.rows.length);
    } else {
      params.successCallback([], 0);
    }
  }
}

const arrayEquals = (arrA: unknown[], arrB: unknown[]) => {
  return (
    Array.isArray(arrA) &&
    Array.isArray(arrB) &&
    arrA.length === arrB.length &&
    arrA.every((val, i) => val === arrB[i])
  );
};

const isFilterModelEqual = (modelA, modelB) => {
  if (!modelA || !modelB) {
    return modelA === modelB;
  }

  const keysA = Object.keys(modelA);
  const keysB = Object.keys(modelB);
  const keys = new Set(keysA.concat(keysB));

  return [...keys].every(
    key =>
      modelA[key]?.filter === modelB[key]?.filter &&
      modelA[key]?.dateFrom === modelB[key]?.dateFrom &&
      (modelA[key]?.selectedOptions === modelB[key]?.selectedOptions ||
        arrayEquals(modelA[key]?.selectedOptions, modelB[key]?.selectedOptions))
  );
};

export const DataGrid = ({
  toolbar,
  columns,
  rows,
  frameworkComponents,
  onSortChanged,
  onFilterChanged,
  isLoading,
  isError,
  selectMode,
  onRowSelected,
  domLayout = 'normal',
  className,
  rowHeight = 46,
  showFilters,
  sortModel,
  filterModel,
  paginationData,
  getRowClass,
  setPageNumber,
  datasource,
  showPagination = true,
  paginationBarClassName,
  selectSide = SelectSide.LEFT,
  suppressRowClickSelection = false,
  pinnedTopRowData,
  pinnedBottomRowData,
  headerCheckboxSelection = false,
  checkBoxColumnPinned = false,
  gridReady,
  enablePaginationSelectPage,
  rowModelType = 'infinite',
  rowData
}: GridProps) => {
  const classes = useGridStyles();
  const [gridApi, setGridApi] = useState<GridApi>(null);
  const [showNoRecordsOverlayTimer, setShowNoRecordsOverlayTimer] = useState<any>(null);
  const [overlayTemplate, setOverlayTemplate] = useState<string>('');
  const noRowsTemplate = `<span className="ag-overlay-loading-center">No Records.<span>`;
  const errorRowsTemplate = `<span className="ag-overlay-loading-center" style="color: red;">Oops, Something went wrong. Please try again later.<span>`;

  const _showPagination = !!(showPagination && paginationData && setPageNumber);

  const onGridReady = params => {
    setGridApi(params.api);
    if (gridReady) {
      gridReady(params);
    }
  };

  const toggleFilters = (columns, filtersEnabled) => {
    if (!filtersEnabled) {
      const disableFilter = column => ({ ...column, floatingFilter: false });
      columns = columns.map(disableFilter);
      if (columns.children) {
        columns.children = columns.children.map(disableFilter);
      }
    }
    return columns;
  };

  const getColumns = () => {
    const cols = toggleFilters(columns, showFilters);
    const checkboxCol = getCheckBoxColumn(headerCheckboxSelection, checkBoxColumnPinned);
    if (selectSide === SelectSide.LEFT) {
      return selectMode ? [checkboxCol, ...cols] : cols;
    } else {
      return selectMode ? [...cols, checkboxCol] : cols;
    }

  };

  const columnDefs = useMemo(getColumns, [selectMode, columns, showFilters]);

  const _datasource = useMemo(() => new Datasource(), []);

  useEffect(() => {
    if (datasource) return;
    _datasource.setRows(rows, gridApi);
  }, [datasource, rows, gridApi, _datasource]);

  useEffect(() => {
    if (datasource && gridApi) gridApi.refreshInfiniteCache();
  }, [datasource]);

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        clearTimeout(showNoRecordsOverlayTimer);
        gridApi.showLoadingOverlay();
      } else if (isError) {
        setOverlayTemplate(errorRowsTemplate);
        const timeoutId = setTimeout(() => {
          gridApi.showNoRowsOverlay();
        }, 100)
        setShowNoRecordsOverlayTimer(timeoutId);
      } else if (rows?.length == 0) {
        setOverlayTemplate(noRowsTemplate);
        const timeoutId = setTimeout(() => {
          gridApi.showNoRowsOverlay();
        }, 100)
        setShowNoRecordsOverlayTimer(timeoutId);
      } else {
        clearTimeout(showNoRecordsOverlayTimer);
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isLoading, rows.length, isError]);


  useEffect(() => {
    if (gridApi && sortModel !== undefined) {
      const currentSortModel = gridApi.getSortModel();
      if (!isSortModelEqual(currentSortModel, sortModel)) {
        gridApi.setSortModel(sortModel);
      }
    }
  }, [gridApi, sortModel]);

  useEffect(() => {
    if (gridApi && filterModel !== undefined) {
      const currentFilterModel = gridApi.getFilterModel();
      if (!isFilterModelEqual(currentFilterModel, filterModel)) {
        gridApi.setFilterModel(filterModel);
      }
    }
  }, [gridApi, filterModel]);

  const updateSelected = () => {
    if (!gridApi || !selectMode) return;
    gridApi.forEachLeafNode(node => {
      node.setSelected(node.data.isSelected);
    });
  };

  const isSortModelEqual = (modelA, modelB) => {
    if (!modelA || !modelB) {
      return modelA === modelB;
    }

    return (
      modelA?.length === modelB?.length &&
      modelA.every(
        (item, i) =>
          item?.colId === modelB[i]?.colId && item?.sort === modelB[i]?.sort
      )
    );
  };

  const handleSortChanged = params => {
    const newSortModel = params.api.getSortModel();
    if (!isSortModelEqual(sortModel, newSortModel)) {
      onSortChanged(params);
    }
  };

  const handleFilterChanged = params => {
    const newFilterModel = params.api.getFilterModel();
    if (!isFilterModelEqual(filterModel, newFilterModel)) {
      onFilterChanged(params);
    }
  };

  const onComponentStateChanged = () => {
    updateSelected();
  };

  return (
    <>
      <MuiGrid container justifyContent="center" style={{ flexGrow: 1 }}>
        <MuiGrid
          item
          xs={12}
          className={`ag-theme-alpine ${classes.grid} ${className}`}
        >
          {toolbar}
          <AgGridReact
            containerStyle={{
              height: domLayout == 'normal' ? '100%' : 'auto'
            }}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              sortable: true,
              resizable: true,
              suppressMenu: true,
            }}
            rowSelection={selectMode}
            suppressRowClickSelection={suppressRowClickSelection}
            onRowSelected={onRowSelected}
            columnDefs={columnDefs}
            rowModelType={rowModelType}
            datasource={datasource ?? _datasource}
            rowData={rowData}
            onSortChanged={handleSortChanged}
            onFilterChanged={handleFilterChanged}
            frameworkComponents={frameworkComponents}
            tooltipShowDelay={400}
            domLayout={domLayout}
            rowHeight={rowHeight}
            overlayLoadingTemplate={`<span class='${classes.loadingOverlay}'>Loading...</span>`}
            overlayNoRowsTemplate={overlayTemplate}
            onGridReady={onGridReady}
            onComponentStateChanged={onComponentStateChanged}
            cacheBlockSize={paginationData?.pageSize}
            enableCellTextSelection={true}
            getRowClass={getRowClass}
            pinnedBottomRowData={pinnedBottomRowData}
            pinnedTopRowData={pinnedTopRowData}
          />
          {_showPagination && (
            <PaginationBar
              {...paginationData}
              className={clsx('pagination-bar', paginationBarClassName)}
              onChange={(e, v) => setPageNumber(v)}
              enablePaginationSelectPage={enablePaginationSelectPage}
            />
          )}
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
