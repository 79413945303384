import { GridColumnProps } from "@roc/ui";
import { TaxTabs } from "../types/types";
import { PAYMENT_APPROVAL_COLUMNS, TAX_CERT_COLUMNS } from "./constants";

export const getTaxColumns = (tab: TaxTabs): GridColumnProps[] => {
  switch (tab) {
    case TaxTabs.TAX_CERTIFICATE:
    case TaxTabs.REDEMPTION_REQUEST:
      return TAX_CERT_COLUMNS;
    case TaxTabs.PAYMENT_APPROVAL:
      return PAYMENT_APPROVAL_COLUMNS;
    default:
      return TAX_CERT_COLUMNS;
  }
}
