import { Alert } from '@material-ui/lab';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';

export const NoEntityDialog = observer(({ open, onCancel, onContinue }) => {
  const dialogContent = (
    <Alert severity="info" style={{ width: '100%' }}>
      Adding an entity now avoids further delays down the line.
    </Alert>
  );

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        onClick={() => onCancel()}
        color="primary"
        variant="outlined"
        testId="cancel"
      >
        Cancel
      </Button>
      <Button
        onClick={() => onContinue()}
        color="primary"
        variant="contained"
        testId="continue"
      >
        Continue Without
      </Button>
    </>
  );

  return (
    <StandardDialog
      title="Make your processing more efficient"
      open={open}
      onClose={onCancel}
      handleClose={onCancel}
      maxWidth={'sm'}
      dialogContent={dialogContent}
      dialogActions={dialogActions}
      removeContentBox
    />
  );
});
