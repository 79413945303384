import { ApiResponse, GlobalStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE } from '@roc/feature-utils';
import { InsuranceInformationReviewInternalService } from '../services/insuranceInformationReviewInternalService';
import { InsuranceInformationReviewBaseStore } from './insuranceInformationReviewBaseStore';
import { InsuranceInformationBaseStore } from './insuranceInformationBaseStore';

export class InsuranceInformationReviewInternalStore extends InsuranceInformationReviewBaseStore {
  insuranceInformationReviewService: InsuranceInformationReviewInternalService;

  constructor(
    globalStore: GlobalStore,
    insuranceInformationStore: InsuranceInformationBaseStore
  ) {
    super(globalStore, insuranceInformationStore);
    this.insuranceInformationReviewService = new InsuranceInformationReviewInternalService();
  }

  *getInsuranceQuotes(loanId) {
    try {
      const response: ApiResponse = yield this.insuranceInformationReviewService.getInsuranceQuotes(
        loanId
      );
      const quotes = response?.data ?? [];
      this.quotes = quotes.filter(quote => !quote?.quoteShowId?.includes('Elmsure'));
      this.quotesOptions = this.quotes.map(quote => ({
        label: quote.quoteShowId,
        value: quote.insuranceQuoteDetailId,
      }));
      if (this.quotes.length) {
        this.setSelectedQuote(this.quotesOptions[0].value);
      }
    } catch (error) {
      console.log('Error occurred on getInsuranceQuotes', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *getInsuranceReviewData(quoteId: string) {
    try {
      this.insuranceReviewData = [];
      this.insuranceQuoteDataId = null;
      const response: ApiResponse = yield this.insuranceInformationReviewService.getInsuranceReviewData(
        quoteId
      );
      const data = response?.data;
      this.insuranceReviewData = this.normalizeAutomatedReviewItems(data.sectionData);
      this.insuranceQuoteDataId = data.insuranceQuoteDataId;
    } catch (error) {
      console.log('Error occurred while getting the data', error);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *saveData() {
    try {
      yield this.insuranceInformationReviewService.saveData(
        this.insuranceQuoteDataId,
        this.insuranceReviewData
      );
    } catch (e) {
      console.error('Error on saveData', e);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *changeQuoteStatus(quoteId: any, status: string) {
    try {
      this.insuranceInformationReviewService.changeQuoteStatus(quoteId, status);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *createResolutions(data: any) {
    yield this.insuranceInformationReviewService.createResolutions(data);
  }
}
