import { Service } from '@roc/feature-app-core';

const url = {
  GET_TAX_CERTIFICATE_DASHBOARD: '/api/v1/loan/loanPropertyTax/getTaxDashboard',
  CREATE_TAX_CERTIFICATE: '/api/v1/vendor/lereta/order/createOrder',
  CREATE_REDEMPTION_REQUEST: '/api/v1/vendor/lereta/order/createRedemptionRequest',
  GET_TAX_PAYMENT_DASHBOARD: '/api/v1/loan/loanPropertyTax/getTaxPaymentDashboard',
  UPDATE_PROPERTY_TAX_STATUS: '/api/v1/loan/loanPropertyTax/updatePropertyTaxStatus',
  UPLOAD_PAYMENT_ZIP: '/api/v1/vendor/lereta/order/uploadPaymentZip',
  SEND_PAYMENT_APPROVAL: '/api/v1/vendor/lereta/order/sendPaymentApproval',
};

export class TaxService extends Service {

  getTaxCertificateDashboard(
    param: string,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_TAX_CERTIFICATE_DASHBOARD,
      {
        param,
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  createTaxCertificate(loanId) {
    return this.post(
      `${url.CREATE_TAX_CERTIFICATE}?loanId=${loanId}`, null,
    );
  }

  createRedemptionRequest(loanIds) {
    return this.post(
      `${url.CREATE_REDEMPTION_REQUEST}`, loanIds,
    );
  }

  getTaxPaymentDashboard(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      url.GET_TAX_PAYMENT_DASHBOARD,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  updatePropertyTaxStatus(recordId, status) {
    return this.post(
      `${url.UPDATE_PROPERTY_TAX_STATUS}?recordId=${recordId}&status=${status}`, null
    );
  }

  uploadPaymentZip(
    file: File,
  ) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(url.UPLOAD_PAYMENT_ZIP, formData);
  };

  sendPaymentApproval() {
    return this.post(
      `${url.SEND_PAYMENT_APPROVAL}`, null,
    );
  }

}
