import { Box, Button, Typography } from '@material-ui/core';
import { Add, Close, Edit, MoreVert, Send, Link } from '@material-ui/icons';
import {
  DataGrid as Grid,
  SelectFilterComponent,
  SelectFloatingFilterComponent,
  AgGridColumnProps,
  IconButton,
  BorrowerBaseballCard,
  StandardDialog,
  ConfirmationMenuItem,
  StatusChip,
  StatusType,
  MenuItem,
  AlertDialog,
  DialogState,
} from '@roc/ui';
import { formatPhoneNumber } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import React, { ReactNode, useState } from 'react';
import { useBorrowerBaseballCardStore } from '../hooks/useBorrowerBaseballCardStore';
import { useBorrowersStore } from '../hooks/useBorrowersStore';
import { useBaseStore, useUserStore } from '@roc/feature-app-core';
import { insertIf, userPortalStatusProperties } from '@roc/feature-utils';
import { Menu, ListItemIcon, ListItemText } from '@material-ui/core';
import AddBorrowerWithPrequalificationDialog from './addBorrowerWithPrequalificationDialog';
import BorrowerPreScreenDialog from '../borrowerPreScreen/borrowerPreScreenDialog';
import { LoanType } from '@roc/feature-utils';
import { BorrowerSetupToDoDialog } from '../../borrowerSetupToDo';

const noOpComparator = () => 0;

const { NO_ACCOUNT, INVITATION_EMAIL_SENT, ACCOUNT_CREATED, DEACTIVATED_ACCOUNT } = userPortalStatusProperties;

export const BorrowersGrid = observer(({ toolbar }: { toolbar: ReactNode }) => {
  const { borrowersStore } = useBorrowersStore();
  const { currentBorrower, borrowerSetupStore } = borrowersStore;
  const { userStore } = useUserStore();
  const { borrowerBaseballCardStore } = useBorrowerBaseballCardStore();
  const { globalStore } = useBaseStore();

  const columns: AgGridColumnProps[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      cellRenderer: 'borrowerNameRenderer',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 100,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'emailAddress',
      headerName: 'Email',
      minWidth: 250,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'cellPhone',
      headerName: 'Cell Phone',
      minWidth: 150,
      cellRenderer: 'phoneCellRenderer',
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 300,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    {
      field: 'exposureMonitoring',
      headerName: 'Exposure Monitoring',
      minWidth: 200,
      comparator: noOpComparator,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    ...insertIf(globalStore.lenderInfo?.enableBorrowerPortal, [
      {
        field: 'portalStatus',
        headerName: 'Borrower Portal Status',
        minWidth: 300,
        cellRenderer: 'portalStatusCellRenderer',
        comparator: noOpComparator,
        filter: 'selectFilter',
        floatingFilter: true,
        floatingFilterComponent: 'selectFloatingFilter',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          options: [
            { label: NO_ACCOUNT.name, value: NO_ACCOUNT.value },
            { label: INVITATION_EMAIL_SENT.name, value: INVITATION_EMAIL_SENT.value },
            { label: ACCOUNT_CREATED.name, value: ACCOUNT_CREATED.value },
            { label: DEACTIVATED_ACCOUNT.name, value: DEACTIVATED_ACCOUNT.value },
          ],
        },
      }
    ]),
    {
      field: 'emailAddress',
      headerName: 'Action',
      minWidth: 125,
      cellRenderer: 'actionCellRenderer',
      comparator: noOpComparator,
    },
  ];

  const [isBorrowerBaseballCardOpen, setIsBorrowerBaseballCardOpen] = useState(
    false
  );
  const [isBorrowerPreScreenOpen, setIsBorrowerPreScreenOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [contactId, setContactId] = useState('');
  const [userId, setUserId] = useState('');
  const [borrowerEmail, setBorrowerEmail] = useState('');
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showDeactivateAccount, setShowDeactivateAccount] = useState(false);
  const [showResendInvite, setShowResendInvite] = useState(false);
  const [showMenuItems, setShowMenuItems] = useState(false);
  const menuOpen = Boolean(anchorEl);

  const frameworkComponents = {
    selectFilter: SelectFilterComponent,
    selectFloatingFilter: SelectFloatingFilterComponent,
    borrowerNameRenderer: params => {
      const borrowerName = params.value;
      return (
        <div
          onClick={() => openBorrowerPreScreen(params.node.data)}
          style={{ textDecoration: 'underline', color: '#5c94de', cursor: 'pointer' }}>
          {borrowerName}
        </div>
      );
    },
    phoneCellRenderer: ({ value }) => {
      const phoneNumber = formatPhoneNumber(value);
      return (
        <a style={{ textDecoration: 'underline', color: '#5c94de', cursor: 'pointer' }} href={`rcapp://r/call?number=${value}`} target="_blank">
          {phoneNumber}
        </a>
      );
    },
    portalStatusCellRenderer: ({ value }) =>
      value ? (
        <StatusChip statusType={StatusType.USER_PORTAL_STATUS} label={value} size="small" />
      ) : null,
    actionCellRenderer: params => {
      return (
        <Box>
          <IconButton
            testId={`edit-borrower-${params.node.data?.borrowerId}`}
            onClick={() => { openBorrowerPreScreen(params.node.data); }}
          >
            <Edit color="primary" />
          </IconButton>
          {((globalStore.lenderInfo?.enableBorrowerPortal && globalStore.userFeatures?.allowManageBorrowerAccounts)) &&
            <IconButton
              testId={'manage-borrowers'}
              onClick={e => openMenu(e, params.node.data)}>
              <MoreVert color="primary" />
            </IconButton>
          }
        </Box>
      );
    },
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, record) => {
    const { portalStatus, emailAddress, contactId, userId } = record;
    setContactId(contactId);
    setUserId(userId);
    setBorrowerEmail(emailAddress);
    setShowCreateAccount(portalStatus === NO_ACCOUNT.value);
    setShowDeactivateAccount(portalStatus === ACCOUNT_CREATED.value);
    setShowResendInvite(portalStatus === INVITATION_EMAIL_SENT.value);
    setShowMenuItems(portalStatus !== DEACTIVATED_ACCOUNT.value)
    setAnchorEl(event.currentTarget);
    borrowersStore.setCurrentBorrower(record);
  };

  const openBorrowerPreScreen = borrower => {
    borrowersStore.setCurrentBorrower(borrower);
    borrowersStore.setPreScreenTasks([]);
    setIsBorrowerPreScreenOpen(true);
  }

  const closeBorrowerBaseballCard = () => {
    setIsBorrowerBaseballCardOpen(false);
    borrowerBaseballCardStore.verifiedTrackRecord = [];
    borrowerBaseballCardStore.unverifiedTrackRecord = [];
    borrowerBaseballCardStore.publicTrackRecord = [];
    borrowerBaseballCardStore.currentBorrower = null;
  };

  const closeBorrowerPreScreen = () => {
    borrowersStore.setCurrentBorrower(null);
    setIsBorrowerPreScreenOpen(false);
  }

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    borrowersStore.gridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    borrowersStore.gridStore.setFilterModel(filterModel);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    borrowersStore.setCurrentBorrower(null);
  };

  const getBorrowerLoginLink = () => {
    const response = Promise.resolve(borrowersStore.getBorrowerLoginLink(userId));
    response.then((value) => {
      navigator.clipboard.writeText(value.toString());
    });
  }

  const handleClose = () => {
    borrowersStore.setRequestSuccess(false);
  };

  const companyId = () => {
    const companyIdValue = userStore.userInformation.companyId ?? globalStore?.selectedCompanyId;
    return companyIdValue;
  };

  const closeQualifyBorrowerModal = async () => {
    borrowersStore.handleCloseBorrower();
  }

  return (
    <>
      <Grid
        toolbar={toolbar}
        columns={columns}
        rows={borrowersStore.gridStore.gridData.data.rows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        isLoading={borrowersStore.gridStore.isLoading}
        showFilters={borrowersStore.gridStore.showFilters}
        sortModel={borrowersStore.gridStore.sortModel}
        filterModel={borrowersStore.gridStore.filterModel}
        paginationData={borrowersStore.gridStore.gridData.meta}
        setPageNumber={borrowersStore.gridStore.setPageNumber}
        domLayout="autoHeight"
      />
      <StandardDialog
        open={isBorrowerBaseballCardOpen}
        maxWidth="xl"
        handleClose={closeBorrowerBaseballCard}
        onClose={closeBorrowerBaseballCard}
        dialogContent={
          <>
            <BorrowerBaseballCard store={borrowerBaseballCardStore} />
            <Box flex="1" display="flex" justifyContent="flex-end">
              <Button variant='outlined' color='primary' onClick={closeBorrowerBaseballCard}>
                Close
              </Button>
            </Box>
          </>
        }
      />
      <AddBorrowerWithPrequalificationDialog
        handleClose={closeQualifyBorrowerModal}
        open={borrowersStore.isNewBorrowerOpen}
        borrower={currentBorrower}
        handleSaveBorrower={(onSuccess) => { borrowersStore.saveNewBorrower(onSuccess) }}
        title={borrowersStore.dialogState === DialogState.ADD ? 'Add Borrower' : 'Borrower Setup'}
        borrowerSetupStore={borrowerSetupStore}
        loanType={LoanType.RESIDENTIAL_BRIDGE}
      />
      <BorrowerPreScreenDialog
        handleClose={closeBorrowerPreScreen}
        open={isBorrowerPreScreenOpen}
        borrower={currentBorrower}
        companyId={companyId()}
      />
      <BorrowerSetupToDoDialog
        open={borrowersStore.isBorrowerSetupOpen}
        borrowerId={currentBorrower?.borrowerId}
        handleClose={() => borrowersStore.setIsBorrowerSetupOpen(false)}
      />
      {showMenuItems &&
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
          transformOrigin={{ vertical: 'top', horizontal: 'left', }}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          {globalStore.lenderInfo?.enableBorrowerPortal && globalStore.userFeatures?.allowManageBorrowerAccounts && (
            <>
              {showCreateAccount && (
                <ConfirmationMenuItem
                  testId={`createAccount`}
                  onClick={() => {
                    borrowersStore.createBorrowerUserAccount(contactId, companyId());
                    handleMenuClose();
                  }}
                  confirmDialogProps={{
                    body: `Are you sure want to create a borrower portal account for ${borrowerEmail}?`,
                  }}
                >
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Create Account</ListItemText>
                </ConfirmationMenuItem>
              )}
              {showDeactivateAccount && (
                <ConfirmationMenuItem
                  testId={`deactivateAccount`}
                  onClick={() => {
                    borrowersStore.deactivateUser(userId, userStore.userInformation?.username);
                    handleMenuClose();
                  }}
                  confirmDialogProps={{
                    body: `Are you sure want to deactivate ${borrowerEmail}?`,
                  }}
                >
                  <ListItemIcon>
                    <Close fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Deactivate Account</ListItemText>
                </ConfirmationMenuItem>
              )}
              {showResendInvite && (
                <ConfirmationMenuItem
                  testId={`resendInvite`}
                  onClick={() => {
                    borrowersStore.resendInvite(userId);
                    handleMenuClose();
                  }}
                  confirmDialogProps={{
                    body: `Are you sure want to resend the invite for ${borrowerEmail}?`,
                  }}
                >
                  <ListItemIcon>
                    <Send fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Resend Invite</ListItemText>
                </ConfirmationMenuItem>
              )}
              {showResendInvite && (
                <MenuItem
                  testId={`getBorrowerLoginLink`}
                  onClick={() => { getBorrowerLoginLink() }}
                >
                  <ListItemIcon>
                    <Link fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Copy Invitation Link</ListItemText>
                </MenuItem>
              )}
            </>
          )}
        </Menu>
      }
      <AlertDialog
        open={borrowersStore.requestSuccess}
        body={
          <>
            <Typography>
              An invitation has been sent to <strong> {borrowerEmail} </strong> to create the account.
            </Typography>
          </>
        }
        handleClose={handleClose}
      />
    </>
  );
});
