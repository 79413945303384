import { Grid, makeStyles } from '@material-ui/core';
import { isNotBlank } from '@roc/feature-utils';
import {
  AddressDetailsField,
  createDateField,
  createNumberFormatField,
  createSelectField,
} from '@roc/ui';
import { QuoteTypeStore } from 'libs/feature-one-tool/src/quote/stores/quoteTypeStore';
import { observer } from 'mobx-react';
import { createTextField } from '../../../components/temp/componentBuiderTemp';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';

const entityTypes = [
  { value: 'Corporation', label: 'Corporation' },
  { value: 'Limited Liability Company', label: 'Limited Liability Company' },
];

export const EntityInformationForm = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const { entityStore } = quoteTypeStore;
  const { entityFormStore } = entityStore;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        {createTextField({
          label: 'Entity Name',
          fieldName: 'entityName',
          testId: 'entityName',
          store: entityFormStore,
          standaloneLabel: false,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createNumberFormatField({
          label: 'Entity EIN',
          fieldName: 'entityEin',
          testId: 'entityEin',
          store: entityFormStore,
          format: '##-#######',
          standaloneLabel: false,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createSelectField({
          label: 'Entity Type',
          fieldName: 'entityType',
          testId: 'entityType',
          options: entityTypes,
          store: entityFormStore,
          standaloneLabel: false,
        })}
      </Grid>
      <Grid item xs={12} sm={6}>
        {createDateField({
          label: 'Date of Incorporation',
          fieldName: 'dateOfIncorporation',
          testId: 'dateOfIncorporation',
          store: entityFormStore,
          standaloneLabel: false,
          disableFuture: true,
        })}
      </Grid>
      <Grid item xs={12}>
        <AddressDetailsField
          name={'address'}
          value={entityFormStore.form.fields.address.value}
          onChange={value => entityFormStore.onFieldChange('address', value)}
          required
          standaloneLabel={false}
          error={
            !entityFormStore.form.meta.isValid &&
            isNotBlank(entityFormStore.form.fields.address.error)
          }
          helperText={
            !entityFormStore.form.meta.isValid &&
            entityFormStore.form.fields.address.error
          }
        />
      </Grid>
    </Grid>
  );
});
