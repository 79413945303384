import { Service } from '@roc/feature-app-core';
import { isMobileApp, isNil } from '@roc/feature-utils';

const url = {
  LOANS: '/api/v1/loan/getLoans',
  QUOTE_LOANS: '/api/v1/loan/getLoanQuotes',
  FUNDEDLOANS: '/api/v1/loan/getFundedLoans',
  FUNDEDLOANS_FOR_DRAWS: '/api/v1/loan/getFundedLoansForDrawRequest',
  GET_FUNDED_LOANS_FOR_PAYOFF_REQUEST: '/api/v1/loan/getFundedLoansForPayoffRequest',
  PIPELINE_LOANS: '/api/v1/loan/getPipelineLoans',
  APPRAISAL_TO_DOS: '/api/v1/loan/getMyPendingAppraisalTasks',
  PENDING_TASKS: '/api/v1/loan/getPendingTasks',
  CONDITIONAL_APPROVED_LOANS: '/api/v1/loan/getConditionallyApprovedLoans',
  DRAFT_LOANS: '/api/v1/draftloan/getDraftLoans',
  BACKUP_LOANS: '/api/v1/loan/getMyBackUpLoans',
  AGREED_LOANS: '/api/v1/loan/getMyAgreedLoans',
  LOAN_DETAILS: '/api/v1/loan/getLoan',
  LOAN_SUMMARY: '/api/v1/loan/getLoanSummary',
  SAVE_DRAFT_LOAN: '/api/v1/draftloan/saveDraftLoan',
  PREVIOUS_DRAWS: '/api/v1/loan/getTransactions',
  STATE_COUNTIES: '/api/v1/pricer/stateCounties',
  STATE_COUNTIES_PUBLIC: '/api/v1/pricer/public/stateCounties',
  SUBMIT_BRIDGE_LOAN: '/api/v1/loan/submitBridgeLoan',
  SUBMIT_TERM_LOAN: '/api/v1/loan/submitTermLoan',
  SUBMIT_BRIDGE_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR: '/api/v1/loan/loanApplications/submitLoanApplicationForBrokerOrLenderOriginator',
  SUBMIT_SPECIALIZED_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR: '/api/v1/loan/loanApplications/submitSpecializedLoanApplicationForBrokerOrLenderOriginator',
  SUBMIT_TERM_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR: '/api/v1/loan/loanApplications/submitTermLoanApplicationForBrokerOrLenderOriginator',
  WIMBA_DETAILS: '',
  LOAN_PRODUCTS: '',
  LENDER_ATTORNEYS: '/api/v1/loan/getAllLenderAttorneys',
  WIMBA_STATES: '/api/v1/loan/getWimbaStates',
  TOTAL_UNREAD_MESSAGES: '/api/v1/loan/getTotalUnreadMessages',
  GET_INTERNAL_INSURANCE_REVIEWERS: '/api/v1/loan/getInternalInsuranceReviewers',
  SET_LOAN_PRIORITY: '/api/v1/loan/setLoanPriority',
  CHANGE_LOAN_STATUS: '/api/v1/loan/changeLoanStatus',
  CHANGE_ASSIGNEE: '/api/v1/loan/changeAssignee',
  DELETE_DRAFT_LOAN: '/api/v1/draftloan/deleteDraftLoan',
  GET_DRAFT_LOAN: '/api/v1/draftloan/getDraftLoan',
  CALENDLY_PREFERENCES: '/api/v1/loan/calendly/getCalendlyPreferences',
  SAVE_BORROWERS: '/api/v1/loan/saveBorrowers',
  SAVE_LENDER_ORIGINATOR: '/api/v1/loan/saveLenderPercentage',
  CLOSING_STATUS: '/api/v1/loan/getLoanClosingStatus',
  RESEND_QUOTE: '/api/v1/loan/resendQuote',
  REQUEST_ELMSURE_QUOTE: '/api/v1/loan/requestElmsureQuote',
  SEND_ERROR_EMAIL: '/api/v1/user/sendErrorEmail',
  LOGGING: '/api/v1/loan/logging/log',
  LOAN_ORIGINATORS: '/api/v1/loan/lenderOriginator/getLenderOriginators',
  RENTAL_DEAL_NAMES: '/api/v1/loan/getRentalDealNames',
  UPLOAD_DOCUMENT: '/api/v1/document/upload',
  FETCH_DRAFT_LOAN_ID: '/api/v1/loan/fetchDraftLoanIdForFastrackPayment',
  UPDATE_LOAN_FAST_TRACK_PAYMENTS: '/api/v1/document/formDocument/updateLoanFastrackInfoState',
  RELATIONSHIP_MANAGERS: '/api/v1/loan/getRelationshipManagers',
  VALIDATE_BRIDGE_LOAN: '/api/v1/validation/validateBridgeLoan',
  VALIDATE_BRIDGE_LOAN_STEP: '/api/v1/validation/validateStepBridgeLoan',
  MULTIFAMILY_PRICING_MODEL: '/api/v1/pricer/multifamilyPricer/getPricingModelFromExternal',
  EXTENSIONS: '/api/v1/loan/getExtensions',
  MY_EXTENSIONS: '/api/v1/loan/getAllExtensions',
  MY_PAYOFFS: '/api/v1/loan/getAllPayoffs',
  GET_FUNDED_LOANS_FOR_EXTENSIONS_REQUEST: '/api/v1/loan/getFundedLoansForExtensionRequest',
  GET_PROPOSAL: '/api/v1/loan/proposals/getLoanProposalById',
  REQUEST_FAST_TRACK_PAYMENT: '/api/v1/loan/requestFastrackPayment',
  GET_LOAN_FAST_TRACK_FEES: '/api/v1/loan/fastTrackFees',
  GET_FAST_TRACK_PAYMENT_URL: '/api/v1/loan/loanFastTrackPaymentUrl',
  ESTIMATED_CLOSING_COSTS: '/api/v1/loan/getBridgeLoanClosingCosts',
  ESTIMATED_RENTAL_CLOSING_COSTS: '/api/v1/loan/getRentalLoanClosingCosts',
  TERTIARY_MARKET_ZIPCODES: '/api/v1/loan/getAllTertiaryMarketZipCode',
  SAVE_LOAN_LENDER_ORIGINATOR: '/api/v1/loan/lenderUsers/saveLoanParticipants',
  GET_TOTAL_LOAN_REQUESTS: '/api/v1/draftloan/getLoanRequestsTotalCount',
  GET_CONVERSATIONS_TOGGLE_STATUS: '/api/v1/user/getUserBackUpConversationToggle',
  UPDATE_CONVERSATIONS_TOGGLE_STATUS: '/api/v1/user/updateUserBackUpConversationToggle',
  GET_BACKGROUND_TASK_STATUS: '/api/v1/user/getBackgroundTaskProcessingStatus',
  SAVE_DRAFT_PROPOSAL: '/api/v1/loan/proposals/updateProposal',
  GET_LOAN_EXPIRATION_DATES: '/api/v1/loan/getLoanExpirationDates',
  GET_LOAN_CLOSING_CONDITIONS: '/api/v1/loan/getLoanClosingConditionsByLoanId',
  GET_LENDER_ATTORNEYS_BY_STATE: '/api/v1/loan/getAllLenderAttorneysByState',
  GET_LOAN_PRESCREEN_DATA: '/api/v1/loan/getLoanPrescreenData',
  GET_CONDITIONALLY_APPROVED_LOANS: '/api/v1/loan/getConditionallyApprovedLoans',
  GET_PRIORITY_LOANS_V2: '/api/v1/loan/getPriorityLoansV2',
  VALIDATE_BORROWERS: '/api/v1/validation/validateBorrowers',
  VALIDATE_ENTITY: '/api/v1/validation/validateEntity',
  GET_LOAN_APPLICATION_BY_ID: '/api/v1/loan/loanApplications/getLoanApplicationById',
  UPDATE_LOAN_APPLICATION_STATUS: '/api/v1/loan/loanApplications/updateLoanApplicationStatusExternal',
  TITLE_HISTORY: '/api/v1/loan/getFirstAmericanTitleHistory',
  GET_BORROWER_HISTORY_RENTAL_LOANS: '/api/v1/loan/getBorrowerHistoryRentalLoans',
  GET_BORROWER_HISTORY_BRIDGE_LOANS: '/api/v1/loan/getBorrowerHistoryBridgeLoans',
  GET_BORROWER_PREV_PARTNERS: '/api/v1/loan/getBorrowerPreviousPartners',
  GET_LOAN_EXPOSURE: '/api/v1/loan/getLoanExposure',
  GET_BRIDGE_COMMISSION: '/api/v1/commissions/generateCommissionForBridgeLoan',
  GET_TERM_COMMISSION: '/api/v1/commissions/generateCommissionForTermLoan',
  GENERATE_COMMISSIONS: '/api/v1/commissions/generateCommissions',
  GET_LOAN_COMMISSION: '/api/v1/commissions/getLoanCommission',
  GET_ARE_BORROWERS_PRE_QUALIFIED: '/api/v1/validation/getAreBorrowersPreQualifiedFromPrescreenValidations',
  GET_EXCEPTIONS_AND_COMMENTS_EXTERNAL: '/api/v1/loan/getExceptionsAndCommentsExternal',
  GET_ARE_BORROWERS_PRE_QUALIFIED_FOR_TERM_LOANS: '/api/v1/validation/getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans',
  GET_LOAN_TO_DOS: '/api/v1/loan/loanToDo/getLoanToDos',
  GET_DELINQUENT_LOANS: '/api/v1/loan/getMyDelinquentLoans',
  GET_MATURITY_LOANS: '/api/v1/loan/getMyMaturityLoans',
  REJECT_LOAN_REQUEST: '/api/v1/draftloan/rejectLoanRequest',
};

export class LoanService extends Service {
  url;
  constructor() {
    super();
    this.url = url;
  }
  getMyLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyQuoteLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.QUOTE_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyFundedLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.FUNDEDLOANS_FOR_DRAWS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }
  getFundedLoansForPayoffRequest(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.GET_FUNDED_LOANS_FOR_PAYOFF_REQUEST,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getFundedLoansForExtensionRequest(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.GET_FUNDED_LOANS_FOR_EXTENSIONS_REQUEST,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getExtensions(

    start: number,
    count: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      this.url.EXTENSIONS, {
      start,
      count,
      sortDir,
      sortBy,
      filters,
      dropdownFilters,
    }
    )
  }
  getMyExtensions(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.MY_EXTENSIONS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyPayoffs(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.MY_PAYOFFS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyPipelineLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.PIPELINE_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyAppraisalToDos(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.APPRAISAL_TO_DOS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getConditionalApprovedLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      this.url.GET_CONDITIONALLY_APPROVED_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getPriorityLoansV2(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      this.url.GET_PRIORITY_LOANS_V2,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyDraftLoans(
    start: number,
    count: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object,
    type: string
  ) {
    return this.get(
      this.url.DRAFT_LOANS,
      {
        start,
        count,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
        type
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyBackUpLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.BACKUP_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getMyAgreedLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    externalStatus: object
  ) {
    return this.get(
      this.url.AGREED_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        externalStatus,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  getLoanDetails(id: string) {
    return this.get(this.url.LOAN_DETAILS, {
      id,
    }, {
      disableGlobalLoading: isMobileApp()
    });
  }

  getLoanSummary(id: string) {
    return this.get(url.LOAN_SUMMARY, {
      id,
    }, {
      disableGlobalLoading: isMobileApp()
    });
  }
  saveDraftLoan(loan: any, syncDraftLoanTasks = false) {
    return this.post(`${this.url.SAVE_DRAFT_LOAN}?syncDraftLoanTasks=${syncDraftLoanTasks}`, loan);
  }

  submitBridgeLoan(loan: any) {
    //checkSessionMatch do we need ?
    return this.post(this.url.SUBMIT_BRIDGE_LOAN, loan);
  }

  submitTermLoan(loan: any) {
    return this.post(this.url.SUBMIT_TERM_LOAN, loan);
  }

  submitLoanApplicationForBrokerOrLenderOriginator(loan: any) {
    return this.post(this.url.SUBMIT_BRIDGE_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR, loan);
  }

  submitSpecializedLoanApplicationForBrokerOrLenderOriginator(loan: any) {
    return this.post(this.url.SUBMIT_SPECIALIZED_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR, loan);
  }

  submitTermLoanApplicationForBrokerOrLenderOriginator(loan: any) {
    return this.post(this.url.SUBMIT_TERM_LOAN_APPLICATION_FOR_BROKER_OR_LENDER_ORIGINATOR, loan);
  }

  getDraws(loanId: number) {
    return this.get(`${this.url.PREVIOUS_DRAWS}?loanId=${loanId}`);
  }

  getStateCounties() {
    return this.get(this.url.STATE_COUNTIES);
  }

  getStateCountiesPublic() {
    return this.get(this.url.STATE_COUNTIES_PUBLIC);
  }

  getWimbaDetails() {
    return this.get(this.url.WIMBA_DETAILS);
  }

  getLoanProducts() {
    return this.get(this.url.LOAN_PRODUCTS);
  }

  getLenderAttorneys() {
    return this.get(this.url.LENDER_ATTORNEYS);
  }

  getLenderAttorneysByState(state, isInternalClosingTeamOptionEnabled, loanAmount) {
    return this.get(this.url.GET_LENDER_ATTORNEYS_BY_STATE, {
      isInternalClosingTeamOptionEnabled,
      state,
      loanAmount
    });
  }

  getWimbaStates() {
    return this.get(this.url.WIMBA_STATES);
  }

  getAllTertiaryMarketZipCode() {
    return this.get(this.url.TERTIARY_MARKET_ZIPCODES);
  }

  getTotalUnreadMessages() {
    return this.get(
      this.url.TOTAL_UNREAD_MESSAGES,
      {},
      {
        disableGlobalLoading: true,
      }
    );
  }

  getInternalInsuranceReviewers() {
    return this.get(
      this.url.GET_INTERNAL_INSURANCE_REVIEWERS,
    );
  }

  setLoanPriority(loanId: number, priority: string) {
    return this.post(this.url.SET_LOAN_PRIORITY, { loanId, priority });
  }

  changeLoanStatus(
    loanId: number,
    loanStatus: string,
    statusChangeReason: string,
    statusChangeComment: string
  ) {
    const endpointUrl = `${this.url.CHANGE_LOAN_STATUS}?loanId=${loanId}`;
    return this.post(endpointUrl, {
      loanStatus,
      statusChangeReason,
      statusChangeComment,
    });
  }

  changeAssignee(loanId: number, assignee: string) {
    return this.post(this.url.CHANGE_ASSIGNEE, { loanId, assignee });
  }

  deleteDraftLoan(loanId: number) {
    return this.delete(this.url.DELETE_DRAFT_LOAN, {
      draftLoanId: loanId,
    });
  }

  getDraftLoan(draftLoanId: number) {
    return this.get(this.url.GET_DRAFT_LOAN, {
      draftLoanId,
    });
  }

  getProposalById(proposalId: number) {
    return this.get(this.url.GET_PROPOSAL, {
      proposalId,
    });
  }

  getCalendlyPreferences(loanId: number) {
    return this.get(this.url.CALENDLY_PREFERENCES, { loanId });
  }

  saveBorrowers(loanId: number, borrowers: any[]) {
    const endpointUrl = `${this.url.SAVE_BORROWERS}?loanId=${loanId}`;
    return this.post(endpointUrl, { borrowers });
  }

  getLoanClosingStatus(loanId: number) {
    return this.get(this.url.CLOSING_STATUS, { loanId });
  }

  resendQuote(loanId: number, borrowerEmail: string) {
    const endpointUrl = `${this.url.RESEND_QUOTE}?loanId=${loanId}&borrowerEmailId=${borrowerEmail}`;
    return this.post(endpointUrl, {});
  }

  requestElmsureQuote(loanId: number, quoteType: string) {
    const endpointUrl = `${this.url.REQUEST_ELMSURE_QUOTE}?loanId=${loanId}&quoteType=${quoteType}`;
    return this.post(endpointUrl, {});
  }

  sendErrorEmail(subject: string, error: string, details: string) {
    const body = {
      subject,
      body: `BrowserInfo: ${navigator.userAgent} Error message: ${error} \n Details: ${details}`,
    };
    return this.post(this.url.SEND_ERROR_EMAIL, body);
  }

  log(data, additionalInfo?: string) {
    return this.post(this.url.LOGGING, { data, additionalInfo });
  }

  getOriginators() {
    return this.get(this.url.LOAN_ORIGINATORS);
  }

  getRentalDealNames(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(this.url.RENTAL_DEAL_NAMES, {
      page,
      pageSize,
      sortDir,
      sortBy,
      filters,
    });
  }

  uploadInsuranceDocument(file: File) {
    const formData = new FormData();
    formData.append(
      'documentMetadata',
      JSON.stringify({
        documentCollection: 'FAST_TRACK_INSURANCE_DOCUMENTS',
      })
    );
    formData.append('document', file);
    return this.post(this.url.UPLOAD_DOCUMENT, formData);
  }

  fetchDraftLoanId() {
    return this.get(this.url.FETCH_DRAFT_LOAN_ID);
  }

  updateLoanFastrackInfo(fastTrackOptions: any) {
    return this.put(this.url.UPDATE_LOAN_FAST_TRACK_PAYMENTS, fastTrackOptions);
  }

  getRelationshipManagers(loanId: number) {
    return this.get(this.url.RELATIONSHIP_MANAGERS, {
      loanId,
    });
  }

  getOutOfBoxWarnings(loan: any) {
    return this.post(this.url.VALIDATE_BRIDGE_LOAN, loan);
  }

  getValidateBridgeLoanErrors(loan: any, skipLeverageValidations: boolean) {
    return this.post(`${this.url.VALIDATE_BRIDGE_LOAN}?skipLeverageValidations=${skipLeverageValidations}`, loan);
  }

  validateBridgeLoanStep(loan: any, step: string, skipLeverageValidations: boolean) {
    return this.post(`${this.url.VALIDATE_BRIDGE_LOAN_STEP}?step=${step}&skipLeverageValidations=${skipLeverageValidations}`, loan);
  }

  saveLenderOriginator(loanId: number, lenderPercentage: any[]) {
    const endpointUrl = `${this.url.SAVE_LENDER_ORIGINATOR}?loanId=${loanId}`;
    console.log(endpointUrl, { lenderPercentage });
    return this.post(endpointUrl, { lenderPercentage });
  }

  getMultifamilyPricingModel(loan: any) {
    return this.post(this.url.MULTIFAMILY_PRICING_MODEL, loan);
  }

  requestFastTrackPaymentEmail(fastTrackInfo: any) {
    return this.post(this.url.REQUEST_FAST_TRACK_PAYMENT, fastTrackInfo);
  }

  getLoanFastTrackFees() {
    return this.get(this.url.GET_LOAN_FAST_TRACK_FEES);
  }

  getLoanFastTrackPaymentUrl(loanId) {
    return this.get(this.url.GET_FAST_TRACK_PAYMENT_URL, { loanId });
  }

  getBridgeLoanClosingCosts(loanId: number) {
    return this.get(this.url.ESTIMATED_CLOSING_COSTS, { loanId });
  }

  getRentalLoanClosingCosts(loanId: number) {
    return this.get(this.url.ESTIMATED_RENTAL_CLOSING_COSTS, { loanId });
  }

  saveLoanParticipants(loanId: number, lenderOriginatorId) {
    const endpointUrl = `${this.url.SAVE_LOAN_LENDER_ORIGINATOR}?loanId=${loanId}${isNil(lenderOriginatorId) ? `` : `&lenderOriginatorId=${lenderOriginatorId}`}`;
    return this.post(endpointUrl, {});
  }
  getLoanRequestsTotalCount() {
    return this.get(this.url.GET_TOTAL_LOAN_REQUESTS, {}, {
      disableGlobalLoading: true
    });
  }
  getConversationsToggleStatus(userId: number) {
    return this.get(this.url.GET_CONVERSATIONS_TOGGLE_STATUS, { userId }, {
      disableGlobalLoading: true
    });
  }
  updateUserBackUpConversationToggle(userId: number) {
    return this.put(`${this.url.UPDATE_CONVERSATIONS_TOGGLE_STATUS}?userId=${userId}`, {});
  }
  getIsBackgroundTaskBeingProcessed(userId: number) {
    return this.get(this.url.GET_BACKGROUND_TASK_STATUS, { userId }, {
      disableGlobalLoading: true
    });
  }

  saveDraftProposal(loan: any) {
    return this.post(this.url.SAVE_DRAFT_PROPOSAL, loan);
  }

  getLoanExpirationDates(loanId: number) {
    const endpointUrl = `${this.url.GET_LOAN_EXPIRATION_DATES}?loanId=${loanId}`;
    console.log(endpointUrl)
    return this.get(endpointUrl, {});
  }
  getLoanClosingConditions(loanId: number) {
    const endpointUrl = `${this.url.GET_LOAN_CLOSING_CONDITIONS}?loanId=${loanId}`;
    return this.get(endpointUrl, {});
  }

  getLoanPrescreenData(loanId: number) {
    return this.get(this.url.GET_LOAN_PRESCREEN_DATA, {
      loanId,
    });
  }

  getMyPendingTasks(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object,
    dropdownFilters: object
  ) {
    return this.get(
      this.url.PENDING_TASKS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters,
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getValidateBorrowerLoanErrors(borrowers: any) {
    return this.post(this.url.VALIDATE_BORROWERS, borrowers);
  }

  getValidateEntityLoanErrors(entityId: number) {
    const endpointUrl = `${this.url.VALIDATE_ENTITY}?entityId=${entityId}`
    return this.post(endpointUrl, {});
  }

  getLoanApplicationById(loanId: number) {
    return this.get(url.GET_LOAN_APPLICATION_BY_ID, { loanId });
  }

  getBorrowerHistoryRentalLoans(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, borrowerId: number) {
    return this.get(url.GET_BORROWER_HISTORY_RENTAL_LOANS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, borrowerId },
      { disableGlobalLoading: true }
    );
  }

  getBorrowerHistoryBridgeLoans(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, borrowerId: number) {
    return this.get(url.GET_BORROWER_HISTORY_BRIDGE_LOANS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, borrowerId },
      { disableGlobalLoading: true }
    );
  }

  getBorrowerPreviousPartners(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, borrowerId: number) {
    return this.get(url.GET_BORROWER_PREV_PARTNERS,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, borrowerId },
      { disableGlobalLoading: true }
    );
  }

  getLoanExposure(pageNumber: number, pageSize: number, sortBy: string, sortDir: string, filters: string, dropdownFilters: string, borrowerId: number) {
    return this.get(url.GET_LOAN_EXPOSURE,
      { pageNumber, pageSize, sortBy, sortDir, filters, dropdownFilters, borrowerId },
      { disableGlobalLoading: true }
    );
  }

  updateLoanApplicationStatus(data: any) {
    return this.put(url.UPDATE_LOAN_APPLICATION_STATUS, data);
  }

  getTitleHistory(
    propertyReferenceNumber: number,
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: any,
  ) {
    return this.get(
      `${url.TITLE_HISTORY}/${propertyReferenceNumber}`,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
      },
      {
        disableGlobalLoading: page > 1,
      }
    );
  }

  getBridgeCommmission(data: any) {
    return this.post(url.GET_BRIDGE_COMMISSION, data);
  }

  getTermCommission(data: any) {
    return this.post(url.GET_TERM_COMMISSION, data);
  }

  generateCommissions(startDate: string, endDate: string, loanType: string, loanId: number) {
    return this.get(url.GENERATE_COMMISSIONS, { startDate, endDate, loanType, loanId });
  }

  getLoanCommission(loanId: number) {
    return this.get(url.GET_LOAN_COMMISSION, { loanId });
  }

  getAreBorrowersPreQualifiedFromPrescreenValidations(data: any) {
    return this.post(this.url.GET_ARE_BORROWERS_PRE_QUALIFIED, data);
  }
  getExceptionsAndCommentsExternal(loanId: any, commentType: string) {
    return this.get(url.GET_EXCEPTIONS_AND_COMMENTS_EXTERNAL, { loanId, commentType });
  }
  getAreBorrowersPreQualifiedFromPrescreenValidationsForTermLoans(data: any) {
    return this.post(this.url.GET_ARE_BORROWERS_PRE_QUALIFIED_FOR_TERM_LOANS, data);
  }
  getLoanToDos(loanId, loanProcess) {
    return this.get(`${this.url.GET_LOAN_TO_DOS}/${loanId}`, { loanProcess });
  }

  getMyDelinquentLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.GET_DELINQUENT_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getMyMaturityLoans(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: object
  ) {
    return this.get(
      this.url.GET_MATURITY_LOANS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  rejectLoanRequest(loanApplicationId: number) {
    return this.delete(this.url.REJECT_LOAN_REQUEST, {
      loanApplicationId: loanApplicationId
    });
  }

}
