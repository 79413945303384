import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@roc/feature-app-core';
import { Paper, Tabs } from '@roc/ui';
import { observer } from 'mobx-react';
import { TaxCertificateGrid } from './taxCertificateGrid';
import { TaxTabs } from '../types/types';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';


const TABS = [
  {
    value: TaxTabs.TAX_CERTIFICATE,
    label: 'Tax Certificate',
  },
  {
    value: TaxTabs.REDEMPTION_REQUEST,
    label: 'Redemption',
  },
  {
    value: TaxTabs.PAYMENT_APPROVAL,
    label: 'Payment Approval',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    //Same as nportal dashboard
    maxWidth: '1400px',
  },
  paper: {
    boxShadow: 'none !important',
    border: '1px solid #cdcdcd',
    padding: '0px',
    borderRadius: '0 !important',
  },

}));



export const TaxCertificateComponent = observer(() => {
  const classes = useStyles();
  const { taxCertificateStore } = useTaxCertificateStore();
  const { tab } = taxCertificateStore;


  return (
    <Layout title="Delinquent Property Taxes" containerClass={classes.container}>
      <Box pb={2}>
        <Tabs
          selectedTab={TABS.findIndex(t => t.value == tab)}
          tabs={Object.values(TABS)}
          onTabChange={(e, i) => taxCertificateStore.setTab(TABS[i].value)}
        />
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1}>
          <Paper className={classes.paper}>
            <TaxCertificateGrid />
          </Paper>
        </Box>
      </Box>
    </Layout>
  );
});
