import { PersonAddOutlined, PostAddOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { CustomAlert } from '../../../components/customAlert';
import { CustomButton } from '../../../components/customButton';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { QuoteStep } from '../../../utils/constants';

export const NoEntityAlert = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();

  return (
    <CustomAlert
      severity="info"
      variant="outlined"
      action={
        <CustomButton
          size="medium"
          theme="blue"
          startIcon={<PostAddOutlined />}
          testId="edit-borrower"
          onClick={() => quoteTypeStore.goToStep(QuoteStep.ENTITY_INFORMATION)}
        >
          ADD ENTITY
        </CustomButton>
      }
    >
      <AlertTitle>No Entity</AlertTitle>
      <ul>
        <li>
          Entity information is optional at this stage of the quote and loan
          submission process.
        </li>
        <li>
          However, the loan cannot be finalized or funds distributed until an
          entity is associated with it.
        </li>
      </ul>
    </CustomAlert>
  );
});
