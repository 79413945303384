/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Grid } from "@material-ui/core";
import { TitleDivider } from "./titleDivider";
import Alert from "@material-ui/lab/Alert";
import { observer } from "mobx-react";
import { createCurrencyField, createTextField } from "@roc/ui";
import { InternalValuationReviewStore } from "../stores/internalValuationReviewStore";

interface ValuationFormProps {
  internalValuationReviewStore: InternalValuationReviewStore;
  readOnly: boolean;
}

export const ValuationForm = observer(({ internalValuationReviewStore, readOnly }: ValuationFormProps) => {

  const valuationFormStore = internalValuationReviewStore.valuationFormStore;
  const appraiser = valuationFormStore.form.fields.appraiser.value;
  const showScopeOfWorkField = internalValuationReviewStore.showScopeOfWork();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {createCurrencyField({
          label: 'Borrower Value',
          fieldName: 'borrowerValue',
          testId: 'borrowerValue',
          store: valuationFormStore,
          standaloneLabel: false,
          disabled: true
        })}
      </Grid>
      <Grid item xs={4}>
        {createCurrencyField({
          label: 'As-Repaired Value',
          fieldName: 'asRepairedValue',
          testId: 'asRepairedValue',
          store: valuationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={4}>
        {createCurrencyField({
          label: 'As-Is Value',
          fieldName: 'asIsValue',
          testId: 'asIsValue',
          store: valuationFormStore,
          standaloneLabel: false,
          disabled: readOnly
        })}
      </Grid>
      <Grid item xs={12}>
        {createTextField({
          label: 'Value Reconciliation Comments',
          fieldName: 'valueReconciliationComments',
          testId: 'valueReconciliationComments',
          store: valuationFormStore,
          standaloneLabel: false,
          multiline: true,
          minRows: 4,
          disabled: readOnly
        })}
      </Grid>
      {showScopeOfWorkField && (<Grid item xs={12}>
        {createTextField({
          label: 'Scope of Work',
          fieldName: 'scopeOfWorkDescription',
          testId: 'scopeOfWorkDescription',
          store: valuationFormStore,
          standaloneLabel: false,
          multiline: true,
          minRows: 4
        })}
      </Grid>)}
      <Grid item xs={12}>
        <TitleDivider title="Appraiser" />
        <Alert severity={appraiser ? "info" : "warning"}>{appraiser || "Not available"}</Alert>
      </Grid>
    </Grid>
  );
});
