import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { PermIdentityOutlined } from '@material-ui/icons';
import {
  createCheckboxField,
  createSelectField,
  createTextField,
} from '../../../components/temp/componentBuiderTemp';
import { createNumberFormatFieldNoDecimalsAndWithLimit } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { EditControls } from '../../../components/editControls';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import {
  FORM_MAX_WIDTH,
  verifiedInvestmentPropertiesOptions,
} from '../../../utils/constants';
import { FirstNameAutocompleteField } from './borrowerInformationFields';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { PercentagePicker } from '../../leverage/components/leverageComponents';

const useStyles = makeStyles(theme => ({
  infoMessage: {
    fontStyle: 'italic',
    marginTop: '0rem',
    marginLeft: '1rem',
    color: 'gray',
    fontSize: '12px',
  },
}));

interface BorrowerInformationFormProps {
  store: BorrowerFormStore;
  onDeleteClick: (borrowerKey: string) => void;
  removeButtonDisabled?: boolean;
  showActions?: boolean;
  displayPGField: boolean;
  showTitle?: boolean;
}

export const BorrowerInformationForm = observer(
  ({
    store,
    onDeleteClick,
    showActions = true,
    removeButtonDisabled = false,
    displayPGField,
    showTitle = true,
  }: BorrowerInformationFormProps) => {
    const classes = useStyles();
    const { quoteTypeStore } = useQuoteTypeStore();
    const { borrowersStore } = quoteTypeStore;
    const { showOwnershipPercentage } = borrowersStore;
    const { cannotCreateBorrower, isNewBorrower } = store;
    const { globalStore } = useStore();

    function saveHandler() {
      const isValid = store.runFormValidationWithMessage(
        'Please complete all the required fields'
      );
      store.setShowErrors(true);
      if (isValid) {
        store.setEditMode(false);
      }
    }

    const firstName = store.form.fields.firstName.value;
    const fundedBorrower = store.form.fields.projectsCompleted.value;

    useEffect(() => {
      if (firstName) {
        if (globalStore.userFeatures?.displayLeads) {
          store.findBorrowersAndLeadsByNameDebounced(firstName);
        } else {
          store.findBorrowersByNameDebounced(firstName);
        }
      }
    }, [firstName]);

    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {showTitle && (
          <Grid
            item
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            style={{ maxWidth: FORM_MAX_WIDTH, marginBottom: '0.5rem' }}
          >
            <Grid item>
              <RoundedBackgroundIcon
                Icon={PermIdentityOutlined}
                iconColor="#6D6D6D"
              />
            </Grid>
            <Grid item>
              <Typography variant="h4" style={{ color: '#6D6D6D' }}>
                Add Borrower
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={2} style={{ maxWidth: FORM_MAX_WIDTH }}>
          <Grid item xs={12} sm={6}>
            <FirstNameAutocompleteField
              store={store}
              optional
              disabled={
                fundedBorrower || Boolean(store?.selectedRecord['firstName'])
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {createTextField({
              label: 'Last Name',
              fieldName: 'lastName',
              testId: 'lastName',
              store,
              standaloneLabel: false,
              disabled:
                fundedBorrower ||
                Boolean(store?.selectedRecord['lastName']) ||
                (cannotCreateBorrower && isNewBorrower),
              addMarkForRequiredFields: false,
            })}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.infoMessage}>
              Please search for or add a new borrower above.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {createTextField({
              label: 'Email',
              fieldName: 'emailAddress',
              testId: 'emailAddress',
              store,
              standaloneLabel: false,
              disabled:
                Boolean(store?.selectedRecord['emailAddress']) ||
                fundedBorrower ||
                cannotCreateBorrower,
              isRequired: true,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createNumberFormatFieldNoDecimalsAndWithLimit({
              label: 'Fico Score',
              fieldName: 'ficoScore',
              testId: 'ficoScore',
              store,
              standaloneLabel: false,
              showErrors: store.form.meta.showErrors || false,
              disabled: !Boolean(store?.selectedRecord['ficoScore'])
                ? false
                : fundedBorrower || (cannotCreateBorrower && isNewBorrower),
              isRequired: true,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            {createSelectField({
              store,
              testId: 'numberOfVerifiedInvestmentProperties',
              fieldName: 'numberOfVerifiedInvestmentProperties',
              label: 'Number of Verified Investment Properties',
              options: verifiedInvestmentPropertiesOptions,
              standaloneLabel: false,
              addMarkForRequiredFields: false,
              disabled: !Boolean(
                store?.selectedRecord['numberOfVerifiedInvestmentProperties']
              )
                ? false
                : fundedBorrower || (cannotCreateBorrower && isNewBorrower),
              isRequired: true,
            })}
          </Grid>
          {displayPGField && (
            <Grid item xs={showOwnershipPercentage ? 8 : 12}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  {createCheckboxField({
                    store,
                    fieldName: 'personalGuarantor',
                    label:
                      'This borrower is a personal guarantor for the loan.',
                    color: 'primary',
                  })}
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    className={classes.infoMessage}
                    style={{ textAlign: 'center' }}
                  >
                    If yes, the borrower's credit and background reports are
                    needed. if no, only a background report is needed.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {showOwnershipPercentage && (
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                pb={1}
              >
                <PercentagePicker
                  label="Ownership Percentage"
                  fieldName="percentageOfOwnership"
                  value={store.form.fields.percentageOfOwnership.value}
                  onChange={val =>
                    store.form.fields.onChange('percentageOfOwnership', val)
                  }
                />
              </Box>
              <Typography
                variant="body2"
                className={classes.infoMessage}
                style={{ textAlign: 'center' }}
              >
                *If greater than 51%, the borrower must be a personal guarantor.
              </Typography>
            </Grid>
          )}
          {showActions && (
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              style={{ padding: '1rem' }}
            >
              <EditControls
                onDeleteClick={() => onDeleteClick(store.key)}
                onSaveClick={saveHandler}
                removeButtonDisabled={removeButtonDisabled}
                onResetClick={() => store.reset()}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
);
