import { GridColumnProps } from '@roc/ui';
import { filterProps, noOpComparator } from '@roc/ui/utils';


export const TAX_CERT_COLUMNS: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    width: 90,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
    width: 90,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'createdDate',
    headerName: 'Creation Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
];

export const PAYMENT_APPROVAL_COLUMNS: GridColumnProps[] = [
  {
    field: 'loanId',
    headerName: 'Loan Id',
    width: 90,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 125,
    comparator: noOpComparator,
    ...filterProps(),
    editable: true,
    cellEditor: 'dropdownGridEditor',
  },
  {
    field: 'parcel',
    headerName: 'Parcel',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'taxYear',
    headerName: 'Tax Year',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'tape',
    headerName: 'Tape',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'goodThroughDate',
    headerName: 'Good Through Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
  {
    field: 'originalDueDate',
    headerName: 'Original Due Date',
    cellRenderer: 'dateCellRenderer',
    minWidth: 125,
    comparator: noOpComparator,
    ...filterProps(),
  },
];