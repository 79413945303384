import { Work, WorkOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { FORM_MAX_WIDTH } from '../../../utils/constants';
import { OneToolCard } from '../../common/oneToolCard';
import { EntityInformationForm } from './entityInformationForm';

export const AddEntityCard = observer(() => {
  return (
    <OneToolCard
      maxWidth={FORM_MAX_WIDTH}
      icon={WorkOutline}
      title={'Add Entity'}
    >
      <EntityInformationForm />
    </OneToolCard>
  );
});
