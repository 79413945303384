import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, StandardDialog } from '@roc/ui';
import { observer } from 'mobx-react';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { QuoteStep } from '../../../utils/constants';

export const NoBorrowersDialog = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const { summaryStore } = quoteTypeStore;

  const close = () => {
    summaryStore.closeNoBorrowersDialog();
  };

  const addBorrowers = () => {
    summaryStore.closeNoBorrowersDialog();
    quoteTypeStore.goToStep(QuoteStep.BORROWER_INFORMATION);
  };

  const dialogContent = (
    <Alert severity="error" style={{ width: '100%' }}>
      <AlertTitle>Valid Borrower Needed to Continue</AlertTitle>
      <ul>
        <li>
          Please note that valid borrower(s) is required to continue to loan
          submission.
        </li>
        <li>
          Any borrowers currently NOT represented, could be added returning to
          the add borrower section of the quote.
        </li>
      </ul>
    </Alert>
  );

  const dialogActions = (
    <>
      <Button
        style={{ marginRight: '8px' }}
        onClick={close}
        color="primary"
        variant="outlined"
        testId="cancel"
      >
        Cancel
      </Button>
      <Button
        onClick={addBorrowers}
        color="primary"
        variant="contained"
        testId="continue"
      >
        Goto add borrower
      </Button>
    </>
  );

  return (
    <StandardDialog
      title="Needs a Valid Borrower"
      open={summaryStore.showNoBorrowersDialog}
      onClose={close}
      handleClose={close}
      maxWidth={'sm'}
      dialogContent={dialogContent}
      dialogActions={dialogActions}
      removeContentBox
    />
  );
});
