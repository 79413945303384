import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Borrower } from "@roc/feature-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { observer } from 'mobx-react';
import Grid from "@material-ui/core/Grid";
import { CreditReviewForm, useCreditReviewExternalStore } from '@roc/feature-borrower-review';
import { BackgroundReviewForm, useBackgroundReviewStore } from "@roc/feature-borrower-review";
import BorrowerTrackRecord from './borrowerTrackRecord'
import { Layout, useUserStore } from '@roc/feature-app-core';
import { formatCurrency, formatPhoneNumber } from '@roc/ui/utils';
import { colors } from '../utils/todoConstants'
import TabPanel from '../components/common/tabPanel'
import {
  getValueOrDefaultDash,
  parseDate
} from 'libs/feature-one-tool/src/quote/utils/utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { useBorrowersStore } from "@roc/feature-borrowers";

const useStylesGrid = makeStyles(theme => ({
  rowStyle:{
    height: '20px',
    borderStyle: 'solid',
    borderColor: '#CBCBCB'
  },
  cellStyle: {
    alignItems: 'center'
  }
}));

export const BorrowerExposure = ({borrower}) => {
  const classes = useStylesGrid();
  const { borrowersStore } = useBorrowersStore();
  const borrowerExposureData = borrowersStore?.borrowerExposureData?.data;

  const rows = borrowerExposureData?.rows;
  const columns = borrowerExposureData?.columns;

  const getExposureText = () => {
    if(borrower?.exposureMonitoring === 'No Exposure'){
      return `${borrower?.firstName} ${borrower?.lastName} has no exposure yet. Please check the table below to know more details.`
    }
    return `${borrower.exposureMessage}. Please check the table below to know more details.`
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Borrower Exposure
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'subtitle1'}>
          {getExposureText()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.rowStyle}>
                {columns?.map((col) => {
                  return(
                    <TableCell align="center" className={classes.cellStyle}><Typography style={{fontWeight: 'bold'}}>{col?.name}</Typography></TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map(data => {
                return(
                  <TableRow className={classes.rowStyle}>
                    <TableCell align="center">{data?.loanType}</TableCell>
                    <TableCell align="center">{data?.count}</TableCell>
                    <TableCell align="center">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(data?.amount)}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '-20px',
    marginLeft: '- 8px',
    minHeight: '300px',
    padding: 0,
    [theme.breakpoints.up(theme.breakpoints.values['lg']+200)]: {
      minWidth: theme.breakpoints.values['lg'],
    },
  },
  tab: {
    color: colors.raisinBlack,
    fontWeight: 500,
    lineHeight: '24px',
    borderTopLeftRadius: '8px',
    width: 'calc(25% - 4px)',
    borderTopRightRadius: '8px',
    border: '1px solid #eee',
    opacity: 1,
  },
  activeTab: {
    backgroundColor: colors.desertStorm,
    fontWeight: 'bold',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
  },
  subtitle: {
    fontSize: '10px',
    fontWeight: 400,
    color: colors.dune,
  },
  borrowerTabBorder: {
    borderBottom: `solid 1px #eee`,
  },
}));
enum TabsEnum {
  PERSONAL_INFO,
  CREDIT_INFO,
  BACKGROUND_INFO,
  TRACK_RECORD,
  BORROWER_EXPOSURE
}
interface Props {
  borrower: Borrower;
  loanBorrower: Borrower;
  loan: any;
}

const BorrowerOverview = observer(({ borrower, loan, loanBorrower }: Props) => {
  const classes = useStyles();
  const { creditReviewStore } = useCreditReviewExternalStore();
  const { creditItemStore } = creditReviewStore;
  const { userStore } = useUserStore();
  const { backgroundReviewStore } = useBackgroundReviewStore();
  const { backgroundItemStore } = backgroundReviewStore;

  const [value, setValue] = React.useState(TabsEnum.PERSONAL_INFO);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (borrower?.borrowerId) {
      creditItemStore.currentBorrower = borrower?.borrowerId;
      creditItemStore.loanId = loan?.loanId;
      creditItemStore.isInternal = userStore.isNportalUser;
      creditItemStore.loan = loan;
      creditItemStore.initializeService();
      backgroundItemStore.currentBorrower = borrower?.borrowerId;
      backgroundItemStore.isInternal = false;
      backgroundItemStore.initializeService();
    }
  }, [borrower?.borrowerId]);

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.borrowerTabBorder}
        aria-label="borrower-tabs"
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab
          label="PERSONAL INFO"
          className={`${classes.tab} ${value === TabsEnum.PERSONAL_INFO ? classes.activeTab : ""}`}
        />
        <Tab
          label="CREDIT INFO"
          className={`${classes.tab} ${value === TabsEnum.CREDIT_INFO ? classes.activeTab : ""}`}
        />
        <Tab
          label="BACKGROUND INFO"
          className={`${classes.tab} ${value === TabsEnum.BACKGROUND_INFO ? classes.activeTab : ""}`}
        />
        <Tab
          label="TRACK RECORD"
          className={`${classes.tab} ${value === TabsEnum.TRACK_RECORD ? classes.activeTab : ""}`}
        />
        <Tab
        label="BORROWER EXPOSURE"
        className={`${classes.tab} ${value === TabsEnum.BORROWER_EXPOSURE ? classes.activeTab : ""}`}
        />
      </Tabs>
      <TabPanel value={value} index={TabsEnum.PERSONAL_INFO} px={0}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography className={classes.title}>{getValueOrDefaultDash(borrower?.citizenshipStatus)}</Typography>
            <Typography className={classes.subtitle}>
              CITIZENSHIP STATUS{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title}>{getValueOrDefaultDash(borrower?.fullAddress.trim())}</Typography>
            <Typography className={classes.subtitle}>ADDRESS </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{getValueOrDefaultDash(formatPhoneNumber((borrower?.cellPhone)))}</Typography>
            <Typography className={classes.subtitle}>
              PHONE NUMBER{" "}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{getValueOrDefaultDash(borrower?.driverLicenseState)}</Typography>
            <Typography className={classes.subtitle}>
              DRIVER’S LICENSE STATE{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title}>{getValueOrDefaultDash(borrower?.driverLicenseNumber)}</Typography>
            <Typography className={classes.subtitle}>
              DRIVER’S LICENSE NUMBER
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{parseDate(borrower?.driverLicenseExpirationDate)}</Typography>
            <Typography className={classes.subtitle}>
              DRIVER’S LICENSE EXPIRATION DATE{" "}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{borrower?.assets ? formatCurrency(borrower?.assets) : '-'}</Typography>
            <Typography className={classes.subtitle}>Assets </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title}>{borrower?.liabilities ? formatCurrency(borrower?.liabilities) : '-'}</Typography>
            <Typography className={classes.subtitle}>LIABILITIES </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.title}>{borrower?.netWorth ? formatCurrency(borrower?.netWorth) : '-'}</Typography>
            <Typography className={classes.subtitle}>NET WORTH </Typography>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={TabsEnum.CREDIT_INFO} px={0}>
        <CreditReviewForm store={creditItemStore} showButtons={false} hideTitle={true} />
      </TabPanel>
      <TabPanel value={value} index={TabsEnum.BACKGROUND_INFO}  px={0}>
        <BackgroundReviewForm
          showEmptySections={false}
          showBorrowerInformation={false}
          showBorrowerExperience={false}
          hideTitle={true}
          showResolutions={false}
          showButtons={false}
          store={backgroundItemStore} />
      </TabPanel>
      <TabPanel value={value} index={TabsEnum.TRACK_RECORD} px={0}>
        <BorrowerTrackRecord borrowerId={borrower?.borrowerId} loanId={loan?.loanId} />
      </TabPanel>
      <TabPanel value={value} index={TabsEnum.BORROWER_EXPOSURE} px={0}>
        <BorrowerExposure borrower={loanBorrower}/>
      </TabPanel>
    </div>
  );
})

export default BorrowerOverview;
