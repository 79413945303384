import clsx from 'clsx';
import { OneToolCard, OneToolCardBorder } from '../../common/oneToolCard';
import { PreflightActionButtons } from './preflightActionButtons';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { Edit, ExpandMore } from '@material-ui/icons';
import { QuoteTypeStore } from '../../../stores/quoteTypeStore';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { PreflightStep, preflightSteps } from '../../../utils/constants';
import { observer } from 'mobx-react';
import { EditControlsPreflightCards } from '../../../components/editControls';
import { Button } from '@roc/ui';

const useStyles = makeStyles(theme => ({
  disabled: {
    pointerEvents: 'none',
    backgroundColor: '#F1F2F3',
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    border: `1px solid lightgray`,
    borderRadius: '16px !important',
    padding: theme.spacing(4),
  },
}));

interface PrefligthCardProps {
  store: QuoteTypeStore;
  disabled?: boolean;
  step: PreflightStep;
  FormComponent: React.FC;
  AccordionComponent: React.FC<{
    expanded: boolean;
    onExpand: (expanded: boolean) => void;
    onEdit: () => void;
  }>;
  current?: boolean;
  itemIndex?: number;
  onDelete?: () => void;
  onReset?: () => void;
  onSave?: () => void;
  onSkip?: () => void;
  onEdit?: () => void;
  disableDelete?: boolean;
  disableSave?: boolean;
  disableReset?: boolean;
  disableSkip?: boolean;
}

export const PrefligthCard = observer((props: PrefligthCardProps) => {
  const {
    store,
    step,
    FormComponent,
    AccordionComponent,
    itemIndex = 0,
    onReset,
    onDelete,
    onSave,
    onSkip,
    onEdit,
    disableDelete = false,
    disableReset = false,
    disableSave = false,
    disableSkip = false,
  } = props;
  const classes = useStyles();
  const { preflightStore } = store;
  const currentStep = preflightStore.step;
  const currentItemIndex = preflightStore.itemIndex;
  const isCurrentStep = step === currentStep && itemIndex === currentItemIndex;
  const isStepComplete = preflightStore.isStepComplete(step, itemIndex);
  const notReady = !isCurrentStep && !isStepComplete;
  const disableCard = notReady;
  const [expanded, setExpanded] = useState(false);
  const anchorRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const element = anchorRef.current;
    if (isCurrentStep && element) {
      const y = element.getBoundingClientRect().top + window.scrollY;
      const distanceFromTop = 100;
      const heightOfEarnightCard = 150;
      window.scroll({
        top: y - distanceFromTop - heightOfEarnightCard,
        behavior: 'smooth',
      });
    }
  }, [isCurrentStep]);

  return (
    <div ref={anchorRef}>
      <OneToolCardBorder disabled={disableCard}>
        <Grid container spacing={3}>
          {isCurrentStep ? (
            <>
              <Grid item xs={12}>
                <FormComponent />
              </Grid>
              <Grid item xs={12}>
                <PreflightActionButtons
                  onDelete={onDelete}
                  onSave={onSave}
                  onReset={onReset}
                  onSkip={onSkip}
                  disableDelete={disableDelete}
                  disableReset={disableReset}
                  disableSave={disableSave}
                  disableSkip={disableSkip}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <AccordionComponent
                  expanded={expanded}
                  onExpand={expanded => setExpanded(expanded)}
                  onEdit={() => {
                    setExpanded(false);
                    onEdit();
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </OneToolCardBorder>
    </div>
  );
});
