import { Grid, Typography, makeStyles, Button, Link, Divider } from "@material-ui/core";
import { useStore } from "@roc/client-portal-shared/hooks";
import { useEffect } from "react";
import { SelectField, FileUploadArea } from "@roc/ui";
import { GetApp } from '@material-ui/icons';
import { TextField } from "@roc/ui";
import { observer } from "mobx-react";

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    margin: '0 0 30px 0'
  },
  summary: {
    borderRadius: 10,
    border: '1px solid #dddddd',
    padding: '20px'
  },
  propertyAddress: {
    fontWeight: 'bold'
  },
  addressRow: {
    display: 'flex',
    alignItems: 'center'
  },
  editModeContainer: {
    padding: '20px'
  },
  donwloadLink: {
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  dataLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    fontWeight: 'bold'
  },
}));

type NavProps = {
  setUploadMode?: (open: boolean) => void;
  setOverrideMode?: (open: boolean) => void;
}

const floodZoneOptions = [
  { label: 'Zone A', value: 'A' },
  ...Array.from({ length: 30 }, (_, x) => ({ label: `Zone A${x + 1}`, value: `A${x + 1}` })),
  { label: 'Zone A99', value: 'A99' },
  { label: 'Zone AE', value: 'AE' },
  { label: 'Zone AH', value: 'AH' },
  { label: 'Zone AO', value: 'AO' },
  { label: 'Zone AR', value: 'AR' },
  { label: 'Zone B', value: 'B' },
  { label: 'Zone C', value: 'C' },
  { label: 'Zone D', value: 'D' },
  { label: 'Zone V', value: 'V' },
  { label: 'Zone VE', value: 'VE' },
  { label: 'Zone X', value: 'X' },
]

export const PropertiesSummary = observer(({ ...props }: NavProps) => {
  const classes = useStyles();
  const { insuranceInformationReviewStore: { insuranceInformationStore, floodZoneStore } } = useStore();
  return (
    <Grid className={classes.container}>
      <Grid container spacing={3} className={classes.summary}>
        {insuranceInformationStore.loanDetails?.propertiesMap?.rows?.map((property, index) =>
        (<>{index > 0 && <Divider style={{ width: '100%', border: '1px solid #dddddd', margin: '10px' }} />}
          <Grid container item xs={12} key={property?.propertyId} spacing={4}>
            <Grid item container xs={12} className={classes.addressRow}>
              <Grid xs={6} item>
                <Typography className={classes.propertyAddress}>{property?.address}</Typography>
              </Grid>
              <Grid container item xs={6} direction="row-reverse" spacing={1}>
                {!property.hasFloodCert && <Grid item>
                  <Button variant="contained" color='primary' size="large" onClick={() => {
                    floodZoneStore.setProperty(property);
                    props.setUploadMode(true);
                  }}>EDIT</Button>
                </Grid>}
                {!property.floodOverrideReason && <Grid item>
                  <Button variant="outlined" color='primary' size="large" onClick={() => {
                    floodZoneStore.setProperty(property);
                    props.setOverrideMode(true);
                  }}>OVERRIDE</Button>
                </Grid>}
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid container xs={4}>
                <Grid item xs={12} className={classes.dataLabel}>
                  Flood Zone
                </Grid>
                <Grid item xs={12}>
                  {property?.floodZone && `Zone ${property?.floodZone}`}
                </Grid>
              </Grid>
              <Grid xs={4} container>
                <Grid item xs={12} className={classes.dataLabel}>
                  Quote Override
                </Grid>
                <Grid item xs={12}>
                  {property?.floodOverrideReason ? 'Yes' : 'No'}
                </Grid>
              </Grid>
              <Grid xs={4} container className={classes.dataLabel}>
                <Grid item xs={12}>
                  Document
                </Grid>
                <Grid item xs={12}>
                  {property.hasFloodCert ? <Link className={classes.donwloadLink}
                    onClick={() => {
                      floodZoneStore.downloadFloodCert(property);
                    }}
                  >FloodCert.pdf <GetApp /></Link> : '--'}
                </Grid>
              </Grid>
            </Grid>
            {property?.floodOverrideReason && <Grid item container xs={12} spacing={1}>
              <Grid item xs={6} className={classes.dataLabel}>
                Override Explanation
              </Grid>
              <Grid item xs={12}>
                {property?.floodOverrideReason}
              </Grid>
            </Grid>}
          </Grid></>)
        )}
      </Grid>
    </Grid>);
});

export const EditPropertyFlood = observer(({ ...props }: NavProps) => {
  const { insuranceInformationReviewStore: { floodZoneStore, insuranceInformationStore } } = useStore();
  const classes = useStyles();

  useEffect(() => {
    if (!floodZoneOptions.map(option => option.value).includes(floodZoneStore.selectedProperty?.floodZone)) {
      floodZoneStore.selectedProperty.floodZone = null;
    }
  }, []);

  return (
    <Grid container spacing={2} className={classes.editModeContainer}>
      <Grid xs={12} item>
        <Typography variant="h5">{floodZoneStore.selectedProperty?.address}</Typography>
      </Grid>
      <Grid xs={4} item>
        <SelectField
          label='Select a flood zone'
          standaloneLabel
          required
          variant='outlined'
          fullWidth
          onChange={value => floodZoneStore.setFloodZone(value)}
          testId='floodZone'
          value={floodZoneStore.selectedProperty?.floodZone}
          options={floodZoneOptions}
        />
      </Grid>
      <Grid xs={12} item>
        <Grid item xs={12}>
          <Typography>
            Flood certification document *
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FileUploadArea
            items={floodZoneStore.floodCertFiles}
            setItems={(updateFunc) => {
              const updatedItems = updateFunc(floodZoneStore.floodCertFiles);
              floodZoneStore.setFloodCertFiles(updatedItems);
            }}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} item spacing={1} direction="row-reverse">
        <Grid item>
          <Button size="large" variant="contained" color='primary' onClick={() => {
            floodZoneStore.saveFloodCert(async () => {
              await insuranceInformationStore.getLoanDetails(insuranceInformationStore.loanId);
              props.setUploadMode(false);
            });
          }}>Save</Button>
        </Grid>
        <Grid item>
          <Button size="large" variant="outlined" color='primary' onClick={() => props.setUploadMode(false)}>Cancel</Button>
        </Grid>
      </Grid>
    </Grid>);
})

export const OverrideFloodZone = observer(({ ...props }: NavProps) => {
  const { insuranceInformationReviewStore: { floodZoneStore, insuranceInformationStore } } = useStore();
  const classes = useStyles();
  return (
    <Grid spacing={2} container xs={12} item className={classes.editModeContainer}>
      <Grid xs={12} item>
        <Typography variant="h5">{floodZoneStore.selectedProperty?.address}</Typography>
      </Grid>
      <Grid xs={12} item>
        <TextField
          label='Please provide a detailed explanation as to why the flood quote is not required'
          standaloneLabel
          fullWidth
          required
          testId='floodException'
          multiline
          minRows={10}
          variant="outlined"
          value={floodZoneStore.selectedProperty?.floodOverrideReason}
          onChange={(event) => floodZoneStore.setFloodOverrideReason(event?.target?.value)}
        />
      </Grid>
      <Grid container xs={12} item spacing={1} direction="row-reverse">
        <Grid item>
          <Button size="large" variant="contained" color='primary' onClick={() => {
            floodZoneStore.saveFloodExceptionToProperty(async () => {
              await insuranceInformationStore.getLoanDetails(insuranceInformationStore.loanId);
              props.setOverrideMode(false);
            });
          }}>Save</Button>
        </Grid>
        <Grid item>
          <Button size="large" variant="outlined" color='primary' onClick={() => props.setOverrideMode(false)}>Cancel</Button>
        </Grid>
      </Grid>
    </Grid>);
});