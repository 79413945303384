import { Service } from '@roc/feature-app-core';

const url = {
  BORROWERS: '/api/v1/loan/borrower/getAllBorrowers',
  SAVE_BORROWER: '/api/v1/loan/borrower/saveBorrower',
  GET_BORROWER_BY_ID: '/api/v1/loan/borrower/getBorrowerById',
  BORROWERS_AND_LEADS: '/api/v1/loan/borrower/getAllBorrowersAndLeads',
  GET_PUBLIC_PROPERTY_DATA: '/api/v1/loan/borrower/getMultiplePublicPropertyData',
  GET_ALL_LENDER_BORROWERS: '/api/v1/loan/borrower/getAllLenderBorrowers',
  CREATE_BORROWER_USER: '/api/v1/user/createBorrowerUser',
  CREATE_BORROWER_USER_WITH_COMPANY: '/api/v1/user/createBorrowerUserWithCompany',
  RESEND_INVITE: '/api/v1/user/resendCreatePasswordInvite',
  DEACTIVATE_USER: '/api/v1/user/deactivateUser',
  GET_BORROWER_LOGIN_LINK: '/api/v1/user/getPasswordLinkForUser',
  GET_BASEBALL_CARD_DETAILS: '/api/v1/loan/getBorrowerBaseballCardViewOnly',
  GET_VERIFIED_TRACK_RECORD: '/api/v1/loan/getVerifiedPropertiesInTrackRecord',
  SAVE_PROPERTY_INFORMATION: '/api/v1/loan/addTrackRecord',
  EDIT_PROPERTY_INFORMATION: '/api/v1/loan/editTrackRecord',
  DELETE_TRACK_RECORD: '/api/v1/loan/deleteTrackRecord',
  DELETE_MULTIPLE_TRACK_RECORDS: '/api/v1/loan/deleteMultiplpeTrackRecords',
  GET_UNVERIFIED_TRACK_RECORD: '/api/v1/loan/getUnverifiedPropertiesInTrackRecord',
  GET_PUBLIC_TRACK_RECORD: '/api/v1/loan/getPublicTrackRecord',
  ENABLE_BORROWER_PORTAL_BY_TPO: '/api/v1/loan/enableBorrowerPortalForBorrowersByTpo',
  GET_BORROWER_PORTAL_PREFERENCES_BY_TPO: '/api/v1/loan/getLenderBOByCompanyId',
  DOWNLOAD_HANDBOOK: '/api/v1/loan/loanDocuments/downloadLoanBorrowerHandbookFile',

  CREATE_TASK: '/api/v1/tasks/createTasks',
  UPLOAD_TASK_FILE: '/api/v1/tasks/uploadTaskFile',
  GET_TASKS: '/api/v1/tasks/getTasks',
  DOWNLOAD_TASK_FILE: '/api/v1/tasks/downloadTaskFile',
  GET_PRE_SCREEN_TASKS: '/api/v1/loan/borrower/getBorrowerPreScreenTasks',
  GET_BORROWER_TIER: '/api/v1/loan/borrower/getBorrowerTierByBorrowerId',
  SAVE_PG_FOR_LOAN_BORROWER: '/api/v1/loan/borrower/savePgForLoanBorrower',
  BORROWER_PRESCREEN_INFO: '/api/v1/loan/borrowerSetup/getBorrowerPrescreenInfoForSubmission',
  BORROWER_EXPOSURE_DATA: '/api/v1/loan/borrower/getBorrowerExposureGrid',
};

export class BorrowersService extends Service {

  getBorrowerExposureGrid(borrowerId){
    return this.get(url.BORROWER_EXPOSURE_DATA + "?borrowerId=" + borrowerId)
  }

  savePropertyInformation(data: any) {
    return this.post(url.SAVE_PROPERTY_INFORMATION, data);
  }

  editPropertyInformation(data: any) {
    return this.post(url.EDIT_PROPERTY_INFORMATION, data);
  }


  deleteTrackRecord(id: number) {
    return this.delete(`${url.DELETE_TRACK_RECORD}/${id}`);
  }

  deleteMultipleTrackRecords(body: string) {
    const config = {
      headers: {
        'content-type': 'application/json'
      }
    }
    return this.post(`${url.DELETE_MULTIPLE_TRACK_RECORDS}`, { trackRecordIds: body }, config);
  }

  createTask(body: any) {
    return this.post(url.CREATE_TASK, body);
  }

  uploadTaskFile(body: any) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return this.post(url.UPLOAD_TASK_FILE, body, config);
  }

  getTasks(body) {
    return this.post(url.GET_TASKS, body);
  }

  downloadTaskFile(taskId) {
    return this.get(url.DOWNLOAD_TASK_FILE + "?taskId=" + taskId, null, { responseType: 'arraybuffer' })
      .then(response => new Blob([response.data]));
  }

  getAllBorrowers(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.BORROWERS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }
  saveBorrower(borrower) {
    return this.post(url.SAVE_BORROWER, borrower);
  }

  getAllBorrowersAndLeads(
    page: number,
    pageSize: number,
    sortDir: string,
    sortBy: string,
    filters: string,
    dropdownFilters: string
  ) {
    return this.get(
      url.BORROWERS_AND_LEADS,
      {
        page,
        pageSize,
        sortDir,
        sortBy,
        filters,
        dropdownFilters
      },
      {
        disableGlobalLoading: true,
      }
    );
  }

  getAllLenderBorrowers(page: number, pageSize: number, sortDir: string, sortBy: string, filters: object, dropdownFilters: object) {
    return this.get(
      url.GET_ALL_LENDER_BORROWERS,
      { page, pageSize, sortDir, sortBy, filters, dropdownFilters },
      { disableGlobalLoading: true }
    );
  }

  createBorrowerUser(borrowerUser) {
    return this.post(url.CREATE_BORROWER_USER_WITH_COMPANY, borrowerUser);
  }

  resendInvite(userId: number) {
    return this.put(`${url.RESEND_INVITE}?userId=${userId}`, {});
  }

  deactivateUser(deactivateUserRequest) {
    return this.post(url.DEACTIVATE_USER, deactivateUserRequest);
  }

  getBorrowerLoginLink(userId: number) {
    return this.get(`${url.GET_BORROWER_LOGIN_LINK}?userId=${userId}`, {});
  }

  getBaseballCardDetails(borrowerId: number, loanId?: number) {
    return this.get(url.GET_BASEBALL_CARD_DETAILS, { borrowerId, loanId });
  }

  getVerifiedTrackRecord(borrowerId: number,) {
    return this.get(url.GET_VERIFIED_TRACK_RECORD, { borrowerId });
  }

  getUnverifiedTrackRecord(borrowerId: number) {
    return this.get(url.GET_UNVERIFIED_TRACK_RECORD, { borrowerId });
  }

  getPublicTrackRecord(borrowerId: number) {
    return this.get(url.GET_PUBLIC_TRACK_RECORD, { borrowerId });
  }

  enableBorrowerPortalForBorrowersByTpo(enableBorrowerPortal: boolean, companyId: number) {
    return this.post(`${url.ENABLE_BORROWER_PORTAL_BY_TPO}?enableBorrowerPortal=${enableBorrowerPortal}&companyId=${companyId}`, {});
  }

  getBorrowerPortalPreferencesByTpo(companyId: number) {
    return this.get(`${url.GET_BORROWER_PORTAL_PREFERENCES_BY_TPO}?companyId=${companyId}`, {});
  }

  downloadHandbook() {
    return this.post(url.DOWNLOAD_HANDBOOK, {}, {}, { responseType: 'arraybuffer' });
  }

  getBorrowerById(borrowerId?: number | string) {
    return this.get(url.GET_BORROWER_BY_ID, { borrowerId });
  }

  getBorrowerPreScreenTasks(borrowerId: number | string, taskType: string) {
    return this.get(url.GET_PRE_SCREEN_TASKS, { borrowerId, taskType });
  }

  getBorrowerTierByBorrowerId(borrowerId: number) {
    return this.get(url.GET_BORROWER_TIER, { borrowerId });
  }

  savePgToLoanBorrower(params) {
    return this.put(`${url.SAVE_PG_FOR_LOAN_BORROWER}?loanId=${params.loanId}&borrowerId=${params.borrowerId}&borrowerEntityId=${params.borrowerEntityId}&personalGuarantor=${params.personalGuarantor}`, {});
  }

  getBorrowerPrescreenInfoForSubmission(params) {
    return this.get(url.BORROWER_PRESCREEN_INFO, params);
  }
}
