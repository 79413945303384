/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Tabs } from "@roc/ui";
import { useState } from "react";
import { ValuationTab } from "./valuationTab";
import { NeigborhoodTab } from "./neighborhoodTab";
import { TitleHistoryTab } from "./titleHistoryTab";
import { InternalValuationReviewStore } from "../../stores/internalValuationReviewStore";
import { observer } from "mobx-react";
import { Box } from "@material-ui/core";
import { LiquidityReportTab } from "./liquidityReportTab";
import { ComparableAnalysisTab } from "./comparableAnalysisTab";
import { ReportHistoryTab } from "./reportHistoryTab";

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    backgroundColor: "transparent !important",
    border: "0 !important",
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& > div": {
      paddingBottom: "0px !important",
    },
    "& .MuiTabs-flexContainer": {
      gap: "3px",
    },
    "& .MuiTabs-root": {
      paddingLeft: "1rem",
    },
  },
  tab: {
    backgroundColor: "#F7F7F7",
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    borderRadius: "10px 10px 0px 0px",
    border: "none",
    borderBottom: "none",
    width: "fit-content",
    maxWidth: "none",
    "& .MuiTab-root": {
      minWidth: "14rem !important",
    }
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 700,
    color: "#FFFFFF",
  },
  tabContent: {
    backgroundColor: "#FFFFFF",
    minHeight: "450px"
  },
}));

interface InternalValuationReviewTabsProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const InternalValuationReviewTabs = observer(({ internalValuationReviewStore }: InternalValuationReviewTabsProps) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      label: "Valuation",
      value: "Valuation",
    },
    {
      label: "NEIGHBORHOOD & HOUSING CONDITIONS",
      value: "NEIGHBORHOOD & HOUSING CONDITIONS",
    },
    {
      label: "COMPARABLE ANALYSIS",
      value: "COMPARABLE ANALYSIS",
    },
    {
      label: "LIQUIDITY REPORT",
      value: "LIQUIDITY REPORT",
    },
    {
      label: "TITLE HISTORY",
      value: "TITLE HISTORY",
    },
    {
      label: "REPORT HISTORY",
      value: "REPORT HISTORY",
    },
  ];

  const onTabChangeHandler = (_, index) => {
    if (tabIndex < 3) {
      internalValuationReviewStore.saveInternalValuationReview(true);
    }
    if (index === 5) {
      internalValuationReviewStore.loadReportHistoryRecords(true);
    }
    setTabIndex(index);
  };

  return (
    <>
      <Tabs
        tabs={tabs}
        tabClassName={classes.tab}
        tabSelectedClassName={classes.activeTab}
        className={classes.tabs}
        selectedTab={tabIndex === -1 ? 0 : tabIndex}
        onTabChange={onTabChangeHandler}
      />
      <Box className={classes.tabContent}>
        {tabIndex === 0 && <ValuationTab internalValuationReviewStore={internalValuationReviewStore} />}
        {tabIndex === 1 && <NeigborhoodTab internalValuationReviewStore={internalValuationReviewStore} />}
        {tabIndex === 2 && <ComparableAnalysisTab internalValuationReviewStore={internalValuationReviewStore} />}
        {tabIndex === 3 && <LiquidityReportTab internalValuationReviewStore={internalValuationReviewStore} />}
        {tabIndex === 4 && <TitleHistoryTab internalValuationReviewStore={internalValuationReviewStore} />}
        {tabIndex === 5 && <ReportHistoryTab internalValuationReviewStore={internalValuationReviewStore} />}
      </Box>
    </>
  );
});
