import { Grid, Menu, ListItemText, ListItemIcon, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, PlaylistAddCheck } from '@material-ui/icons';
import { Button, ConfirmationMenuItem, ConfirmDialog, Toolbar } from '@roc/ui';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { OrderTaxCertificateModal } from './orderTaxCertificateModal';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';
import { TaxTabs } from '../types/types';
import { UploadPaymentZipModal } from './uploadPaymentZipModal';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    gap: '10px'
  },
}));


export const TaxCertificateToolbar = observer(() => {
  const [showOrderCertificateModal, setShowOrderCertificateModal] = useState(false)
  const { taxCertificateStore } = useTaxCertificateStore();

  const createTaxCertificate = (loanId) => {
    taxCertificateStore.createTaxCertificate(loanId);
    setShowOrderCertificateModal(false)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              testId="create-order-bpo"
              onClick={() => {
                setShowOrderCertificateModal(true);
              }}
              size="small"
            >
              Order Certificate
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
      <OrderTaxCertificateModal
        showOrderCertificateModal={showOrderCertificateModal}
        closeOrderModal={() => setShowOrderCertificateModal(false)}
        handleOrderConfirm={createTaxCertificate}
      />
    </>


  );
});


export const RedemptionToolbar = observer(() => {
  const classes = useStyles();
  const { taxCertificateStore } = useTaxCertificateStore();
  const { loanIds } = taxCertificateStore;
  const anchorRef = useRef(null);
  const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);

  const closeMenu = () => {
    setShowBulkActionsMenu(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Toolbar>

            <Box style={{ textAlign: 'center' }}>
              <div ref={anchorRef} style={{ display: 'inline-block' }}>
                <Button
                  onClick={() => { setShowBulkActionsMenu(true) }}
                  testId="open_menu"
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={loanIds.length == 0}
                >
                  Bulk Actions <ArrowDropDown style={{ marginRight: '-6px' }} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorRef.current}
                  open={showBulkActionsMenu}
                  onClose={closeMenu}
                  keepMounted={true}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                >
                  <ConfirmationMenuItem
                    testId={`create-redemption-request`}
                    onClick={async () => {
                      taxCertificateStore.createRedemptionRequest();
                    }}
                    confirmDialogProps={{
                      body: `Are you sure you create redemption request?`,
                    }}
                  >
                    <ListItemIcon>
                      <PlaylistAddCheck fontSize="small" />
                    </ListItemIcon>
                    <ListItemText onClick={closeMenu}>Create Redemption Request</ListItemText>
                  </ConfirmationMenuItem>
                </Menu>
              </div>
            </Box>
          </Toolbar>
        </Grid>
      </Grid>
    </>
  );
});


export const PaymentApprovalToolbar = observer(() => {
  const classes = useStyles();
  const { taxCertificateStore } = useTaxCertificateStore();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const sendPaymentApproval = () => {
    taxCertificateStore.sendPaymentApproval();
    setOpenConfirmModal(false);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Toolbar>
            <Box className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                testId="create-order-bpo"
                onClick={() => {
                  setOpenUploadModal(true);
                }}
                size="small"
              >
                Upload Payment Files
              </Button>
              <Button
                variant="contained"
                color="primary"
                testId="create-order-bpo"
                onClick={() => setOpenConfirmModal(true)}
                size="small"
              >
                Send Payment Approval
              </Button>
            </Box>

          </Toolbar>
        </Grid>
      </Grid>
      <UploadPaymentZipModal open={openUploadModal} onClose={() => setOpenUploadModal(false)} />

      <ConfirmDialog
        open={openConfirmModal}
        body={<span>Are you sure you want to send payment approval?</span>}
        confirmButtonText={'Confirm'}
        handleCancel={() => setOpenConfirmModal(false)}
        handleClose={() => setOpenConfirmModal(false)}
        handleConfirm={sendPaymentApproval}
      />
    </>

  );
});

export const getTaxToolbar = (tab: TaxTabs): React.ReactNode => {

  switch (tab) {
    case TaxTabs.TAX_CERTIFICATE:
      return <TaxCertificateToolbar />
    case TaxTabs.REDEMPTION_REQUEST:
      return <RedemptionToolbar />
    case TaxTabs.PAYMENT_APPROVAL:
      return <PaymentApprovalToolbar />
    default:
      return <></>;
  }

}
