import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { action, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { EntityFormStore } from './entityFormStore';
import {
  DateFormat,
  formatDate,
  GENERIC_ERROR_MESSAGE,
  parseAddress,
} from '@roc/feature-utils';
import { SearchEntityGridStore } from './searchEntityGridStore';
import { SelectEntityOption } from '../../utils/constants';
import { QuoteTypeStore } from '../quoteTypeStore';

export class EntityStore {
  private globalStore: GlobalStore;
  private oneToolService: OneToolService;
  private quoteTypeStore: QuoteTypeStore;
  public entityFormStore: EntityFormStore;
  public entityDropdownData: any[];
  public borrowers: any[];
  public borrowersFormsStoreIds: string[];
  public searchEntityGridStore: SearchEntityGridStore;
  public selectEntityOption: SelectEntityOption;

  constructor(globalStore, quoteTypeStore: QuoteTypeStore) {
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.oneToolService = new OneToolService();
    this.entityFormStore = new EntityFormStore(globalStore);
    this.entityDropdownData = [];
    this.borrowers = [];
    this.borrowersFormsStoreIds = [];
    this.searchEntityGridStore = new SearchEntityGridStore(globalStore, this);

    makeObservable(this, {
      reset: action,
      entityDropdownData: observable,
      borrowers: observable,
      selectEntityOption: observable,
      getEntityDropdownData: flow,
      fetchEntityById: flow,
      borrowersFormsStoreIds: observable,
      setSelectEntityOption: action,
      loadEntityData: action,
      loadEntity: action,
      getEntity: action,
      clearEntityData: action,
      clearSelectedBorrowers: action,
    });
  }

  setSelectEntityOption(option: SelectEntityOption) {
    if (this.selectEntityOption != option) {
      this.selectEntityOption = option;
      this.clearEntityData();
      if (
        SelectEntityOption.SEARCH_ENTITY === this.selectEntityOption ||
        SelectEntityOption.SEARCH_ENTITY === option
      ) {
        this.clearSelectedBorrowers();
      }
    }
  }

  clearEntityData() {
    this.entityFormStore.reset();
    const hasEntity = this.selectEntityOption != SelectEntityOption.NO_ENTITY;
    this.entityFormStore.onFieldChange('hasEntity', hasEntity);
  }

  clearSelectedBorrowers() {
    this.quoteTypeStore.borrowersStore.resetBorrowerFormStores();
    this.quoteTypeStore.borrowersStore.addNewBorrower();
  }

  *getEntityDropdownData() {
    try {
      const response = yield this.oneToolService.getEntityDropdownData();
      this.entityDropdownData = response.data.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *fetchEntityById(entityId: number) {
    try {
      const { borrowersStore } = this.quoteTypeStore;
      const response = yield this.oneToolService.getEntityById(entityId);
      const entity = response?.data?.data;
      const parsedAddress = parseAddress(entity?.entityAddress, undefined);

      this.loadEntity({
        ...entity,
        address: {
          ...parsedAddress,
          address:
            (parsedAddress.street_number || '') +
            `${parsedAddress.street ? ' ' + parsedAddress.street : ''}`,
        },
      });

      this.borrowers = Array.from(new Set(response.data.data.borrowers));

      this.borrowersFormsStoreIds.forEach(id =>
        borrowersStore.removeBorrower(id)
      );
      if (this.borrowers.length > 0) {
        borrowersStore.resetBorrowerFormStores();
      }

      this.borrowers.forEach(borrower => {
        const borrowerFormStore = borrowersStore.createBorrowerForm();
        //borrowerFormStore.loadForm(borrower);
        borrowerFormStore.loadForm({
          borrowerId: borrower.borrowerId,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          emailAddress: borrower.emailAddress,
          ficoScore: borrower.ficoScore,
          numberOfVerifiedInvestmentProperties:
            borrower.experience === 0 ? '' : borrower.experience,
          assets: borrower.assets,
          liabilities: borrower.liabilities,
          projectsCompleted:
            !!borrower.portalProjectsCompleted ||
            !!borrower.portalProjectsActive,
          percentageOfOwnership: borrower.percentageOfOwnership,
          experience: borrower.experience,
          citizenshipStatus: borrower.citizenshipStatus,
        });
        borrowerFormStore.setEditMode(false);
        this.borrowersFormsStoreIds.push(borrowerFormStore.key);
        borrowersStore.addBorrower(borrowerFormStore);
      });
      this.entityFormStore.setIsValid(true);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  loadEntityData(quoteData) {
    this.selectEntityOption = quoteData.uiState.selectEntityOption;
    this.loadEntity(quoteData.entity);
  }

  loadEntity(entity) {
    this.entityFormStore.loadForm({
      entityId: entity?.entityId ?? '',
      entityName: entity?.entityName ?? '',
      entityEin: entity?.entityEin ?? '',
      entityType: entity?.entityType ?? '',
      dateOfIncorporation: entity?.dateOfIncorporation ?? null,
      address: entity?.address ?? null,
      hasEntity: entity?.hasEntity ?? true,
    });
  }

  reset() {
    this.entityFormStore.reset();
    this.selectEntityOption = null;
    this.borrowersFormsStoreIds = [];
  }

  setHasEntity(value) {
    this.entityFormStore.reset();
    this.entityFormStore.onFieldChange('hasEntity', value);
  }

  getEntity() {
    const values = this.entityFormStore.getFormValues();
    return {
      ...values,
      dateOfIncorporation: formatDate(
        values.dateOfIncorporation,
        DateFormat.MMDDYYYY
      ),
    };
  }

  validateEntity() {
    switch (this.selectEntityOption) {
      case SelectEntityOption.ADD_ENTITY:
        this.entityFormStore.runFormValidationWithMessage();
        return this.entityFormStore.form.meta.isValid;
      case SelectEntityOption.SEARCH_ENTITY:
        if (!this.entityFormStore.form.fields.entityId.value) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'You need to select an entity to continue.',
          });
          return false;
        }
        return true;
      case SelectEntityOption.NO_ENTITY:
        return true;
    }
  }
}
