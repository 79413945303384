import { Grid, Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import { observer } from 'mobx-react';
import {
  Button, createAutocompleteAddressField, createCheckboxField, createCurrencyField, createDateField,
  createMultiSelectField, createPhoneField, createTextField, FileUploadArea
} from '@roc/ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isNil } from '@roc/feature-utils';
import { useEffect } from 'react';
import { InsuranceInformationBaseStore } from '../stores';
import { Layout } from '@roc/feature-app-core';
import { InfoOutlined } from '@material-ui/icons';

export type InsuranceQuoteProps = {
  store: InsuranceInformationBaseStore,
  showCancelOption?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldLabel: {
      '& .MuiTypography-body1': {
        fontWeight: 'bold'
      }
    },
    agentNotice: {
      color: 'grey',
      fontWeight: 'bold',
      margin: '0px 10px'
    },
    agentNoticeContainer: {
      padding: 0,
    },
    alert: {
      display: 'flex',
      alignItems: 'center'
    },
    alertIcon: {
      display: 'inline-block',
      margin: '5px'
    },
    documentsErrorMessage: {
      color: '#EB5463',
      fontSize: '0.75rem',
      marginTop: '0.25rem',
      fontWeight: 'lighter',
      fontFamily: 'Manrope, sans- serif'
    }
  })
);

export const AddEditInsuranceQuote = observer((props: InsuranceQuoteProps) => {
  const { store, showCancelOption } = props;
  const { insuranceQuoteFormStore } = props.store;
  const classes = useStyles();

  useEffect(() => {
    insuranceQuoteFormStore.setPortalToForm();
  }, [])


  return (
    <div style={{ backgroundColor: '#FFF', width: '100%' }}>
      <Layout>
        <Grid container spacing={2}>
          {!insuranceQuoteFormStore.isInternal && <Grid item xs={12} className={classes.agentNoticeContainer}>
            <Typography className={classes.alert}><InfoOutlined className={classes.alertIcon} /><b>Please note that we will only reach out to the agent if you add them to the loan chat by following this steps <a href='https://www.veed.io/embed/2a1c3b11-1056-4f9e-96bf-91f6e048e431' target='_blank'>here</a></b></Typography>
          </Grid>}
          <Grid item xs={4}>
            {createTextField({
              label: 'Insurance Agent Name',
              fieldName: 'insuranceAgentName',
              testId: 'insuranceAgentName',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createTextField({
              label: 'Insurance Agent Email',
              fieldName: 'insuranceAgentEmail',
              testId: 'insuranceAgentEmail',
              store: insuranceQuoteFormStore,
              type: 'email',
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createPhoneField({
              label: 'Insurance Agent Phone',
              fieldName: 'insuranceAgentPhone',
              testId: 'insuranceAgentPhone',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          {insuranceQuoteFormStore.isInternal && <Grid item xs={12}>
            {createAutocompleteAddressField({
              fieldName: 'insuranceAgentAddress',
              testId: 'address',
              label: 'Insurance Agent Address *',
              store: insuranceQuoteFormStore,
              onChange: (event: any) => insuranceQuoteFormStore.onFieldChange('insuranceAgentAddress', event?.target?.value),
              standaloneLabel: false,
              showToolTip: false
            })}
          </Grid>}
          <Grid item xs={4}>
            {createTextField({
              label: `Policy Number${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'policyNumber',
              testId: 'policyNumber',
              store: insuranceQuoteFormStore,
              standaloneLabel: false
            })}
          </Grid>
          <Grid item xs={4}>
            {createDateField({
              label: `Insurance Effective Date${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'insuranceEffectiveDate',
              testId: 'insuranceEffectiveDate',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createDateField({
              label: `Insurance Expiry Date${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'insuranceExpiryDate',
              testId: 'insuranceExpiryDate',
              store: insuranceQuoteFormStore,
              standaloneLabel: false
            })}
          </Grid>
          <Grid item xs={4}>
            {createTextField({
              label: `Carrier${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'carrier',
              testId: 'carrier',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createTextField({
              label: `Carrier NAIC${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'carrierNaic',
              testId: 'carrierNaic',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createCurrencyField({
              label: `Coverage Amount${!insuranceQuoteFormStore.isInternal ? ' (Optional)' : ''}`,
              fieldName: 'coverageAmount',
              testId: 'coverageAmount',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
            })}
          </Grid>
          <Grid item xs={4}>
            {createCurrencyField({
              label: `Insurance Premium`,
              fieldName: 'insurancePremium',
              testId: 'insurancePremium',
              store: insuranceQuoteFormStore,
              standaloneLabel: false
            })}
          </Grid>
          <Grid item xs={4}>
            {createCurrencyField({
              label: `Paid Already`,
              fieldName: 'paidAlready',
              testId: 'paidAlready',
              store: insuranceQuoteFormStore,
              standaloneLabel: false
            })}
          </Grid>
          <Grid item xs={4}>
            {createCurrencyField({
              label: 'Remaining Amount',
              fieldName: 'remainingAmount',
              testId: 'remainingAmount',
              store: insuranceQuoteFormStore,
              standaloneLabel: false,
              disabled: true,
            })}
          </Grid>
          {!isNil(insuranceQuoteFormStore.form.fields.insurancePremium.value) &&
            <Grid item xs={12}>
              <Alert severity={insuranceQuoteFormStore.paymentType == 'Paid Already' ? 'success' : 'info'}>
                <AlertTitle>
                  <>
                    {insuranceQuoteFormStore.paymentType == 'Paid Already' ? 'Insurance Premium is ' : 'The remaining amount will be '}
                    <b>{insuranceQuoteFormStore.paymentType}</b>
                  </>
                </AlertTitle>
              </Alert>
            </Grid>
          }
          <Grid item xs={12} className={classes.boldLabel}>
            {createMultiSelectField({
              label: 'Please select the properties for the quote',
              fieldName: 'properties',
              testId: 'properties',
              store: insuranceQuoteFormStore,
              options: store?.propertiesOptions,
            })}
          </Grid>
          {insuranceQuoteFormStore.isInternal && <><Grid item xs={12}>
            <Typography>
              <b>Insurance Coverage *</b>
            </Typography>
            {(!isNil(insuranceQuoteFormStore.documentIsValid) && !insuranceQuoteFormStore.getValue('generalLiabilityCoverage') &&
              !insuranceQuoteFormStore.getValue('propertyCoverage') &&
              !insuranceQuoteFormStore.getValue('floodCoverage')) &&
              <Typography className={classes.documentsErrorMessage}>
                <b>Insurance coverage is required.</b>
              </Typography>}
          </Grid>
            <Grid item xs={2}>
              {createCheckboxField({
                label: 'Package',
                fieldName: 'packageCoverage',
                store: insuranceQuoteFormStore,
              })}
            </Grid>
            <Grid item xs={2}>
              {createCheckboxField({
                label: 'Property',
                fieldName: 'propertyCoverage',
                store: insuranceQuoteFormStore,
                disabled: insuranceQuoteFormStore.packageSelected
              })}
            </Grid>
            <Grid item xs={3}>
              {createCheckboxField({
                label: 'General Liability',
                fieldName: 'generalLiabilityCoverage',
                store: insuranceQuoteFormStore,
                disabled: insuranceQuoteFormStore.packageSelected
              })}
            </Grid>
            <Grid item xs={2}>
              {createCheckboxField({
                label: 'Flood',
                fieldName: 'floodCoverage',
                store: insuranceQuoteFormStore,
              })}
            </Grid></>}
          {!props.store.currentQuote.insuranceQuoteDetailId && <><Grid item xs={12}>
            <Typography>
              <b>Documents *</b>
            </Typography>
            {!isNil(insuranceQuoteFormStore.documentIsValid) && !insuranceQuoteFormStore.documentIsValid &&
              <Typography className={classes.documentsErrorMessage}>
                <b>A document is required.</b>
              </Typography>}
          </Grid>
            <Grid item xs={12}>
              <FileUploadArea
                items={insuranceQuoteFormStore.documents}
                setItems={(updateFunc) => {
                  const updatedItems = updateFunc(insuranceQuoteFormStore.documents);
                  insuranceQuoteFormStore.setDocuments(updatedItems);
                }}
                multiple
              />
            </Grid></>}
          <Grid item xs={12} container direction='row' justifyContent='flex-end' spacing={2}>
            {showCancelOption && <Grid item>
              <Button
                testId="cancelQuote"
                variant="outlined"
                color="primary"
                onClick={() => store.handleCloseSave()}
              >
                Cancel
              </Button>
            </Grid>}
            <Grid item>
              <Button
                testId="saveQuote"
                variant="contained"
                color="primary"
                onClick={() => store.validateAndSaveQuote(insuranceQuoteFormStore.getInsuranceQuote())}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </div>
  );
});