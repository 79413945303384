import { ChatRoomType, FilterType } from "../types/communicationTypes";

export const MAX_FILE_SIZE = 52428800;

export const templatePlaceHolders = [
  '{firstname}',
  '{lastname}',
  '{fullname}'
]

export const chatRoomTypeFilters = {
  [FilterType.TASKS]: [ChatRoomType.TASKS]
}