import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { Borrower } from '../types';
import { EmailAddress, FirstName, LastName, PersonalGuarantor } from '@roc/ui/formComponents';
import { useBorrowersStore } from '../hooks/useBorrowersStore';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button } from '@roc/ui';
import { BorrowerSetupForm } from '../borrowerSetup';
import { useStore } from '@roc/client-portal-shared/hooks';
import BorrowerSetupLink from '../borrowerSetup/borrowerSetupLink';
import { insertIf, isNil } from '@roc/feature-utils';
import ErrorMessages from './errorMessages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepsContentContainer: {
      margin: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    alertWrapper: {
      width: '100%',
      marginBottom: '16px'
    }
  })
);

export type AddBorrowerWithPrequalificationFormProps = {
  borrower?: Borrower;
  handleClose: () => void;
  handleSaveBorrower: (onSuccess?: () => void) => void;
  isInternal?: boolean;
  borrowerSetupStore: any;
  loanId?: number
  borrowerEntityId?: number;
  refreshLoan?: () => void;
  tpoAlreadyHaveInformationStore: any;
  showAdditionalInformation?: boolean;
};

export const AddBorrowerWithPrequalificationForm = observer((props: AddBorrowerWithPrequalificationFormProps) => {
  const { borrower, handleSaveBorrower, handleClose, isInternal = false, borrowerSetupStore, loanId, borrowerEntityId, refreshLoan, tpoAlreadyHaveInformationStore, showAdditionalInformation = false } = props;

  const { borrowersStore } = useBorrowersStore();
  const { newBorrowerFormStore, globalStore } = borrowersStore;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(borrowersStore?.initialBorrowerFormStep ?? 0);
  const [stepsLabel, setStepsLabel] = useState([]);
  const personalGuarantor = !isNil(borrower?.personalGuarantor) ? borrower?.personalGuarantor : borrower?.runCreditOnCreation;

  useEffect(() => {
    if (borrower && borrowersStore?.initialBorrowerFormStep) {
      setStepsLabel([
        'Guarantor/Non Guarantor',
        'Borrower Setup',
        ...insertIf(showAdditionalInformation, ['Additional Information']),
      ]);
    } else {
      setStepsLabel([
        'Personal Information',
        ...insertIf(!globalStore?.lenderInfo?.showOnlyCafOption, ['Borrower Setup']),
        ...insertIf(showAdditionalInformation, ['Additional Information']),
      ]);
    }
  }, []);

  useEffect(() => {
    if (borrower && borrowersStore?.initialBorrowerFormStep) {
      const step = borrowerSetupStore.lineItemDefaultStep === 'Borrower Setup' ? 1 : borrowersStore?.initialBorrowerFormStep;
      setActiveStep(step);
    }
  }, [borrowerSetupStore.lineItemDefaultStep]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  }

  const handleNext = async () => {
    if (!borrowersStore.initialBorrowerFormStep) {
      if (activeStep === 0) {
        handleSaveBorrower(() => {
          if (!globalStore?.lenderInfo?.showOnlyCafOption) {
            setActiveStep(activeStep + 1);
          }
          else {
            const { borrowerSetupFormStore } = borrowerSetupStore;
            borrowerSetupFormStore.onFieldChange('creditBackgroundCheck', true);
            borrowerSetupStore.saveBorrowerSelection(() => {
              if (showAdditionalInformation) {
                setActiveStep(activeStep + 1)
              }
              else {
                handleClose();
              }
            });
          }
        });
      } else if (activeStep === 1) {
        if (!globalStore?.lenderInfo?.showOnlyCafOption) {
          borrowerSetupStore.saveBorrowerSelection(() => {
            if (showAdditionalInformation) {
              setActiveStep(activeStep + 1)
            }
            else {
              handleClose();
            }
          });
        }
        else {
          const success = await tpoAlreadyHaveInformationStore.saveForAlreadyHaveBorrowerInformation(borrower?.borrowerId, personalGuarantor, loanId || borrower?.loanId);
          if (success) {
            handleClose();
          }
        }
      } else if (activeStep === 2) {
        const success = await tpoAlreadyHaveInformationStore.saveForAlreadyHaveBorrowerInformation(borrower?.borrowerId, personalGuarantor, loanId || borrower?.loanId);
        if (success) {
          handleClose();
        }

      }
    } else {
      if (activeStep === 0) {
        const callback = () => {
          if (!isNil(loanId)) {
            refreshLoan();
            handleClose();
          } else {
            setActiveStep(activeStep + 1)
          }
        }
        borrowerSetupStore.savePgForLoanBorrower(loanId, borrowerEntityId, () => callback());
      }
      else if (activeStep === 1) {
        borrowerSetupStore.saveBorrowerSelection(() => {
          if (showAdditionalInformation) {
            setActiveStep(activeStep + 1)
          }
          else {
            handleClose();
          }
        });
      } else if (activeStep === 2) {
        const success = await tpoAlreadyHaveInformationStore.saveForAlreadyHaveBorrowerInformation(borrower?.borrowerId, personalGuarantor, loanId || borrower?.loanId);
        if (success) {
          handleClose();
        }

      }
    }

  }

  const getAddForm = () => (
    <Grid container justifyContent={'center'} spacing={2}>
      <Grid item xs={12}>
        <FirstName store={newBorrowerFormStore} disabled={newBorrowerFormStore.isEditDisabled} />
      </Grid>
      <Grid item xs={12}>
        <LastName store={newBorrowerFormStore} disabled={newBorrowerFormStore.isEditDisabled} />
      </Grid>
      <Grid item xs={12}>
        <EmailAddress store={newBorrowerFormStore} required disabled={newBorrowerFormStore.isEditDisabled} />
      </Grid>
      <Grid item xs={12}>
        <PersonalGuarantor store={newBorrowerFormStore} label={`Will this borrower be a personal guarantor?(If yes, the borrower's credit and background reports are needed.  If no, only a background report is needed.)`} />
      </Grid>
    </Grid>
  );

  const getGuarantorForm = () => (
    <Grid container justifyContent={'center'} spacing={2}>
      <Grid item xs={12}>
        <PersonalGuarantor store={borrowerSetupStore.borrowerSetupFormStore} label={`Will this borrower be a personal guarantor?(If yes, the borrower's credit and background reports are needed.  If no, only a background report is needed.)`} />
      </Grid>
    </Grid>
  );

  const getSetupForm = () => {
    if (!borrowerSetupStore.currentBorrower) {
      return <></>;
    }
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <BorrowerSetupForm borrowerSetupStore={borrowerSetupStore} handleSave={handleNext} onBack={handleBack} isInternal={isInternal} />
        </Grid>
      </Grid >
    )
  };

  const getSetupResultContent = () => {
    if (!borrowerSetupStore.currentBorrower) {
      return <></>;
    }
    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <BorrowerSetupLink borrowerSetupStore={borrowerSetupStore} onBack={handleBack} handleClose={handleClose} handleNext={handleNext} handleBack={handleBack} tpoAlreadyHaveInformationStore={tpoAlreadyHaveInformationStore} />
        </Grid>
      </Grid >
    );
  }

  const getStepContent = (step: number) => {
    if (borrowersStore.initialBorrowerFormStep === 2) {
      if (step === 0) {
        return getGuarantorForm();
      }
      if (step === 1) {
        return getSetupForm();
      }
      if (showAdditionalInformation && step === 2) {
        return getSetupResultContent();
      }
    }
    if (step === 0) {
      return getAddForm();
    }
    if (step === 1) {
      if (!globalStore?.lenderInfo?.showOnlyCafOption) {
        return getSetupForm();
      }
      else if (globalStore?.lenderInfo?.showOnlyCafOption && showAdditionalInformation) {
        return getSetupResultContent();
      }
      else {
        return <></>;
      }
    }
    if (showAdditionalInformation && step === 2) {
      return getSetupResultContent();
    }
    return <></>;
  }

  const renderButtons = () => {
    let buttons = [];

    if (activeStep === 0) {
      buttons = buttons.concat(
        (<Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          testId={`save-${activeStep + 1}`}
          className={classes.button}
        >
          SAVE
        </Button>)
      )
    }

    return (
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12} className={classes.buttonContainer}>
          {buttons}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container justifyContent={'center'} style={{ marginTop: '-24px' }}>
      <ErrorMessages currentBorrower={borrowerSetupStore.currentBorrower} dialogState={borrowersStore?.dialogState} />
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsLabel.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }} >
              <Grid xs={12} className={classes.stepsContentContainer}>
                {getStepContent(index)}
                <Grid xs={12}>
                  {renderButtons()}
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
});

export default AddBorrowerWithPrequalificationForm;
