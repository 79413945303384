import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createRadioField } from '@roc/ui';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { insertIf, yesNoOptionsBoolean } from '@roc/feature-utils';
import { TodoStatus } from '@roc/feature-loan-details';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '13px',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: '100px'
  },
  alertsQuestions: {
    fontSize: '1rem',
    marginBottom: '8px'
  },
}));

export const BorrowerSetupOptions = observer((props) => {
  const { store, refreshLoan } = props;
  const [isPersonalGuarantor, setIsPersonalGuarantor] = useState(store.personalGuarantor)
  useEffect(() => {
    setIsPersonalGuarantor(store.borrowerSetupOptionsFormStore.getFormValues().runCreditOnCreation)
  }, [store.borrowerSetupOptionsFormStore.form.fields.runCreditOnCreation.value])
  const classes = useStyles();
  const { globalStore } = store;

  useEffect(() => {
    if (globalStore?.lenderInfo?.showOnlyCafOption) {
      store.borrowerSetupOptionsFormStore.onFieldChange('sendIdVerification', true);
    }
  }, [])

  const pgOptions = [
    { label: `Please send a <b>soft</b> credit pull authorization from portal`, value: true },
    ...insertIf(!globalStore?.lenderInfo?.showOnlyCafOption, [{ label: `I have a Xactus360 <b>hard</b> credit pull along with borrower information to upload`, value: false }])
  ];

  const nonPgOptions = [
    { label: `Please send the borrower an authorization form for the background check`, value: true },
    ...insertIf(!globalStore?.lenderInfo?.showOnlyCafOption, [{ label: `I have the borrowers information to supply on my own`, value: false }])
  ];

  return (
    <Grid item xs={12}>
      {store.isEditOptionEnabled ?
        <Alert severity="info">
          <AlertTitle><b>Is this borrower a personal guarantor?</b></AlertTitle>
          {createRadioField({
            label: "",
            fieldName: 'runCreditOnCreation',
            testId: 'runCreditOnCreation',
            store: store.borrowerSetupOptionsFormStore,
            options: yesNoOptionsBoolean,
            row: false,
          })}
          <AlertTitle><b>How are we obtaining your borrowers information?</b></AlertTitle>
          {isPersonalGuarantor ?
            <>
              {createRadioField({
                label: "Please choose one of the following",
                fieldName: 'sendIdVerification',
                testId: 'sendIdVerification',
                store: store.borrowerSetupOptionsFormStore,
                needHtml: true,
                options: pgOptions,
                row: false,
              })}
            </>
            :
            <>
              {createRadioField({
                label: "Please choose one of the following",
                fieldName: 'sendIdVerification',
                testId: 'sendIdVerification',
                store: store.borrowerSetupOptionsFormStore,
                options: nonPgOptions,
                row: false,
              })}
            </>
          }
          <div />
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              store.handleCancelEditOption();
            }}
            testId="cancel"
            className={classes.button}
            size='small'
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              store.editBorrowerSetupOptions(refreshLoan);
            }}
            testId="save"
            className={classes.button}
            size='small'
          >
            Save
          </Button>
        </Alert>
        :
        <Alert
          severity='info'
          action={
            (store.personalInformationStatus != TodoStatus.ACCEPTED
              || store.idInformationStatus != TodoStatus.ACCEPTED
              || store.financialInformationStatus == TodoStatus.ACCEPTED
              || store.creditInformationStatus == TodoStatus.ACCEPTED) ?
              <Button
                onClick={() => {
                  store.handleEditOption();
                }}
                color="primary"
                size="small"
                testId="edit"
                variant="outlined"
              >
                Edit
              </Button>
              : undefined
          }
        >
          <>
            <AlertTitle><b>Is this borrower a personal guarantor?</b></AlertTitle>
            {store.personalGuarantor ?
              <p className={classes.alertsQuestions}>Yes.</p>
              :
              <p className={classes.alertsQuestions}>No.</p>
            }
            <br />
            <AlertTitle><b>How are we obtaining your borrowers information?</b></AlertTitle>
            {store.personalGuarantor ?
              <>
                {store.sendIdVerification ?
                  <p className={classes.alertsQuestions}> Please send a <b>soft</b> credit pull authorization from portal.</p>
                  :
                  <p className={classes.alertsQuestions}> I have a Xactus360 <b>hard</b> credit pull along with borrower information to upload</p>
                }
              </>
              :
              <>
                {store.sendIdVerification ?
                  <p className={classes.alertsQuestions}>Please send the borrower an authorization form for the background check.</p>
                  :
                  <p className={classes.alertsQuestions}> I have the borrowers information to supply on my own.</p>
                }
              </>
            }

            <br />
            <p>Click "Edit" if you would like to change this.</p>
          </>
        </Alert>
      }
    </Grid>
  );
});