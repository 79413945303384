import { GlobalStore, GridStore, UserStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, observable } from "mobx";
import { SearchPropertyFormStore } from "./searchPropertyFormStore";
import { CompToolService } from "../services/compToolService";
import { CompResponse, ComparableProperty, LoanInformation } from "../types/propertyTypes";
import { CompToolFiltersStore } from "./compToolFiltersStore";
import { isNil, segmentAnalytics, SegmentTrackerEventCompTool } from "@roc/feature-utils";
import { CompToolFeedbackStore } from "./compToolFeedbackStore";
import { EditSubjectPropertyFormStore } from "./editSubjectPropertyFormStore";
import { CompToolPhotosStore } from "./compToolPhotosStore";

export class CompToolStore {
  globalStore: GlobalStore;
  userStore: UserStore;
  searchPropertyFormStore: SearchPropertyFormStore;
  compToolFiltersStore: CompToolFiltersStore;
  compToolFeedbackStore: CompToolFeedbackStore;
  editSubjectPropertyFormStore: EditSubjectPropertyFormStore;
  compToolPhotosStore: CompToolPhotosStore;
  compToolService: CompToolService;
  compResponse: CompResponse;
  subjectProperty: ComparableProperty;
  selectedProperty: ComparableProperty;
  loading: boolean;
  openPropertyDialog: boolean;
  openFiltersModal: boolean;
  showGridView: boolean;
  compToolGridStore: GridStore;
  openCompareGadget: boolean;
  selectedCompareProperties: ComparableProperty[] = [];
  savedPropertyComps: any[] = [];
  gridApi: any;
  openCarouselPhotos: boolean;
  showCompareScreen: boolean;
  filtersApplied: boolean;
  showWarningMessage: boolean;
  currentPage: number;
  editProperty: any;

  propertiesMapExtended: boolean;
  titleTransactionExpanded: boolean;
  rentalComps: boolean;
  isInternal: boolean;
  internalUsername: string;
  loanInformation: LoanInformation;
  property: any;

  constructor(globalStore, userStore) {
    this.userStore = userStore;
    this.globalStore = globalStore;
    this.searchPropertyFormStore = new SearchPropertyFormStore(globalStore)
    this.compToolFiltersStore = new CompToolFiltersStore(globalStore)
    this.compToolService = new CompToolService();
    this.compToolFeedbackStore = new CompToolFeedbackStore(globalStore);
    this.editSubjectPropertyFormStore = new EditSubjectPropertyFormStore(globalStore);
    this.compToolPhotosStore = new CompToolPhotosStore(globalStore);
    this.loading = true;
    this.openPropertyDialog = false;
    this.showGridView = true;
    this.openCompareGadget = false;
    this.openCarouselPhotos = false;
    this.showCompareScreen = false;
    this.filtersApplied = false;
    this.showWarningMessage = false;
    this.currentPage = 1;
    this.editProperty = {};

    this.propertiesMapExtended = false;
    this.titleTransactionExpanded = false;
    this.isInternal = false;

    makeObservable(this, {
      fetchCompToolProperties: flow,
      compResponse: observable,
      subjectProperty: observable,
      loading: observable,
      setLoading: action,
      openPropertyDialog: observable,
      setOpenPropertyDialog: action,
      selectedProperty: observable,
      setSelectedProperty: action,
      openFiltersModal: observable,
      setOpenFiltersModal: action,
      showGridView: observable,
      setShowGridView: action,
      openCompareGadget: observable,
      setOpenCompareGadget: action,
      selectedCompareProperties: observable,
      savedPropertyComps: observable,
      addSelectCompareProperties: action,
      clearSelectedCompareProperties: action,
      gridApi: observable,
      setGridApi: action,
      openCarouselPhotos: observable,
      setOpenCarouelPhotos: action,
      showCompareScreen: observable,
      setCompareScreen: action,
      filtersApplied: observable,
      setFiltersApplied: action,
      fetchPropertiesWithPagination: flow,
      removePropertyFromCompareTool: action,
      showWarningMessage: observable,
      setShowWarningMessage: action,
      currentPage: observable,
      setCurrentPage: action,
      sendCompFeedback: flow,
      editProperty: observable,
      reset: action,
      propertiesMapExtended: observable,
      setPropertiesMapExtended: action,
      titleTransactionExpanded: observable,
      setTitleTransactionExpanded: action,
      rentalComps: observable,
      setRentalComps: action,
      editSubjectProperty: flow,
      internalUsername: observable,
      setInternalUsername: action,
      loanInformation: observable,
      setLoanInformation: action,
      getCompResponse: action,
      getPropertyById: flow,
      property: observable,
      savePropertyComps: flow,
      getPropertyComps: flow
    });
  }

  *getPropertyById(propertyId: number, callback?: () => void) {
    try {
      if (!propertyId) {
        this.property = null;
        if (callback) callback();
        return;
      }

      const response = yield this.compToolService.getPropertyById(propertyId);

      if (response?.data) {
        this.property = response.data;
      } else {
        this.property = null;
      }

      if (callback) callback();

    } catch (error) {
      this.property = null;
      console.error('Error fetching property by ID:', error);
    }
  }

  getCompResponse() {
    return this.compResponse;
  }

  reset() {
    this.compToolFiltersStore.reset();
    this.editSubjectPropertyFormStore.reset();
    this.filtersApplied = false;
    this.setShowGridView(true);
    this.editProperty = {};
    this.rentalComps = false;
    this.compToolFiltersStore.form.fields.MONTHS.values.value = 12;
    this.loanInformation = null;
    this.savedPropertyComps = [];
  }

  setLoanInformation(loanId: number, propertyId: number) {
    this.loanInformation = {
      loan_id: loanId,
      property_id: propertyId
    };
  }

  setRentalComps(rentalComps) {
    this.rentalComps = rentalComps;
    if (this.rentalComps) {
      this.compToolFiltersStore.onFieldsChange('GEO__DIST', 'max', 3);
      this.compToolFiltersStore.form.fields.MONTHS.values.value = 18;
    } else {
      this.compToolFiltersStore.onFieldsChange('GEO__DIST', 'max', 1);
      this.compToolFiltersStore.form.fields.MONTHS.values.value = 12;
    }
    this.fetchCompToolProperties(1, true);
  }

  setInternalUsername(username: string) {
    this.internalUsername = username;
  }

  setTitleTransactionExpanded(titleTransactionExpanded) {
    this.titleTransactionExpanded = titleTransactionExpanded;
    if (titleTransactionExpanded) {
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEventCompTool.COMP_TOOL_OPEN_TRANSACTION_HISTORY,
        userName: this.isInternal ? this.internalUsername : this.userStore.userInformation?.username,
        subjectAddress: this.subjectProperty.data.address,
        propertyAddress: this.selectedProperty.data.address,
      });
    }
  }

  setPropertiesMapExtended(propertiesMapExtended) {
    this.propertiesMapExtended = propertiesMapExtended;
  }

  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  setShowWarningMessage(showWarningMessage: boolean) {
    this.showWarningMessage = showWarningMessage;
  }

  setFiltersApplied(filtersApplied: boolean) {
    this.filtersApplied = filtersApplied;
  }

  setCompareScreen(value: boolean) {
    this.showCompareScreen = value
  }

  setOpenCarouelPhotos(value: boolean) {
    this.openCarouselPhotos = value
    segmentAnalytics.trackEvent({
      name: SegmentTrackerEventCompTool.COMP_TOOL_OPEN_ALL_PHOTOS,
      userName: this.isInternal ? this.internalUsername : this.userStore.userInformation?.username,
      subjectAddress: this.subjectProperty.data.address,
      propertyAddress: this.selectedProperty.data.address,
      isSubject: this.selectedProperty.is_subject
    });
  }

  setGridApi(value) {
    this.gridApi = value
  }



  clearSelectedCompareProperties() {
    this.selectedCompareProperties = [];
    this.setOpenCompareGadget(false)
    this.clearSelected();
  }

  removePropertyFromCompareTool(propertyId) {
    this.selectedCompareProperties = this.selectedCompareProperties.filter(x => x.property_id !== propertyId);
  }

  clearSelected() {
    const selectedNodes = this.gridApi.getSelectedNodes();

    if (!this.showGridView && selectedNodes.length > 0) {
      selectedNodes.forEach(node => {
        node.setSelected(false);
      });
    }
  }

  addSelectCompareProperties(property: any) {
    if (!isNil(property.property_id) && property.property_id !== '') {
      if (this.selectedCompareProperties?.some(p => p.property_id === property.property_id)) {
        //delete the property if it was selected again
        this.selectedCompareProperties = this.selectedCompareProperties.filter(p => p.property_id !== property.property_id)
      } else {
        //only supports three selected properties
        if (this.selectedCompareProperties.length < 3) {
          this.selectedCompareProperties = [...this.selectedCompareProperties, property];
        } else {
          this.selectedCompareProperties.shift();
          this.selectedCompareProperties.push(property);
        }
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEventCompTool.COMP_TOOL_COMP_CHECKED,
          userName: this.isInternal ? this.internalUsername : this.userStore.userInformation?.username,
          subjectAddress: this.subjectProperty.data.address,
          compAddress: property.data.address
        });
        this.setOpenCompareGadget(true)
      }
      if (this.selectedCompareProperties.length == 0) {
        this.setOpenCompareGadget(false)
      }
    }

  }

  setOpenCompareGadget(value: boolean) {
    this.openCompareGadget = value
  }

  setShowGridView(value: boolean) {
    this.showGridView = value
    this.selectedCompareProperties = [];
    this.setOpenCompareGadget(false)
    if (!this.showGridView) {
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEventCompTool.COMP_TOOL_LIST_VIEW_CLICKED,
        userName: this.isInternal ? this.internalUsername : this.userStore.userInformation?.username,
        subjectAddress: this.subjectProperty.data.address,
      });
    }

  }

  setOpenFiltersModal(value: boolean) {
    this.openFiltersModal = value;
    this.clearSelectedCompareProperties()
  }

  setSelectedProperty(property: ComparableProperty) {
    this.selectedProperty = property;
  }

  setOpenPropertyDialog(value: boolean) {
    this.openPropertyDialog = value;
    this.compToolFeedbackStore.showFeedbackThankYou = false;
    this.compToolFeedbackStore.showFeedbackComment = false;
    this.compToolFeedbackStore.feedBackComment = '';
    this.setTitleTransactionExpanded(false)
  }

  setLoading(value: boolean) {
    this.loading = value;
  }


  *fetchCompToolProperties(page, trackEvent = false) {
    try {
      const compRequest = this.getCompRequest(page);
      this.setCurrentPage(page);
      const response = yield this.compToolService.getCompToolPropertiesData(compRequest);

      if (!response?.data?.data) {
        this.handleNoDataError();
        this.setLoading(false);
        return false;
      }

      const compProperties = JSON.parse(response.data.data);
      this.compResponse = compProperties as CompResponse;

      if (page === 1) {
        this.subjectProperty = this.compResponse.properties.find(p => p.is_subject);
        if (trackEvent) {
          this.trackCompToolEvent(this.userStore.userInformation?.username);
        }
      }
      this.getPropertyComps();

      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setShowWarningMessage(true)
      this.setLoading(false)
      this.compResponse = null;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting comp tool data',
      });
      return false;
    }
  }

  handleNoDataError() {
    if (this.filtersApplied) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'No matching comps were found. Please try using less strict filters.',
      });
    } else {
      this.compResponse = null;
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting comp tool data',
      });
      this.setShowWarningMessage(true);
    }
  }

  trackCompToolEvent(username) {
    const subjectAddress = this.subjectProperty.data.address;
    const eventPayload = {
      userName: username,
      subjectAddress,
    };

    if (this.filtersApplied) {
      segmentAnalytics.trackEvent({
        ...eventPayload,
        name: SegmentTrackerEventCompTool.COMP_TOOL_FILTERS_SEARCH,
        newFilters: this.compToolFiltersStore.getFiltersRequest(),
      });
    } else if (this.rentalComps) {
      this.compToolFiltersStore.loadValues(this.compResponse.filters_options.fields);
      segmentAnalytics.trackEvent({
        ...eventPayload,
        name: SegmentTrackerEventCompTool.COMP_TOOL_RENTAL_REPORTS,
      });
    } else {
      this.compToolFiltersStore.loadValues(this.compResponse.filters_options.fields);
      segmentAnalytics.trackEvent({
        ...eventPayload,
        name: SegmentTrackerEventCompTool.COMP_TOOL_SEARCH,
      });
    }
  }

  getCompRequest(page) {
    const fields = this.searchPropertyFormStore.form.fields;
    const distance = this.compToolFiltersStore.getMinMaxValue('GEO__DIST', 'max');
    const lookbackMonths = this.compToolFiltersStore.getFormValue('MONTHS');
    const includeActiveProperties = this.compToolFiltersStore.getFormValue('IS_ACTIVE');
    const compRequest =
    {
      address: fields.address.value,
      page: page,
      lookback_months: lookbackMonths ? lookbackMonths : 12,
      include_active_listings: (includeActiveProperties ? (includeActiveProperties.includes("True")) : true),
      edit_subject_features: this.editProperty,
      rental: this.rentalComps,
      per_page: 11,
      lat: fields.latitude.value,
      lng: fields.longitude.value,
      state: fields.state.value,
      mi_distance: distance.value > 1 ? distance.value : 1,
      ...(this.filtersApplied && { filters: this.compToolFiltersStore.getFiltersRequest() })
    }

    return compRequest;
  }


  *fetchPropertiesWithPagination(page) {
    try {
      segmentAnalytics.trackEvent({
        name: SegmentTrackerEventCompTool.COMP_TOOL_PAGE_CLICKED,
        userName: this.userStore.userInformation?.username,
        subjectAddress: this.subjectProperty.data.address,
        currentPage: this.currentPage,
        newPage: page
      });
      yield this.fetchCompToolProperties(page)
    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting comp tool data',
      });
      return false;
    }
  }

  *sendCompFeedback(property) {
    try {
      if (this.filtersApplied) {
        const compRequest = this.getCompRequest(this.currentPage)
        const response = yield this.compToolFeedbackStore.sendCompToolFeedback(compRequest, this.subjectProperty, property)

      } else {
        const compRequest = {
          address: this.searchPropertyFormStore.form.fields.address.value,
          page: this.currentPage,
        };
        const response = yield this.compToolFeedbackStore.sendCompToolFeedback(compRequest, this.subjectProperty, property)
      }
    }
    catch (error) {
      console.log(error);
      return false;
    }
  }


  *editSubjectProperty() {
    try {
      this.editProperty = this.editSubjectPropertyFormStore.getEditSubjectProperty();
      const {
        rooms,
        bedrooms,
        full_bathrooms,
        half_bathrooms,
        area,
        condition_tag,
        lot_size,
        taxes,
        year_built
      } = this.subjectProperty.data;

      segmentAnalytics.trackEvent({
        name: SegmentTrackerEventCompTool.COMP_TOOL_EDIT_SUBJECT_PROPERTY,
        userName: this.userStore.userInformation?.username,
        subjectAddress: this.subjectProperty.data.address,
        originalData: {
          rooms,
          bedrooms,
          full_bathrooms,
          half_bathrooms,
          area,
          condition_tag,
          lot_size,
          taxes,
          year_built
        },
        editData: this.editProperty
      });


      yield this.fetchCompToolProperties(this.currentPage)

      this.setOpenPropertyDialog(false)

      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Subject Property succesfully saved',
      });

    }
    catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting comp tool data',
      });
      return false;
    }
  }

  *getPropertyComps() {
    try {
      this.savedPropertyComps = [];
      if (!this.property || !this.property.propertyId) {
        return;
      }

      const response = yield this.compToolService.getPropertyCompBySubjectPropertyId(this.property.propertyId);
      this.savedPropertyComps = response.data.data;
    } catch (err) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving comparable properties.',
      });
      console.log(err);
    }
  }

  *savePropertyComps() {
    try {

      if (!this.property || !this.property.propertyId) {
        return;
      }
      const savedPropertyComps = [...this.selectedCompareProperties];
      const subjectPropertyComp = this.mapComparablePropertyToPropertyCompRequest(this.property.propertyId, this.subjectProperty, 0);

      const payload = savedPropertyComps.map((p, index) => this.mapComparablePropertyToPropertyCompRequest(this.property.propertyId, p, index + 1));
      payload.push(subjectPropertyComp);

      yield this.compToolService.savePropertyComps(payload);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Property Comps succesfully saved',
      });

      this.savedPropertyComps = payload;
    } catch (err) {
      this.savedPropertyComps = [];
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving comparable properties.',
      });
      console.log(err);
    }

  }

  mapComparablePropertyToPropertyCompRequest(subjectPropertyId: string, comparableProperty: ComparableProperty, sequence: number) {

    const parsedAddress = this.parseAddress(comparableProperty.data.address);

    const propertyComp = {
      subjectPropertyId: subjectPropertyId,
      comparePropertyId: comparableProperty.property_id,
      sequence,
      address: parsedAddress.address,
      city: parsedAddress.city,
      state: parsedAddress.state,
      zipCode: parsedAddress.zipCode,
      mlsNumber: comparableProperty.data.mls_number,
      proximity: subjectPropertyId === comparableProperty.property_id ? 0 : comparableProperty.similarity.mi_distance,
      propertyType: comparableProperty.data.property_type,
      saleType: comparableProperty.data.sale_type,
      lotSize: comparableProperty.data.lot_size,
      yearBuilt: comparableProperty.data.year_built,
      pricePerSqFt: comparableProperty.data.sqft_price,
      parkingType: null,
      amenities: null,
      hasPool: comparableProperty.data.pool,
      mlsComments: comparableProperty.data.comment,
      latitude: comparableProperty.data.lat,
      longitude: comparableProperty.data.lng

    };

    return propertyComp;
  }

  parseAddress(input) {
    const parts = input.split(", ");
    const address = parts[0];
    const city = parts[1];
    const state = parts[2];
    const zipCode = parts[3];

    return { address, city, state, zipCode };
  }


}
