import { ApiResponse, GlobalStore, UserStore } from '@roc/feature-app-core';
import {
  ELMSURE,
  bridgeLoanSubTypes,
  GENERIC_ERROR_MESSAGE,
  LoanSubType,
  PURCHASE,
  REFINANCE,
  isFixAndFlip,
  isGroundUp,
  isNil,
  isNotBlank,
  notLicensedinState,
  isStabilizedBridge,
  LoanType,
  getDaysBetween,
} from '@roc/feature-utils';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../services/oneToolService';
import { QuoteStep, quoteSteps as steps } from '../utils/constants';
import { BorrowersStore } from './borrowers/borrowersStore';
import { LoanEconomicsStore } from './loanEconomics/loanEconomicsStore';
import { PropertiesStore } from './properties/propertiesStore';
import { SummaryStore } from './summary/summaryStore';
import { PreflightStore } from './prefight/preflightStore';
import { BrokerStore } from './broker/brokerStore';
import { EntityStore } from './entity/entityStore';
import { ClosingStore } from '../stores/closing/closingStore';
import { getDealName } from '@roc/feature-loans';
import { buildAddress, sum } from '../utils/utils';
import { differenceInYears, parse, startOfToday } from 'date-fns';

export abstract class QuoteTypeStore {
  protected globalStore: GlobalStore;
  userStore: UserStore;

  private oneToolSevice: OneToolService;

  brokerStore: BrokerStore;
  entityStore: EntityStore;
  borrowersStore: BorrowersStore;
  propertiesStore: PropertiesStore;
  loanEconomicsStore: LoanEconomicsStore;
  closingStore: ClosingStore;
  summaryStore: SummaryStore;
  preflightStore: PreflightStore;
  fastTrackFees: any;
  fastTrackLoanId: string | number;
  fastTrackPaymentUrl: string;

  loanSubtype: LoanSubType;

  step: QuoteStep;
  lastCompletedStep: QuoteStep;

  quoteId: number;
  loanId: number;

  quoteAccepted: boolean;

  submittedLoan;

  allErrors: string[];
  allWarnings: string[];
  stepErrors: string[];

  isLoanQuoteDetailsSuccess: boolean;
  areBorrowersPreQualified: boolean;
  disableSubmitLoanButton: boolean;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.borrowersStore = new BorrowersStore(this.globalStore, this);
    this.closingStore = new ClosingStore(this.globalStore, this);
    this.summaryStore = new SummaryStore(this.globalStore, this);
    this.preflightStore = new PreflightStore(this.globalStore, this);
    this.brokerStore = new BrokerStore(globalStore);
    this.entityStore = new EntityStore(globalStore, this);
    this.oneToolSevice = new OneToolService();
    this.setDefaults();

    this.allErrors = [];
    this.allWarnings = [];

    makeObservable(this, {
      loanSubtype: observable,
      step: observable,
      quoteId: observable,
      submittedLoan: observable,
      allErrors: observable,
      allWarnings: observable,
      stepErrors: observable,
      reset: action,
      goToStep: flow,
      validateCurrentStep: action,
      saveQuote: flow,
      loadQuote: flow,
      createNewQuote: flow,
      getQuoteData: action,
      doSubmitLoanValidation: flow,
      submitLoan: flow,
      isManualLeverageRequired: action,
      fastTrackFees: observable,
      fastTrackLoanId: observable,
      fastTrackPaymentUrl: observable,
      loadFastTrackFees: flow,
      fetchFastTrackLoanId: flow,
      getLoanFastTrackPaymentUrl: flow,
      updateLoanFastTrackInfo: flow,
      requestFastTrackPaymentEmail: flow,
      isBridgeLoan: computed,
      validateLeverageStep: action,
      isLoanQuoteDetailsSuccess: observable,
      validateBridgeSubmission: flow,
      validateBorrowerErrors: flow,
      validateEntityErrors: flow,
      getAreBorrowersPreQualifiedFromPrescreenValidations: flow,
      areBorrowersPreQualified: observable,
      isMidConstructionRefinance: action,
      getCostBasisLabel: action,
      validateProtectedBorrowers: action,
      disableSubmitLoanButton: observable,
      isSeasoningDaysGreaterThan180: computed,
      showOnlyLTVValues: computed,
    });
  }

  get isBridgeLoan() {
    return bridgeLoanSubTypes.includes(this.loanSubtype);
  }

  reset() {
    this.step = QuoteStep.ENTITY_INFORMATION;
    this.lastCompletedStep = null;
    this.quoteId = null;
    this.loanId = null;
    //this.quoteAccepted = false;
    this.quoteAccepted = true;
    this.borrowersStore.reset();
    this.propertiesStore.reset();
    this.loanEconomicsStore.reset();
    this.summaryStore.reset();
    this.preflightStore.reset();
    this.brokerStore.reset();
    this.entityStore.reset();
    this.closingStore.reset();
    this.allErrors = [];
    this.allWarnings = [];
    this.isLoanQuoteDetailsSuccess = true;
    this.areBorrowersPreQualified = true;
    this.setDefaults();
  }

  setDefaults() {
    this.fastTrackLoanId = '';
    this.fastTrackFees = {};
    this.fastTrackPaymentUrl = '';
    this.stepErrors = [];
    this.disableSubmitLoanButton = false;
  }

  *goToStep(step: QuoteStep) {
    if (step === QuoteStep.SUMMARY) {
      this.borrowersStore.turnOffBorrowersEditMode();
      this.propertiesStore.turnOffPropertiesEditMode();
    }

    if (steps.indexOf(step) < steps.indexOf(this.step)) {
      this.step = step;
    } else if (this.validateCurrentStep()) {
      if (this.canGoToStep(step)) {
        this.updateLastCompletedStep();
        this.step = step;
        yield this.saveQuote();
      } else {
        this.globalStore.notificationStore.showInfoNotification({
          message: 'Please fill the previous steps',
        });
      }
    }
  }

  private canGoToStep(step: QuoteStep) {
    return (
      steps.indexOf(step) <= steps.indexOf(this.lastCompletedStep) + 1 ||
      steps.indexOf(step) === steps.indexOf(this.step) + 1
    );
  }

  private updateLastCompletedStep() {
    if (steps.indexOf(this.lastCompletedStep) < steps.indexOf(this.step)) {
      this.lastCompletedStep = this.step;
    }
  }

  private checkAllErrors = () => {
    this.allErrors = [];

    const pointsError = this.checkPoints();
    if (pointsError) this.allErrors.push(pointsError);

    if (this.entityStore?.getEntity()?.hasEntity === null) {
      this.allErrors.push(
        'Please answer "Do you have the borrowing entity information?" under Entity section.'
      );
    }

    if (this.borrowersStore?.getBorrowers()?.length === 0) {
      this.allErrors.push('Please select a borrower or add a new one.');
    }

    this.checkBorrowersErrors();

    // if (this.globalStore.lenderInfo?.showTPOLicenseValidation && this.propertiesStore.getProperties().some(property => requiredLicenseStates.includes(normalizeState(property.state)) &&
    //   !this.globalStore.lenderInfo?.stateLicenseList?.includes(normalizeState(property.state))) &&
    //   this.loanInformationStore.licenseUpload.length == 0) {
    //   this.allErrors.push('Please upload the corresponding state license.');
    // }

    if (this.propertiesStore?.getProperties()?.length === 0) {
      this.allErrors.push('Please add a new property.');
    }

    const totalLoanAmount = this.loanEconomicsStore?.enableManualQuote
      ? this.loanEconomicsStore?.manualExceptionQuoteFormStore?.calculatedFields
          ?.totalLoanAmount
      : this.loanEconomicsStore?.instaLeverageQuoteFormStore?.calculatedFields
          ?.totalLoanAmount;
    if (totalLoanAmount === 0) {
      this.allErrors.push('Total loan amount must be greater than 0.');
    }

    if (
      this.closingStore.closingFormStore.getFormValues().preferredTitle ===
      'Other'
    ) {
      this.closingStore.closingFormStore.runFormValidation();
      if (!this.closingStore.closingFormStore.form.meta.isValid) {
        this.allErrors.push(
          'Please fill out all the info for Preferred Title Company.'
        );
      }

      const allowLenderUnderwritingFee = this.globalStore.userFeatures
        ?.allowLenderUnderwritingFee;
      if (allowLenderUnderwritingFee /* && this.userStore.allowLoanPricing */) {
        const error = this.checkLenderUnderwritingFeeValidations();
        if (error) {
          this.allErrors.push(error);
        }
      }
    }
  };

  private checkAllWarnings = () => {
    this.allWarnings = [];

    this.checkBorrowersWarnings();

    const entity = this.entityStore?.getEntity();
    if (entity) {
      if (entity.dateOfIncorporation) {
        const diff = differenceInYears(
          startOfToday(),
          parse(
            this.entityStore?.getEntity()?.dateOfIncorporation,
            'MM/dd/yyyy',
            new Date()
          )
        );
        if (diff > 1) {
          this.allWarnings.push(
            'Certificate of Good Standing is needed within 90 days of closing. These can be ordered on the applicable county/state website'
          );
        }
      }
      if (
        (isNotBlank(entity.entityName) || !isNil(entity.entityEin)) &&
        this.propertiesStore
          ?.getProperties()
          .some(p => p.state !== entity.address?.state)
      ) {
        this.allWarnings.push(
          'The borrower must obtain a foreign entity status in the state of the collateral. These can be ordered on the applicable county/state website'
        );
      }
    }

    // Sundar: This validation is from old loan submission. Commenting since multifamily bridge is not there in one tool
    /* if (this.isMultifamilyBridge && this.isConstructionHoldbackAndLTFC()) {
      this.allWarnings.push(
        'The loan is lopsided (Initial loan amount < Construction Holdback) and over 93% of cost. The initial loan to cost is too high.'
      );
    } */
  };

  private checkPoints() {
    const brokerPoints = this.brokerStore.getBroker().brokerPoints;
    const loanEconomicsFormValues = this.loanEconomicsStore.loanEconomicsFormStore.getFormValues();
    const lenderPoints = this.globalStore?.lenderInfo?.isInternal
      ? loanEconomicsFormValues.capitalProviderPoints || 0
      : (loanEconomicsFormValues.lenderPoints || 0) +
        (loanEconomicsFormValues.capitalProviderPoints || 0);
    return brokerPoints > lenderPoints
      ? 'Broker points cannot exceed total origination points.'
      : '';
  }

  private checkLenderUnderwritingFeeValidations() {
    if (this.loanSubtype === LoanSubType.FIX_AND_FLIP_PRO) {
      return '';
    }
    const totalLoanAmount = this.loanEconomicsStore.enableManualQuote
      ? this.loanEconomicsStore.manualExceptionQuoteFormStore?.calculatedFields
          ?.totalLoanAmount
      : this.loanEconomicsStore.instaLeverageQuoteFormStore?.calculatedFields
          ?.totalLoanAmount;
    let totalFees = 0;
    this.loanEconomicsStore.feeStores.forEach(feeStore => {
      totalFees += feeStore.form.fields.capitalProviderAmount.value;
    });
    if (totalLoanAmount < 500000 && totalFees < 595) {
      return 'Total Fees for this loan must be greater than or equal to $595.';
    }
    if (
      totalLoanAmount >= 500000 &&
      totalLoanAmount < 1000000 &&
      totalFees < 895
    ) {
      return 'Total Fees for this loan must be greater than or equal to $895.';
    }
    if (
      totalLoanAmount >= 1000000 &&
      totalLoanAmount < 2000000 &&
      totalFees < 1295
    ) {
      return 'Total Fees for this loan must be greater than or equal to $1,295.';
    }
    if (
      totalLoanAmount >= 2000000 &&
      totalLoanAmount < 3000000 &&
      totalFees < 1995
    ) {
      return 'Total Fees for this loan must be greater than or equal to $1,995.';
    }
    if (
      totalLoanAmount >= 3000000 &&
      totalLoanAmount < 4000000 &&
      totalFees < 3495
    ) {
      return 'Total Fees for this loan must be greater than or equal to $3,495.';
    }
    if (
      totalLoanAmount >= 4000000 &&
      totalLoanAmount < 5000000 &&
      totalFees < 4495
    ) {
      return 'Total Fees for this loan must be greater than or equal to $4,495.';
    }
    return '';
  }

  private checkBorrowersErrors = () => {
    let thereIsAtLeastOnePG = false;
    this.borrowersStore?.getBorrowers()?.forEach(borrower => {
      thereIsAtLeastOnePG = thereIsAtLeastOnePG || borrower?.personalGuarantor;
    });
    if (!thereIsAtLeastOnePG) {
      this.allErrors.push(
        'At least one Borrower should be Personal Guarantor.'
      );
    }
  };

  private checkBorrowersWarnings = () => {
    if (
      !isFixAndFlip(this.loanSubtype) &&
      this.entityStore?.getEntity()?.hasEntity &&
      this.borrowersStore?.getBorrowers()?.length > 0 &&
      this.calculateBorrowerPercentages() !== 100
    ) {
      this.allWarnings.push(
        'Please ensure that "Percentage of Ownership" adds to a total of 100%. Please adjust the ownership percentage slider accordingly.'
      );
    }
  };

  private calculateBorrowerPercentages = () => {
    return this.borrowersStore
      ?.getBorrowers()
      ?.reduce(
        (total, current) => total + current?.percentageOfOwnership ?? 0,
        0
      );
  };

  *validateBridgeSubmission(submitBridgeLoanRequestData: any) {
    try {
      let response: ApiResponse;
      if (isFixAndFlip(this.loanSubtype)) {
        response = yield this.oneToolSevice.validateBridgeLoanStep(
          {
            ...submitBridgeLoanRequestData,
            internalLender: this.globalStore.lenderInfo?.isInternal,
          },
          'SUMMARY',
          this.loanEconomicsStore.enableManualQuote
        );
      } else {
        const skipLeverageValidations =
          this.loanEconomicsStore.enableManualQuote ||
          isGroundUp(this.loanSubtype);
        response = yield this.oneToolSevice.getValidateBridgeLoanErrors(
          {
            ...submitBridgeLoanRequestData,
            internalLender: this.globalStore.lenderInfo?.isInternal,
          },
          skipLeverageValidations
        );
      }
      if (response.data?.data) {
        const data = response.data.data;
        const validateErrors = data.errors || [];
        this.allErrors = [...this.allErrors, ...validateErrors];

        const validateWarnings = data.warnings || [];
        this.allWarnings = [...this.allWarnings, ...validateWarnings];
      }
    } catch (error) {
      console.log(error);
    }
  }

  *validateBorrowerErrors(quoteData: any, submitBridgeLoanRequestData: any) {
    try {
      const response: ApiResponse = yield this.oneToolSevice.getValidateBorrowerLoanErrors(
        {
          borrowerRows: [...submitBridgeLoanRequestData.borrowers],
          loanValues: {
            marketingPromotionId: quoteData.marketingPromotionId, //marketing id not yet implemented
            //loanId: this.editLoanJson.loanId // edit loan not yet implemented
          },
        }
      );
      if (response.data?.data) {
        const data = response.data.data;
        const validateErrors = data.errors || [];
        this.allErrors = [...this.allErrors, ...validateErrors];

        const validateWarnings = data.warnings || [];
        this.allWarnings = [...this.allWarnings, ...validateWarnings];
      }
    } catch (error) {
      console.log(error);
    }
  }

  *validateEntityErrors(submitBridgeLoanRequestData: any) {
    try {
      const entityId = submitBridgeLoanRequestData?.entity?.borrowerEntityId;
      if (!entityId) return;

      const response: ApiResponse = yield this.oneToolSevice.getValidateEntityLoanErrors(
        entityId
      );
      if (response.data?.data) {
        const data = response.data.data;
        const validateErrors = data.errors || [];
        this.allErrors = [...this.allErrors, ...validateErrors];
      }
    } catch (error) {
      console.log(error);
    }
  }

  *getAreBorrowersPreQualifiedFromPrescreenValidations(
    submitBridgeLoanRequestData: any
  ) {
    try {
      this.areBorrowersPreQualified = false;

      const response = yield this.oneToolSevice.getAreBorrowersPreQualifiedFromPrescreenValidations(
        submitBridgeLoanRequestData
      );

      if (response.data?.data) {
        this.areBorrowersPreQualified = response?.data?.data;

        const validateWarnings = [];
        if (!this.areBorrowersPreQualified) {
          validateWarnings.push(
            'Your loan will be automatically assigned to our underwriting team once borrower credit and background are received.'
          );
        }
        this.allWarnings = [...this.allWarnings, ...validateWarnings];
      }
    } catch (error) {
      console.log(error);
    }
  }

  validateProtectedBorrowers(quoteData) {
    try {
      if (quoteData?.borrowers != null && quoteData?.borrowers.length > 0) {
        const validateWarnings = [];
        quoteData?.borrowers.map(borrower => {
          if (borrower?.protectedBorrower) {
            validateWarnings.push(
              'The Borrower ' +
                borrower.fullName +
                ' currently has loans associated with an active TPO'
            );
          }
        });
        this.allWarnings = [...this.allWarnings, ...validateWarnings];
      }
    } catch (error) {
      console.log(error);
    }
  }

  validateCurrentStep() {
    this.stepErrors = [];
    switch (this.step) {
      case QuoteStep.ENTITY_INFORMATION:
        return this.entityStore.validateEntity();
      case QuoteStep.BORROWER_INFORMATION:
        return this.borrowersStore.validateAllBorrowers();
      case QuoteStep.PROPERTY_INFORMATION:
        return this.propertiesStore.validateAllProperties();
      case QuoteStep.LOAN_ECONOMICS:
        return (
          this.loanEconomicsStore.validateAllFees() &&
          this.brokerStore.validateBroker_quote()
        );
      case QuoteStep.SUMMARY:
        return this.summaryStore.validateSummaryStep();
      case QuoteStep.LEVERAGE:
        return this.validateLeverageStep();
      default:
        return true;
    }
  }

  *saveQuote() {
    try {
      const response = yield this.oneToolSevice.saveQuote(this.getQuoteData());
      this.quoteId = response.data.data.quoteId;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *loadQuote(quoteId) {
    try {
      this.isLoanQuoteDetailsSuccess = true;
      const data = yield this.oneToolSevice.getQuoteById(quoteId);
      const quoteData = data.data.data;

      if (!quoteData || !quoteData.quoteId) {
        throw new Error("Don't have access to the quote");
      }

      this.quoteId = quoteData.quoteId;
      this.loanId = quoteData.loanId;
      this.summaryStore.loadLoanCommentsData(
        quoteData.loanComments,
        quoteData.leverageExceptionComments
      );
      this.borrowersStore.loadBorrowers(quoteData.borrowers);
      this.propertiesStore.loadProperties(quoteData);
      this.closingStore.loadClosingData(quoteData);
      this.loanEconomicsStore.loadLoanEconomics(quoteData.loanEconomics);
      this.brokerStore.loadBroker(quoteData.broker);
      this.entityStore.loadEntityData(quoteData);
      this.preflightStore.loadPreflightState(quoteData);
      this.step = quoteData.uiState.quoteStep;
      if (quoteData.fastTrackLoanId) {
        this.fastTrackLoanId = quoteData.fastTrackLoanId;
        yield this.getLoanFastTrackPaymentUrl();
      }
      yield this.loanEconomicsStore.fetchQuoteOutputs();
    } catch (e) {
      console.log(e);
      this.isLoanQuoteDetailsSuccess = false;
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *createNewQuote() {
    try {
      yield this.loanEconomicsStore.fetchDefaultFees();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  *doSubmitLoanValidation() {
    this.checkAllErrors();
    this.checkAllWarnings();

    const getSubmitBridgeLoanRequest_apiResponse = yield this.oneToolSevice.getSubmitBridgeLoanRequest(
      this.getQuoteData()
    );
    const submitBridgeLoanRequestData =
      getSubmitBridgeLoanRequest_apiResponse.data.data;
    const quoteData = this.getQuoteData();

    yield this.validateBridgeSubmission(submitBridgeLoanRequestData);
    yield this.validateBorrowerErrors(quoteData, submitBridgeLoanRequestData);
    yield this.validateEntityErrors(submitBridgeLoanRequestData);
    yield this.getAreBorrowersPreQualifiedFromPrescreenValidations(
      submitBridgeLoanRequestData
    );
    this.validateProtectedBorrowers(quoteData);

    if (this.allErrors?.length == 0) {
      return true;
    } else {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Please correct the errors listed above.',
      });
      return false;
    }
  }

  *submitLoan() {
    try {
      const validationSuccessful = yield this.doSubmitLoanValidation();
      if (validationSuccessful) {
        this.disableSubmitLoanButton = true;
        const response = yield this.oneToolSevice.submitLoan(
          this.getQuoteData()
        );
        this.submittedLoan = response.data.data;
        this.step = QuoteStep.SUCCESS;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
      this.sendLoanSubmissionErrorEmail(
        e?.error?.response?.data?.error?.message || e?.error?.message || e
      );
    }
  }

  sendLoanSubmissionErrorEmail(error: string) {
    this.oneToolSevice.sendErrorEmail(
      'Bridge Loan Submission Failed',
      `Error occured while submitting ${LoanType.RESIDENTIAL_BRIDGE} (${this.loanSubtype}) loan`,
      error || 'Error occured when submitting loan'
    );
  }

  getQuoteData() {
    const closingData = this.closingStore.getClosingData();
    const summaryData = this.summaryStore.loanCommentsFormStore.getFormValues();

    const brokerData = this.brokerStore.getBroker();
    const loanEconomicsData = this.loanEconomicsStore.getLoanEconomics();

    const brokerPoints = brokerData.brokerPoints;
    loanEconomicsData.brokerPoints = brokerPoints;

    return {
      quoteId: this.quoteId,
      loanId: this.loanId,
      lenderId: this.globalStore.lenderInfo.lenderId,
      loanSubtype: this.loanSubtype,
      originatorId: closingData.originatorId,
      version: 1,
      broker: brokerData,
      entity: this.entityStore.getEntity(),
      closingData: this.closingStore.getClosingData(),
      quoteAccepted: this.quoteAccepted,
      borrowers: this.borrowersStore.getBorrowers(),
      properties: this.propertiesStore.getProperties(),
      loanEconomics: loanEconomicsData,
      uiState: {
        quoteStep: this.step,
        ...this.preflightStore.getPrefligthState(),
        uploadedLicenses: this.propertiesStore.uploadedLicenses,
        selectEntityOption: this.entityStore.selectEntityOption,
      },
      loanLenderDetails: this.loanEconomicsStore.loanLenderDetails,
      loanComments: summaryData.loanComments,
      leverageExceptionComments: summaryData.leverageExceptionComments,
      ...this.getFastTrackData(closingData),
    };
  }

  isManualLeverageRequired(totalLoanAmount: number) {
    const borrowerKnowOwnerOfRecord = this.propertiesStore
      .getProperties()
      .some(p => p.borrowerKnowOwnerOfRecord);

    return (
      totalLoanAmount > 1000000 || // all loan types
      !this.loanEconomicsStore.isLTCAvailable() || // all loan types
      this.isManualLeverageRequiredBasedOnType() ||
      borrowerKnowOwnerOfRecord
    );
  }

  isManualLeverageRequiredBasedOnType() {
    if (isFixAndFlip(this.loanSubtype)) {
      return this.isManualLeverageRequiredFixFlip();
    }
    if (isGroundUp(this.loanSubtype)) {
      return this.isManualLeverageRequiredGU();
    }
    if (isStabilizedBridge(this.loanSubtype)) {
      return this.isManualLeverageRequiredStbBridge();
    }
    return false;
  }

  isManualLeverageRequiredFixFlip() {
    const ficoGuideline = 660;

    const maxBorrFICO = this.borrowersStore
      .getBorrowers()
      .reduce((maxFico, currentBorr) => {
        if ((currentBorr.ficoScore || 0) > maxFico) {
          return currentBorr.ficoScore;
        }
        return maxFico;
      }, 0);

    return maxBorrFICO < ficoGuideline || this.isMidConstructionRefinance();
  }

  isManualLeverageRequiredGU() {
    const ficoGuideline = 680;

    const maxBorrFICO = this.borrowersStore
      .getBorrowers()
      .reduce((maxFico, currentBorr) => {
        if ((currentBorr.ficoScore || 0) > maxFico) {
          return currentBorr.ficoScore;
        }
        return maxFico;
      }, 0);

    const experienceTier = this.borrowersStore
      .getBorrowers()
      .reduce((maxExperience, currentBorr) => {
        if (
          (currentBorr.numberOfVerifiedInvestmentProperties || 0) >
          maxExperience
        ) {
          return currentBorr.numberOfVerifiedInvestmentProperties;
        }
        return maxExperience;
      }, 0);

    return (
      maxBorrFICO < ficoGuideline ||
      experienceTier === 1 ||
      experienceTier === 2 ||
      this.isMidConstructionRefinance()
    );
  }

  isManualLeverageRequiredStbBridge() {
    return false;
  }

  isMidConstructionRefinance() {
    let isRefinance = false;
    let sunkCost = 0;

    this.propertiesStore.getProperties().forEach(prop => {
      if (prop.loanPurpose === REFINANCE) {
        isRefinance = true;
        sunkCost += prop.constructionRenoSpendToDate || 0;
      }
    });

    return isRefinance && sunkCost > 0;
  }

  getCostBasisLabel() {
    let costBasisLabel = 'Purchase Price';
    if (this.propertiesStore.getProperties()?.[0]?.loanPurpose === REFINANCE) {
      const seasoningDays = getDaysBetween(
        new Date(),
        new Date(this.propertiesStore.getProperties()?.[0]?.purchaseDate)
      );
      if (seasoningDays <= 180) {
        // <= 180 days seasoning
        costBasisLabel = 'Cost Basis';
      }
    }
    return costBasisLabel;
  }

  get showOnlyLTVValues() {
    if (this.propertiesStore.getProperties()?.[0]?.loanPurpose === REFINANCE) {
      return this.isSeasoningDaysGreaterThan180;
    }
    return false;
  }

  get isSeasoningDaysGreaterThan180() {
    const properties = this.propertiesStore.getProperties();
    const seasoningDays = getDaysBetween(
      new Date(),
      new Date(properties?.[0]?.purchaseDate)
    );
    return seasoningDays > 180;
  }

  *loadFastTrackFees() {
    try {
      const response = yield this.oneToolSevice.getLoanFastTrackFees();

      this.fastTrackFees = response.data.data;
    } catch (err) {}
  }

  *fetchFastTrackLoanId() {
    try {
      if (!this.fastTrackLoanId) {
        const apiResponse: ApiResponse = yield this.oneToolSevice.fetchFastTrackLoanId();

        this.fastTrackLoanId = apiResponse.data.data;
        yield this.getLoanFastTrackPaymentUrl();
      }
    } catch (error) {
      // do nothing
    }
  }

  *getLoanFastTrackPaymentUrl() {
    try {
      const response = yield this.oneToolSevice.getLoanFastTrackPaymentUrl(
        this.fastTrackLoanId
      );

      this.fastTrackPaymentUrl = response.data.data.url;
    } catch (err) {}
  }

  *updateLoanFastTrackInfo(fastTrackOptions: any, loanId?: string | number) {
    try {
      const borrowers = this.borrowersStore.getBorrowers();
      const properties = this.propertiesStore.getProperties();

      const data = {
        loanId: loanId,
        randomLoanId: this.fastTrackLoanId,
        ...fastTrackOptions,
        properties: properties.length,
        propertiesAddresses: properties
          .map(property => buildAddress(property))
          .join(', '),
        dealName: getDealName(borrowers, properties),
      };
      yield this.oneToolSevice.updateLoanFastrackInfo(data);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving the modal state',
      });
    }
  }

  getFastTrackData(closingData) {
    const { fastTrack, preferredInsurance } = closingData;

    if (!fastTrack) {
      return { fastTrack };
    }

    const isElmsure = preferredInsurance === ELMSURE;
    const properties = this.propertiesStore.getProperties();

    return {
      fastTrack,
      fastTrackLoanId: this.fastTrackLoanId,
      fastTrackOptions: {
        insuranceReview: !isElmsure,
        titleSearch: false,
        insurance: isElmsure,
        rushedAppraisal: true,
        titleAttorneySearch: true,
        properties: properties.length,
        propertiesAddresses: properties
          .map(property => buildAddress(property))
          .join(', '),
      },
    };
  }

  *requestFastTrackPaymentEmail() {
    try {
      yield this.oneToolSevice.requestFastTrackPaymentEmail({
        loanId: this.fastTrackLoanId,
        borrowers: [...this.borrowersStore.getBorrowers()],
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Email sent successfully',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending the email',
      });
    }
  }

  validateLeverageStep() {
    let errors = [];
    errors = errors.concat([
      ...this.loanEconomicsStore.validateLeverageAmounts(),
    ]);

    errors = errors.concat([...this.loanEconomicsStore.validateLoanAmount()]);

    this.stepErrors = errors;
    return errors.length === 0;
  }
}
