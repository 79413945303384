import { Box, Chip, ListItemIcon, Menu } from '@material-ui/core';
import { Edit, MoreVert, Person } from '@material-ui/icons';
import { formatDate, insertIf, isNil, LoanProcess } from '@roc/feature-utils';
import {
  DataGrid,
  GridColumnProps,
  IconButton,
  LinkColumn,
  MenuItem,
  StatusChip,
  StatusType,
} from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import {
  GeneralTasksTab,
  TaskGridRow,
} from '../types/generalTasksTypes';
import { GeneralTaskLoanTooltip } from './generalTaskLoanTooltip';
import { GeneralTaskLoanFasttrackTooltip } from './taskList/generalTaskLoanFasttrackTooltip';
import { useUserStore } from '@roc/feature-app-core';
import { useLoanRoutes } from '@roc/feature-loans-routes-config';
import { TodoTitleLink } from 'libs/feature-loan-details/src/loanDetails/components/cards/todoActions';
import { useDocumentStore } from '@roc/feature-documents';

export const LoansTaskListView = observer(() => {
  const { generalTasksStore } = useGeneralTasksStore();
  const { taskListGridStore, editTaskStore, tab } = generalTasksStore;
  const gridStore = taskListGridStore;
  const [menu, setMenu] = useState<{ el: HTMLElement; row: TaskGridRow }>(null);
  const { userStore } = useUserStore();
  const { loanRoutesConfig } = useLoanRoutes();
  const { documentStore } = useDocumentStore();
  const { loanTodosStore } = documentStore;

  const onPageChange = async pageNumber => {
    await gridStore.setPageNumber(pageNumber);
  };

  const columns: GridColumnProps[] = [
    {
      field: 'taskId',
      headerName: 'Task Id',
      maxWidth: 85,
      cellRenderer: 'taskIdCellRenderer',
    },
    {
      field: 'loanId',
      headerName: 'Loan Id',
      cellRenderer: 'loanIdCellRenderer',
      minWidth: 100,
    },
    {
      field: 'section',
      headerName: 'Section',
      minWidth: 400,
      cellRenderer: 'sectionCellRenderer',
    },
    {
      field: 'taskName',
      headerName: 'Title',
      minWidth: 300,
      cellRenderer: 'titleCellRenderer',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 125,
      cellRenderer: 'statusCellRenderer',
    },
    ...insertIf(GeneralTasksTab.ALL_LOANS_TASKS == tab, [
      {
        field: 'assigneeNames',
        headerName: 'Assignee',
        minWidth: 200,
        cellRenderer: 'assigneeCellRenderer',
      },
    ]),
    {
      field: 'timeElapsed',
      headerName: 'Time Elapsed',
      cellRenderer: 'timeElapsedCellRenderer',
      minWidth: 125,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      minWidth: 220,
      cellRenderer: 'dateCellRenderer',
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Updated Date',
      minWidth: 220,
      cellRenderer: 'dateCellRenderer',
    },
    {
      field: 'taskId',
      headerName: 'Actions',
      minWidth: 85,
      maxWidth: 85,
      cellRenderer: 'actionCellRenderer',
      pinned: 'right',
      cellStyle: {
        marginLeft: '-10px',
        backgroundColor: 'white',
      },
    },
  ];

  const getUrl = (loanId) => {
    return userStore.isNportalUser
      ? '/nportal/#/loan_information/' + loanId
      : loanRoutesConfig.loans(loanId).children.dashboard.url
  }

  const init = async (row) => {
    const loanId = row.loanId;
    await documentStore.loanStore.fetchLoanDetails(loanId);
    generalTasksStore.setLoanId(loanId);
  }

  const onClose = () => {
    generalTasksStore.setLoanId(null);
    generalTasksStore.applyFilters();
  }

  const frameworkComponents = {
    taskIdCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return (
        <LinkColumn
          value={row?.taskId}
          url={'#'}
          onClick={e => editTaskStore.openEditTask(row?.taskId)}
        />
      );
    },
    loanIdCellRenderer(params) {
      const row = params.node.data;
      return (
        <>
          {params.value ? (
            <Box alignItems={'center'} display="flex">
              <GeneralTaskLoanTooltip loanId={row.loanId}>
                <LinkColumn
                  value={params.value}
                  url={'#'}
                  onClick={e => {
                    open(getUrl(params.value));
                    e.preventDefault();
                  }}
                />
              </GeneralTaskLoanTooltip>
              <GeneralTaskLoanFasttrackTooltip loanId={row.loanId} />
            </Box>
          ) : null}
        </>
      );
    },
    sectionCellRenderer(params) {
      return <>{params.value ? params.value : '-'}</>;
    },
    titleCellRenderer(params) {
      const row = params.node.data;
      const title = documentStore.getDisplayDocumentName(row?.document?.taskName);
      const info = row?.document?.documentNameSecondLine || null;
      return (!isNil(row?.document) &&
        <Box display="flex" alignItems="center">
          <TodoTitleLink
            document={row?.document}
            loanProcess={LoanProcess.UNDERWRITING}
            init={() => init(row)}
            showUploadLink={true}
            onClose={() => onClose()}
          >
            {title}
            {info ? ` - ${info}` : null}
          </TodoTitleLink>
        </Box>
      );
    },
    assigneeCellRenderer(params) {
      return (
        <>
          {params.value && (
            <Chip
              style={{ maxWidth: '100%', textOverflow: 'ellipsis' }}
              icon={<Person />}
              variant="outlined"
              size="small"
              label={params.value}
            />
          )}
        </>
      );
    },
    timeElapsedCellRenderer(params) {
      const row = params.node.data;
      const timeElapsed: number = row?.timeElapsed;
      if (timeElapsed) {
        const days = Math.trunc(timeElapsed / 86400);
        const hours = Math.trunc((timeElapsed % 86400) / 3600);
        return (days > 0 ? `${days}D ` : '') + `${hours}H`;
      } else {
        return null;
      }
    },
    statusCellRenderer(params) {
      return (
        !!params.value && (
          <StatusChip
            statusType={StatusType.GENERAL_TASK_STATUS}
            label={params.value}
            size="small"
          />
        )
      );
    },
    dateCellRenderer(params) {
      return <>{formatDate(params.value)}</>;
    },
    actionCellRenderer(params) {
      const row: TaskGridRow = params.node.data;
      return (
        <IconButton
          testId="open-menu"
          onClick={e =>
            setMenu({
              el: e.currentTarget,
              row: params.node.data,
            })
          }
        >
          <MoreVert color="primary" />
        </IconButton>
      );
    },
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    gridStore.setSortModel(sortModel);
  };

  const tasksRows = gridStore.gridData.data.rows.map(task => {
    if (!isNil(task.document)) {
      return loanTodosStore.mapLoanTaskToTodo(task, LoanProcess.UNDERWRITING);
    }
    else {
      return task;
    }
  });

  return (
    <Box>
      <DataGrid
        key={`${tab}`}
        columns={columns}
        rows={tasksRows}
        frameworkComponents={frameworkComponents}
        onSortChanged={onSortChanged}
        isLoading={gridStore.isLoading}
        sortModel={gridStore.sortModel}
        filterModel={gridStore.filterModel}
        paginationData={gridStore.gridData.meta}
        setPageNumber={onPageChange}
        domLayout="autoHeight"
      />
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={menu?.el}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={!!menu}
        onClose={() => {
          onClose();
          setMenu(null);
        }}
      >
        <MenuItem
          testId="edit-task"
          onClick={() => {
            editTaskStore.openEditTask(menu.row.taskId);
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Open Task
        </MenuItem>
      </Menu>
    </Box>
  );
});
