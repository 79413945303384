export enum FinalDocumentType {
  BINDER = 'BINDER',
  INVOICE = 'INVOICE',
  ELMSURE_BINDER = 'ELMSURE_BINDER',
  ELMSURE_INVOICE = 'ELMSURE_INVOICE',
  FLOOD = 'FLOOD',
}

export enum CoverageType {
  PACKAGE = 'Package Coverage',
  PROPERTY = 'Property Coverage',
  LIABILITY = 'Liability Coverage',
  FLOOD = 'Flood'
}

export const QUOTE_STATUS_APPROVED = 'Approved';
