import { observer } from 'mobx-react';
import { useState } from 'react';
import { useQuoteTypeStore } from '../../hooks/useQuoteTypeStore';
import { QuoteStep, SelectEntityOption } from '../../utils/constants';
import { AddEntityCard } from './components/addEntityCard';
import { NoEntityDialog } from './components/noEntityDialog';
import { SearchExistingEntity } from './components/searchExistingEntity';
import { SelectEntityOptionCard } from './components/selectEntityOptionCard';

export const EntityInformationStep = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const [showNoEntityDialog, setShowNoEntityDialog] = useState(false);
  const { entityStore, borrowersStore } = quoteTypeStore;
  const option = entityStore.selectEntityOption;

  const setOption = option => {
    if (option === SelectEntityOption.NO_ENTITY) {
      setShowNoEntityDialog(true);
    } else {
      entityStore.setSelectEntityOption(option);
    }
  };

  const continueWithoutEntity = () => {
    setShowNoEntityDialog(false);
    entityStore.setSelectEntityOption(SelectEntityOption.NO_ENTITY);
    quoteTypeStore.goToStep(QuoteStep.BORROWER_INFORMATION);
  };

  return (
    <>
      {(!option || option === SelectEntityOption.NO_ENTITY) && (
        <SelectEntityOptionCard option={option} onSelect={setOption} />
      )}
      {option === SelectEntityOption.SEARCH_ENTITY && <SearchExistingEntity />}
      {option === SelectEntityOption.ADD_ENTITY && <AddEntityCard />}
      <NoEntityDialog
        open={showNoEntityDialog}
        onCancel={() => setShowNoEntityDialog(false)}
        onContinue={() => continueWithoutEntity()}
      />
    </>
  );
});
