import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  createStyles,
  Typography,
  Box,
  makeStyles
} from "@material-ui/core";
import { InternalValuationReviewStore } from "../stores/internalValuationReviewStore";
import { formatCurrencyField, formatDateField, formatNumberField, getStringOrDefault } from "../utils/utils";
import { createNumberFormatField, createRadioField, createSelectField, createTextField } from "@roc/ui";
import { PropertyCompFormStore } from "../stores/propertyCompFormStore";
import { observer } from "mobx-react";
import { comparabilityOptions, viewOptions } from "../utils/constants";


const useStyles = makeStyles(() => ({
  fontSize: {
    fontSize: "0.9rem"
  },
  radioContainer: {
    "& .MuiFormControl-fullWidth": {
      width: "auto !important"
    }
  },
  fieldContainer: {
    "& input": {
      backgroundColor: "#FFFFFF !important",
      borderRadius: "12px"
    },
    "& .MuiOutlinedInput-multiline": {//for textarea
      backgroundColor: "#FFFFFF !important",
    }
  },
  subjectPropertyCell: {
    fontSize: "0.9rem",
    backgroundColor: "#B8F7FA"
  }
}));

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      padding: 10,
      border: "1px solid black",
      textAlign: "center",
    },
    head: {
      fontWeight: "bold",
      border: "1px solid black",
      textAlign: "center",
      padding: 10,
      letterSpacing: "1px"
    },
  })
)(TableCell);

const StyledTableCellNoBorders = withStyles(() =>
  createStyles({
    body: {
      padding: 10,
      borderBottom: "none"
    },
  })
)(TableCell);


interface ComparablesTableProps {
  internalValuationReviewStore: InternalValuationReviewStore;
}

export const ComparablesTable = observer(({ internalValuationReviewStore }: ComparablesTableProps) => {
  const classes = useStyles();

  const compForms = internalValuationReviewStore.propertyCompFormStores.map(store => store.getFlattenedValues());

  if (compForms.length === 0) {
    return (<Typography variant="h6">
      Comparable Properties Not Available
    </Typography>)
  }

  const compsColumnWidth = (100 - 10) / compForms.length;

  const readOnly = internalValuationReviewStore.readOnly;

  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow key="header-row">
            <StyledTableCell width="10%" className={classes.fontSize}></StyledTableCell>
            {compForms.map((_, i) => (<StyledTableCell width={`${compsColumnWidth}%`} key={`header-cell-${i}`} className={i === 0 ? classes.subjectPropertyCell : classes.fontSize} >{i === 0 ? "SUBJECT PROPERTY" : `COMP #${i}`}</StyledTableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((header, index) => (
            <TableRow key={`body-row-${header}`}>
              <StyledTableCell key={`body-row-cell-${header}`} className={classes.fontSize}>{header}</StyledTableCell>
              {compForms.map((row, i) => (
                <StyledTableCell key={`body-row-cell-${header}-${i}`} className={i === 0 ? classes.subjectPropertyCell : classes.fontSize}>{getCellValue(header, row)}</StyledTableCell>
              ))}
            </TableRow>
          ))}
          {/* Best Comparable Section */}
          {<TableRow>
            <StyledTableCellNoBorders className={classes.fontSize}>BEST COMPARABLE</StyledTableCellNoBorders>
            {compForms.map((comp: any, index) => {

              const propertyCompFormStore = internalValuationReviewStore.propertyCompFormStores[index];

              const isSubjectProperty = propertyCompFormStore.form.fields.sequence.value === 0;

              return (
                <StyledTableCellNoBorders key={`best-comparable-cell-${index}`} className={isSubjectProperty ? classes.subjectPropertyCell : ""} align="center">
                  {!isSubjectProperty && (<Box className={classes.radioContainer} >
                    {createRadioField({
                      store: propertyCompFormStore,
                      testId: 'bestComparable' + propertyCompFormStore.form.fields.comparePropertyId.value,
                      fieldName: 'bestComparable',
                      label: '',
                      options: [{ label: "Best Comparable", value: true }],
                      row: true,
                      disabled: readOnly,
                      onChange: (value) => internalValuationReviewStore.onBestComparableChange(propertyCompFormStore.form.fields.propertyCompId.value, value),
                    })}
                  </Box>)}
                </StyledTableCellNoBorders>
              )
            })}
          </TableRow>
          }
          {/* Input Fields */}
          {fields.map(
            (field, index) => (
              <TableRow key={`field-cell${index}`}>
                <StyledTableCellNoBorders className={classes.fontSize}>{field}</StyledTableCellNoBorders>
                {compForms.map((comp, colIndex) => (
                  <StyledTableCellNoBorders key={`field-cell-input-${index}-${colIndex}`} className={colIndex === 0 ? classes.subjectPropertyCell : ""}>
                    <Box className={classes.fieldContainer}>
                      <TableInputField field={field} comparableFormStore={internalValuationReviewStore.propertyCompFormStores[colIndex]} readOnly={readOnly} />
                    </Box>
                  </StyledTableCellNoBorders>
                ))}
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
});



const ADDRESS = "ADDRESS";
const CITY_STATE_ZIP = "CITY, STATE ZIP";
const MLS_NUMBER = "MLS #";
const PROXIMITY = "PROXIMITY";
const ORIGINAL_LIST_DATE = "ORIGINAL LIST DATE";
const ORIGINAL_LIST_PRICE = "ORIGINAL LIST PRICE";
const SALE_DATE = "SALE DATE";
const SALE_PRICE = "SALE PRICE";
const DOM = "DOM";
const PROPERTY_TYPE = "PROPERTY TYPE";
const SALE_TYPE = "SALE TYPE";
const LOT_SIZE = "LOT SIZE";
const YEAR_BUILT = "YEAR BUILT";
const BEDROOMS = "BEDROOMS";
const BATHROOMS = "BATHROOMS";
const PRICE_PER_SQ = "PRICE PER SQ";
const POOL = "POOL";
const MLS_COMMENTS = "MLS COMMENTS";

const rows = [
  ADDRESS,
  CITY_STATE_ZIP,
  MLS_NUMBER,
  PROXIMITY,
  ORIGINAL_LIST_DATE,
  ORIGINAL_LIST_PRICE,
  SALE_DATE,
  SALE_PRICE,
  DOM,
  PROPERTY_TYPE,
  SALE_TYPE,
  LOT_SIZE,
  YEAR_BUILT,
  BEDROOMS,
  BATHROOMS,
  PRICE_PER_SQ,
  POOL,
  MLS_COMMENTS,
];

function getCellValue(row, comparable) {
  switch (row) {
    case ADDRESS:
      return getStringOrDefault(comparable.address);
    case CITY_STATE_ZIP:
      return `${comparable.city}, ${comparable.state} ${comparable.zipCode}`;
    case MLS_NUMBER:
      return getStringOrDefault(comparable.mlsNumber);
    case PROXIMITY: {
      const proximity = formatNumberField(comparable.proximity, 2, 2);
      if (proximity === '-') return proximity;

      return proximity + ' Miles';
    }
    case ORIGINAL_LIST_DATE:
      return formatDateField(comparable.originalListDate);
    case ORIGINAL_LIST_PRICE:
      return formatCurrencyField(comparable.originalListPrice);
    case SALE_DATE:
      return formatDateField(comparable.saleDate);
    case SALE_PRICE:
      return formatCurrencyField(comparable.salePrice);
    case DOM:
      return formatNumberField(comparable.domTrend);
    case PROPERTY_TYPE:
      return getStringOrDefault(comparable.propertyType);
    case SALE_TYPE:
      return getStringOrDefault(comparable.saleType);
    case LOT_SIZE:
      return formatNumberField(comparable.lotSize);
    case YEAR_BUILT:
      return getStringOrDefault(comparable.yearBuilt);
    case BEDROOMS:
      return formatNumberField(comparable.beds, 0, 2);
    case BATHROOMS:
      return formatNumberField(comparable.baths, 0, 2);
    case PRICE_PER_SQ:
      return formatCurrencyField(comparable.pricePerSqFt);
    case POOL:
      return comparable.hasPool ? "Yes" : "No";
    case MLS_COMMENTS: {
      return getStringOrDefault(comparable.mlsComments);
    }
  }
}

const NO_OF_UNITS = "NO. OF UNITS";
const PARKING_TYPE = "PARKING TYPE";
const AMENITIES = "AMENITIES";
const VIEW = "VIEW";
const COMPARABILITY = "COMPARABILITY";
const ANALYST_COMMENTS = "ANALYST COMMENTS";

const fields = [NO_OF_UNITS, PARKING_TYPE, AMENITIES, VIEW, COMPARABILITY, ANALYST_COMMENTS];


interface TableInputFieldProps {
  comparableFormStore: PropertyCompFormStore;
  field: string;
  readOnly: boolean;
}


function TableInputField({ field, comparableFormStore, readOnly }: TableInputFieldProps) {

  const isSubjectProperty = comparableFormStore.form.fields.sequence.value === 0;

  switch (field) {
    case NO_OF_UNITS:
      return createNumberFormatField({
        label: 'No. of units',
        fieldName: 'noOfUnits',
        testId: 'noOfUnits' + comparableFormStore.form.fields.comparePropertyId.value,
        store: comparableFormStore,
        standaloneLabel: false,
        disabled: readOnly
      });
    case PARKING_TYPE:
      return createTextField({
        label: 'Parking Type',
        fieldName: 'parkingType',
        testId: 'parkingType' + comparableFormStore.form.fields.comparePropertyId.value,
        store: comparableFormStore,
        standaloneLabel: false,
        disabled: readOnly
      });
    case AMENITIES:
      return createTextField({
        label: 'Amenities',
        fieldName: 'amenities',
        testId: 'amenities' + comparableFormStore.form.fields.comparePropertyId.value,
        store: comparableFormStore,
        standaloneLabel: false,
        disabled: readOnly
      });
    case VIEW:
      if (isSubjectProperty) {
        return createTextField({
          label: 'View',
          fieldName: 'view',
          testId: 'view' + comparableFormStore.form.fields.comparePropertyId.value,
          store: comparableFormStore,
          standaloneLabel: false,
          disabled: readOnly
        });
      } else {
        return createSelectField({
          store: comparableFormStore,
          testId: 'view',
          fieldName: 'view',
          label: 'View',
          options: viewOptions,
          standaloneLabel: false,
          disabled: readOnly
        });
      }
    case COMPARABILITY:
      if (isSubjectProperty) {
        return createTextField({
          label: 'Comparability',
          fieldName: 'comparability',
          testId: 'comparability' + comparableFormStore.form.fields.comparePropertyId.value,
          store: comparableFormStore,
          standaloneLabel: false,
          disabled: readOnly
        });
      }
      return createSelectField({
        label: 'Comparability',
        fieldName: 'comparability',
        testId: 'comparability' + comparableFormStore.form.fields.comparePropertyId.value,
        store: comparableFormStore,
        options: comparabilityOptions,
        standaloneLabel: false,
        disabled: readOnly
      });
    case ANALYST_COMMENTS:
      return createTextField({
        label: 'Analyst Comments',
        fieldName: 'analystComments',
        testId: 'analystComments' + comparableFormStore.form.fields.comparePropertyId.value,
        store: comparableFormStore,
        standaloneLabel: false,
        multiline: true,
        minRows: 4,
        disabled: readOnly
      });
    default:
      return null;
  }
}