import { GlobalStore, UserStore } from '@roc/feature-app-core';
import { computed, flow, action, makeObservable, observable } from 'mobx';
import { DialogState } from '@roc/ui';
import { FormStore } from '@roc/feature-app-core';
import { GridStore } from '@roc/feature-app-core';
import { ApiResponse } from '@roc/feature-app-core';
import { BorrowersService } from './../services/borrowersService';
import { Borrower } from '../types';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import { CreditBackgroundType, downloadDocument, isNil } from '@roc/feature-utils';
import NewBorrowerFormStore from './newBorrowerFormStore';
import QualifyBorrowerStore from './qualifyBorrowerStore';
import { BorrowerDetailsVerificationService, CreditBackgroundCheckStatus } from '@roc/feature-credit-background-check';
import { BorrowerSetupService } from '../services/borrowerSetup/borrowerSetupService';
import BorrowerSetupStore from './borrowerSetup/borrowerSetupStore';

export interface BorrowersResponse {
  totalCount: number;
  columns: Array<any>;
  rows: Array<Borrower>;
}

interface BorrowerPortalPreferences {
  automaticBorrowerPortalInvite: boolean;
  lenderTradeName: string;
}

const borrowerForm = {
  fields: {
    borrowerId: {
      value: 0,
      error: null,
      rule: 'required',
    },
    firstName: {
      value: '',
      error: null,
      rule: 'required',
    },
    lastName: {
      value: '',
      error: null,
      rule: 'required',
    },
    emailAddress: {
      value: '',
      error: null,
      rule: 'required|email',
    },
    cellPhone: {
      value: '',
      error: null,
      rule: 'required|regex:/^\\d{3}-?\\d{3}-?\\d{4}$/|alpha_dash',
      message: 'Phone must be a number with the format xxx-xxx-xxxx',
    },
    citizenshipStatus: {
      value: '',
      error: null,
      rule: '',
    },
    experience: {
      value: 1,
      error: null,
      rule: 'required',
    },
    originatorId: {
      value: null,
      rule: '',
    },
  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class BorrowersStore extends FormStore {
  globalStore: GlobalStore;
  private userStore: UserStore;
  private loanParticipantsStore: LoanParticipantsStore;
  private borrowerService: BorrowersService;
  gridStore: GridStore;
  dialogState: DialogState;
  currentBorrower: any;
  requestSuccess: boolean;
  borrowerPortalPreferences: BorrowerPortalPreferences;
  automaticBorrowerPortalInvite: boolean;
  lenderTradeName: string;
  borrowerPortalEnabled: boolean;
  borrowerSaved: boolean;
  qualifyBorrowerStore: QualifyBorrowerStore;
  newBorrowerFormStore: NewBorrowerFormStore;
  borrowerSetupStore: BorrowerSetupStore;
  preScreenTasks: any;
  borrowerTier;
  private borrowerSetupService: BorrowerSetupService;
  private borrowerDetailsVerificationService: BorrowerDetailsVerificationService;

  isBorrowerResponseModalOpen: boolean;
  borrowerResponseStatus: 'success' | 'error';
  borrowerResponseMessage: string;
  initialBorrowerFormStep: number;
  isBorrowerSetupOpen: boolean;
  isNewBorrowerOpen: boolean;
  borrowerExposureData: any;

  constructor(globalStore: GlobalStore, userStore: UserStore) {
    super({ ...borrowerForm }, globalStore);
    this.globalStore = globalStore;
    this.userStore = userStore;
    this.requestSuccess = false;
    this.automaticBorrowerPortalInvite = null;
    this.lenderTradeName = '';
    this.borrowerPortalEnabled = false;
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.gridStore = new GridStore(
      () => this.fetchData(),
      () => this.processData()
    );
    this.borrowerService = new BorrowersService();
    this.borrowerSaved = false;
    this.qualifyBorrowerStore = new QualifyBorrowerStore(this.globalStore, this.userStore);
    this.newBorrowerFormStore = new NewBorrowerFormStore(this.globalStore);
    this.borrowerSetupStore = new BorrowerSetupStore(this.globalStore);
    this.borrowerSetupService = new BorrowerSetupService();
    this.borrowerDetailsVerificationService = new BorrowerDetailsVerificationService();
    this.preScreenTasks = [];

    this.isBorrowerResponseModalOpen = false;
    this.initialBorrowerFormStep = 0;
    this.isBorrowerSetupOpen = false;
    this.isNewBorrowerOpen = false;

    makeObservable(this, {
      borrowerExposureData: observable,
      isBorrowerResponseModalOpen: observable,
      borrowerResponseStatus: observable,
      borrowerResponseMessage: observable,
      borrowerPortalPreferences: observable,
      dialogState: observable,
      showDialog: computed,
      resetDialogState: action,
      addNewBorrower: action,
      getBorrowerExposureGrid: flow,
      editBorrower: action,
      loadParticipants: flow,
      createBorrowerUserAccount: flow,
      deactivateUser: flow,
      resendInvite: flow,
      getBorrowerLoginLink: flow,
      requestSuccess: observable,
      setRequestSuccess: action,
      enableBorrowerPortalForBorrowersByTpo: flow,
      getBorrowerPortalPreferencesByTpo: flow,
      automaticBorrowerPortalInvite: observable,
      setAutomaticBorrowerPortalInvite: action,
      lenderTradeName: observable,
      setLenderTradeName: action,
      borrowerPortalEnabled: observable,
      setBorrowerPortalEnabled: action,
      downloadBorrowerHandbook: flow,
      borrowerSaved: observable,
      setBorrowerSaved: action,
      setCurrentBorrower: action,
      currentBorrower: observable,
      saveBorrowerPreScreen: flow,
      getExtraInfoBorrower: flow,
      getBorrowerData: flow,
      saveEditBorrower: flow,
      getPreScreenTasks: flow,
      preScreenTasks: observable,
      setPreScreenTasks: action,
      fetchTierRating: flow,
      borrowerTier: observable,
      isIdVerificationInProgress: computed,
      saveLeadAsBorrower: flow,
      showSuccessNotification: action,
      saveNewBorrower: flow,
      initialBorrowerFormStep: observable,
      viewLink: action,
      handleNewEditBorrower: action,
      isBorrowerSetupOpen: observable,
      isNewBorrowerOpen: observable,
      setIsBorrowerSetupOpen: action,
      setIsNewBorrowerOpen: action,
      handleNewAddBorrower: action,
      handleCloseBorrower: action,
      setInitialBorrowerValues: action,
      handleBorrowerSetup: action,
      handleCloseBorrowerSetup: action,
    });
  }

  private async fetchData() {
    try {
      if (this.globalStore.lenderInfo?.enableBorrowerPortal) {
        const response: ApiResponse = await this.borrowerService.getAllLenderBorrowers(
          this.gridStore.gridData.meta.pageNumber,
          this.gridStore.gridData.meta.pageSize,
          this.gridStore.gridData.meta.sortDir,
          this.gridStore.gridData.meta.sortBy,
          this.gridStore.gridData.meta.filters,
          this.gridStore.gridData.meta.dropdownFilters
        );
        return response;
      } else {
        const response: ApiResponse = await this.borrowerService.getAllBorrowers(
          this.gridStore.gridData.meta.pageNumber,
          this.gridStore.gridData.meta.pageSize,
          this.gridStore.gridData.meta.sortDir,
          this.gridStore.gridData.meta.sortBy,
          this.gridStore.gridData.meta.filters,
          this.gridStore.gridData.meta.dropdownFilters
        );
        return response;
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching borrowers',
      });
    }
  }

  private processData() {
    this.gridStore.gridData.data.rows = this.gridStore.gridData.data.rows.map(
      row => {
        const addressArr = [];
        addressArr.push(row.streetNumber ?? '');
        addressArr.push(row.streetName ?? '');
        addressArr.push(row.city ?? '');
        addressArr.push(row.state ?? '');
        addressArr.push(row.zipCode ?? '');
        row.address = addressArr.join(' ');
        return row;
      }
    );
  }

  resetDialogState() {
    this.dialogState = undefined;
    this.currentBorrower = undefined;
    this.borrowerTier = undefined;
    if (this.borrowerSaved) {
      this.gridStore.resetAndFetchGridData();
    }
    this.borrowerSaved = false;
  }

  addNewBorrower() {
    this.setCurrentBorrower(null);
    this.reset();
    this.dialogState = DialogState.ADD;
    this.handleNewAddBorrower();
  }

  editBorrower(record) {
    this.reset();
    this.dialogState = DialogState.EDIT;
    this.currentBorrower = record;
    this.loadForm(record);
  }

  get showDialog() {
    return this.dialogState != null;
  }

  handleOpenBorrowerStatusModal(status: 'success' | 'error', message: string) {
    this.borrowerResponseStatus = status;
    this.borrowerResponseMessage = message;
    this.isBorrowerResponseModalOpen = true;
  }

  handleCloseStatusModal() {
    this.isBorrowerResponseModalOpen = false;
  }

  setRequestSuccess(requestSuccess: boolean) {
    this.requestSuccess = requestSuccess;
  }

  *loadParticipants() {
    yield this.loanParticipantsStore.fetchLoanParticipants();
    if (!this.currentBorrower) {
      if (this.globalStore.userFeatures?.isLenderOriginator) {
        this.onFieldChange('originatorId', this.userStore.userInformation.userId);
      }
    }
  }

  *getBorrowerExposureGrid(borrowerId){
    try{
      const response: ApiResponse = yield this.borrowerService.getBorrowerExposureGrid(borrowerId);
      this.borrowerExposureData = response;
    }catch(error){
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting borrower exposure information',
      });
    }
  }

  *createBorrowerUserAccount(contactId, companyId) {
    try {
      const response: ApiResponse = yield this.borrowerService.createBorrowerUser(
        {
          contactId,
          companyId
        }
      );
      this.gridStore.refresh();
      this.setRequestSuccess(true)
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Account created successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating the borrower portal account.',
      });
    }
  }

  *deactivateUser(userId, username) {
    try {
      const response: ApiResponse = yield this.borrowerService.deactivateUser({ userId, username });
      this.gridStore.refresh();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'User deactivated successfully.',
      });
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deactivating the user.',
      });
    }
  }

  *resendInvite(userId) {
    try {
      const response: ApiResponse = yield this.borrowerService.resendInvite(userId);
      this.setRequestSuccess(true);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Invite sent successfully.',
      });
    }
    catch (error) {
      this.setRequestSuccess(true);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while resending the invite.',
      });
    }
  }

  *getBorrowerLoginLink(userId) {
    try {
      const response: ApiResponse = yield this.borrowerService.getBorrowerLoginLink(userId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Link copied successfully!',
      });
      return response.data.data.passwordLink;
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting borrower login link.',
      });
    }
  }

  *enableBorrowerPortalForBorrowersByTpo(enableBorrowerPortal, companyId) {
    try {
      const response: ApiResponse = yield this.borrowerService.enableBorrowerPortalForBorrowersByTpo(enableBorrowerPortal, companyId);
      this.getBorrowerPortalPreferencesByTpo(companyId);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Borrower Portal preferences saved successfully',
      });
      window.location.reload();
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving Borrower Portal preferences',
      });
    }
  }

  *getBorrowerPortalPreferencesByTpo(companyId) {
    try {
      if (!this.globalStore.userFeatures.isBroker) {
        const response: ApiResponse = yield this.borrowerService.getBorrowerPortalPreferencesByTpo(companyId);
        this.setAutomaticBorrowerPortalInvite(response.data.automaticBorrowerPortalInvite);
        this.setBorrowerPortalEnabled(response.data.borrowerPortalEnabled)
        if (response.data.lenderTradeName != null) {
          this.setLenderTradeName(response.data.lenderTradeName);
        } else {
          this.setLenderTradeName(response.data.company.name);
        }
      }
    }
    catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting borrower portal preferences',
      });
    }
  }

  setAutomaticBorrowerPortalInvite(automaticBorrowerPortalInvite: boolean) {
    this.automaticBorrowerPortalInvite = automaticBorrowerPortalInvite;
  }

  setLenderTradeName(lenderTradeName: string) {
    this.lenderTradeName = lenderTradeName;
  }

  setBorrowerPortalEnabled(borrowerPortalEnabled: boolean) {
    this.borrowerPortalEnabled = borrowerPortalEnabled;
  }

  *downloadBorrowerHandbook() {
    try {
      const response = yield this.borrowerService.downloadHandbook();

      downloadDocument(
        response?.data,
        response?.headers,
        'download'
      );
    } catch (e) {
      console.log('error', e);
    }
  }

  setBorrowerSaved(value: boolean) {
    this.borrowerSaved = value;
  }

  setCurrentBorrower(borrower) {
    this.currentBorrower = borrower;
  }

  *saveNewBorrower(onSuccess?: () => void) {
    this.newBorrowerFormStore.runFormValidationWithMessage();
    if (this.newBorrowerFormStore.form.meta.isValid) {
      try {
        let response;
        const {
          borrowerId,
          firstName,
          lastName,
          emailAddress,
          personalGuarantor,
          creditBackgroundCheck,
        } = this.newBorrowerFormStore.getFormValues();

        response = yield this.borrowerService.saveBorrower({
          borrowerId,
          firstName,
          lastName,
          emailAddress,
          runCreditOnCreation: personalGuarantor,
          creditBackgroundCheck
        });

        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Borrower added successfully.',
        });

        this.setCurrentBorrower({
          ...response.data?.data,
        });
        this.newBorrowerFormStore.onFieldChange('borrowerId', this.currentBorrower?.borrowerId)

        this.borrowerSaved = true;
        this.initialBorrowerFormStep = 0;
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving borrower information.',
        });
      }
    }
  }

  *saveBorrowerPreScreen(onSuccess?: () => void) {
    this.newBorrowerFormStore.runFormValidationWithMessage();
    if (this.newBorrowerFormStore.form.meta.isValid) {
      try {
        let response;
        const {
          firstName,
          lastName,
          emailAddress,
          personalGuarantor,
          creditBackgroundCheck,
        } = this.newBorrowerFormStore.getFormValues();
        let cafResponse;

        response = yield this.borrowerService.saveBorrower({
          firstName,
          lastName,
          emailAddress,
          runCreditOnCreation: personalGuarantor,
          creditBackgroundCheck
        });

        try {
          cafResponse = yield this.qualifyBorrowerStore.cafProcess(personalGuarantor, creditBackgroundCheck, response.data?.data);

          let successMessage = 'Borrower added successfully.'
          if (creditBackgroundCheck) {
            successMessage += 'And email has been sent to the borrower.'
          }
          this.globalStore.notificationStore.showSuccessNotification({
            message: successMessage,
          });
        } catch {
          this.globalStore.notificationStore.showInfoNotification({
            message: 'Borrower added successfully but identity verification has failed, please try again in the borrower page.',
          });
        }

        this.setCurrentBorrower({
          ...response.data?.data,
          sendIdVerification: creditBackgroundCheck,
          cafResponse: cafResponse?.data?.data,
        });

        this.borrowerSaved = true;
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        this.globalStore.notificationStore.showErrorNotification({
          message: 'Error while saving borrower information.',
        });
      }
    }
  }

  *saveLeadAsBorrower(borrower, onSuccess?: () => void) {
    try {
      let response;
      const {
        firstName,
        lastName,
        emailAddress,
        cellPhone,
        leadSource,
        leadSourceGroup,
        leadSfId,
        leadId,
      } = borrower;

      response = yield this.borrowerService.saveBorrower({
        firstName,
        lastName,
        emailAddress,
        cellPhone,
        leadSource,
        leadSourceGroup,
        leadSfId,
        leadId,
      });

      this.setCurrentBorrower({
        ...borrower,
        ...response.data?.data,
      });

      this.borrowerSaved = true;
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrower information.',
      });
    }
  }

  *getExtraInfoBorrower(borrowerId?: number | string) {
    try {
      const response = yield this.borrowerService.getBorrowerById(borrowerId);
      const extraData = {
        citizenshipStatus: response.data?.citizenshipStatus,
        driverLicenseNumber: response.data?.driverLicenseNumber,
        driverLicenseState: response.data?.driverLicenseState,
        driverLicenseExpirationDate: response.data?.driverLicenseExpirationDate,
        netWorth: response.data?.netWorth,
        liabilities: response.data?.liabilities,
        assets: response.data?.assets,
        leadSfId: response.data?.leadSfId,
        leadId: response.data?.leadId,
        leadSource: response.data?.leadSource,
        leadSourceGroup: response.data?.leadSourceGroup,
      }

      const newBorrower = Object.assign(this.currentBorrower, extraData);
      this.setCurrentBorrower(newBorrower);
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting latest borrower information.',
      });
    }
  }

  *getBorrowerData(borrowerId?: number | string) {
    try {
      const response = yield this.borrowerService.getBorrowerById(borrowerId);
      this.setCurrentBorrower(response?.data);
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting borrower information.',
      });
    }
  }

  *saveEditBorrower(emailAddress: string, cellPhone: string | number, firstName: string, lastName: string, onSuccess?: () => void) {
    try {
      const response = yield this.borrowerService.saveBorrower({
        borrowerId: this.currentBorrower.borrowerId,
        emailAddress,
        cellPhone,
        firstName,
        lastName,
      });
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Borrower information updated successfully.',
      });
      this.setCurrentBorrower({
        ...this.currentBorrower,
        emailAddress,
        cellPhone,
        firstName,
        lastName,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrower information.',
      });
    }
  }

  *getPreScreenTasks(borrowerId: number | string, taskType) {
    try {
      const response = yield this.borrowerService.getBorrowerPreScreenTasks(borrowerId, taskType);

      this.preScreenTasks = response.data;
    } catch {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting latest borrower information.',
      });
    }
  }

  setPreScreenTasks = (tasks: any) => {
    this.preScreenTasks = tasks;
  };

  *fetchTierRating() {
    try {
      const response = yield this.borrowerService.getBorrowerTierByBorrowerId(
        this.currentBorrower.borrowerId
      );
      this.borrowerTier = response.data;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error getting the borrower tier.',
      });
    }
  }

  get isIdVerificationInProgress() {
    const idVerificationTask = this.preScreenTasks.find(task => task?.type === CreditBackgroundType.ID_VERIFICATION);
    if (!isNil(idVerificationTask)) {
      return idVerificationTask?.status === CreditBackgroundCheckStatus.WAITING_AUTHORIZATION || idVerificationTask?.status === CreditBackgroundCheckStatus.REPORT_REQUEST_ERROR;
    }
    return false;
  }

  showSuccessNotification(message: string) {
    this.globalStore.notificationStore.showSuccessNotification({ message });
  }

  viewLink(borrower) {
    this.handleNewEditBorrower(borrower);
    this.initialBorrowerFormStep = 2;
  }

  handleBorrowerSetup(borrower) {
    this.setInitialBorrowerValues(borrower);
    this.initialBorrowerFormStep = 2;
  }

  handleCloseBorrowerSetup() {
    this.initialBorrowerFormStep = 0;
    this.newBorrowerFormStore.reset();
    this.currentBorrower = null;
  }

  handleNewEditBorrower(borrower) {
    this.dialogState = DialogState.EDIT;
    this.setInitialBorrowerValues(borrower);
    this.setIsBorrowerSetupOpen(true);
  }

  setInitialBorrowerValues(borrower) {
    this.currentBorrower = borrower;
    this.newBorrowerFormStore.loadForm(borrower);
  }

  setIsBorrowerSetupOpen(isBorrowerSetupOpen) {
    this.isBorrowerSetupOpen = isBorrowerSetupOpen;
  }

  setIsNewBorrowerOpen(isNewBorrowerOpen) {
    this.isNewBorrowerOpen = isNewBorrowerOpen;
  }

  handleNewAddBorrower() {
    this.initialBorrowerFormStep = 0;
    this.newBorrowerFormStore.reset();
    this.newBorrowerFormStore.initCreditBackgroundCheck();
    this.setIsNewBorrowerOpen(true);
  }

  handleCloseBorrower() {
    this.setIsNewBorrowerOpen(false);
    if (this.borrowerSaved) {
      this.setIsBorrowerSetupOpen(true);
      this.gridStore.resetAndFetchGridData();
      this.borrowerSaved = false;
    }
  }

  resetBorrowerData() {
    super.reset();
    this.preScreenTasks = [];
    this.borrowerTier = undefined;
    this.currentBorrower = undefined;
  }
}
