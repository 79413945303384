import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { Button } from '@roc/ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useGeneralTasksStore } from '../hooks/useGeneralTasksStore';
import {
  GeneralLoanTasksFilters,
  GeneralTasksFilters,
  GeneralTasksTab,
} from '../types/generalTasksTypes';
import {
  generalTaskStatusOptions,
} from '../utils/generalTasksConstants';
import {
  MultiselectFilter,
  TextFilter,
  UserFilter,
} from './generalTasksToolbarFilterComponents';

const useStyles = makeStyles(theme => ({
  container: {
    paddingtTop: theme.spacing(2),
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
  },
}));

export const LoansTasksToolbarFilters = observer(() => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(true);
  const { generalTasksStore } = useGeneralTasksStore();
  const { filters, assigneeOptions } = generalTasksStore;

  let selectedFilters = [
    GeneralTasksFilters.LOAN_ID,
    GeneralTasksFilters.TASK_ID,
    GeneralLoanTasksFilters.SECTION,
    GeneralLoanTasksFilters.TASK_NAME,
    GeneralTasksFilters.STATUS,
    GeneralTasksFilters.ASSIGNEE,
  ];

  let hiddenFilters = [];

  if (generalTasksStore.tab === GeneralTasksTab.MY_LOANS_TASKS) {
    hiddenFilters.push(GeneralTasksFilters.ASSIGNEE);
  }

  selectedFilters = selectedFilters.filter(
    item => !hiddenFilters.includes(item)
  );

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        <Button
          testId="show-filters"
          size="small"
          style={{ minWidth: 'auto' }}
          onClick={() => setShowFilters(val => !val)}
        >
          {showFilters ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
        <Typography variant="h6"> Filters</Typography>
        <Button
          testId="reset-filters"
          variant="text"
          size="small"
          color="primary"
          style={{ marginLeft: '16px' }}
          onClick={() => generalTasksStore.resetFilters()}
        >
          Reset Filters
        </Button>
      </Box>
      <Collapse in={showFilters}>
        <Box pt={2}>
          <Grid container spacing={2}>
            {selectedFilters.map(filterName => {
              const value = filters?.[filterName];
              const filterProps = {
                value,
                onChange: options => {
                  generalTasksStore.setFilter(filterName, options);
                },
              };
              return (
                <Grid item key={filterName}>
                  {GeneralTasksFilters.LOAN_ID === filterName && (
                    <TextFilter
                      {...filterProps}
                      label={'Loan Id'}
                      maxWidth={'100px'}
                    />
                  )}
                  {GeneralTasksFilters.TASK_ID === filterName && (
                    <TextFilter
                      {...filterProps}
                      label={'Task Id'}
                      maxWidth={'100px'}
                    />
                  )}
                  {GeneralLoanTasksFilters.SECTION === filterName && (
                    <TextFilter {...filterProps} label={'Section'} />
                  )}
                  {GeneralLoanTasksFilters.TASK_NAME === filterName && (
                    <TextFilter {...filterProps} label={'Title'} />
                  )}
                  {GeneralTasksFilters.STATUS === filterName && (
                    <MultiselectFilter
                      {...filterProps}
                      label={'Status'}
                      options={generalTaskStatusOptions}
                    />
                  )}
                  {GeneralTasksFilters.ASSIGNEE === filterName && (
                    <UserFilter
                      {...filterProps}
                      label={'Assignees'}
                      options={(assigneeOptions ?? []).map(assignee => ({
                        ...assignee,
                        label: `${assignee.firstName} ${assignee.lastName}`,
                        value: assignee.userId,
                      }))}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
});