import {
  action,
  computed,
  flow,
  makeObservable,
  observable,
  override,
} from 'mobx';
import { ApiResponse, CompanyDropdownStore, UserStore } from '@roc/feature-app-core';
import { LoanService } from './../services/loanService';
import { SubmitAppraisalService } from '@roc/feature-appraisals';
import {
  FixFlipStore,
  SinglePropertyStore,
  RentalPortfolioStore,
  MultiFamilyStore,
  StabilizedBridgeStore
} from './../../loanSubmission/stores';
import { GlobalStore, GridStore } from '@roc/feature-app-core';
import { ChangeStatusStore } from './changeStatusStore';
import { DocumentLibraryStore } from '@roc/feature-document-library';
import { SelectBorrowersStore } from '@roc/feature-borrowers';
import BorrowerFormStore from './borrowerFormStore';
import { Borrower, LoanRelationshipManagers } from '@roc/feature-types';
import {
  UNDERWRITING,
  CLOSING,
  DRAWS,
  LoanSubType,
  LoanType,
  wimbaFilePath,
  SERVICING,
  isPendingLoanStatus,
  REQUEST_LOAN_TYPE_LOAN,
  DRAFT_LOAN_TYPE_LOAN,
  LoanStatus,
  TRADES,
  isNil,
  segmentAnalytics,
  SegmentTrackerEvent
} from '@roc/feature-utils';
import ResendQuoteStore from './resendQuoteStore';
import { DraftLoan } from '@roc/feature-types';
import { LoanPriority } from '../utils/loansConstants';
import { borrowerDefaults } from '@roc/feature-utils';
import { LoanBaseStore } from '@roc/feature-loans-shared'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { OriginatorsStore } from 'libs/feature-originator/src/originators/stores/originatorStore';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SelectOriginatorsStore } from 'libs/feature-originator/src/originators/stores/selectOriginatorStore';
import { useStore } from '@roc/client-portal-shared/hooks';
import { Originator } from 'libs/feature-originator/src/originators/types/originatorTypes';
import { LoanContactsGridStore } from '../loanDetails/modules/loanContacts/stores/loanContactsGridStore';
import { LoanParticipantsStore } from '@roc/feature-loan-participants';
import { LoanDataFormstore } from '@roc/feature-loans-data';
import { EditLoansStore } from '@roc/feature-edit-loans';
import { DraftLoanTasksStore } from './draftLoanTasksStore';
import { OneToolService } from 'libs/feature-one-tool/src/quote/services/oneToolService';

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});

interface CalendlyPreferences {
  showCalendly: boolean;
  calendlyUsers: Map<number, CalendlyUser>;
  calendlyPrefill: CalendlyPrefill;
  successResponse: boolean;
}

export type CalendlyPrefill = {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  guests: Array<string>;
};

type CalendlyUser = {
  calendlyUserId: number;
  userId: number;
  calendlyUrl: string;
};

type ClosingRequirements = Record<string, string[]>;

const getDefaultCalendlyPreferences = (): CalendlyPreferences => ({
  showCalendly: false,
  calendlyUsers: new Map(),
  calendlyPrefill: {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    guests: [],
  },
  successResponse: null
});

export class LoanStore extends LoanBaseStore {
  originatorStore: OriginatorsStore;
  selectOriginatorsStore: SelectOriginatorsStore;
  private documentLibraryStore: DocumentLibraryStore;
  private loanService: LoanService;
  private currentBorrowerIds: any[];
  private oneToolService: OneToolService;
  submitAppraisalService: SubmitAppraisalService;
  myLoansGridStore: GridStore;
  myFundedLoansForDrawsGridStore: GridStore;
  fundedLoansForPayoffGridStore: GridStore;
  fundedLoansForExtensionsGridStore: GridStore;
  myPipelineLoansGridStore: GridStore;
  myPendingTasksGridStore: GridStore;
  myDraftLoansGridStore: GridStore;
  myRequestLoansGridStore: GridStore;
  myBackUpLoansGridStore: GridStore;
  myAgreedLoansGridStore: GridStore;
  myAppraisalToDosGridStore: GridStore;
  propertiesGridStore: GridStore;
  conditionalApprovedLoansGridStore: GridStore;
  priorityLoansV2GridStore: GridStore;
  myLoanQuotesGridStore: GridStore;
  loanStatusStore: ChangeStatusStore;
  resendQuoteStore: ResendQuoteStore;
  fixFlipStore: FixFlipStore;
  multiFamilyStore: MultiFamilyStore;
  stabilizedBridgeStore: StabilizedBridgeStore;
  singlePropertyStore: SinglePropertyStore;
  rentalPortfolioStore: RentalPortfolioStore;
  selectBorrowersStore: SelectBorrowersStore;
  loanContactsGridStore: LoanContactsGridStore;
  loansDataFormStore: LoanDataFormstore;
  editLoansStore: EditLoansStore;
  selectedTabIndex = 0;
  loanDetails: any = undefined;
  previousDraws = [];
  numberUnreadMessages = 0;
  insuranceReviewers = [];
  submitAppraisalSuccess: boolean = false;
  conversationsToggle: boolean;
  isBackgroundTaskBeingProcessed: boolean;
  propertiesData: [];
  calendlyPreferences: CalendlyPreferences;
  juniorCalendlyPreferences: CalendlyPreferences;
  borrowerUpdatedCounter: number;
  closingRequirements: ClosingRequirements;
  isLoanDetailsSuccess: boolean;
  relationshipManagerData: LoanRelationshipManagers;
  extensionRequest: GridStore;
  estimatedClosingCosts: any = undefined;
  loanParticipantsStore: LoanParticipantsStore;
  companyDropdownStore: CompanyDropdownStore;
  draftLoanTasksStore: DraftLoanTasksStore;
  userStore: UserStore;
  loanFastTrackUrl: string;
  appraisalExpirationDate: string;
  creditExpirationDate: string;
  cogsExpirationDate: string;
  loanClosingConditions: any = undefined;
  loanPrescreenData: any = undefined;
  exceptions: [];
  comments: any = undefined;
  mitigatingFactors: any = undefined;
  loanApprovalData: any = undefined;
  delinquentLoansGridStore: GridStore;
  maturityLoansGridStore: GridStore;
  editLoan: any;
  quoteId: any = undefined;


  constructor(
    globalStore,
    documentLibraryStore,
    fixFlipStore,
    multiFamilyStore,
    singlePropertyStore,
    rentalPortfolioStore,
    stabilizedBridgeStore,
    userStore,
    companyDropdownStore
  ) {
    super(globalStore);
    this.loanStatusStore = new ChangeStatusStore(this.globalStore);
    this.resendQuoteStore = new ResendQuoteStore(this.globalStore, this);
    this.documentLibraryStore = documentLibraryStore;
    this.fixFlipStore = fixFlipStore;
    this.multiFamilyStore = multiFamilyStore;
    this.singlePropertyStore = singlePropertyStore;
    this.rentalPortfolioStore = rentalPortfolioStore;
    this.stabilizedBridgeStore = stabilizedBridgeStore;
    this.userStore = userStore;
    this.companyDropdownStore = companyDropdownStore;
    this.originatorStore = new OriginatorsStore(this.globalStore, userStore);
    this.selectOriginatorsStore = new SelectOriginatorsStore(this.originatorStore, this.globalStore);
    this.loansDataFormStore = new LoanDataFormstore(this.globalStore, this.userStore);
    this.editLoansStore = new EditLoansStore(this.globalStore, this.userStore);
    this.selectBorrowersStore = new SelectBorrowersStore(
      this.globalStore,
      new BorrowerFormStore(globalStore),
      undefined,
      undefined,
      () => this.handleBorrowersChanged(),
      undefined,
      () => new BorrowerFormStore(globalStore)
    );

    this.myLoansGridStore = new GridStore(() => this.fetchMyLoans(), null, 50);
    this.myFundedLoansForDrawsGridStore = new GridStore(
      () => this.fetchMyFundedLoans(),
      null,
      50
    );
    this.fundedLoansForPayoffGridStore = new GridStore(
      () => this.fetchFundedLoansForPayoffRequest(),
      null,
      50
    );
    this.fundedLoansForExtensionsGridStore = new GridStore(
      () => this.fetchFundedLoansForExtensionsRequest(),
      null,
      50
    );
    this.extensionRequest = new GridStore(() => this.fetchExtensions(), null, 50);
    this.myPipelineLoansGridStore = new GridStore(
      () => this.fetchMyPipelineLoans(),
      null,
      50
    );
    this.myPendingTasksGridStore = new GridStore(
      () => this.fetchMyPendingTasks(),
      null,
      50
    );
    this.myDraftLoansGridStore = new GridStore(
      () => this.fetchMyDraftLoans(),
      null,
      50
    );
    this.myRequestLoansGridStore = new GridStore(
      () => this.fetchMyRequestLoans(),
      null,
      50
    );
    this.myBackUpLoansGridStore = new GridStore(
      () => this.fetchMyBackUpLoans(),
      null,
      50
    );
    this.myAgreedLoansGridStore = new GridStore(
      () => this.fetchMyAgreedLoans(),
      null,
      50
    );
    this.priorityLoansV2GridStore = new GridStore(
      () => this.fetchPriorityLoansV2(),
      null,
      50
    );
    this.conditionalApprovedLoansGridStore = new GridStore(
      () => this.fetchConditionalApprovedLoans(),
      null,
      50
    )
    this.delinquentLoansGridStore = new GridStore(
      () => this.fetchMyDelinquentLoans(),
      null,
      50
    );
    this.maturityLoansGridStore = new GridStore(
      () => this.fetchMyMaturityLoans(),
      null,
      50
    );
    this.myAppraisalToDosGridStore = new GridStore(
      () => this.fetchMyAppraisalToDos(),
      null,
      50
    );
    this.myLoanQuotesGridStore = new GridStore(
      () => this.fetchMyQuoteLoans(),
      null,
      50
    );
    this.propertiesGridStore = new GridStore(() => this.fetchProperties());
    this.loanService = new LoanService();
    this.oneToolService = new OneToolService();
    this.submitAppraisalService = new SubmitAppraisalService();
    this.loanContactsGridStore = new LoanContactsGridStore(
      this.globalStore,
      this
    );
    this.loanParticipantsStore = new LoanParticipantsStore(this.globalStore);
    this.draftLoanTasksStore = new DraftLoanTasksStore(this.globalStore);
    this.setDefaults();
    makeObservable(this, {
      // tab index
      selectedTabIndex: observable,
      setSelectedTabIndex: action,
      setConversationsToggle: action,
      setIsBackgroundTaskBeingProcessed: action,
      // loanDetails
      loanDetails: override,
      refreshLoanDetails: flow,
      fetchLoanDetails: flow,
      resetLoanDetails: action,
      setExistingBorrowers: action,
      allowEditBorrowers: computed,
      // loanRequests
      fetchPreviousDraws: flow,
      fetchTotalUnreadMessages: flow,
      fetchInternalInsuranceReviewers: flow,
      numberUnreadMessages: observable,
      insuranceReviewers: observable,
      changeLoanPriority: flow,
      changeAssignee: flow,
      submitFormStatus: flow,
      deleteDraftLoan: flow,
      submitLoanAppraisalOrder: flow,
      submitAppraisalSuccess: observable,
      conversationsToggle: observable,
      isBackgroundTaskBeingProcessed: observable,
      setPropertiesData: action,
      setLoanClosingConditions: action,
      downloadWimbaDoc: flow,
      saveBorrowers: flow,
      saveLenderOriginator: flow,
      fetchClosingRequirements: flow,
      resendQuote: flow,
      requestElmsureQuote: flow,
      //calendly
      getCalendlyPreferences: flow,
      calendlyPreferences: observable,
      juniorCalendlyPreferences: observable,
      borrowerUpdatedCounter: observable,
      closingRequirements: observable,
      isLoanDetailsSuccess: observable,
      setLoanDetailsSuccess: action,
      relationshipManagerData: observable,
      fetchRelationshipManagers: flow,
      //closing costs
      estimatedClosingCosts: observable,
      fetchBridgeLoanClosingCosts: flow,
      fetchRentalLoanClosingCosts: flow,

      saveLoanParticipants: flow,
      getLoanRequestsTotalCount: flow,
      loanFastTrackUrl: observable,
      fetchLoanFastTrackPaymentUrl: flow,
      fetchConversationsToggleStatus: flow,
      fetchIsBackgroundTaskBeingProcessed: flow,
      updateUserBackUpConversationToggle: flow,
      fetchLoanExpirationDates: flow,
      fetchLoanClosingConditions: flow,
      loanClosingConditions: observable,
      loanPrescreenData: observable,
      fetchLoanPrescreenData: flow,
      getBorrowerFromLoanByBorrowerId: action,
      fetchExceptionsAndCommentsExternal: flow,
      exceptions: observable,
      comments: observable,
      mitigatingFactors: observable,
      loanApprovalData: observable,
      editLoan: observable,
      deleteQuote: flow,
      rejectLoanRequest: flow,
      quoteId: observable,
      fetchQuoteByLoanId: action,
    });
  }

  setDefaults() {
    this.isLoanDetailsSuccess = true;
    this.conversationsToggle = false;
    this.isBackgroundTaskBeingProcessed = false;
    this.borrowerUpdatedCounter = 0;
    this.juniorCalendlyPreferences = getDefaultCalendlyPreferences();
    this.calendlyPreferences = getDefaultCalendlyPreferences();
    this.closingRequirements = {};
    this.currentBorrowerIds = [];
    this.loanFastTrackUrl = '';
    this.loanPrescreenData = {};
  }

  setSelectedTabIndex(selectedTabIndex) {
    this.selectedTabIndex = selectedTabIndex;
  }

  setConversationsToggle(toggleValue) {
    this.conversationsToggle = toggleValue;
  }

  setIsBackgroundTaskBeingProcessed(value) {
    this.isBackgroundTaskBeingProcessed = value;
  }

  setPropertiesData(data) {
    this.propertiesData = data;
  }

  setLoanClosingConditions(data) {
    this.loanClosingConditions = data;
  }
  setExistingBorrowers() {
    this.currentBorrowerIds = this.loanDetails.borrowers.map(
      borrower => borrower.borrowerId
    );
    const borrowers = this.loanDetails.borrowers.map(borrower =>
      this.setBorrowersDefaults(borrower, this.loanDetails.borrowers.length)
    );
    this.selectBorrowersStore.setExistingBorrowersFromExternal(borrowers);
  }

  private setBorrowersDefaults(borrower: Borrower, length: number) {
    return {
      ...borrower,
      showEdit: !this.currentBorrowerIds.includes(borrower.borrowerId),
      showDelete:
        (this.currentBorrowerIds.includes(borrower.borrowerId) ||
          !borrower.authSignatory) &&
        length > 1,
    };
  }

  private handleBorrowersChanged() {
    const length = this.selectBorrowersStore.borrowers.length;

    const existingBorrowers = this.selectBorrowersStore.existingBorrowers
      .map(borrower => this.setBorrowersDefaults(borrower, length))
      .sort((a, b) => a.tempId - b.tempId);
    this.selectBorrowersStore.setExistingBorrowersFromExternal(
      existingBorrowers
    );

    const newBorrowers = this.selectBorrowersStore.newBorrowers.map(borrower =>
      this.setBorrowersDefaults(borrower, length)
    );
    this.selectBorrowersStore.newBorrowers = newBorrowers;
  }

  setLoanDetailsSuccess(value) {
    this.isLoanDetailsSuccess = value;
  }

  private async fetchMyLoans() {
    const externalStatus = this.myLoansGridStore.gridData.meta.dropdownFilters
      ?.status;
    const gridFilters = this.myLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      loanSubType: undefined,
      loanTypeAndSubtype: gridFilters.loanSubType,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyLoans(
        this.myLoansGridStore.gridData.meta.pageNumber,
        this.myLoansGridStore.gridData.meta.pageSize,
        this.myLoansGridStore.gridData.meta.sortDir,
        this.myLoansGridStore.gridData.meta.sortBy,
        filters,
        externalStatus
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }
  private async fetchExtensions() {
    try {
      const response: ApiResponse = await this.loanService.getExtensions(
        this.extensionRequest.gridData.meta.pageNumber,
        this.extensionRequest.gridData.meta.pageSize,
        this.extensionRequest.gridData.meta.sortDir,
        this.extensionRequest.gridData.meta.sortBy,
        this.extensionRequest.gridData.meta.filters,
        this.extensionRequest.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching extensions.',
      });
    }
  }

  private async fetchMyFundedLoans() {
    const gridFilters =
      this.myFundedLoansForDrawsGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyFundedLoans(
        this.myFundedLoansForDrawsGridStore.gridData.meta.pageNumber,
        this.myFundedLoansForDrawsGridStore.gridData.meta.pageSize,
        this.myFundedLoansForDrawsGridStore.gridData.meta.sortDir,
        this.myFundedLoansForDrawsGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }

  private async fetchFundedLoansForPayoffRequest() {
    const gridFilters =
      this.fundedLoansForPayoffGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.loanService.getFundedLoansForPayoffRequest(
        this.fundedLoansForPayoffGridStore.gridData.meta.pageNumber,
        this.fundedLoansForPayoffGridStore.gridData.meta.pageSize,
        this.fundedLoansForPayoffGridStore.gridData.meta.sortDir,
        this.fundedLoansForPayoffGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }

  private async fetchFundedLoansForExtensionsRequest() {
    const gridFilters =
      this.fundedLoansForExtensionsGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.loanService.getFundedLoansForExtensionRequest(
        this.fundedLoansForExtensionsGridStore.gridData.meta.pageNumber,
        this.fundedLoansForExtensionsGridStore.gridData.meta.pageSize,
        this.fundedLoansForExtensionsGridStore.gridData.meta.sortDir,
        this.fundedLoansForExtensionsGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }

  private async fetchMyPipelineLoans() {
    const filters = {
      ...this.myPipelineLoansGridStore.gridData.meta.filters,
      priority: this.myPipelineLoansGridStore.gridData.meta.dropdownFilters
        ?.priority,
    };
    const { status, ...dropdownFilters } =
      this.myPipelineLoansGridStore.gridData.meta.dropdownFilters || {};
    const externalStatus = status;
    try {
      const response: ApiResponse = await this.loanService.getMyPipelineLoans(
        this.myPipelineLoansGridStore.gridData.meta.pageNumber,
        this.myPipelineLoansGridStore.gridData.meta.pageSize,
        this.myPipelineLoansGridStore.gridData.meta.sortDir,
        this.myPipelineLoansGridStore.gridData.meta.sortBy,
        filters,
        dropdownFilters,
        externalStatus
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my pipeline loans.',
      });
    }
  }

  private async fetchMyAppraisalToDos() {
    const filters = {
      ...this.myAppraisalToDosGridStore.gridData.meta.filters,
      priority: this.myAppraisalToDosGridStore.gridData.meta.dropdownFilters
        ?.priority,
    };
    const { status, ...dropdownFilters } =
      this.myAppraisalToDosGridStore.gridData.meta.dropdownFilters || {};
    const externalStatus = status;
    try {
      const response: ApiResponse = await this.loanService.getMyAppraisalToDos(
        this.myAppraisalToDosGridStore.gridData.meta.pageNumber,
        this.myAppraisalToDosGridStore.gridData.meta.pageSize,
        this.myAppraisalToDosGridStore.gridData.meta.sortDir,
        this.myAppraisalToDosGridStore.gridData.meta.sortBy,
        filters,
        dropdownFilters,
        externalStatus
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my appraisal to-dos.',
      });
    }
  }

  private async fetchMyPendingTasks() {
    try {
      const filters = {
        ...this.myPendingTasksGridStore.gridData.meta.filters,
        expectedClosingDate: this.formatDateAsISO(
          this.myPendingTasksGridStore.gridData.meta.filters
            ?.expectedClosingDate
        ),
      };
      const response: ApiResponse = await this.loanService.getMyPendingTasks(
        this.myPendingTasksGridStore.gridData.meta.pageNumber,
        this.myPendingTasksGridStore.gridData.meta.pageSize,
        this.myPendingTasksGridStore.gridData.meta.sortDir,
        this.myPendingTasksGridStore.gridData.meta.sortBy,
        filters,
        this.myPendingTasksGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching my pending tasks.',
      });
    }
  }

  private async fetchMyDelinquentLoans() {
    const gridFilters = this.delinquentLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyDelinquentLoans(
        this.delinquentLoansGridStore.gridData.meta.pageNumber,
        this.delinquentLoansGridStore.gridData.meta.pageSize,
        this.delinquentLoansGridStore.gridData.meta.sortDir,
        this.delinquentLoansGridStore.gridData.meta.sortBy,
        filters
      );
      if(response) {
        const processedRows = response.data?.data?.rows;
        processedRows.forEach(row => row.loanType === LoanType.RESIDENTIAL_TERM ? row.payString = '--' : '' );
        response.data.data.rows = processedRows;
      }
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the delinquent loans.',
      });
    }
  }

  private async fetchMyMaturityLoans() {
    const gridFilters = this.maturityLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyMaturityLoans(
        this.maturityLoansGridStore.gridData.meta.pageNumber,
        this.maturityLoansGridStore.gridData.meta.pageSize,
        this.maturityLoansGridStore.gridData.meta.sortDir,
        this.maturityLoansGridStore.gridData.meta.sortBy,
        filters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the maturity loans.',
      });
    }
  }

  private async fetchConditionalApprovedLoans() {
    try {
      const filters = {
        ...this.conditionalApprovedLoansGridStore.gridData.meta.filters,
        expectedClosingDate: this.formatDateAsISO(
          this.conditionalApprovedLoansGridStore.gridData.meta.filters
            ?.expectedClosingDate
        ),
      };
      const response: ApiResponse = await this.loanService.getConditionalApprovedLoans(
        this.conditionalApprovedLoansGridStore.gridData.meta.pageNumber,
        this.conditionalApprovedLoansGridStore.gridData.meta.pageSize,
        this.conditionalApprovedLoansGridStore.gridData.meta.sortDir,
        this.conditionalApprovedLoansGridStore.gridData.meta.sortBy,
        filters,
        this.conditionalApprovedLoansGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching conditionally approved loans.',
      });
    }
  }

  private async fetchPriorityLoansV2() {
    try {
      const filters = {
        ...this.priorityLoansV2GridStore.gridData.meta.filters,
        expectedClosingDate: this.formatDateAsISO(
          this.priorityLoansV2GridStore.gridData.meta.filters
            ?.expectedClosingDate
        ),
      };
      const response: ApiResponse = await this.loanService.getPriorityLoansV2(
        this.priorityLoansV2GridStore.gridData.meta.pageNumber,
        this.priorityLoansV2GridStore.gridData.meta.pageSize,
        this.priorityLoansV2GridStore.gridData.meta.sortDir,
        this.priorityLoansV2GridStore.gridData.meta.sortBy,
        filters,
        this.priorityLoansV2GridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching priority V2 loans.',
      });
    }
  }

  private formatDateAsISO(strDate: string) {
    if (strDate) {
      const [mm, dd, yyyy] = strDate.split('/');
      return [yyyy, mm, dd].filter(Boolean).join('-');
    } else {
      return undefined;
    }
  }

  private async fetchMyDraftLoans() {
    try {
      const response: ApiResponse = await this.loanService.getMyDraftLoans(
        this.myDraftLoansGridStore.gridData.meta.pageNumber,
        this.myDraftLoansGridStore.gridData.meta.pageSize,
        this.myDraftLoansGridStore.gridData.meta.sortDir,
        this.myDraftLoansGridStore.gridData.meta.sortBy,
        this.myDraftLoansGridStore.gridData.meta.filters,
        this.myDraftLoansGridStore.gridData.meta.dropdownFilters,
        DRAFT_LOAN_TYPE_LOAN
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching draft loans.',
      });
    }
  }

  private async fetchMyQuoteLoans() {
    const externalStatus = this.myLoanQuotesGridStore.gridData.meta.dropdownFilters
      ?.status;
    const gridFilters = this.myLoanQuotesGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      loanSubType: undefined,
      loanTypeAndSubtype: gridFilters.loanSubType,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyQuoteLoans(
        this.myLoanQuotesGridStore.gridData.meta.pageNumber,
        this.myLoanQuotesGridStore.gridData.meta.pageSize,
        this.myLoanQuotesGridStore.gridData.meta.sortDir,
        this.myLoanQuotesGridStore.gridData.meta.sortBy,
        filters,
        externalStatus
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the quote loans.',
      });
    }
  }

  private async fetchProperties() {
    const response: ApiResponse = await new Promise(resolve => {
      (async () => {
        const {
          pageNumber,
          pageSize,
          sortDir,
          sortBy,
        } = this.propertiesGridStore.gridData.meta;
        const totalCount = this.propertiesData.length;

        let data = [...this.propertiesData];
        let sortedData = sortDir
          ? data.sort((a, b) =>
            sortDir === 'ASC'
              ? collator.compare(a[sortBy], b[sortBy])
              : collator.compare(b[sortBy], a[sortBy])
          )
          : data;
        data = sortedData.slice(
          (pageNumber - 1) * pageSize,
          pageNumber * pageSize
        );

        resolve(
          new ApiResponse(
            {
              data: {
                rows: data,
                totalCount,
              },
            },
            {}
          )
        );
      })();
    });
    return response;
  }

  *refreshLoanDetails() {
    const { loanId } = this.loanDetails;
    yield this.fetchLoanDetails(loanId);
  }

  *fetchLoanDetails(loanId, successCallback = () => { }) {
    try {
      this.setLoanDetailsSuccess(true);
      const response: ApiResponse = yield this.loanService.getLoanDetails(
        loanId
      );
      if (response.data?.data?.loanId === undefined) {
        throw new Error("Don't have access to the loan");
      }
      this.loanDetails = response.data?.data;

      // if the user is backOfficeProcessor and user's current active companyId is different from loan's companyId,
      // then set the loan's company in companyDropdown
      if (this.globalStore.userFeatures?.showMultiCompanyDropdown
        && this.loanDetails?.companyId != this.globalStore?.selectedCompanyId) {
        yield this.companyDropdownStore.changeCompanyNavigation(this.loanDetails.companyId);
      }

      if (this.loanDetails?.borrowers) {
        this.loanDetails.borrowers = this.processBorrowers(
          this.loanDetails.borrowers
        );
        this.setExistingBorrowers();
      }

      yield this.fetchRelationshipManagers(loanId);
      successCallback();
    } catch (error) {
      console.log(error);
      this.setLoanDetailsSuccess(false);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan details.',
      });
    }
  }

  *fetchRelationshipManagers(loanId) {
    try {
      if (this.globalStore.lenderInfo) {
        const {
          primaryRelationshipManagerName,
          secondaryRelationshipManagerName,
        } = this.globalStore.lenderInfo;

        this.relationshipManagerData = {
          primaryRelationshipManager: primaryRelationshipManagerName,
          secondaryRelationshipManager: secondaryRelationshipManagerName,
        };
      } else {
        const response: ApiResponse = yield this.loanService.getRelationshipManagers(
          loanId
        );
        this.relationshipManagerData = response.data.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching relationship manager details',
      });
    }
  }

  resetLoanDetails() {
    this.loanDetails = undefined;
    this.setLoanDetailsSuccess(true);
  }

  *fetchPreviousDraws(loanId) {
    try {
      const response: ApiResponse = yield this.loanService.getDraws(loanId);
      this.previousDraws = [...(response.data.data?.rows ?? [])];
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching previous draws.',
      });
    }
  }

  *fetchTotalUnreadMessages() {
    try {
      const response: ApiResponse = yield this.loanService.getTotalUnreadMessages();
      this.numberUnreadMessages = response.data?.data || 0;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching unread messages.',
      });
    }
  }

  *fetchInternalInsuranceReviewers() {
    try {
      const response = yield this.loanService.getInternalInsuranceReviewers();
      this.insuranceReviewers = Array.isArray(response.data.data) ? response.data.data : JSON.parse(response.data.data);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching Internal Insurance Reviewers.',
      });
    }
  }

  *changeLoanPriority(loanId: number, priority: LoanPriority) {
    try {
      const response: ApiResponse = yield this.loanService.setLoanPriority(
        loanId,
        priority
      );
      this.myPipelineLoansGridStore.fetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Loan has been marked as ${priority.toLowerCase()} priority successfully.`,
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while marking loan as ${priority.toLowerCase()} priority.`,
      });
    }
  }

  *submitFormStatus(loanId: number, grid?: String) {
    const values = this.loanStatusStore.getFormValues();

    try {
      const response: ApiResponse = yield this.loanService.changeLoanStatus(
        loanId,
        values['status'],
        values['reasonForStatus'],
        values['statusChangeComment']
      );

      const success = !!response.data?.data?.success;
      if (success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan status updated successfully.',
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: `Cannot change loan status: ${response.data?.data?.message}`,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while changing loan status.',
      });
    }
    if (grid == 'myLoans') {
      this.myLoansGridStore.refresh();
    } else {
      this.myPipelineLoansGridStore.refresh();
    }
  }

  *changeAssignee(loanId: number, assignee: string, callback = () => { }) {
    try {
      const response: ApiResponse = yield this.loanService.changeAssignee(
        loanId,
        assignee
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: `Assignee changed successfully.`,
      });
      callback();
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: `Error while changing assignee.`,
      });
    }
  }

  *deleteDraftLoan(loanId: number, quoteDashboard = false) {
    try {
      const response: ApiResponse = yield this.loanService.deleteDraftLoan(
        loanId
      );
      const success = !!response.data?.data?.success;
      if (success) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Draft loan deleted successfully.',
        });
        if(quoteDashboard) {
          yield this.myLoanQuotesGridStore.resetAndFetchGridData();
        } else {
          this.myDraftLoansGridStore.refresh();
        }
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deleting draft loan.',
      });
    }
  }

  *deleteQuote(quoteId) {
    try {
      yield this.oneToolService.deleteQuote(quoteId);
      yield this.myLoanQuotesGridStore.resetAndFetchGridData();
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Quote deleted successfully.',
      });
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while deleting quote',
      });
    }
  }

  isTermLoan(loanType: string) {
    return loanType === LoanType.RESIDENTIAL_TERM;
  }

  isFixFlipOrGroundUp(loanSubType: string) {
    return (loanSubType === LoanSubType.FIX_AND_FLIP || loanSubType === LoanSubType.FIX_AND_FLIP_PRO ||
      loanSubType === LoanSubType.GROUND_UP);
  }

  isStabilized(loanSubType: string) {
    return loanSubType === LoanSubType.STABILIZED_BRIDGE
  }

  isSingleProperty(loanSubType: string) {
    return (
      loanSubType === LoanSubType.SINGLE_PROPERTY ||
      loanSubType === LoanSubType.SINGLE_PROPERTY_MULTIFAMILY ||
      loanSubType === LoanSubType.SINGLE_PROPERTY_MIXED_USE
    );
  }

  continueDraftLoan(currentDraftLoan: DraftLoan) {
    try {
      const { loanType, loanSubType, id, loanApplicationId } = currentDraftLoan;
      if (this.isTermLoan(loanType)) {
        if (this.isSingleProperty(loanSubType)) {
          if (isNil(id)) {
            this.singlePropertyStore.setLoanApplicationId(loanApplicationId);
          } else {
            this.singlePropertyStore.setDraftLoanId(id);
          }
        } else {
          this.rentalPortfolioStore.setDraftLoanId(id);
        }
      } else {
        if (this.isFixFlipOrGroundUp(loanSubType)) {
          if (isNil(id)) {
            this.fixFlipStore.setLoanApplicationId(loanApplicationId);
          } else {
            this.fixFlipStore.setDraftLoanId(id);
          }
        }
        else if (this.isStabilized(loanSubType)) {
          this.stabilizedBridgeStore.setDraftLoanId(id);
        } else {
          this.multiFamilyStore.setDraftLoanId(id);
        }
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while trying to continue with draft loan.',
      });
    }
  }

  *submitLoanAppraisalOrder(loanId: number, gfdPayment: boolean) {
    try {
      const response: ApiResponse = yield this.submitAppraisalService.submitLoanAppraisal(
        loanId,
        gfdPayment
      );
      this.submitAppraisalSuccess = response.data?.data?.success;

      if (this.submitAppraisalSuccess) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Appraisal order submitted successfully.',
        });
        segmentAnalytics.trackEvent({
          name: SegmentTrackerEvent.LOAN_APPRAISAL,
          userName: this.userStore?.userInformation?.username,
        });
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.data.message,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while submitting the appraisal.',
      });
    }
  }

  isLoanSubtypeSupported(subtype) {
    return true;
  }

  isLoanStatusRetractedOrOnHold(status) {
    return LoanStatus.RETRACTED === status || LoanStatus.ON_HOLD === status;
  }

  processBorrowers(borrowers) {
    return borrowers.map(borrower => {
      const roles = borrower.roleInTransaction
        ? borrower.roleInTransaction.split(',')
        : borrower.roleInTransaction;

      return {
        ...borrower,
        roleInTransaction:
          roles &&
          roles.map(role => {
            return { label: role };
          }),
      };
    });
  }

  getValues = (options: any[]) => {
    const values = options ? options.map(option => option.value) : [];
    return values.join(',');
  };

  get borrowersRows() {
    const defaultFormValues = this.selectBorrowersStore.borrowerFormDialogStore.borrowerFormStore.getFormValues();
    const restrictCustomCreditReport = this.globalStore.lenderInfo.restrictCustomCreditReport;

    return this.selectBorrowersStore.borrowers.map(borrower => {
      let { borrowerId } = borrower;

      if (typeof borrowerId === 'string' && borrowerId.includes('LEAD')) {
        borrowerId = null;
      }

      return {
        borrowerId,
        firstName: borrower.firstName,
        lastName: borrower.lastName,
        fullName: `${borrower.firstName} ${borrower.lastName}`,
        emailAddress: borrower.emailAddress,
        cellPhone: borrower.cellPhone,
        roleInTransaction: this.getValues(borrower.roleInTransaction),
        personalGuarantor:
          borrower.personalGuarantor ?? borrowerDefaults.personalGuarantor,
        experience: borrower.experience ?? defaultFormValues.experience,
        pctOwnership: borrower.pctOwnership ?? defaultFormValues.pctOwnership,
        citizenshipStatus:
          borrower.citizenshipStatus || defaultFormValues.citizenshipStatus,
        hasSubstantialChanges:
          borrower.hasSubstantialChanges ??
          defaultFormValues.hasSubstantialChanges,
        substantialChangesDescription: borrower.substantialChangesDescription,
        authSignatory:
          borrower.authSignatory ?? defaultFormValues.authSignatory,
        leadSfId: borrower.leadSfId,
        leadId: borrower.leadId,
        leadSource: borrower.leadSource,
        leadSourceGroup: borrower.leadSourceGroup,
        creditBackgroundCheck:
          borrower.creditBackgroundCheck ??
          (restrictCustomCreditReport ||
            defaultFormValues.creditBackgroundCheck),
        bankrupcty: borrower.bankruptcy ?? defaultFormValues.bankrupcty,
        foreclosure: borrower.foreclosure ?? defaultFormValues.foreclosure,
      };
    });
  }

  *getCalendlyPreferences(loanId: any) {
    try {
      const response: ApiResponse = yield this.loanService.getCalendlyPreferences(
        loanId
      );

      this.calendlyPreferences = response.data?.data;
      this.calendlyPreferences.successResponse = true;


    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while getting calendly preferences.',
      });
    }
  }

  *downloadWimbaDoc() {
    try {
      yield this.documentLibraryStore.downloadPublicDocument(wimbaFilePath);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while downloading Wimba Disclosure Statement.',
      });
    }
  }

  *saveBorrowers() {
    try {
      const response: ApiResponse = yield this.loanService.saveBorrowers(
        this.loanDetails.loanId,
        this.borrowersRows
      );

      this.selectBorrowersStore.reset();

      this.borrowerUpdatedCounter++;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving borrowers.',
      });
    }
  }

  *fetchClosingRequirements() {
    try {
      const response: ApiResponse = yield this.loanService.getLoanClosingStatus(
        this.loanDetails.loanId
      );

      this.closingRequirements = JSON.parse(response.data?.data);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching closing requirements.',
      });
    }
  }

  *resendQuote(loanId: number, borrowerEmail: string) {
    try {
      const response: ApiResponse = yield this.loanService.resendQuote(
        loanId,
        borrowerEmail
      );
      const data = JSON.parse(response.data?.data);

      if (!data?.success) {
        this.globalStore.notificationStore.showErrorNotification({
          message: data?.responseMessage,
        });
      } else {
        this.globalStore.notificationStore.showSuccessNotification({
          message: data?.responseMessage,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while resending quote.',
      });
    } finally {
      this.resendQuoteStore.reset();
      this.resendQuoteStore.setDefaultBorrowerEmailAddress();
    }
  }

  *requestElmsureQuote(loanId: number, quoteType: string) {
    try {
      const response: ApiResponse = yield this.loanService.requestElmsureQuote(
        loanId, quoteType
      );
      const data = JSON.parse(response.data?.data);

      if (!data?.success) {
        this.globalStore.notificationStore.showErrorNotification({
          message: data?.responseMessage,
        });
      } else {
        this.globalStore.notificationStore.showSuccessNotification({
          message: data?.responseMessage,
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while requesting Elmsure quote.',
      });
    }
  }

  getLoanProcessEntitlements(loanProcess: string) {
    const { userFeatures } = this.globalStore;
    if (!userFeatures) return null;
    if (loanProcess === UNDERWRITING) {
      return userFeatures.underwritingEntitlements;
    } else if (loanProcess === CLOSING) {
      return userFeatures.closingEntitlements;
    } else if (loanProcess === DRAWS) {
      return userFeatures.drawsEntitlements;
    } else if (loanProcess === SERVICING) {
      return userFeatures.servicingEntitlements;
    } else if (loanProcess === TRADES) {
      return userFeatures.tradeEntitlements;
    }
  }

  setExistingOriginatorsPercentage() {
    const list = [...this.loanDetails.lenderOriginatorNames];
    var flag = false;
    var percentageLo
    this.loanDetails.lenderOriginatorNames.map((lender, i) => { percentageLo = lender.percentage, flag = true });
    list.splice(0, 0, {
      lenderName: this.loanDetails.lenderName,
      lenderId: this.loanDetails.lenderId,
      percentage: flag ? 100 - percentageLo : 100,
      isLoanSubmitter: true
    })


    const items = list.map(item =>
      this.setLenderOriginatorDefaults(item)
    );

    this.selectOriginatorsStore.setExistingOriginatorsFromExternal(items);
  }

  private setLenderOriginatorDefaults(item: Originator) {
    return {
      ...item,
      showEdit: !item.isLoanSubmitter,
      showDelete: !item.isLoanSubmitter
    };
  }

  get originatorRows() {
    return this.selectOriginatorsStore.originators.map(originator => {
      return {
        lenderId: originator.lenderId,
        lenderName: originator.lenderName,
        companyName: originator.companyName,
        percentage: originator.percentage
      }
    })
  }

  *saveLenderOriginator() {
    try {
      const response: ApiResponse = yield this.loanService.saveLenderOriginator(
        this.loanDetails.loanId,
        this.originatorRows);
      this.setExistingOriginatorsPercentage();
      this.originatorStore.selectOriginatorsStore.reset();
      this.fetchLoanDetails(this.loanDetails.loanId);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving lender originators.',
      });
    }
  }

  get allowEditBorrowers() {
    return isPendingLoanStatus(this.loanDetails.status);
  }

  *fetchBridgeLoanClosingCosts(loanId) {
    try {
      const response: ApiResponse = yield this.loanService.getBridgeLoanClosingCosts(
        loanId
      );
      this.estimatedClosingCosts = response.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching closing costs.',
      });
    }
  }

  *fetchRentalLoanClosingCosts(loanId) {
    try {
      const response: ApiResponse = yield this.loanService.getRentalLoanClosingCosts(
        loanId
      );
      this.estimatedClosingCosts = response.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching rental closing costs.',
      });
    }
  }

  getLoanParticipants() {
    this.loanParticipantsStore.fetchLoanParticipants();
  }

  *saveLoanParticipants() {
    try {
      const response: ApiResponse = yield this.loanService.saveLoanParticipants(
        this.loanDetails.loanId,
        this.loanParticipantsStore.selectedOriginator.originatorId);
      yield this.fetchLoanDetails(this.loanDetails.loanId);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while saving loan participants.',
      });
    }
  }


  private async fetchMyRequestLoans() {
    try {
      const response: ApiResponse = await this.loanService.getMyDraftLoans(
        this.myRequestLoansGridStore.gridData.meta.pageNumber,
        this.myRequestLoansGridStore.gridData.meta.pageSize,
        this.myRequestLoansGridStore.gridData.meta.sortDir,
        this.myRequestLoansGridStore.gridData.meta.sortBy,
        this.myRequestLoansGridStore.gridData.meta.filters,
        this.myRequestLoansGridStore.gridData.meta.dropdownFilters,
        REQUEST_LOAN_TYPE_LOAN
      );
      const rows = (response?.data?.data?.rows || []).map(row => ({
        ...row,
        key: row.loanApplicationId
          ? 'loan-application-id-' + row?.loanApplicationId
          : 'draft-loan-id' + row?.id,
      }));
      return new ApiResponse({ data: { ...response?.data?.data, rows }  }, null);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching draft loans.',
      });
    }
  }

  private async fetchMyBackUpLoans() {
    const externalStatus = this.myBackUpLoansGridStore.gridData.meta.dropdownFilters
      ?.status;
    const gridFilters = this.myBackUpLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      loanSubType: undefined,
      loanTypeAndSubtype: gridFilters.loanSubType,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyBackUpLoans(
        this.myBackUpLoansGridStore.gridData.meta.pageNumber,
        this.myBackUpLoansGridStore.gridData.meta.pageSize,
        this.myBackUpLoansGridStore.gridData.meta.sortDir,
        this.myBackUpLoansGridStore.gridData.meta.sortBy,
        filters,
        externalStatus,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }
  private async fetchMyAgreedLoans() {
    const externalStatus = this.myAgreedLoansGridStore.gridData.meta.dropdownFilters
      ?.status;
    const gridFilters = this.myAgreedLoansGridStore.gridData.meta.filters ?? {};
    const filters = {
      ...gridFilters,
      loanSubType: undefined,
      loanSubtype: gridFilters.loanSubType,
    };
    try {
      const response: ApiResponse = await this.loanService.getMyAgreedLoans(
        this.myAgreedLoansGridStore.gridData.meta.pageNumber,
        this.myAgreedLoansGridStore.gridData.meta.pageSize,
        this.myAgreedLoansGridStore.gridData.meta.sortDir,
        this.myAgreedLoansGridStore.gridData.meta.sortBy,
        filters,
        externalStatus,
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loans.',
      });
    }
  }
  *getLoanRequestsTotalCount() {
    try {
      const response: ApiResponse = yield this.loanService.getLoanRequestsTotalCount();
      return response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching total Loan Requests.',
      });
    }
  }

  *fetchLoanFastTrackPaymentUrl(loanId: string) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanFastTrackPaymentUrl(loanId);
      this.loanFastTrackUrl = response.data.data.url;
    } catch (error) {
    }
  }

  *fetchConversationsToggleStatus(userId: number) {
    try {
      const response: ApiResponse = yield this.loanService.getConversationsToggleStatus(userId);
      this.conversationsToggle = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching conversations toggle status',
      });
    }
  }

  *fetchIsBackgroundTaskBeingProcessed(userId: number) {
    try {
      const response: ApiResponse = yield this.loanService.getIsBackgroundTaskBeingProcessed(userId);
      this.isBackgroundTaskBeingProcessed = response.data.data;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching background task status',
      });
    }
  }

  *updateUserBackUpConversationToggle(userId: number) {
    try {
      yield this.loanService.updateUserBackUpConversationToggle(userId);
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating conversations toggle status',
      });
    }
  }

  *fetchLoanExpirationDates(loanId: number) {
    try {
      const response = yield this.loanService.getLoanExpirationDates(loanId);
      console.log(response.data.data.appraisalExpirationDate)
      this.appraisalExpirationDate = response.data.data.appraisalExpirationDate;
      this.creditExpirationDate = response.data.data.creditExpirationDate;
      this.cogsExpirationDate = response.data.data.cogsExpirationDate;
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching the loan expiration dates',
      });
    }
  }

  *fetchLoanClosingConditions(loanId: number) {
    try {
      const response = yield this.loanService.getLoanClosingConditions(loanId);
      this.setLoanClosingConditions(response.data);
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching closing conditions',
      });
    }
  }

  *fetchLoanPrescreenData(loanId: number) {
    try {
      const response: ApiResponse = yield this.loanService.getLoanPrescreenData(loanId);
      this.loanPrescreenData = response.data?.data;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching loan prescreen data.',
      });
    }
  }

  getBorrowerFromLoanByBorrowerId(borrowerId: number) {
    return this.loanDetails?.borrowers?.find(borrower => borrower.borrowerId === borrowerId);
  }

  *fetchExceptionsAndCommentsExternal(loanId, commentType) {
    try {
      const apiResponse: ApiResponse = yield this.loanService.getExceptionsAndCommentsExternal(
        loanId, commentType);
      this.exceptions = apiResponse.data.exceptions;
      this.comments = apiResponse.data.comments;
      this.mitigatingFactors = apiResponse.data.mitigatingFactors;
      this.loanApprovalData = apiResponse.data.loanApprovalData;
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching exceptions and comments',
      });
    }
  }

  getEditLoanJson() {
    this.fixFlipStore?.editLoanJson?.loanId ? this.editLoan = this.fixFlipStore.editLoanJson :
      this.stabilizedBridgeStore?.editLoanJson?.loanId ? this.editLoan = this.stabilizedBridgeStore.editLoanJson :
        this.editLoan = null;
  }

  *rejectLoanRequest(currentDraftLoan: DraftLoan) {
    try {
      const { loanSubType, id, loanApplicationId, borrowerLoanApplication } = currentDraftLoan;
      const loanId = loanApplicationId??id;
      let response: ApiResponse;
      if (borrowerLoanApplication || this.isFixFlipOrGroundUp(loanSubType)){
        response = yield this.loanService.rejectLoanRequest(
          loanId
        );
      }else{
        response = yield this.loanService.deleteDraftLoan(
          loanId
        );
      }
      const success = !!response.data?.data?.success;
      if (success) {
        yield this.myRequestLoansGridStore.resetAndFetchGridData();
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Loan request rejected successfully.',
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while rejecting loan request.',
      });
    }
  }

  async fetchQuoteByLoanId(loanId: number) {
    try {
      const response: ApiResponse = await this.oneToolService.getQuoteByLoanId(loanId);
      const quoteId = response?.data?.data?.quoteId
      if (quoteId) {
      this.quoteId = quoteId;
      return response;
      }
      else{
        this.quoteId = null;
        return null;
      }
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching quote.',
      });
    }
  }

}
