import { ApiResponse, SubscriptionTopic, WebSocketStore } from "@roc/feature-app-core";
import { action, flow, makeObservable, override } from "mobx";
import { ConversationAttribute, ResetUnreadCountRequest, UpdateConversationStarredStatusRequest } from "../types/communicationTypes";
import { chatRoomTypeFilters } from "../utils/constants";
import { CommunicationStore } from "./communicationStore";

export class MyCommunicationStore extends CommunicationStore {
  webSocketStore: WebSocketStore;

  constructor(globalStore, userStore, webSocketStore, communicationService, isInternal = false, showMessagePreview = false) {
    super(globalStore, userStore, communicationService, isInternal, true, true, showMessagePreview);
    this.webSocketStore = webSocketStore;
    makeObservable(this, {
      subscribeToConversationUpdates: action,
      unsubscribeFromConversationUpdates: action,
      resetUnreadCount: flow,
      updateConversationStarredStatus: flow,
      getParticipantsAllowedByLoanId: flow,
      getParticipantsAllowedByChatRoomId: flow,
      setConversationMessageDefaultTag: flow,
    });
  }

  *resetUnreadCount(loanId: number) {
    try {
      const request: ResetUnreadCountRequest = {
        chatRoomId: this.currentChatRoomId,
        chatRoomType: this.currentChatRoomType,
        userId: this.userId,
        conversationSid: this.currentConversationSid,
        loanId,
      }
      this.communicationService.resetUnreadCount(request);
    } catch (e) {
      // do nothing
    }
  }

  *updateConversationStarredStatus(isStarred: boolean, chatRoomId, conversationSid, callBack?: () => void) {
    try {
      const request: UpdateConversationStarredStatusRequest = {
        chatRoomId,
        userId: this.userId,
        isStarred,
      }
      yield this.communicationService.updateConversationStarredStatus(request);
      this.conversationStarred[conversationSid] = isStarred;
      callBack();
    } catch (e) {
      // do nothing
    }
  }

  *getParticipantsAllowedByLoanId(loanId, disableGlobalLoading = false) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByLoanId(loanId, disableGlobalLoading);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.error("Error retrieving Participants")
      return {};
    }
  }

  *getParticipantsAllowedByChatRoomId(chatRoomId, disableGlobalLoading = false) {
    try {
      const response: ApiResponse = yield this.communicationService.getParticipantsAllowedByChatroomId(chatRoomId, disableGlobalLoading);
      const { data } = response;
      return data.data;
    } catch (error) {
      console.error("Error retrieving Participants")
      return {};
    }
  }

  *setConversationMessageDefaultTag(conversationAttribute: ConversationAttribute, conversationInfo) {
    if (conversationAttribute?.loanId) {
      const response: ApiResponse = yield this.communicationService.getConversationMessageDefaultTag(conversationAttribute?.loanId, [conversationInfo]);
      if (response?.data?.data) {
        const result = response?.data?.data.result;
        [conversationInfo].forEach(c => {
          if (result[c.conversationSid]) {
            this.conversationMessageDefaultTag[c.conversationSid] = result[c.conversationSid];
          }
        });
      }
    }
  }

  subscribeToConversationUpdates() {
    this.webSocketStore.subscribeToConversationUpdates((notification) => {
      const conversation = this.conversations[notification.conversationSid];
      const chatRoomType = conversation?.attributes?.chatRoomType;
      const filterMatchesChatRoomType = this.filters.every(
        filter =>
          !chatRoomTypeFilters[filter.type] ||
          chatRoomTypeFilters[filter.type].includes(chatRoomType)
      );
      if (!filterMatchesChatRoomType) {
        return;
      }
      
      if (this.conversations[notification.conversationSid]
        /* || this.twilioIdentityClients[notification.twilioIdentity] */) {
        if (notification.hasMentions) {
          this.conversationMentioned[notification.conversationSid] = true;
        }
        if (notification.lastMentionedDate) {
          this.conversationLastMentionedDate[notification.conversationSid] = notification.lastMentionedDate;
        }
        return;
      }
      this.loadOneConversation(notification.conversationSid, (isError) => {
        if (!isError) {
          this.flashTaskBarIcon();
          const conversation = this.conversations[notification.conversationSid];
          const attributes = (conversation.attributes as ConversationAttribute);
          const loanIdText = attributes.loanId ? `Loan# ${attributes.loanId} - ` : '';
          this.sendNotification({
            title: `${loanIdText}${this.getConversationDisplayName(conversation)}`,
            roomType: attributes.chatRoomType,
            body: `You have a new message`,
            conversationSid: conversation.sid,
            hasMentions: notification.hasMentions
          });
        }
      });
    })
  }

  unsubscribeFromConversationUpdates() {
    this.webSocketStore.unsubscribe(SubscriptionTopic.TOPIC_CONVERSATION_MESSAGE_UPDATES);
  }

}