import { debounce } from '@material-ui/core';
import { ApiResponse, GlobalStore, GridStore } from '@roc/feature-app-core';
import { GENERIC_ERROR_MESSAGE, getPageFromObject } from '@roc/feature-utils';
import { action, flow, makeObservable, observable, override } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { EntityStore } from './entityStore';

const PAGE_SIZE = 20;

export class SearchEntityGridStore extends GridStore {
  private globalStore: GlobalStore;
  private entityStore: EntityStore;
  private oneToolService: OneToolService;

  entities: any[] = [];
  searchString = '';

  fetchGridDataDebounced = debounce(() => this.fetchGridData());

  constructor(globalStore: GlobalStore, entityStore: EntityStore) {
    super(() => this.fetchPage(), undefined, PAGE_SIZE);
    this.globalStore = globalStore;
    this.entityStore = entityStore;
    this.oneToolService = new OneToolService();

    makeObservable(this, {
      searchString: observable,
      reset: override,
      setSearchString: action,
      fetchEntities: flow,
    });
  }

  reset() {
    super.reset();
    this.entities = [];
    this.searchString = '';
    this.gridData.meta.pageSize = PAGE_SIZE;
  }

  *fetchEntities() {
    try {
      this.reset();
      const response = yield this.oneToolService.getAllBorrowerEntities();
      this.entities = response.data.data.rows;
      this.fetchGridData();
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  setSearchString(searchString) {
    this.searchString = searchString;
    this.fetchGridDataDebounced();
  }

  fetchPage = async () => {
    const rows = this.entities
      .map(entity => ({
        borrowerEntityId: entity.borrowerEntityId,
        companyName: entity.company?.name || '',
        borrowers: entity.borrowers.map(borrower => ({
          borrowerName: `${borrower.borrower.firstName} ${borrower.borrower.lastName}`,
          ownership: borrower.pctOwnership,
        })),
      }))
      .filter(row =>
        row.borrowers
          .map(b => b.borrowerName)
          .concat(row.companyName)
          .some(s =>
            s
              .toLocaleLowerCase()
              .includes(this.searchString.toLocaleLowerCase())
          )
      );
    const data = getPageFromObject(rows, this.gridData.meta);
    return new ApiResponse({ data }, null);
  };
}
/*{
    "borrowerEntityId": 33080,
    "ein": "13-1384509",
    "type": "Limited Liability Company",
    "operatingAgreementDate": "02/11/2015",
    "company": {
        "name": "AANHDNWM",
        "address": "1416 Ruatan Street, Hyattsville, MD 20783",
        "streetNumber": "1416",
        "streetName": "Ruatan Street",
        "aptNumber": "",
        "city": "Hyattsville",
        "state": "MD",
        "zipCode": "20783"
    },
    "borrowers": [
        {
            "pctOwnership": 100,
            "borrower": {
                "borrowerId": 29519,
                "firstName": "IjleAeBI",
                "lastName": "IHoAXXlu",
                "cellPhone": "3938878578",
                "emailAddress": "nwjiCMgKkoAsDvc@gmail.com",
                "experience": 3,
                "medianScore": 711,
                "citizenshipStatus": "US Citizen",
                "portalProjectsCompleted": 0,
                "portalProjectsActive": 1,
                "leadSource": "Sales User Uploaded",
                "leadSourceGroup": "SALESUSERUPLOAD",
                "leadSfId": "00Q1U00000XOnlAUAT",
                "bankruptcyProvidedAtOrigination": false,
                "foreclosureProvidedAtOrigination": false,
                "criminalHistoryProvidedAtOrigination": false,
                "borrowerBrokerProvidedAtOrigination": false,
                "borrowerGCProvidedAtOrigination": false,
                "hasDob": true,
                "hasSsn": true,
                "preQualificationStatus": "Pending",
                "borrowerPreQualificationDetail": {
                    "borrowerPreQualificationDetailId": 24541,
                    "borrowerId": 29519,
                    "pgStatus": "Not Pre-Qualified",
                    "nonPgStatus": "Not Pre-Qualified",
                    "createdBy": "sql",
                    "createdDate": "08/11/2023",
                    "lastUpdatedBy": "sql",
                    "lastUpdatedDate": "08/11/2023"
                },
                "preQualificationInProgress": false,
                "runCreditOnCreation": true
            }
        }
    ]
}*/
