import { Box, CircularProgress, Collapse, Grid, Grow, Slide, Zoom } from "@material-ui/core";
import { lighten } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ArrowDownward, ArrowUpward, CloseOutlined, ExpandLess, ExpandMore, Fullscreen, FullscreenExit, Maximize, Minimize, SendOutlined } from "@material-ui/icons";
import { AddSnippet } from "./addSnippet";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, ConfirmDialog, IconButton } from "@roc/ui";
import { EditorAttachment } from "./attachments/editorAttachment";
import { AttachFileButton } from "./attachments/attachFileButton";
import { MessageInput } from "@chatscope/chat-ui-kit-react";
import { MAX_FILE_SIZE } from "../utils/constants";
import { Message } from "@twilio/conversations";
import { getAuthorName, isInternal } from "../helper";
import { ReplyToMessage } from "./replyToMessage";
import { ChatRoomType, ParticipantAttribute, ParticipantDetails } from "../types/communicationTypes";
import htmlToDraft from 'html-to-draftjs';
import { Alert } from "@material-ui/lab";
import { GPTMessageCorrectionDialog } from "./gptMessageCorrectionDialog";

const toolbar = {
  options: ['inline', 'blockType', 'list'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    inDropdown: false,
    options: ['Blockquote', 'Code'],
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
  },
};

const toolbarHeight = 44;
const textAreaHeight = 70;

const useStyles = makeStyles(theme => ({
  toolbarClassName: {
    margin: 0,
    height: toolbarHeight,
    '& .rdw-option-wrapper': {
      padding: '14px 6px',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
  },
  toolbarBorrowerAlert: {
    padding: '0 8px',
    fontSize: 14,
    fontWeight: 'bold',
    height: 30,
    '& .MuiAlert-icon': {
      padding: '4px 0'
    },
    '& .MuiAlert-message': {
      padding: '4px 0'
    }
  },
  wrapperClassName: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  editorClassName: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 0, 2),
    overflow: 'visible',
    height: 'auto',
    '& .DraftEditor-root': {
      height: (props: any) => props.expandMessageInputArea ? 'auto' : textAreaHeight,
      minHeight: textAreaHeight,
      position: 'inherit',
      '& .DraftEditor-editorContainer': {
        position: 'inherit',
      }
    },
    '& .rdw-suggestion-wrapper': {
      position: 'absolute',
    },
    '& .rdw-suggestion-dropdown': {
      maxHeight: 500,
      minWidth: 350,
      backgroundColor: '#efefef',
      borderColor: '#e1e1e1',
      borderRadius: '10px',
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      fontSize: 16,
      bottom: (props: any) => props.expandMessageInputArea ? 'auto' : 22,
    },
    '& .rdw-mention-link': {
      color: '#005f9b',
      background: '#d7efff',
      padding: '2px 4px',
      fontSize: 12,
      borderRadius: 4,
      textDecoration: 'none',
      cursor: 'auto',
      fontWeight: 'bold'
    },
    '& .rdw-suggestion-option': {
      cursor: 'pointer',
      padding: '8px 16px',
      minWidth: 250,
      borderColor: '#d3d3d3',
      '&:hover': {
        background: '#ccc',
        fontWeight: 'bold',
      }
    },
    '& .rdw-suggestion-option-active': {
      backgroundColor: '#ccc',
      fontWeight: 'bold',
    }
  },
}));

interface Attributes {
  firstName: string;
  lastName: string
}


const MobileCustomMessageInput = (props) => {
  const [initialValue, setInitialValue] = useState("");

  const [openModalGPT, setOpenModalGPT] = useState(false);

  const onProcessMessageThroughGPT = async (message, attachments, mentions) => {
    const loggedInUser = props.communicationStore.getloggedInParticpant(props.communicationStore.participants[props.communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
    const isInternalUser = isInternal(loggedInUser?.userId.toString(), props.roles);
    if (isInternalUser || (props.chatRoomType === ChatRoomType.NEW_LOAN_REQUEST && loggedInUser?.role === "backOfficeProcessor")) {
      setInitialValue(message)
      const rephraseMessage = await props.communicationStore.rephraseMessage(message);
      props.setMobileRephraseMessage(rephraseMessage)
      setOpenModalGPT(true)
    } else {
      props.sendMessage(message, attachments, mentions);
      props.clearAttachments();
      props.setMobileMessage("")
    }
  }

  return (
    <>
      <Box display={'flex'}>
        {props.files.map(file => (
          <Box p={1}>
            <EditorAttachment
              file={file}
              onRemove={() => {
                props.setFiles(props.files.filter(f => f != file));
              }}
            />
          </Box>
        ))}
      </Box>
      <MessageInput
        attachButton={true}
        value={props.checkGPT ? props.mobileMessage : props.mobileRephraseMessage}
        sendDisabled={props.checkGPT ? !props.mobileMessage : !props.mobileRephraseMessage}
        sendOnReturnDisabled={true}
        onAttachClick={props.onAttachClick}
        onChange={(innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => {
          props.setMobileMessage(innerHtml);
          props.onChange(innerHtml);
        }}
        onSend={(innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => {
          if (props.checkGPT) {
            onProcessMessageThroughGPT(props.mobileMessage, props.files, []);
          } else {
            props.sendMessage(props.mobileMessage, props.files, []);
            props.clearAttachments();
            props.setMobileMessage("")
            setOpenModalGPT(false)
          }
        }} placeholder="Type message here..." />
      <GPTMessageCorrectionDialog
        open={openModalGPT}
        isTabletSize={props.isTabletSize}
        initialMessage={initialValue}
        onClose={() => {
          setOpenModalGPT(false);
        }}
        rephrasedComponent={
          <MobileCustomMessageInput {...props}
            checkGPT={false} />
        } />
    </>
  );
};


const ReplyToBar = ({ replyToMessage, onClear }: {
  replyToMessage: Message;
  onClear: () => void;
}) => {
  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
    setTimeout(() => {
      onClear();
    }, 200);
  };
  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <Collapse in={show} unmountOnExit>
      <ReplyToMessage message={replyToMessage} onClose={onClose} replyToPrefix={'Reply To:'} />
    </Collapse>
  );
}

export const CustomMessageInputContainer = (props) => {

  const [editorState, setEditorState] = useState<EditorState>(null);
  const [dialogEditorState, setDialogEditorState] = useState<EditorState>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [mentions, setMentions] = useState<String[]>([]);
  const [message, setMessage] = useState('');
  const [mobileMessage, setMobileMessage] = useState('');
  const [mobileRephraseMessage, setMobileRephraseMessage] = useState('');
  const [gptMessageCorrectionDialogOpen, setGPTMessageCorrectionDialogOpen] = useState<boolean>(false);
  const replyToMessage = props.replyToMessage;

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
    setDialogEditorState(EditorState.createEmpty());
  }, []);

  useEffect(() => {
    if (replyToMessage) {
      addMentionToReply();
    }
  }, [replyToMessage]);

  const addMentionToReply = () => {
    if (editorState && replyToMessage) {
      const participant = props.participants[props.currentConversationSid]?.find(p => p?.attributes?.firstName?.trim().toLowerCase() + " " + p?.attributes?.lastName?.charAt(0).toLowerCase() === replyToMessage?.attributes?.authorName.toLowerCase());
      const authorName = getAuthorName(participant, replyToMessage);

      if (authorName) {
        const mentionHtml = createMentionHtml(authorName);
        insertMentionInEditor(mentionHtml);
      }
    }
  };

  const getAuthorName = (participant, replyToMessage) => {
    if (participant) {
      const loggedInUser = props.communicationStore.getloggedInParticpant(props.communicationStore.participants[props.communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
      const isReplyToSameUser = loggedInUser?.userId === participant?.attributes?.userId;

      if (!participant.attributes.isSystemUser && !isReplyToSameUser) {
        return participant.attributes.firstName.trim() + " " + participant.attributes.lastName.charAt(0);
      }
    }

    const authorName = replyToMessage?.attributes?.authorName?.toLowerCase();
    const matchedMention = props.mentionsList.find(m => m.value.toLowerCase().includes(authorName));
    return matchedMention ? matchedMention.value : null;
  };


  const createMentionHtml = (authorName) => {
    return `<a href="javascript:void(0)" class="wysiwyg-mention" data-mention="" data-value="${authorName}" target="_blank">@${authorName}</a>`;
  };

  const insertMentionInEditor = (mentionHtml) => {
    const { contentBlocks, entityMap } = htmlToDraft(mentionHtml);
    if (contentBlocks) {
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const newContent = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        contentState.getBlockMap()
      );
      setEditorState(EditorState.push(editorState, newContent, 'insert-fragment'));
    }
  };

  return (
    <CustomMessageInput
      key={"normalCustomMessageInput"}
      dialogEditorState={dialogEditorState}
      setDialogEditorState={setDialogEditorState}
      editorState={editorState}
      setEditorState={setEditorState}
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
      attachments={attachments}
      setAttachments={setAttachments}
      mentions={mentions}
      setMentions={setMentions}
      message={message}
      setMessage={setMessage}
      mobileMessage={mobileMessage}
      setMobileMessage={setMobileMessage}
      mobileRephraseMessage={mobileRephraseMessage}
      setMobileRephraseMessage={setMobileRephraseMessage}
      gptMessageCorrectionDialogOpen={gptMessageCorrectionDialogOpen}
      setGPTMessageCorrectionDialogOpen={setGPTMessageCorrectionDialogOpen}
      checkGPT={true}
      editorId={"normalEditor"}
      {...props} />
  )

}

export const CustomMessageInput = (props) => {
  const { as, onChange, messageSnippets, expandMessageInputArea, toggleExpand, loading, showSnippet, isTabletSize, replyToMessage, onReplyClear, mentionsList, communicationStore, participants, currentConversationSid, sendMessage, editorState, setEditorState, dialogEditorState, setDialogEditorState, isDisabled, setIsDisabled, attachments, setAttachments, mentions, setMentions, message, setMessage, mobileMessage, setMobileMessage, gptMessageCorrectionDialogOpen, setGPTMessageCorrectionDialogOpen, checkGPT, chatRoomType, editorId } = props;
  const classes = useStyles({
    expandMessageInputArea: expandMessageInputArea
  });
  const { manageParticipantsStore } = communicationStore;
  const editorRef = useRef(null);
  const [hideMessageInputArea, setHideMessageInputArea] = useState<boolean>(
    false
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [participantsToAdd, setParticipantsToAdd] = useState<string[]>([]);
  const [initialMessage, setInitialMessage] = useState('');
  const isBorrowerUser = communicationStore.globalStore.isBorrowerUser;

  useEffect(() => {
    if (editorRef?.current) {
      editorRef.current.focusEditor();
    }
  }, [editorRef?.current, replyToMessage]);

  useEffect(() => {
    setIsDisabled(!editorState?.getCurrentContent().hasText());
  }, [editorState]);

  const handlePastedText = () => {
    if (editorState) {
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();
      const newContent = Modifier.applyInlineStyle(
        currentContent,
        currentSelection,
        'background-color: inherit;'
      );
      setEditorState(
        EditorState.push(editorState, newContent, 'change-inline-style')
      );
    }
  };


  const addFiles = (newFiles: File[]) => {
    const validFiles = newFiles.filter(
      ({ size }) => size < MAX_FILE_SIZE + 1
    );

    if (validFiles.length < newFiles.length) {
      // TODO: show error
    }

    setAttachments(currentFiles => [...currentFiles, ...validFiles]);
  }

  const handlePastedFiles = files => {
    addFiles(files);
  };

  const onSnippetMessageSelection = value => {
    const selectedSnippet = messageSnippets.find(snippet => snippet.snippetId === value);
    if (editorState && selectedSnippet) {
      const blocksFromHTML = convertFromHTML(selectedSnippet.content);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(
        EditorState.createWithContent(content)
      );
      setMessage(selectedSnippet.content);
      onChange();
    }
  };

  const onEditorStateChange = (e) => {
    if (e.getCurrentContent().hasText()) {
      onChange();
    }
    setMessage(draftToHtml(convertToRaw(e.getCurrentContent())));
    setEditorState(e);
    const entityMap: any[] = convertToRaw(e.getCurrentContent()).entityMap;
    let mentions = Object.values(entityMap).filter((entity): any => {
      if (entity?.type === 'MENTION') {
        return entity;
      }
    }).map(e => e.data.value);
    setMentions(mentions);
  }

  const onDialogEditorStateChange = (e) => {
    if (e.getCurrentContent().hasText()) {
      onChange();
    }
    setMessage(draftToHtml(convertToRaw(e.getCurrentContent())));
    setDialogEditorState(e);
    const entityMap: any[] = convertToRaw(e.getCurrentContent()).entityMap;
    let mentions = Object.values(entityMap).filter((entity): any => {
      if (entity?.type === 'MENTION') {
        return entity;
      }
    }).map(e => e.data.value);
    setMentions(mentions);
  }

  const toggleMessageInputArea = () => {
    setHideMessageInputArea(!hideMessageInputArea);
  };

  const onFilesChange = (event): void => {
    const { files } = event.target as HTMLInputElement;
    if (files?.length) {
      addFiles(Array.from(files));
    }
  };

  const onAttachClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = onFilesChange;
    input.click();
  };

  const addParticipantsAndSendMessage = async () => {
    setModalOpen(false);
    await addParticipants(participantsToAdd)
    sendMessage(message, attachments, mentions);
    setAttachments([]);
    setEditorState(EditorState.createEmpty());
  }

  const processMessageThroughGPT = async (message, attachments, mentions) => {
    const loggedInUser = props.communicationStore.getloggedInParticpant(props.communicationStore.participants[props.communicationStore.currentConversationSid])?.attributes as ParticipantAttribute;
    const isInternalUser = isInternal(loggedInUser?.userId.toString(), props.roles);

    if (isInternalUser || (chatRoomType === ChatRoomType.NEW_LOAN_REQUEST && loggedInUser?.role === "backOfficeProcessor")) {
      setInitialMessage(message);
      const rephraseMessage = await communicationStore.rephraseMessage(message);
      const blocksFromHTML = htmlToDraft(rephraseMessage);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      setDialogEditorState(
        EditorState.createWithContent(contentState)
      );
      setGPTMessageCorrectionDialogOpen(true);
    } else {
      onMessageSend(message, attachments, mentions)
    }
  };

  const onMessageSend = async (message, attachments, mentions) => {
    let result = getNonParticipantsList(mentions);
    if (result.length > 0) {
      setModalOpen(true);
    } else {
      sendMessage(message, attachments, mentions);
      setAttachments([]);
      setEditorState(EditorState.createEmpty());
      setGPTMessageCorrectionDialogOpen(false);
    }
  };

  const addParticipants = async (participantsToAdd) => {
    setModalOpen(false);
    const participants = communicationStore.manageParticipantsStore.internalParticipants;

    for (const name of participantsToAdd) {
      const participant = participants.find(p => p.firstName?.trim() + " " + p.lastName?.trim()?.charAt(0) === name);

      if (participant) {
        console.log(participant.emailAddress);
        await manageParticipantsStore.addParticipant(communicationStore.currentChatRoomId, participant.emailAddress);
      }
    }
  };

  const getNonParticipantsList = (mentions) => {
    let participantsToAdd = [];
    const keys = participants[currentConversationSid] && Object.keys(participants[currentConversationSid]);
    let uniqueMentions = [...new Set(mentions)];
    for (let i in uniqueMentions) {
      let isParticipant = false;
      let plainNameMention = (uniqueMentions[i] as string).split('|')[0].trim();
      for (let participant in keys) {
        const p = participants[currentConversationSid][participant].attributes as Attributes;
        const name = p.firstName + " " + p.lastName?.charAt(0);
        if (name === plainNameMention || plainNameMention === "here") {
          isParticipant = true;
          break;
        }
      }
      if (!isParticipant) {
        participantsToAdd.push(plainNameMention);
      }
    }
    setParticipantsToAdd(participantsToAdd);
    return participantsToAdd;
  };

  return (
    <div style={{
      position: 'relative'
    }}>
      {
        replyToMessage && <ReplyToBar replyToMessage={replyToMessage} onClear={onReplyClear} />
      }
      {
        isTabletSize ?
          <MobileCustomMessageInput {...props}
            files={attachments}
            checkGPT={true}
            onAttachClick={onAttachClick}
            clearAttachments={() => setAttachments([])} /> :
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            borderTop: '1px solid #ccc',
            flexGrow: 1
          }}>
            <Grid container justifyContent="space-between" alignItems="center" style={{ padding: '2px 8px 2px 0' }}>
              <Grid item>
                {
                  hideMessageInputArea || !showSnippet || !messageSnippets.length ? null :
                    <AddSnippet messageSnippets={messageSnippets.map(snippet => { return { value: snippet.snippetId, label: snippet.name } })} onSelect={onSnippetMessageSelection} />
                }
              </Grid>
              <Grid item>
                {
                  hideMessageInputArea ? null :
                    <>
                      <AttachFileButton files={attachments} onAttachClick={onAttachClick} />
                      <Button
                        style={{ marginRight: 8, fontSize: 12 }}
                        testId="expand"
                        variant="outlined"
                        color="default"
                        startIcon={expandMessageInputArea ? <FullscreenExit /> : <Fullscreen />}
                        onClick={() => toggleExpand()}
                        size='small'
                      >
                        {expandMessageInputArea ? 'Collapse' : 'Expand'}
                      </Button>
                    </>
                }
                {
                  expandMessageInputArea ? null :
                    <Button
                      style={{ fontSize: 12 }}
                      testId="expand"
                      variant="outlined"
                      color="default"
                      startIcon={hideMessageInputArea ? <ExpandLess /> : <ExpandMore />}
                      onClick={() => toggleMessageInputArea()}
                      size='small'
                    >
                      {hideMessageInputArea ? 'Show' : 'Hide'}
                    </Button>
                }
              </Grid>
            </Grid>
            <Collapse in={!hideMessageInputArea}>
              <div style={{ cursor: 'text', width: '100%', flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <Editor
                  key={editorId}
                  ref={editorRef}
                  toolbar={toolbar}
                  toolbarCustomButtons={
                    communicationStore.isBorrowerRoom(communicationStore.currentConversation) && !isBorrowerUser ?
                      [<Alert className={classes.toolbarBorrowerAlert} severity="error">This room has borrower in it.</Alert>] : []
                  }
                  handlePastedText={handlePastedText}
                  handlePastedFiles={handlePastedFiles}
                  editorState={checkGPT ? editorState : dialogEditorState}
                  wrapperClassName={classes.wrapperClassName}
                  toolbarClassName={classes.toolbarClassName}
                  editorClassName={classes.editorClassName}
                  onEditorStateChange={checkGPT ? (e) => onEditorStateChange(e) : (e) => onDialogEditorStateChange(e)}
                  spellCheck={true}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: mentionsList
                  }}
                />
              </div>
              <Box
                style={{ display: 'flex', justifyContent: 'space-between' }}
                justifyContent="space-between"
                alignItems="flex-end"
                p={1.5}
              >
                <Grid container spacing={2}>
                  {attachments.map(file => (
                    <Grid item>
                      <EditorAttachment
                        file={file}
                        onRemove={() => {
                          setAttachments(attachments.filter(f => f != file));
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Button
                  testId="send-message"
                  variant="contained"
                  color="primary"
                  disabled={isDisabled || loading}
                  endIcon={loading ? <CircularProgress thickness={4} size={16} style={{
                    color: '#777'
                  }} /> : <SendOutlined />}
                  onClick={() => {
                    if (checkGPT) {
                      processMessageThroughGPT(message, attachments, mentions);
                    } else {
                      onMessageSend(message, attachments, mentions);
                    }
                  }}
                >
                  Send
                </Button>
              </Box>
            </Collapse>
            <ConfirmDialog
              open={modalOpen}
              title={`Add Participant${participantsToAdd.length != 1 ? 's' : ''}`}
              body={
                <>
                  You tried to mention {participantsToAdd.length === 1 ? 'a person' : 'people'} who {participantsToAdd.length === 1 ? "isn't" : "aren't"} in this chat, would you like to add&nbsp;
                  {participantsToAdd.map((name, index) => (
                    <React.Fragment key={name}>
                      {index > 0 && (index === participantsToAdd.length - 1 ? ' and ' : ', ')}
                      <strong>{`"${name}"`}</strong>
                    </React.Fragment>
                  ))}
                  &nbsp;as participant{participantsToAdd.length !== 1 ? 's' : ''}?
                </>
              }
              handleCancel={() => {
                setModalOpen(false);
              }}
              handleClose={() => {
                setModalOpen(false);
              }}
              handleConfirm={() => {
                addParticipantsAndSendMessage()
              }}
              confirmButtonText={`Add participant${participantsToAdd.length != 1 ? 's' : ''}`}
              cancelButtonText={'Cancel'}
            />
            <GPTMessageCorrectionDialog
              open={gptMessageCorrectionDialogOpen}
              initialMessage={initialMessage}
              isTabletSize={isTabletSize}
              onClose={() => {
                setGPTMessageCorrectionDialogOpen(false);
              }}
              rephrasedComponent={
                <CustomMessageInput
                  as={MessageInput}
                  key={"DialogCustomMessageInput"}
                  editorId={"DialogEditor"}
                  roles={props.roles}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  dialogEditorState={dialogEditorState}
                  setDialogEditorState={setDialogEditorState}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  mentions={mentions}
                  setMentions={setMentions}
                  message={message}
                  setMessage={setMessage}
                  gptMessageCorrectionDialogOpen={false}
                  setGPTMessageCorrectionDialogOpen={setGPTMessageCorrectionDialogOpen}
                  checkGPT={false}
                  replyToMessage={replyToMessage}
                  onReplyClear={() => onReplyClear}
                  sendMessage={sendMessage}
                  communicationStore={communicationStore}
                  mentionsList={mentionsList}
                  onChange={onChange}
                  messageSnippets={communicationStore.messageSnippets}
                  expandMessageInputArea={expandMessageInputArea}
                  toggleExpand={toggleExpand}
                  loading={communicationStore.loading}
                  showSnippet={showSnippet}
                  participants={participants}
                  currentConversationSid={currentConversationSid}
                  isTabletSize={isTabletSize} />
              } />
          </div >
      }
    </div>
  );
}