import { DataGrid, GridColumnProps, SelectMode, StatusChip, StatusType } from '@roc/ui';

import { observer } from 'mobx-react';
import { DateFormat, formatDate } from '@roc/feature-utils';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { ReactNode, useEffect, useState } from 'react';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';
import { filterProps, formatCurrency, noOpComparator } from '@roc/ui/utils';
import { TaxTabs } from '../types/types';
import { getTaxColumns } from '../utils/utils';
import { getTaxToolbar } from './taxToolbar';
import { StatusEditorPaymentApprobal } from './statusEditorPaymentApprobal';


const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: '65vh',
    overflow: 'hidden',
  },
}));

export const TaxCertificateGrid = observer(() => {
  const classes = useStyles();
  const { taxCertificateStore } = useTaxCertificateStore();
  const { taxCertificateGridStore, tab, loanIds } = taxCertificateStore;

  useEffect(() => {
    taxCertificateStore.clearLoanIds();
    onTabChange();
  }, [tab]);

  const onTabChange = () => {
    taxCertificateGridStore.resetAndFetchGridData();
  };

  const frameworkComponents = {
    statusCellRenderer: ({ value }) =>
      value ? (<StatusChip statusType={StatusType.DOCUMENT} label={value} size="small" />) : null,
    dateCellRenderer: ({ value }) => formatDate(value, DateFormat.MMDDYYYY) ?? null,
    currencyCellRenderer({ value }) {
      return formatCurrency(value);
    },
    dropdownGridEditor: StatusEditorPaymentApprobal,
  };

  const onSortChanged = params => {
    const sortModel = params.api.getSortModel();
    taxCertificateGridStore.setSortModel(sortModel);
  };

  const onFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    taxCertificateGridStore.setFilterModel(filterModel);
  };

  const onRowSelected = (params) => {
    // TODO - WE SHOULD USE A TRANSACTION ID
    taxCertificateStore.setLoanIds(params?.data?.loanId, params?.node.selected);
  };


  return (
    <Box>
      <Grid item className={classes.gridContainer}>
        <DataGrid
          columns={getTaxColumns(tab)}
          rows={taxCertificateGridStore.gridData.data.rows}
          frameworkComponents={frameworkComponents}
          selectMode={tab === TaxTabs.REDEMPTION_REQUEST && SelectMode.MULTIPLE}
          headerCheckboxSelection={true}
          suppressRowClickSelection={true}
          toolbar={getTaxToolbar(tab)}
          key={tab}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
          isLoading={taxCertificateGridStore.isLoading}
          showFilters={taxCertificateGridStore.showFilters}
          sortModel={taxCertificateGridStore.sortModel}
          filterModel={taxCertificateGridStore.filterModel}
          paginationData={taxCertificateGridStore.gridData.meta}
          setPageNumber={taxCertificateGridStore.setPageNumber}
          onRowSelected={onRowSelected}
        />
      </Grid>
    </Box>
  );
});