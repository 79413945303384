import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, makeObservable, observable } from 'mobx';
import { BorrowerEmailFormStore } from './borrowerEmailFormStore';
import { QuoteTypeStore } from '../quoteTypeStore';
import { LoanCommentsFormStore } from './loanCommentsFormStore';

export class SummaryStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;

  showLoanCommentsDialog = false;
  showNoBorrowersDialog = false;

  borrowerEmailFormStore: BorrowerEmailFormStore;
  loanCommentsFormStore: LoanCommentsFormStore;

  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.borrowerEmailFormStore = new BorrowerEmailFormStore(
      globalStore,
      quoteTypeStore
    );
    this.loanCommentsFormStore = new LoanCommentsFormStore(
      globalStore,
      quoteTypeStore
    );
    makeObservable(this, {
      reset: action,
      showNoBorrowersDialog: observable,
      validateSummaryStep: action,
      closeNoBorrowersDialog: action,
      showNoBorrowersAlert: computed,
    });
  }

  loadLoanCommentsData(loanComments, leverageExceptionComments) {
    const form = this.loanCommentsFormStore;
    form.loadForm({
      loanComments: loanComments ?? '',
      leverageExceptionComments: leverageExceptionComments ?? '',
    });
    return form;
  }

  validateSummaryStep() {
    if (this.showNoBorrowersAlert) {
      this.showNoBorrowersDialog = true;
      return false;
    }
    return true;
  }

  closeNoBorrowersDialog() {
    this.showNoBorrowersDialog = false;
  }

  reset() {
    this.borrowerEmailFormStore.reset();
    this.loanCommentsFormStore.reset();
  }

  get showNoBorrowersAlert() {
    return this.quoteTypeStore.borrowersStore.borrowerFormStores.some(
      form =>
        !form.form.fields.firstName.value ||
        !form.form.fields.lastName.value ||
        !form.form.fields.emailAddress.value
    );
  }
}
