import { useContext } from "react";
import { QuoteTypeStore } from "../stores/quoteTypeStore";
import { QuoteTypeStoreContext } from "../utils/quoteTypeStoreContext";

interface QuoteTypeStoreHook {
  quoteTypeStore: QuoteTypeStore;
}

export const useQuoteTypeStore = ():QuoteTypeStoreHook  => {
  const quoteTypeStore = useContext(QuoteTypeStoreContext);
  return { quoteTypeStore };
}