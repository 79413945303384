import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, lighten, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { LoginLogoImg, Page } from '@roc/feature-app-core';
import { Paper, PaperBorderType, PreviewBackdrop } from '@roc/ui';
import clsx from 'clsx';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState } from 'react';
import { useDocumentLibraryRoutes } from '../../hooks/useDocumentLibraryRoutes';
import DocumentIndex from '../../components/documentIndex';

const useStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    overflowX: 'auto',
    position: 'relative',
    height: '100%',
    padding: '16px',
    background: '#FFF',
    fontSize: 16,
    '& table': {
      width: '100%',
      textAlign: 'center',
      borderCollapse: "collapse",
    },
    '& table th': {
      padding: '5px',
      background: lighten(theme.palette.primary.main, 0.5),
      border: '0.25px solid black',
    },
    '& table td': {
      padding: '5px',
      borderCollapse: "collapse",
      border: '0.25px solid black',
      textAlign: 'left',
    },
    '& td p': {
      marginLeft: '5px'
    },
    '& ul,ol': {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(0.5),
    },
    '& h6': {
      fontSize: 16,
      padding: 4,
      fontWeight: 'bold',
    },
    '@media (max-width: 600px)': {
      fontSize: '8px',
      padding: 0,
    },
  },
  documentCardTable: {
    '& table th': {
      background: lighten(theme.palette.primary.main, 0.5),
      border: '0.25px solid black',
      padding: "9px",
    }
  },
  documentCard: {
    padding: "0!important"
  },
  documentCardTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    position: "relative",
    marginRight: "4px",
    marginLeft: "4px",
    padding: "4px",
    top: "4px",
    background: lighten(theme.palette.primary.main, 0.5),
    fontWeight: 'bold',
    border: '0.25px solid black'
  }
}));

const usePrintModeStyles = makeStyles((theme: Theme) => ({
  documentContainer: {
    fontSize: 10,
    '& ul,ol': {
      marginLeft: theme.spacing(2),
    },
    '& h6': {
      fontSize: 12,
    },
  },
}));

const DocumentCard = ({
  title = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      title={title}
      titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
      titleVariant={'h6'}
      borderType={paperBorder}
      className={clsx(classes.documentCard, paperClassName)}
      style={paperStyle}>
      <Box p={0.5} className={paperBodyClassName}>
        {documentToReactComponents(data)}
      </Box>
    </Paper>
  )
}

const CustomDocumentCard = ({
  primaryTitle = undefined,
  secondaryTitle = undefined,
  data,
  paperBorder = PaperBorderType.SOLID_BORDER,
  paperClassName = '',
  paperTitleClassName = '',
  paperBodyClassName = '',
  paperStyle = {},
}) => {
  const classes = useStyles();
  return <Paper
    title={primaryTitle}
    titleClass={clsx(classes.documentCardTitle, paperTitleClassName)}
    titleVariant={'h6'}
    borderType={paperBorder}
    className={clsx(classes.documentCard, paperClassName)}
    style={paperStyle}>
    <Paper
      borderType={paperBorder}
      style={{ padding: '16px' }}
    >
      {documentToReactComponents(secondaryTitle)}
    </Paper>
    <Box p={0.5} className={paperBodyClassName}>
      <DocumentCard
        data={data}
        paperBorder={paperBorder}
      />
    </Box>
  </Paper>
}

const ProductBoxTiers = ({ data }) => {
  return (
    <>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tier1ExperienceTitle}
          data={data.tier1Experience}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <CustomDocumentCard
          primaryTitle={data.tierLoanParametersTitle}
          secondaryTitle={data.tier1Borrowers}
          data={data.tier1LoanParameters}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tierLeverageTitle}
          data={data.tier1Leverage}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="5%">
        <DocumentCard
          data={data.tier1Notes}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>

      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tier2ExperienceTitle}
          data={data.tier2Experience}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <CustomDocumentCard
          primaryTitle={data.tierLoanParametersTitle}
          secondaryTitle={data.tier2Borrowers}
          data={data.tier2LoanParameters}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tierLeverageTitle}
          data={data.tier2Leverage}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="5%">
        <DocumentCard
          data={data.tier2Notes}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>

      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tier3ExperienceTitle}
          data={data.tier3Experience}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <CustomDocumentCard
          primaryTitle={data.tierLoanParametersTitle}
          secondaryTitle={data.tier3Borrowers}
          data={data.tier3LoanParameters}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tierLeverageTitle}
          data={data.tier3Leverage}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="5%">
        <DocumentCard
          data={data.tier3Notes}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>

      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tier4ExperienceTitle}
          data={data.tier4Experience}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <CustomDocumentCard
          primaryTitle={data.tierLoanParametersTitle}
          secondaryTitle={data.tier4Borrowers}
          data={data.tier4LoanParameters}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tierLeverageTitle}
          data={data.tier4Leverage}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="5%">
        <DocumentCard
          data={data.tier4Notes}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>

      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tier5ExperienceTitle}
          data={data.tier5Experience}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <CustomDocumentCard
          primaryTitle={data.tierLoanParametersTitle}
          secondaryTitle={data.tier5Borrowers}
          data={data.tier5LoanParameters}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="1%">
        <DocumentCard
          title={data.tierLeverageTitle}
          data={data.tier5Leverage}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
      <Box mb={2} paddingBottom="5%">
        <DocumentCard
          data={data.tier5Notes}
          paperBorder={PaperBorderType.NO_BORDER}
        />
      </Box>
    </>
  )

}

export const FixAndFlipProductBox = ({
  contentId,
  data,
  preview = false,
  printMode = false,
  setContainerRef,
}: {
  contentId: string,
  data: any,
  preview?: boolean,
  printMode?: boolean,
  setContainerRef: any,
}) => {
  const theme = useTheme();
  const { documentLibraryRoutesConfig } = useDocumentLibraryRoutes();
  const classes = useStyles();
  const printModeClasses = usePrintModeStyles();
  const containerRef = useRef(null);

  const [printModeContainerStyle, setPrintModeContainerStyle] = useState<any>();
  const [containerStyle, setContainerStyle] = useState<any>();

  const sections = [
    { id: 'keyRatios', title: data.keyRatiosTitle },
    { id: 'generalInfo', title: data.generalInformationTitle },
    { id: 'exitStrategy', title: data.exitStrategyTitle },
    { id: 'borrowerAndGuarantor', title: data.borrowerAndGuarantorTitle },
    { id: 'borrowerFaq', title: data.borrowerFaqScenariosTitle },
    { id: 'propertyAndSourcing', title: data.propertyAndSourcingTitle },
    { id: 'propertyFaq', title: data.propertyFaqScenariosTitle },
    { id: 'sourcingFaq', title: data.sourcingFaqScenariosTitle },
    { id: 'thirdPartyReportsFaq', title: data.thirdPartyReportsFaqScenariosTitle },
    { id: 'documentRequirements', title: data.documentRequirementsTitle },
    { id: 'borrowerExposureMonitoring', title: data.borrowerExposureMonitoringsurveilTitle },
    { id: 'experienceAndLeverage', title: data.experienceAndLeverageTitle },
    { id: 'experienceRequirements', title: data.experienceRequirementsTitle },
    { id: 'experienceScoreFaq', title: data.experienceScoreCalculationFaqTitle },
    { id: 'arltvEligibilityLoan', title: data.arltvEligibilityLoanTitle },
    { id: 'arltvEligibilityBorrower', title: data.arltvEligibilityBorrowerTitle },
    { id: 'arltvEligibilityCollateral', title: data.arltvEligibilityCollateralTitle },
    { id: 'arltvEligibilityExitStrategy', title: data.arltvEligibilityExitStrategyTitle },
    { id: 'glossary', title: data.glossaryTitle },
  ];

  useEffect(() => {
    const doc = new jsPDF("p", "mm", "a4");
    let width: any = doc.internal.pageSize.getWidth();
    let height: any = doc.internal.pageSize.getHeight();
    width = `${Math.floor(width)}mm`;
    height = `${Math.floor(height)}mm`;
    setPrintModeContainerStyle({
      margin: 'auto',
      minWidth: width,
      maxWidth: width,
      height: height
    });
    setContainerStyle({
      margin: 'auto',
      maxWidth: theme.breakpoints.values.lg,
    });
  }, []);

  useEffect(() => {
    setContainerRef(containerRef);
  }, [containerRef])

  if (!data) {
    return null;
  }

  return (
    <div ref={containerRef} style={printMode ? printModeContainerStyle : containerStyle}>

      <Page routeDefinition={documentLibraryRoutesConfig.documentLibraryDocument(contentId)}>
        <Box className={clsx(classes.documentContainer, printMode ? printModeClasses.documentContainer : undefined)}>
          {preview && <PreviewBackdrop />}
          <LoginLogoImg imgStyle={{ maxWidth: 200 }} />
          <Box my={2} textAlign='center'>
            <Typography variant={'h4'}>
              <strong>{data.productBox}</strong>
            </Typography>
            <Paper
              title={data.fixAndFlip}
              titleClass={classes.documentCardTitle}
              titleVariant={'h5'}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            />
            <Paper
              title={data.investmentPropertyLoan}
              titleClass={classes.documentCardTitle}
              titleVariant={'h6'}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <Paper
              title="Table of Contents"
              titleClass={classes.documentCardTitle}
              titleVariant={"h6"}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            >
              <DocumentIndex data={data} sections={sections} />
            </Paper>
          </Box>

          <Box id="keyRatios" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.keyRatiosTitle}
              data={data.keyRatios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="generalInfo" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.generalInformationTitle}
              data={data.generalInformation}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="exitStrategy" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.exitStrategyTitle}
              data={data.exitStrategy}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="borrowerAndGuarantor" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.borrowerAndGuarantorTitle}
              data={data.borrowerAndGuarantor}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="borrowerFaq" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.borrowerFaqScenariosTitle}
              data={data.borrowerFaqScenarios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="propertyAndSourcing" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.propertyAndSourcingTitle}
              data={data.propertyAndSourcing}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="propertyFaq" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.propertyFaqScenariosTitle}
              data={data.propertyFaqScenarios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="sourcingFaq" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.sourcingFaqScenariosTitle}
              data={data.sourcingFaqScenarios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="thirdPartyReportsFaq" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.thirdPartyReportsFaqScenariosTitle}
              data={data.thirdPartyReportsFaqScenarios}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="documentRequirements" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.documentRequirementsTitle}
              data={data.documentRequirements}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="borrowerExposureMonitoring" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.borrowerExposureMonitoringsurveilTitle}
              data={data.borrowerExposureMonitoringSurveil}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="experienceAndLeverage" mb={2}>
            <Paper
              title={data.experienceAndLeverageTitle}
              titleClass={classes.documentCardTitle}
              titleVariant={'h6'}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            />
          </Box>
          <Box mb={2}>
            <DocumentCard
              title={data.highLevelSpecsSubtitle}
              data={data.highLevelSpecs}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.eligibleRehabTypesSubtitle}
              data={data.eligibleRehabTypes}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="experienceRequirements" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.experienceRequirementsTitle}
              data={data.experienceRequirements}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <ProductBoxTiers
            data={data?.tiers?.content[0]?.data?.target?.fields}
          />
          <Box id="experienceScoreFaq" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.experienceScoreCalculationFaqTitle}
              data={data.experienceScoreCalculationFaq}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="arltvEligibility" mb={2}>
            <Paper
              title={data.arltvEligibilityTitle}
              titleClass={classes.documentCardTitle}
              titleVariant={'h6'}
              borderType={PaperBorderType.SOLID_BORDER}
              className={classes.documentCard}
            />
          </Box>
          <Box mb={2}>
            <DocumentCard
              title={data.arltvEligibilityLoanTitle}
              data={data.arltvEligibilityLoan}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="arltvEligibilityBorrower" mb={2}>
            <DocumentCard
              title={data.arltvEligibilityBorrowerTitle}
              data={data.arltvEligibilityBorrower}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="arltvEligibilityCollateral" mb={2}>
            <DocumentCard
              title={data.arltvEligibilityCollateralTitle}
              data={data.arltvEligibilityCollateral}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="arltvEligibilityExitStrategy" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.arltvEligibilityExitStrategyTitle}
              data={data.arltvEligibilityExitStrategy}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
          <Box id="glossary" mb={2} paddingBottom="5%">
            <DocumentCard
              title={data.glossaryTitle}
              data={data.glossary}
              paperBorder={PaperBorderType.NO_BORDER}
            />
          </Box>
        </Box>
      </Page>
    </div>
  );
}