import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@roc/ui';

const blueTheme = createTheme({
  palette: {
    primary: {
      main: 'rgba(229, 246, 253, 1)',
      contrastText: 'rgba(28, 63, 120, 1)',
    },
  },
});

const redTheme = createTheme({
  palette: {
    primary: {
      main: 'rgba(253, 237, 237, 1)',
      contrastText: 'rgba(95, 33, 32, 1)',
    },
  },
});

const greenTheme = createTheme({
  palette: {
    primary: {
      main: 'rgba(237, 247, 237, 1)',
      contrastText: 'rgba(46, 125, 50, 1)',
    },
  },
});

const themes = {
  red: redTheme,
  green: greenTheme,
  blue: blueTheme,
};

interface CustomButtonProps extends Omit<ButtonProps, 'color'> {
  theme: 'red' | 'green' | 'blue';
}

export const CustomButton = (props: CustomButtonProps) => {
  const { theme, ...buttonProps } = props;
  return (
    <MuiThemeProvider theme={themes[theme]}>
      <Button {...buttonProps} color="primary" variant="contained" />
    </MuiThemeProvider>
  );
};
