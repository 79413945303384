import { action, observable, flow, makeObservable } from 'mobx';
import { ApiResponse, GlobalStore, GridStore, } from '@roc/feature-app-core';
import { TaxService } from '../services/taxService';
import { TaxTabs } from '../types/types';
import { FileUpload } from '@roc/ui';

export class TaxCertificateStore {
  globalStore: GlobalStore;
  service: TaxService;
  taxCertificateGridStore: GridStore;
  tab: TaxTabs;
  loanIds: number[] = [];


  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    this.service = new TaxService();
    this.tab = TaxTabs.TAX_CERTIFICATE;
    this.taxCertificateGridStore = new GridStore(() => this.fetchTaxDashboard(),
      null,
      50);
    makeObservable(this, {
      createTaxCertificate: flow,
      tab: observable,
      setTab: action,
      loanIds: observable,
      createRedemptionRequest: flow,
      updatePropertyTaxStatus: flow,
      uploadPaymentZip: flow,
      sendPaymentApproval: flow,
    });
  };

  private async fetchTaxDashboard() {
    switch (this.tab) {
      case TaxTabs.TAX_CERTIFICATE:
      case TaxTabs.REDEMPTION_REQUEST:
        return await this.fetchTaxCertificateDashboard();
      case TaxTabs.PAYMENT_APPROVAL:
        return await this.fetchPaymentApprovalDashboard();
      default:
        return await this.fetchTaxCertificateDashboard();
    }
  }

  private async fetchTaxCertificateDashboard() {
    try {
      const filters = {
        ...this.taxCertificateGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.service.getTaxCertificateDashboard(
        this.tab,
        this.taxCertificateGridStore.gridData.meta.pageNumber,
        this.taxCertificateGridStore.gridData.meta.pageSize,
        this.taxCertificateGridStore.gridData.meta.sortDir,
        this.taxCertificateGridStore.gridData.meta.sortBy,
        filters,
        this.taxCertificateGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching tax dashboard.',
      });
    }
  };


  private async fetchPaymentApprovalDashboard() {
    try {
      const filters = {
        ...this.taxCertificateGridStore.gridData.meta.filters
      };
      const response: ApiResponse = await this.service.getTaxPaymentDashboard(
        this.taxCertificateGridStore.gridData.meta.pageNumber,
        this.taxCertificateGridStore.gridData.meta.pageSize,
        this.taxCertificateGridStore.gridData.meta.sortDir,
        this.taxCertificateGridStore.gridData.meta.sortBy,
        filters,
        this.taxCertificateGridStore.gridData.meta.dropdownFilters
      );
      return response;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while fetching tax payment dashboard.',
      });
    }
  };

  *createTaxCertificate(loanId) {
    try {
      const response: ApiResponse = yield this.service.createTaxCertificate(loanId);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Order created successfully.'
        });
        this.taxCertificateGridStore.resetAndFetchGridData();
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.error?.message
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating tax certificate.',
      });
    }
  };

  *createRedemptionRequest() {
    try {
      const response: ApiResponse = yield this.service.createRedemptionRequest(this.loanIds);
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Redemption request created successfully.'
        });
        this.taxCertificateGridStore.resetAndFetchGridData();
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.error?.message
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while creating redemption request.',
      });
    }
  };

  setTab(tab: TaxTabs) {
    this.tab = tab;
  };

  setLoanIds = (id, selected) => {
    if (id != null && !selected) {
      this.removeLoanIds(id);
    } else if (id != null && !this.loanIds.includes(id)) {
      this.loanIds.push(id);
    }
  }

  clearLoanIds = () => {
    this.loanIds = [];
  }

  removeLoanIds(id) {
    this.loanIds = this.loanIds.filter(recordsId => recordsId != id);
  }

  *updatePropertyTaxStatus(id: number, status: string) {
    const proccesed = false;
    try {
      const response = yield this.service.updatePropertyTaxStatus(id, status);
      return response.data.success;
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while updating property tax status.',
      });
    }
  }

  *uploadPaymentZip(
    fileUpload: FileUpload,
  ) {
    try {
      var newFile;
      if (fileUpload) {
        const file = fileUpload.file;
        const blob = file.slice(0, file.size, file.type);
        newFile = new File([blob], fileUpload.name, { type: file.type });
      }

      yield this.service.uploadPaymentZip(
        newFile
      );
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Payment zip file uploaded successfully.',
      });
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while uploading payment zip file.',
      });
    }
  };

  *sendPaymentApproval() {
    try {
      const response: ApiResponse = yield this.service.sendPaymentApproval();
      if (response.data.status === 'OK') {
        this.globalStore.notificationStore.showSuccessNotification({
          message: 'Payment approval sent correctly.'
        });
        this.taxCertificateGridStore.resetAndFetchGridData();
      } else {
        this.globalStore.notificationStore.showErrorNotification({
          message: response.data?.error?.message
        });
      }
    } catch (error) {
      this.globalStore.notificationStore.showErrorNotification({
        message: 'Error while sending payment approval.',
      });
    }
  };

}
