import { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { BooleanChip, Button, TooltipOnOverflow } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';
import { formatCurrency, formatToDecimal, getRocPointsIn } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { EditSubmissionButton } from '@roc/feature-edit-loans';
import { AddNewTaskButton } from '@roc/feature-general-tasks';
import { StandardDialog } from '@roc/ui';
import { CreateAppraisalOrderButton } from '../common/createAppraisalOrderButton';
import { EditLoanButton } from '@roc/feature-loans-shared';
import { Alert } from '@material-ui/lab';
import {
  US_TREASURY_10_YR_DESC,
  US_TREASURY_10_YR_INDEX,
  US_TREASURY_5_YR_DESC,
  US_TREASURY_5_YR_INDEX,
} from '@roc/feature-loans';
import { insertIf, LoanSubType } from '@roc/feature-utils';
import LoanSummaryTabs from '../common/loanSummaryTabs'
import LoanSummaryExpandableTable from '../common/loanSummaryExpandableTable';
import { Commissions } from '../common/comissions'
import { SubmissionVsUnderwritingValues } from '../common/submissionValuesVsUnderwritingValues'
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
  useLoanDetailsInfoItemStyles,
} from '../loanDetailsCustomCard';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import {
  LoanType,
} from '@roc/feature-utils';
import {
  getValueOrDefaultDash,
  formatPercentageValue
} from 'libs/feature-one-tool/src/quote/utils/utils';
import { PropertyDetailsDialog } from '../cards/propertiesCard'
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import { useSubmissionVsUnderwritingStore } from '../../hooks/useSubmissionVsUnderwritingStore';

const formatPoints = value => {
  return Number((value || 0).toFixed(4));
};

type loanSummaryProps = {
  displayCreateAppraisalOrderButton: boolean,
  classes: any,
  showEditLoanButton: boolean,
  setViewDetailDialog: any,
  loanStore: any,
  loanDetails: any,
  showCreateTask: boolean
  allowLoanPricing: boolean
  lenderPoints?: any,
  isInternal: boolean
  isOffHudBroker: boolean
  showEditSubmissionButton: boolean,
  isModalView?: boolean
  showPropertyDialog?: any,
  setShowPropertyDialog?: any,
  setProperty?: any,
  property?: any
  submissionVsUnderwritingStore?: any
}

export const LoanDetailsCardTerm = observer(() => {
  const {
    allowLoanPricing,
    showCreateApprasialOrderButton,
    showEditLoanButton,
    showEditSubmissionButton,
  } = useLoanFlags();
  const [showViewDetailDialog, setViewDetailDialog] = useState(
    false
  );
  const { loanStore, globalStore } = useStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const classes = useLoanDetailsInfoItemStyles();
  const isInternal = globalStore?.lenderInfo?.isInternal;
  const showCreateTask = userStore?.isBackOfficeUser;
  const displayCreateAppraisalOrderButton = showCreateApprasialOrderButton && loanDetails?.loanSubType !== LoanSubType.FIX_AND_FLIP_PRO;
  const isOffHudBroker = loanDetails?.referralFeePct && loanDetails?.referralFeePct > 0 ? true : false;

  return (
    <LoanDetailsCustomCard contentStyle={{
      minWidth: 1100
    }} cardContentProps={{
      paperNoShadow: true,
      style: {
        overflow: 'auto'
      }
    }}>
      <CardComponent
        displayCreateAppraisalOrderButton={displayCreateAppraisalOrderButton}
        classes={classes}
        showEditLoanButton={showEditLoanButton}
        setViewDetailDialog={setViewDetailDialog}
        loanStore={loanStore}
        loanDetails={loanDetails}
        showCreateTask={showCreateTask}
        allowLoanPricing={allowLoanPricing}
        isInternal={isInternal}
        isOffHudBroker={isOffHudBroker}
        showEditSubmissionButton={showEditSubmissionButton} />
      <StandardDialog
        open={showViewDetailDialog}
        maxWidth="xl"
        fullWidth={true}
        dialogContent={<CardComponent
          setViewDetailDialog={setViewDetailDialog}
          displayCreateAppraisalOrderButton={displayCreateAppraisalOrderButton}
          classes={classes}
          isModalView={true}
          showEditLoanButton={showEditLoanButton}
          loanStore={loanStore}
          loanDetails={loanDetails}
          showCreateTask={showCreateTask}
          allowLoanPricing={allowLoanPricing}
          isInternal={isInternal}
          isOffHudBroker={isOffHudBroker}
          showEditSubmissionButton={showEditSubmissionButton} />}
        handleClose={() => setViewDetailDialog(false)}
        onClose={() => setViewDetailDialog(false)}
      />
    </LoanDetailsCustomCard>
  );
});

export const CardComponent = observer(({ displayCreateAppraisalOrderButton, classes, showEditLoanButton, loanStore, loanDetails, showCreateTask, allowLoanPricing, isInternal, isOffHudBroker, showEditSubmissionButton, setViewDetailDialog, isModalView }: loanSummaryProps) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { submissionVsUnderwritingStore } = useSubmissionVsUnderwritingStore();
  const { loanSummary } = loanSummaryStore;
  const { showCommissionsCard } = useLoanFlags();
  const showBorrowerLiquidityCard = loanDetails.loanSubType === LoanSubType.SINGLE_PROPERTY;
  const tabs = [
    'Other Loan Terms', 'Brokers', 'Property Details', ...insertIf(showCommissionsCard, ['Commissions']), 'SUB/UW Values', ...insertIf(allowLoanPricing, ['Pricing/Rate Lock']), ...insertIf(allowLoanPricing, ['Points & Fees']), ...insertIf(showBorrowerLiquidityCard, ['Borrower Liquidity']),
  ]
  const { loanPricingData = {} } = loanStore.loanDetails;
  const [property, setProperty] = useState(null);
  const [showPropertyDialog, setShowPropertyDialog] = useState(
    false
  );
  const noOfUnits = (loanDetails?.properties || []).reduce(
    (total, p) => total + p.units,
    0
  );
  const formatPercentage = value =>
    `${value != null ? Number(value).toFixed(2) : ''}%`;
  const formatDigit = (value, digits) => value?.toFixed(3, digits);

  let ltvValue = '-';
  if (loanDetails?.aggregateLoanToAsIs) {
    ltvValue = `${loanDetails?.aggregateLoanToAsIs}%`;
  } else if (loanDetails?.loanType === LoanType.RESIDENTIAL_TERM && loanDetails?.estimatedLTV) {
    ltvValue = `${loanDetails?.estimatedLTV}%`;
  }
  const getSwapIndexLabel = (index: number) => {
    if (index === US_TREASURY_5_YR_INDEX) return US_TREASURY_5_YR_DESC;
    else if (index === US_TREASURY_10_YR_INDEX) return US_TREASURY_10_YR_DESC;
  };
  return (<>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 20 }}>
      <Typography variant="h5" style={{ marginTop: 8 }}>
        Loan Summary
      </Typography>
      <Box display='flex'>
        {!isModalView && displayCreateAppraisalOrderButton && <CreateAppraisalOrderButton buttonStyle={classes.button} />}
        {!isModalView && showEditLoanButton && (
          <EditLoanButton containerStyle={classes.containerStyle} loanStore={loanStore} data={loanDetails} buttonStyle={classes.button} />
        )}
        {!isModalView && showCreateTask &&
          <AddNewTaskButton buttonStyle={classes.button} loanId={loanDetails?.loanId} />
        }
        {!isModalView && <Button className={classes.button} testId='view-detail' variant="text" color="primary" onClick={() => setViewDetailDialog(true)}>
          View detail
        </Button>}
      </Box>
    </Grid>
    <Grid item xs={12} style={{ display: 'flex' }}>
      <Grid item xs={isModalView ? 12 : 3} md={3} style={{ position: 'relative', marginRight: 60 }}>
        <Grid container spacing={0} style={{ position: 'relative', paddingTop: 0 }} className={`${classes.amountHighligher} ${classes.amountHighligterTerms}`}>
          <LoanDetailsInfoItem
            boxStyle={{
              position: 'relative'
            }}
            value={formatCurrency(loanDetails?.amount, 0)}
            label={loanDetails?.loanSubType}
            valueStyle={{
              fontSize: 34,
              fontWeight: 400
            }}
            labelStyle={{
              color: "#939494",
              textTransform: 'uppercase',
              letterSpacing: '1.5px',
              fontWeight: 'bold',
              fontSize: 11,
              marginBottom: 8
            }}
            secondValue={<>
              <span>{ltvValue}</span>
              <span style={{
                color: "#939494",
                fontSize: 10
              }}>&nbsp;{'LTV'}</span>
            </>}
            secondValueStyle={{
              fontSize: 20,
              fontWeight: 'bold',
              marginTop: -6,
            }}
            thirdLabel='Prepayment penalty'
            thirdValue={loanDetails.prepaymentDescription}
            thirdValueStyle={{
              fontSize: 18
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={0} style={{
          position: 'relative'
        }}>
          {allowLoanPricing &&
            <Grid item xs={isModalView ? 12 : 3} md={3}>
              <LoanDetailsInfoItem
                value={`${formatPoints((loanDetails?.brokerPointsIn ?? 0) +
                  (loanDetails?.sfrLenderPointsIn ?? 0) +
                  (getRocPointsIn(loanDetails) ?? 0) + (loanDetails?.referralFeePct ?? 0))
                  } points`}
                labelStyle={{
                  color: "#939494",
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  letterSpacing: '1.5px',
                  fontSize: 11
                }}
                label={
                  <>
                    {isInternal || isOffHudBroker
                      ? (isOffHudBroker
                        ? `${formatPoints(loanDetails?.referralFeePct ?? 0)} Off Hud Broker | `
                        : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} On Hud Broker | `
                      )
                      : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} Broker | `}
                    {formatPoints(loanDetails?.sfrLenderPointsIn ?? 0)} Lender <br />
                    {formatPoints(getRocPointsIn(loanDetails) ?? 0)} Capital Provider
                  </>
                }
              />
            </Grid>
          }
          <Grid item xs={3}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestPayment ?? 0)}
              label="monthly PI payment"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                fontWeight: 'bold',
                letterSpacing: '1.5px',
                fontSize: 11
              }}
            />
          </Grid>
          {allowLoanPricing && <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.rate ? `${loanDetails?.rate}%` : '-'}
              label="Interest Rate"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>}
          {allowLoanPricing && <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={getValueOrDefaultDash(loanDetails?.rateType)}
              label={loanDetails?.amortization ?? 'Fixed rate'}
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>}
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.loanPurpose ? loanDetails?.loanPurpose : '-'}
              label='Loan Purpose'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={noOfUnits + ` Unit${noOfUnits > 1 ? 's' : ''}`}
              label='No. of Units'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={getValueOrDefaultDash(loanDetails?.pitiaDSCR)}
              label='PITIA DSCR'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestReserve ?? 0)}
              label='P&I Reserves'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                fontWeight: 'bold',
                letterSpacing: '1.5px',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.pledgeOfShares ? 'Yes' : 'No'}
              label="Pledge of Shares"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={'-'}
              label="Due At Closing"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontWeight: 'bold',
                fontSize: 11
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 6} md={6}>
            <LoanDetailsInfoItem
              value={<TooltipOnOverflow
                title={getValueOrDefaultDash(loanDetails?.attorneyName)}
                placement="bottom"
              >
                <Typography className={classes.overflowElipsis}>
                  {getValueOrDefaultDash(loanDetails?.attorneyName)}
                </Typography>
              </TooltipOnOverflow>}
              label='Attorney'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 10,
                fontWeight: 'bold'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} className={classes.loanSummaryBottomSection}>
      <Grid item xs={3} style={{ position: 'relative', textAlign: 'center', marginTop: '-60px' }}>
        {showEditSubmissionButton &&
          <EditSubmissionButton buttonStyle={classes.button} loanStore={loanStore} loanId={loanDetails?.loanId} />
        }
      </Grid>
    </Grid>
    {isModalView && property && <PropertyDetailsDialog
      open={showPropertyDialog}
      onClose={() => setShowPropertyDialog(false)}
      property={property}
      loanSubType={loanDetails?.loanSubType}
    />}
    {isModalView &&
      <LoanSummaryTabs
        classes={classes}
        tabs={tabs}
        tabsContent={[
          <LoanSummaryExpandableTable
            title='Other Loan Terms'
            hasTwoColumns={false}
            data={[
              {
                left: 'Loan Purpose',
                right: getValueOrDefaultDash(loanDetails?.loanPurpose)
              },
              {
                left: 'Borrower Cash Out at Close',
                right: formatCurrency(loanDetails?.borrowerCashAtClose ?? 0)
              },
              {
                left: 'P&I Reserves at close (Months)',
                right: getValueOrDefaultDash(loanDetails?.interestReserveMonths)
              },
              {
                left: 'Servicer',
                right: getValueOrDefaultDash(loanDetails?.servicer),
                subrows: [
                  { left: 'Servicer id', right: getValueOrDefaultDash(loanDetails?.servicerId) },
                  { left: 'First Payment Date', right: getValueOrDefaultDash(loanDetails?.firstServicerPaymentDueDate) }
                ]
              },
              {
                left: 'MERS MIN Number',
                right: getValueOrDefaultDash(loanDetails?.minNumber)
              },
              {
                left: 'Amortization',
                right: getValueOrDefaultDash(loanDetails?.amortization)
              },
              {
                left: 'Int Only Periods(Months)',
                right: getValueOrDefaultDash(loanDetails?.interestOnlyMonths)
              },
              {
                left: 'Loan Guaranty',
                right: getValueOrDefaultDash(loanDetails?.recourseStructure)
              },
              ...insertIf(allowLoanPricing, [{
                left: 'Capital Provider Int Rate',
                right: formatPercentageValue(loanDetails?.rocRate)
              }]),
              {
                left: 'Original Loan Terms (Months)',
                right: getValueOrDefaultDash(loanDetails?.duration)
              },
              {
                left: 'Capital Provider Underwriting Fee',
                right: loanDetails?.underwritingFee ? formatCurrency(loanDetails?.underwritingFee) : '-'
              },
              {
                left: 'Orig. Amortization Terms (Months)',
                right: getValueOrDefaultDash(loanDetails?.originalAmortizationTerms)
              },
              {
                left: 'Prepayment Penalty Period (Months)',
                right: getValueOrDefaultDash(loanDetails?.prepayPenaltyMonths)
              },
              {
                left: 'Prepayment Description',
                right: getValueOrDefaultDash(loanDetails?.prepaymentDescription)
              },
              {
                left: 'Loan LTV at Origination',
                right: formatPercentage(loanDetails?.aggregateLoanToAsIs ?? 0)
              },
              {
                left: 'Loan LTC at Origination',
                right: formatPercentage(loanDetails?.aggregateLoanToCurrentCost ?? 0)
              },
              {
                left: 'Origination Source',
                right: formatPercentage(loanDetails?.originationSource ?? 0)
              },
              ...insertIf(allowLoanPricing, [{
                left: 'Total Origination Fee',
                right: formatPercentage(
                  parseFloat(loanDetails?.totalOriginationFee || 0) +
                  parseFloat(loanDetails?.loanRetailFees?.retailPoints || 0)
                )
              },
              {
                left: 'Total Underwriting Fee',
                right: formatCurrency(
                  parseFloat(loanDetails?.totalUnderwritingFee || 0) +
                  parseFloat(loanDetails?.loanRetailFees?.retailUnderwritingFee || 0)
                )
              },
              {
                left: 'Capital Provider Underwriting Fee',
                right: formatCurrency(
                  parseFloat(loanDetails?.rocUnderwritingFees || 0) +
                  parseFloat(loanDetails?.loanRetailFees?.retailUnderwritingFee || 0)
                )
              },
              {
                left: 'Broker/Lender Underwriting Fee',
                right: formatCurrency(loanDetails?.brokerLenderUnderwritingFees ?? 0)
              },
              {
                left: 'Lender Premium',
                right: getValueOrDefaultDash(loanDetails?.lenderPremium || 0)
              }
              ])
            ]} />,
          <LoanSummaryExpandableTable hasTwoColumns={false} title='Brokers' data={[ 
            {
              left: 'Broker',
              right: '',
              expanded:true,
              subrows: [
                { left: 'Company Name', right: getValueOrDefaultDash(loanDetails?.broker?.company?.name) },
                { left: 'Company EIN Number', right: getValueOrDefaultDash(loanDetails?.broker?.company?.einNumber) }
              ]
            }
          ]} />,
          <LoanSummaryExpandableTable
            hasTwoColumns={false}
            title='Property Details'
            data={[
              {
                left: 'Property count',
                right: getValueOrDefaultDash(loanDetails?.propertyCount)
              },
              {
                left: 'Total Most Recent Valuation',
                right: formatCurrency(loanDetails?.mostRecentValuation ?? 0)
              },
              {
                left: 'Total Cost Basis',
                right: formatCurrency(loanDetails?.totalCostBasis ?? 0)
              },
              {
                left: 'Total Debt Payoff Amount',
                right: formatCurrency(loanDetails?.totalDebtPayoffAmount ?? 0)
              },
              {
                left: 'Total Annual Gross Rent',
                right: formatCurrency(loanDetails?.annualGrossRent ?? 0)
              },
              {
                left: 'PITIA',
                right: formatCurrency(loanDetails?.pitia ?? 0)
              },
              {
                left: 'NOI',
                right: formatCurrency(loanDetails?.noi ?? 0)
              },
              {
                left: 'Net Cash Flow',
                right: formatCurrency(loanDetails?.netCashFlow ?? 0)
              },
              {
                left: 'NCF DSCR',
                right: formatDigit(loanDetails?.netDSCR ?? 0, 3)
              },
              {
                left: 'PITIA DSCR',
                right: formatDigit(loanDetails?.pitiaDSCR ?? 0, 3)
              },
              ...loanDetails?.properties?.map((property) => {
                return ({
                  left: <>{property?.address}</>,
                  right: '',
                  subrows: [
                    {
                      left: 'Unit #',
                      right: getValueOrDefaultDash(property?.units)
                    },
                    {
                      left: 'City',
                      right: getValueOrDefaultDash(property?.city)
                    },
                    {
                      left: 'State',
                      right: getValueOrDefaultDash(property?.state)
                    },
                    {
                      left: 'Zip',
                      right: getValueOrDefaultDash(property?.zipCode)
                    },
                    {
                      left: 'Property Type',
                      right: getValueOrDefaultDash(property?.propertyType)
                    },
                    {
                      left: 'Annual Gross Rent',
                      right: formatCurrency(property?.annualGrossRent ?? 0)
                    },
                    {
                      left: 'Annual PITIA',
                      right: formatCurrency(property?.annualPITIA ?? 0)
                    },
                    {
                      left: 'Annual NOI',
                      right: formatCurrency(property.annualNOI ?? 0)
                    },
                    {
                      left: 'Annual NCF',
                      right: formatCurrency(property?.annualNCF ?? 0)
                    },
                    {
                      left: 'Annual Debt Service',
                      right: formatCurrency(property?.annualDebtService ?? 0)
                    },
                    {
                      left: 'Net DSCR',
                      right: formatToDecimal(property?.netDSCR ?? 0)
                    },
                    {
                      left: 'Most Recent Valuation',
                      right: formatCurrency(property?.propertyValuation ?? 0)
                    },
                    {
                      left: 'Property Loan Amount',
                      right: formatCurrency(property?.propertyLoanAmount ?? 0)
                    },
                    {
                      left: 'Exit Strategy',
                      right: getValueOrDefaultDash(property?.exitStrategy)
                    },
                    {
                      left: 'Occupancy',
                      right: getValueOrDefaultDash(property?.occupancy)
                    },
                    {
                      left: 'Flood Zone',
                      right: getValueOrDefaultDash(property?.floodZone)
                    },
                    {
                      left: 'Minimum release',
                      right: formatCurrency(property?.releaseAmount ?? 0)
                    },
                    {
                      left: 'Valuation Review',
                      right: getValueOrDefaultDash(property?.internalValuationReviewRequired)
                    },
                    {
                      left: 'Valuation Review Reason',
                      right: getValueOrDefaultDash(property?.internalValuationReviewRequiredReason)
                    },
                  ]
                })
              })
            ]} />,
          ...insertIf(showCommissionsCard, [
            <Commissions loanId={loanDetails?.loanId} />,
          ]),
          <SubmissionVsUnderwritingValues
            store={submissionVsUnderwritingStore}
            loanId={loanDetails?.loanId}
            isInternal={false} loanDetails={loanDetails}
          />,
          ...insertIf(allowLoanPricing, [<LoanSummaryExpandableTable
            hasTwoColumns={false}
            title='Pricing/Rate Lock'
            data={[
              {
                left: 'Program Type',
                right: getValueOrDefaultDash(loanPricingData?.programType)
              },
              {
                left: 'Spread on Pricing Date',
                right: getValueOrDefaultDash(loanPricingData?.spreadRate)
              },
              {
                left: 'Indicative Gross Rate On Pricing Date',
                right: getValueOrDefaultDash(loanPricingData?.grossRateOnPricingDate)
              },
              {
                left: 'Pricing Date',
                right: getValueOrDefaultDash(loanPricingData?.pricingDate)
              },
              {
                left: 'Pricing Index',
                right: `${getSwapIndexLabel(loanPricingData.swapIndex)}`
              },
              {
                left: 'Index Value On Pricing Date',
                right: formatPercentage(loanPricingData.swapRateAtPricing ?? 0)
              },
              {
                left: 'Index Rate Floor',
                right: formatPercentage(loanPricingData.swapRateAtOrigination ?? 0)
              },
              {
                left: 'Current Index Value For Loan',
                right: formatPercentage(loanPricingData.swapRate ?? 0)
              },
              {
                left: 'Today’s Indicative Gross Rate',
                right: formatPercentage(
                  (
                    parseFloat(loanPricingData.swapRate ?? 0) +
                    parseFloat(loanPricingData.spreadRate ?? 0)
                  ).toFixed(4)
                )
              },
              {
                left: 'Borrower Spread Lock',
                right: <BooleanChip value={loanPricingData?.borrowerSpreadLock} />
              },
              {
                left: 'Borrower Gross Rate Lock Expiry Date',
                right: getValueOrDefaultDash(loanPricingData.borrowerGrossRateLockExpiryDate)
              },
              {
                left: 'Borrower Gross Rate Lock',
                right: <BooleanChip value={loanPricingData.borrowerGrossRateLock}/>
              },
              {
                left: 'Borrower Spread Lock Expiry Date',
                right: getValueOrDefaultDash(loanPricingData.borrowerSpreadLockExpiryDate)
              },
              {
                left: 'Borrower Rate Lock Extension 1 Start Date',
                right: getValueOrDefaultDash(loanPricingData?.firstPaidBorrowerGrossRateLockStartDate)
              },
              {
                left: 'Borrower Rate Lock Extension 1 Expiry Date',
                right: getValueOrDefaultDash(loanPricingData?.firstPaidBorrowerGrossRateLockExpiryDate)
              },
              {
                left: 'Borrower Rate Lock Extension 2 Start Date',
                right: getValueOrDefaultDash(loanPricingData?.secondPaidBorrowerGrossRateLockStartDate)
              },
              {
                left: 'Borrower Rate Lock Extension 2 Expiry Date',
                right: getValueOrDefaultDash(loanPricingData?.secondPaidBorrowerGrossRateLockExpiryDate)
              },
            ]} />]),
          ...insertIf(allowLoanPricing, [<LoanSummaryExpandableTable
            hasTwoColumns={true}
            data={[
              {
                left: 'Points',
                right:
                {
                  column1: formatPercentage(loanDetails?.rocPointsIn ?? 0),
                  column2: isInternal ? formatPercentage(loanDetails?.loanRetailFees?.retailPoints || 0) : loanDetails?.sfrLenderPointsIn
                }
              },
              {
                left: 'Processing Fee',
                right:
                {
                  column1: formatCurrency(loanDetails?.processingFee ?? 0),
                  column2: isInternal ? formatCurrency(loanDetails?.loanRetailFees?.retailProcessingFee || 0) : formatCurrency(loanDetails?.loanLenderDetails?.processingFee)
                }
              },
              {
                left: 'Admin Fee',
                right:
                {
                  column1: formatCurrency(loanDetails?.rocAdminFee ?? 0),
                  column2: isInternal ? formatCurrency(loanDetails?.loanRetailFees?.retailAdminFee || 0) : formatCurrency(loanDetails?.loanLenderDetails?.adminFee)
                }
              },
              {
                left: 'Underwriting Fee',
                right:
                {
                  column1: formatCurrency(loanDetails?.rocUnderwritingFees ?? 0),
                  column2: isInternal ? formatCurrency(loanDetails?.loanRetailFees?.retailUnderwritingFee || 0) : formatCurrency(loanDetails?.loanLenderDetails?.underwritingFee)
                }
              },
              {
                left: 'Commitment Fee',
                right:
                {
                  column1: formatCurrency(loanDetails?.rocCommitmentFee ?? 0),
                  column2: isInternal ? formatCurrency(loanDetails?.loanRetailFees?.retailCommitmentFee || 0) : formatCurrency(loanDetails?.loanLenderDetails?.commitmentFee)
                }
              },
              {
                left: 'Buydown Fee',
                right:
                {
                  column1: formatCurrency(loanDetails?.rocBuydownFee ?? 0),
                  column2: isInternal ? formatCurrency(loanDetails?.loanRetailFees?.retailBuydownFee || 0) : formatCurrency(loanDetails?.loanLenderDetails?.lenderBuydownFee)
                }
              }
            ]}
            title="Points & Fees"
            column1Title="Rate Buydown"
            column2Title={isInternal ? 'Add-On' : 'Your Fees'}
          />]),
          ...insertIf(showBorrowerLiquidityCard, [<Box>
            <Alert severity="error" style={{ marginBottom: 20 }}>Please note that this is only an estimate based on numbers at the time of underwriting, this may update to different numbers once we have a closing date and the final HUD is approved.</Alert>
            <LoanSummaryExpandableTable
              hasTwoColumns={false}
              title='Borrower Liquidity'
              data={[
                {
                  left: 'Liquidity in Bank Statements',
                  right: formatCurrency(loanDetails.liquidityInBankStatements ?? 0)
                },
                {
                  left: 'Total Liquidity Required',
                  right: formatCurrency(loanDetails.liquidityNeededForClose ?? 0)
                },
              ]} />
          </Box>])
        ]}
      />}
  </>)
})
