import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';

interface section {
  id: string;
  title: string;
}

interface DocumentIndexProps {
  data: any;
  sections: section[];
}

const DocumentIndex: React.FC<DocumentIndexProps> = ({ data, sections }) => {

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + document.documentElement.scrollTop - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      window.history.pushState(null, '', `#${id}`);
    }
  };

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ol style={{ paddingLeft: '20px', margin: 0 }}>
            {sections.slice(0, Math.ceil(sections.length / 2)).map((section) => (
              <li key={section.id} style={{ marginBottom: '4px' }}>
                <a
                  href={`#${section.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection(section.id);
                  }}
                  style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ol>
        </Grid>
        <Grid item xs={6}>
          <ol start={Math.ceil(sections.length / 2) + 1} style={{ paddingLeft: '20px', margin: 0 }}>
            {sections.slice(Math.ceil(sections.length / 2)).map((section) => (
              <li key={section.id} style={{ marginBottom: '4px' }}>
                <a
                  href={`#${section.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection(section.id);
                  }}
                  style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}
                >
                  {section.title}
                </a>
              </li>
            ))}
          </ol>
        </Grid>
      </Grid>
    </Box>
  );
};


export default DocumentIndex;
