import { GlobalStore } from '@roc/feature-app-core';
import { formatPercentage } from '@roc/ui/utils';
import { makeObservable, override, action, observable, flow } from 'mobx';
import { sum } from '../../utils/utils';
import { QuoteTypeStore } from '../quoteTypeStore';
import { LoanEconomicsStore } from './loanEconomicsStore';
import {
  PURCHASE, REFINANCE, getDaysBetween, GENERIC_ERROR_MESSAGE
} from '@roc/feature-utils';
import { OneToolService } from '../../services/oneToolService';

export class StabilizedBridgeLoanEconomicsStore extends LoanEconomicsStore {
  private exitRate = 0;

  constructor(globalStore: GlobalStore, quoteTypeStore: QuoteTypeStore) {
    super(globalStore, quoteTypeStore);
    makeObservable(this, {
      calculateKeyRatios: override,
      mapCalculatedFields: override,
      fetchExitRate: flow,
    });
  }

  *fetchExitRate() {
    try {
      if (!this.exitRate) {
        const response = yield this.oneToolSevice.getExitRate();
        this.exitRate = response.data.data;
      }
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE
      })
    }
  }

  caclulateFields(properties: any[], leverage: any): Record<string, number> {
    //Leverage
    const totalSellerCredit = sum(properties.filter(p => !!p.sellerCredit).map(p => p.sellerCreditAmount));

    const { loanToCost, constructionReserve } = leverage;
    let totalPurchasePrice = 0;
    if (properties?.[0]?.loanPurpose === PURCHASE) {
      totalPurchasePrice = sum(properties.map(p => p.purchasePrice)) - totalSellerCredit;
    } else {
      const seasoningDays = getDaysBetween(new Date(), new Date(properties?.[0]?.purchaseDate));
      if (seasoningDays <= 180) {
        // <= 180 days seasoning
        totalPurchasePrice = sum(properties.map(p => p.purchasePrice)) + sum(properties.map(p => p.constructionRenoSpendToDate || 0));
      } else {
        // > 180 days seasoning
        totalPurchasePrice = sum(properties.map(p => p.currentMarketValue));
      }

    }


    const totalProposedConstructionBudget = sum(
      properties.map(p => p.renovationBudget)
    );
    const totalAfterRepairValue = sum(
      properties.map(p => p.marketValueAfterCompletion)
    );

    const totalMonthlyMarketRent = sum(
      properties.map(p => p.monthlyMarketRent)
    );

    const totalAnnualTaxes = sum(
      properties.map(p => p.annualTaxes)
    );

    const totalAnnualInsurance = sum(
      properties.map(p => p.annualInsurance)
    );

    const totalMarketValue = sum(properties.map(p => p.currentMarketValue));
    const initialAdvance = totalPurchasePrice * (loanToCost / 100);
    const constructionHoldback =
      totalProposedConstructionBudget * (constructionReserve / 100);
    const totalLoanAmount = initialAdvance;
    const afterRepairLTV = (totalLoanAmount / totalAfterRepairValue) * 100;
    const calculatedLTV = (totalLoanAmount / totalMarketValue) * 100;
    const annualExitDebtService = this.exitRate * totalLoanAmount / 100;
    const exitDSCR = (totalMonthlyMarketRent * 12) / (annualExitDebtService + totalAnnualTaxes + totalAnnualInsurance);

    return {
      totalMarketValue,
      calculatedLTV,
      totalPurchasePrice,
      totalProposedConstructionBudget,
      totalAfterRepairValue,
      initialAdvance,
      constructionHoldback,
      totalLoanAmount,
      afterRepairLTV,
      loanToCost,
      exitDSCR
    };
  }


  mapCalculatedFields() {
    const manualExceptionQuoteData = this.manualExceptionQuoteFormStore.calculatedFields;
    const instaLeverageQuote = this.instaLeverageQuoteFormStore.calculatedFields;

    return {
      totalLoanAmount: instaLeverageQuote.totalLoanAmount,
      exceptionTotalLoanAmount: manualExceptionQuoteData.totalLoanAmount,
    }
  }

  calculateKeyRatios() {
    const leverage = this.getLoanEconomics();
    const calculatedFields = this.calculatedFields;

    const ltcPurchasePricePercentage = formatPercentage(leverage.loanToCost);

    return `${ltcPurchasePricePercentage}`;
  }

  validateLeverageAmounts() {
    const errors = [];

    if (!this.enableManualQuote) {
      const maxLTC = this.instaQuoteRanges?.maxLoanToCost >= 0 ? this.instaQuoteRanges?.maxLoanToCost : 0;
      const ltc = this.calculatedFields?.loanToCost || 0;

      const maxLTV = this.instaQuoteRanges?.maxARLTV;
      const ltv = this.calculatedFields?.calculatedLTV || 0;

      const exitDSCR = this.calculatedFields?.exitDSCR;
      const minExitDSCR = this.instaQuoteRanges?.minExitDSCR;

      if (!!exitDSCR && exitDSCR < minExitDSCR) {
        errors.push(`Exit DSCR should not be less than ${minExitDSCR}x.`);
      }
      if (this.quoteTypeStore.showOnlyLTVValues) {
        if (ltc > maxLTV) {
          errors.push(`The loan amount you are requesting is over ${maxLTV}% LTV. Please reduce the LTV so the loan request is ${maxLTV}% LTV or less.`);
        }
      } else {
        if (ltc > maxLTC) {
          errors.push(`The loan amount you are requesting is over ${maxLTC}% LTC. Please reduce the LTC so the loan request is ${maxLTC}% LTC or less.`);
        }

        if (ltv > maxLTV) {
          errors.push(`The loan amount you are requesting is over ${maxLTV}% LTV. Please reduce the LTV so the loan request is ${maxLTV}% LTV or less.`);
        }
      }
    }

    return errors;
  }
}
