import { Box, debounce, Grid, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search, Work, WorkOutline } from '@material-ui/icons';
import { DataGrid, filterProps, SelectMode, TextField } from '@roc/ui';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { FORM_MAX_WIDTH } from '../../../utils/constants';
import { OneToolCard } from '../../common/oneToolCard';

const useStyles = makeStyles(theme => ({
  container: {
    ['& .ag-cell-value, & .ag-selection-checkbox']: {
      alignSelf: 'flex-start',
    },
  },
  button: {
    width: '358px',
    margin: '0 auto',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.2),' +
      '0px 2px 2px 0px rgba(0, 0, 0, 0.14),' +
      '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  },
}));

export enum SelectEntityOption {
  SEARCH_ENTITY = 'SEARCH_ENTITY',
  ADD_ENTITY = 'ADD_ENTITY',
  NO_ENTITY = 'NO_ENTITY',
}

interface CardProps {
  option: SelectEntityOption;
  onSelect: (option: SelectEntityOption) => void;
}

export const SearchExistingEntityCard = observer(() => {
  const classes = useStyles();
  const { quoteTypeStore } = useQuoteTypeStore();
  const { entityStore } = quoteTypeStore;
  const { searchEntityGridStore } = entityStore;

  useEffect(() => {
    searchEntityGridStore.fetchEntities();
  }, []);

  const columns = [
    {
      field: 'companyName',
      headerName: 'Entity Name',
      ...filterProps(),
    },
    {
      field: 'borrowerEntityId',
      headerName: 'Borrower Name',
      cellRenderer: 'borrowerNameCellRenderer',
      ...filterProps(),
    },
    {
      field: 'borrowerEntityId',
      headerName: '% of Ownership',
      cellRenderer: 'ownershipCellRenderer',
      maxWidth: 140,
      ...filterProps(),
    },
  ];

  const debounced = debounce(fn => fn(), 10);

  const frameworkComponents = {
    borrowerNameCellRenderer: params => {
      const borrowers = params.node.data?.borrowers ?? [];
      params.node.setRowHeight(6 + borrowers.length * 40);
      debounced(() => params.api.onRowHeightChanged());
      return (
        <Box>
          {borrowers.map(borrower => (
            <Box>{borrower.borrowerName}</Box>
          ))}
        </Box>
      );
    },
    ownershipCellRenderer: params => {
      const borrowers = params.node.data?.borrowers ?? [];
      return (
        <Box textAlign={'right'}>
          {borrowers.map(borrower => (
            <Box>{borrower.ownership} %</Box>
          ))}
        </Box>
      );
    },
  };

  const onRowSelected = params => {
    if (params.node.isSelected()) {
      const row = params.node.data;
      entityStore.fetchEntityById(row.borrowerEntityId);
    } else {
      entityStore.clearEntityData();
    }
  };

  return (
    <>
      <OneToolCard
        maxWidth={FORM_MAX_WIDTH}
        icon={WorkOutline}
        title={'Search an Existing Entity'}
      >
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <TextField
              testId="search-text"
              value={searchEntityGridStore.searchString}
              fullWidth
              variant="outlined"
              placeholder="Filter by Search Existing Entity, Borrower’s First or Last Name"
              onChange={e =>
                searchEntityGridStore.setSearchString(e.target.value)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              domLayout="autoHeight"
              columns={columns}
              rows={searchEntityGridStore.gridData.data.rows}
              rowData={searchEntityGridStore.gridData.data.rows}
              frameworkComponents={frameworkComponents}
              selectMode={SelectMode.SINGLE}
              onRowSelected={onRowSelected}
              isLoading={searchEntityGridStore.isLoading}
              sortModel={searchEntityGridStore.sortModel}
              filterModel={searchEntityGridStore.filterModel}
              paginationData={searchEntityGridStore.gridData.meta}
              setPageNumber={searchEntityGridStore.setPageNumber}
              rowModelType={'clientSide'}
            />
          </Grid>
        </Grid>
      </OneToolCard>
    </>
  );
});
