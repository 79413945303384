import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { EntityInformationSummaryCard } from '../entityInformation/components/entityInformationSummaryCard';
import { AddBorrowerCard } from './components/addBorrowerCard';
import { BorrowerFormCard } from './components/borrowerFormCard';

interface BorrowerInformationStepProps {
  store: QuoteTypeStore;
}

export const BorrowerInformationStep = observer(
  ({ store }: BorrowerInformationStepProps) => {
    const { borrowersStore, entityStore } = store;
    const { entityFormStore } = entityStore;
    const { borrowerFormStores } = borrowersStore;
    const { hasEntity } = entityFormStore.form.fields;

    return (
      <>
        <Grid container spacing={2} alignItems="center">
          {!!hasEntity.value && (
            <Grid item xs={12}>
              <EntityInformationSummaryCard />
            </Grid>
          )}
          {borrowerFormStores.map(borrowerFormStore => (
            <Grid item xs={12}>
              <BorrowerFormCard borrowerFormStore={borrowerFormStore} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <AddBorrowerCard />
          </Grid>
        </Grid>
      </>
    );
  }
);
