/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ApiResponse, FormStore, GlobalStore } from "@roc/feature-app-core";
import { action, computed, flow, makeObservable, observable, reaction } from "mobx";
import { SubjectPropertyInformationStore } from "./subjectPropertyInformationStore";
import { ValuationFormStore } from "./valuationFormStore";
import { NeighborhoodFormStore } from "./neigborhoodFormStore";
import { InternalValuationReviewService } from "../services/internalValuationReviewService";
import { GENERIC_ERROR_MESSAGE, LoanSubType } from "@roc/feature-utils";
import { ValuationReviewReportInternalService } from "apps/roc-internal-portal/src/app/modules/valuationReview/services/valuationReviewInternalService";
import { convertStringNumberToNumber, formatNumberField, getFullAddress } from "../utils/utils";
import { PropertyCompFormStore } from "./propertyCompFormStore";
import { DocumentService } from "@roc/feature-documents";
import { remainingReno, totalCapex, totalRemainingReno } from "../utils/scopeOfWork";

const form = {
  fields: {

  },
  meta: {
    isValid: false,
    error: null,
  },
};

export class InternalValuationReviewStore extends FormStore {
  globalStore: GlobalStore;
  subjectPropertyInformationStore: SubjectPropertyInformationStore;
  valuationFormStore: ValuationFormStore;
  neighborhoodFormStore: NeighborhoodFormStore;
  propertyCompFormStores: PropertyCompFormStore[];
  loanId: number;
  propertyId: number;
  readOnly: boolean;
  initialData: any;
  reportHistoryRecords: any[];
  loanDocument: any;

  internalValuationReviewService: InternalValuationReviewService;
  valuationReviewInternalService: ValuationReviewReportInternalService;
  private documentService: DocumentService;

  constructor(globalStore: GlobalStore) {
    super(form, globalStore);
    this.globalStore = globalStore;
    this.subjectPropertyInformationStore = new SubjectPropertyInformationStore(globalStore);
    this.valuationFormStore = new ValuationFormStore(globalStore);
    this.neighborhoodFormStore = new NeighborhoodFormStore(globalStore);

    this.internalValuationReviewService = new InternalValuationReviewService();
    this.valuationReviewInternalService = new ValuationReviewReportInternalService();
    this.documentService = new DocumentService();
    this.setDefaults();

    makeObservable(this, {
      loadInternalValuationReviewData: flow,
      loanId: observable,
      propertyId: observable,
      readOnly: observable,
      saveInternalValuationReview: flow,
      downloadAppraisalReport: flow,
      getInternalValuationReviewData: action,
      isDirty: computed,
      loadStore: action,
      onBestComparableChange: action,
      propertyCompFormStores: observable,
      reportHistoryRecords: observable,
      loadReportHistoryRecords: action,
      uploadDocument: flow,
      renovationBudget: computed,
      completedRenovation: computed,
      totalRenovationBudget: computed,
      projectSummary: computed,
      markAsComplete: flow,
      saveLegacyValuationDetails: flow,
      hasScopeOfWork: computed,
    });


    reaction(
      () => ({
        address: this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.address.value,
        city: this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.city.value,
        state: this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.state.value,
        zipCode: this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.zipCode.value,
        beds: convertStringNumberToNumber(this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.beds.value),
        baths: convertStringNumberToNumber(this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.baths.value),
        propertyType: this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.propertyType.value,
        yearBuilt: convertStringNumberToNumber(this.subjectPropertyInformationStore.propertyInformationFormStore.form.fields.yearBuilt.value),
      }),
      (newValue, oldValue) => {
        if (!this.propertyCompFormStores || this.propertyCompFormStores.length === 0) return;

        const subjectPropertyCompFormStore = this.propertyCompFormStores.find(store => store.form.fields.sequence.value === 0);

        if (!subjectPropertyCompFormStore) return;

        Object.entries(newValue).forEach(([key, value]) => {
          subjectPropertyCompFormStore.onFieldChange(key, value);
        });
      }
    );
  }

  *loadInternalValuationReviewData(loanId: number, propertyId: number, readOnly: boolean) {
    try {
      this.loanId = loanId;
      this.propertyId = propertyId;


      const response: ApiResponse = yield this.internalValuationReviewService.getInternalValuationReviewData(loanId, propertyId);
      this.initialData = { ...response.data.data, charts: JSON.parse(response.data.data.charts) };

      this.readOnly = readOnly || this.initialData.readOnly;

      this.loadStore(this.initialData);

      this.loadReportHistoryRecords(true);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  setDefaults() {
    this.reset();
    this.initialData = null;
    this.loanId = null;
    this.propertyId = null;
    this.readOnly = null;
    this.subjectPropertyInformationStore.reset();
    this.valuationFormStore.reset();
    this.neighborhoodFormStore.reset();
    this.propertyCompFormStores = [];
    this.reportHistoryRecords = [];
    this.loanDocument = null;
  }

  loadStore(payload) {
    this.subjectPropertyInformationStore.loadStore(payload);
    this.valuationFormStore.loadForm({ ...payload?.internalValuationReview, borrowerValue: payload?.borrowerValue, appraiser: payload?.appraiser });
    this.neighborhoodFormStore.loadForm({
      ...payload?.internalValuationReview,
      ...payload?.neighborhoodHousingConditions,
    });

    const propertyComps = [...payload.propertyComps];
    this.propertyCompFormStores = [];
    if (propertyComps && propertyComps.length > 0) {
      propertyComps.sort((pc1, pc2) => pc1.sequence - pc2.sequence);
      propertyComps.forEach(propertyComp => {
        const propertyCompFormStore = new PropertyCompFormStore(this.globalStore);
        propertyCompFormStore.loadForm(propertyComp);
        this.propertyCompFormStores.push(propertyCompFormStore);
      })
    }
  }

  *saveInternalValuationReview(disableGlobalLoading = false) {
    try {
      //save internal valuation review
      const payload = this.getInternalValuationReviewData();
      const response = yield this.internalValuationReviewService.saveInternalValuationReview(payload, disableGlobalLoading);
      this.initialData.internalValuationReview = { ...response.data.data };

      //save legacy valuation review: this is temporary
      //done in background
      this.saveLegacyValuationDetails(payload, true);

      //save property comps
      let propertyComps = [];
      const propertyCompsPayload = this.getPropertyCompsData();

      if (propertyCompsPayload) {
        const propertyCompsResponse = yield this.internalValuationReviewService.updatePropertyComps(propertyCompsPayload, disableGlobalLoading);
        propertyComps = propertyCompsResponse.data.data;
      }

      this.loadStore({ ...this.initialData, propertyComps });
      if (!disableGlobalLoading) {
        this.globalStore.notificationStore.showSuccessNotification({
          message: "Record Saved Successfully!",
        });
      }
    } catch (e) {
      console.log(e);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  getInternalValuationReviewData() {
    const propertyInformationValues: any = this.subjectPropertyInformationStore.propertyInformationFormStore.getFlattenedValues();
    const valuationValues: any = this.valuationFormStore.getFlattenedValues();
    const neighborhoodValues: any = this.neighborhoodFormStore.getFlattenedValues();

    const internalValuationReview = {
      ...this.initialData.internalValuationReview,
      ...propertyInformationValues,
      beds: convertStringNumberToNumber(propertyInformationValues.beds),
      baths: convertStringNumberToNumber(propertyInformationValues.baths),
      livingArea: convertStringNumberToNumber(propertyInformationValues.livingArea),
      landArea: convertStringNumberToNumber(propertyInformationValues.landArea),
      parking: convertStringNumberToNumber(propertyInformationValues.parking),
      yearBuilt: convertStringNumberToNumber(propertyInformationValues.yearBuilt),
      asRepairedValue: valuationValues.asRepairedValue,
      asIsValue: valuationValues.asIsValue,
      scopeOfWorkDescription: valuationValues.scopeOfWorkDescription,
      valueReconciliationComments: valuationValues.valueReconciliationComments,
      location: neighborhoodValues.location,
      neighborhoodZoning: neighborhoodValues.neighborhoodZoning,
      highestAndBestUse: neighborhoodValues.highestAndBestUse,
      hasAdverseFactors: neighborhoodValues.hasAdverseFactors,
      adverseFactorsComments: neighborhoodValues.adverseFactorsComments,
    };
    return internalValuationReview;
  }

  getPropertyCompsData() {
    if (!this.propertyCompFormStores || this.propertyCompFormStores.length === 0) {
      return null;
    }
    return this.propertyCompFormStores.map(store => store.getFlattenedValues());
  }

  *downloadAppraisalReport() {
    try {
      yield this.valuationReviewInternalService.downloadAppraisalReport(this.propertyId);
    } catch (error) {
      console.log(error);
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  get isDirty() {
    let propertyCompsDirty = false;
    this.propertyCompFormStores.forEach(store => {
      propertyCompsDirty = propertyCompsDirty || store.isDirty;
    });

    return this.subjectPropertyInformationStore.propertyInformationFormStore.isDirty
      || this.valuationFormStore.isDirty
      || this.neighborhoodFormStore.isDirty
      || propertyCompsDirty;
  }

  onBestComparableChange(propertyCompId: number, value: any) {
    const booleanValue = value === 'true';

    const changedStore = this.propertyCompFormStores.find(store => store.form.fields.propertyCompId.value === propertyCompId);
    const othersStores = this.propertyCompFormStores.filter(store => store.form.fields.propertyCompId.value !== propertyCompId);

    changedStore.onFieldChange("bestComparable", booleanValue);
    othersStores.forEach(store => store.onFieldChange("bestComparable", !booleanValue));
  }

  getPropertyCompsMarkers() {
    const compForms: any[] = this.propertyCompFormStores.map(store => store.getFlattenedValues());

    const markers = [];
    if (compForms && compForms.length > 0) {
      compForms.forEach((propertyComp, index) => {
        let proximity = formatNumberField(propertyComp.proximity, 2, 2);
        if (proximity !== '-') {
          proximity += ' miles';
        }

        markers.push({
          id: propertyComp.propertyCompId,
          mapTitle: index === 0 ? "Subject" : `C${index}`,
          isSubjectProperty: index === 0,
          lat: propertyComp.latitude,
          lng: propertyComp.longitude,
          description: index === 0 ? "Subject Property" : `Comparable Property #${index}`,
          address: getFullAddress(propertyComp),
          proximity: proximity
        })
      })

    }
    return markers;
  }

  loadReportHistoryRecords(disableGlobalLoading = false) {
    if (this.initialData?.loanTaskId) {
      this.documentService.getDocumentHistoryWithUserFullName(this.initialData?.loanTaskId, disableGlobalLoading)
        .then(response => {
          if (response?.data?.data) {
            this.reportHistoryRecords = response.data.data;
          }
        }).catch(err => {
          console.log(err);
          this.globalStore.notificationStore.showErrorNotification({
            message: "There was an error fetching the Report History Records.",
          });
        });
    }
  }

  *uploadDocument(file: File) {
    if (!this.initialData.loanTaskId) {
      return;
    }

    const blob = file.slice(0, file.size, file.type);
    const newFile = new File([blob], file.name, { type: file.type });
    const formData = new FormData();
    formData.append('file', newFile);

    const response = yield this.documentService.uploadInternalValuationReport(
      this.loanId,
      this.propertyId,
      this.initialData.loanTaskId,
      formData,
      true
    );
    this.globalStore.notificationStore.showSuccessNotification({
      message: "Report uploaded correctly.",
    });

    this.loadReportHistoryRecords(true);
    return response;
  }
  get hasScopeOfWork() {
    return !!this.initialData?.scopeOfWork;
  }

  get renovationBudget() {
    if (!this.initialData?.scopeOfWork) {
      return null;
    }
    return remainingReno(this.initialData.scopeOfWork.categoryMap);
  }
  get completedRenovation() {
    if (!this.initialData?.scopeOfWork) {
      return null;
    }
    return totalCapex(this.initialData.scopeOfWork.categoryMap);
  }
  get totalRenovationBudget() {
    if (!this.initialData?.scopeOfWork) {
      return null;
    }
    return totalRemainingReno(this.initialData.scopeOfWork.categoryMap);
  }
  get projectSummary() {
    if (!this.initialData?.scopeOfWork) {
      return null;
    }
    return this.initialData.scopeOfWork.projectDescription;
  }

  *markAsComplete() {
    try {
      if (!this.loanDocument) {
        const loanDocument = yield this.getPropertyLoanDocument(this.loanId, this.propertyId);

        if (!loanDocument) {
          this.globalStore.notificationStore.showErrorNotification({
            message: 'Internal Valuation Report Loan Task not found.',
          });
          return;
        }

        this.loanDocument = loanDocument;
      }

      this.loanDocument.status = "Accepted";
      yield this.documentService.updateDocument(this.loanDocument);
      this.globalStore.notificationStore.showSuccessNotification({
        message: 'Internal Valuation Report marked as Accepted successfully.',
      });
    } catch (err) {
      console.log(err);
      this.globalStore.notificationStore.showErrorNotification({
        message: 'There was an error marking the Internal Valuation Report as Complete.',
      });
    }
  }

  async getPropertyLoanDocument(loanId: number, collateralId: number) {
    try {
      const response = await this.documentService.getPropertyDocument(loanId, "Internal Valuation Report", collateralId);

      if (response?.data?.status === 'OK' && response?.data?.data) {
        return await JSON.parse(response?.data?.data);
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  *saveLegacyValuationDetails(payload, disableGlobalLoading) {
    try {

      const internalValue = [LoanSubType.FIX_AND_FLIP, LoanSubType.FIX_AND_FLIP_PRO, LoanSubType.GROUND_UP].includes(this.initialData.loanSubType) ? payload.asRepairedValue : payload.asIsValue;

      const data = {
        propertyId: this.propertyId,
        loanId: this.loanId,
        comments: payload.valueReconciliationComments,
        internalValue
      }
      yield this.valuationReviewInternalService.saveValuationDetails(data, disableGlobalLoading);
    }
    catch (error) {
      console.log(error);
    }
  }

  showScopeOfWork() {
    if (!this.initialData?.loanSubType) {
      return false;
    }
    return [LoanSubType.FIX_AND_FLIP, LoanSubType.FIX_AND_FLIP_PRO, LoanSubType.GROUND_UP].includes(this.initialData.loanSubType);
  }

}
