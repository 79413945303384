import { Grid, Box } from '@material-ui/core';
import { FileUpload, FileUploadItem, StandardDialog, Button, FileUploadArea } from '@roc/ui';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useTaxCertificateStore } from '../hooks/useTaxCertificateStore';


interface IUploadPaymentZipModalProps {
  open: boolean;
  onClose: () => void;
}


const useStyles = makeStyles(theme => ({
  entityLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  autoComplete: {
    minWidth: '400px'
  },

  editableInput: {
    backgroundColor: 'transparent',
    border: 'solid lightgrey 1px',
    outline: 'none',
    marginBottom: 0,
    width: '100%',
    borderRadius: 8,
    fontFamily: 'Oswald, sans-serif;',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '8px'
  },
}));

export const UploadPaymentZipModal = observer(({ open, onClose }: IUploadPaymentZipModalProps) => {
  const classes = useStyles();
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const { taxCertificateStore } = useTaxCertificateStore();

  const _onUpload = async () => {
    taxCertificateStore.uploadPaymentZip(uploads[0])
    setUploads([]);
    onClose();
  };

  const onRemove = (index: number) => {
    setUploads(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const onRename = (index: number, newName: string) => {
    setUploads(prevItems => {
      const items = [...prevItems];
      items[index].name = newName;
      return items;
    });
  };

  const getContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FileUploadArea
            items={[]}
            setItems={(items) => setUploads(items)}
            multiple={false} />
        </Grid>
        <Grid item xs={12} sm={12}>
          {uploads.map((item, i) => (
            <FileUploadItem
              key={item.file.name}
              name={item.name}
              file={item.file}
              onSaveName={newName => onRename(i, newName)}
              onRemove={() => onRemove(i)}
            />
          ))}
        </Grid>
      </Grid>
    )
  }

  return (
    <StandardDialog
      open={open}
      title={"Upload Payment Files zip"}
      maxWidth="md"
      handleClose={() => {
        onClose();
        setUploads([]);
      }}
      dialogContent={
        getContent()
      }
      dialogActions={(
        <>
          <Box mr={2}>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={() => {
                onClose();
                setUploads([]);
              }}
              testId="cancel"
            >
              CANCEL
            </Button>
            <Button
              disabled={uploads.length <= 0}
              variant="contained"
              color="primary"
              onClick={_onUpload}
              testId="save"
            >
              UPLOAD
            </Button>
          </Box>
        </>
      )}
    />
  );
});