import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, SubmitButton, PDFPreview } from '@roc/ui';
import { Add, Check, Send, Clear } from '@material-ui/icons';
import { AddEditInsuranceQuote } from './addEditInsuranceQuote';
import { useEffect, useState } from 'react';
import { InsuranceQuotesTable } from './insuranceQuotesTable';
import { InsuranceInformationBaseStore, QUOTE_STATUS_APPROVED } from '@roc/feature-insurance-review';
import { Layout } from '@roc/feature-app-core';
import { ElmsureForm } from './requestElmsureForm';
import { ResendInsuranceQuoteForm } from '@roc/feature-documents';
import { InsuranceInformationFinalDocumentSelection } from './insuranceInformationFinalDocumentSelection';
import { InsuranceInformationFinalDocumentsSummary } from './insuranceInformationFinalDocumentsSummary';

export type InsuranceInformationProps = {
  store: InsuranceInformationBaseStore,
  loanId: string,
  loanDocumentId: string,
  showTitle?: boolean,
};

export const InsuranceInformation = observer((props: InsuranceInformationProps) => {
  const { store, loanId, loanDocumentId, showTitle = true } = props;
  const { isSaveInsuranceQuoteActive, quotes, finalChoiceQuotes, propertiesMissingQuotes } = store;
  const [isFinalDocumentSelectionOpen, setFinalDocumentSelectionOpen] = useState(false);

  useEffect(() => {
    store.init(loanId, loanDocumentId);
  }, [store, loanId, loanDocumentId]);

  useEffect(() => {
    store.loadFinalDocuments();
  }, []);

  useEffect(() => {
    store.isApproved = ((store.finalBinder && store.finalInvoice && store.finalFloodCertificate) || (store.finalElmsureBinder, store.finalFloodCertificate, store.finalElmsureInvoice))
      && store.finalChoiceQuotes.every(quote => quote.status === QUOTE_STATUS_APPROVED);

  }, [store.finalBinder,
  store.finalInvoice,
  store.finalChoiceQuotes,
  store.finalElmsureBinder,
  store.finalElmsureInvoice]);


  const handleApproveInsurance = () => {
    const elmsureQuotes = store.finalChoiceQuotes.map(quote => quote?.insuranceAgentName.toLowerCase().includes('elmsure'));
    const otherQuotes = store.finalChoiceQuotes.map(quote => !quote?.insuranceAgentName.toLowerCase().includes('elmsure'));
    if (elmsureQuotes != null && elmsureQuotes.length > 0) {
      store.approveInsurance();
    }
    if (otherQuotes != null && otherQuotes.length > 0) {
      setFinalDocumentSelectionOpen(true);
    }
  }

  return (
    <>
      {isSaveInsuranceQuoteActive ?
        <AddEditInsuranceQuote store={store} showCancelOption={true} />
        : isFinalDocumentSelectionOpen ?
          <InsuranceInformationFinalDocumentSelection store={store} onCancel={() => setFinalDocumentSelectionOpen(false)} onApprove={() => setFinalDocumentSelectionOpen(false)} />
          :
          <div style={{ backgroundColor: '#FFF', width: '100%' }}>
            <Layout>
              <Grid container spacing={2}>
                {showTitle && <Grid item xs={12} style={{ margin: '0 0 30px 0' }}>
                  <Box mb={1}>
                    <Typography variant="h4">Insurance Information</Typography>
                  </Box>
                  <Divider />
                </Grid>}
                {propertiesMissingQuotes.length > 0 &&
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      <AlertTitle><b>Insurance Information is missing for below properties</b></AlertTitle>
                      <ul>
                        {propertiesMissingQuotes.map(propertyMissingQuote =>
                          <li>{propertyMissingQuote}</li>
                        )}
                      </ul>
                    </Alert>
                  </Grid>
                }
                <Grid item xs={12}>
                  <Typography variant="h6">
                    To add details about the <b>insurance</b> (or) <b>quote</b> you received elsewhere, click the button below
                  </Typography>
                </Grid>
                <Grid container spacing={1} item xs={12}>
                  <Grid item>
                    <Button
                      testId="addQuote"
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      onClick={() => {
                        store.handleOpenSave();
                      }}
                    >
                      Add Insurance / Quote
                    </Button>
                  </Grid>
                  <Grid item>
                    {store.quotes.some(quote => quote.quoteShowId.includes('Elmsure')) ?
                      <SubmitButton
                        variant="contained"
                        color="primary"
                        startIcon={<Send />}
                        testId="resendElmsureQuote"
                        onClick={() => store.resendElmsureQuote()}
                        submitDialogProps={{
                          title: `Confirm borrower email address to resend the quote`,
                          body: <ResendInsuranceQuoteForm store={store.resendElmsureQuoteStore} />,
                          okButtonText: 'Submit',
                          cancelButtonText: 'Close',
                        }}
                      >
                        RESEND ELMSURE QUOTE
                      </SubmitButton>
                      : <SubmitButton
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        testId="requestElmsureQuote"
                        onClick={() => store.requestQuoteFromElmsure()}
                        submitDialogProps={{
                          submitValidation: () => store.validateElmsureQuoteSubmission(),
                          title: `Request Elmsure Quote`,
                          body: <ElmsureForm store={store} />,
                          okButtonText: 'Submit',
                          cancelButtonText: 'Close',
                        }}
                      >
                        REQUEST ELMSURE QUOTE
                      </SubmitButton>}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InsuranceQuotesTable
                    title={'Insurance / Quotes'}
                    quotes={quotes}
                    emptyMessage={'No insurance information have been added yet'}
                    store={store}
                    allowActions={true}
                  />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <InsuranceQuotesTable
                    title={'Insurance / Quotes (Selected as Final Choice)'}
                    quotes={finalChoiceQuotes}
                    emptyMessage={'No insurance / quote have been selected as final choice yet'}
                    store={store}
                    allowActions={true}
                  />
                </Grid>
                {store.isApproved && (
                  <Grid item xs={12}>
                    <InsuranceInformationFinalDocumentsSummary store={store} />
                  </Grid>
                )}
                <Grid container spacing={1} item xs={12} direction='row-reverse'>
                  {store.insuranceQuoteFormStore.isInternal && !store.isApproved &&
                    <Grid item>
                      <Button
                        testId="approveInsurance"
                        variant="contained"
                        color="primary"
                        startIcon={<Check />}
                        onClick={() => handleApproveInsurance()}>
                        Approve Insurance
                      </Button>
                    </Grid>}
                  {store.insuranceQuoteFormStore.isInternal && store.isApproved &&
                    <Grid item>
                      <Button
                        testId="revertInsurance"
                        variant="outlined"
                        color="primary"
                        onClick={() => store.revertInsurance()}
                        startIcon={<Clear />}>
                        Revert Insurance
                      </Button>
                    </Grid>}
                </Grid>
              </Grid >
            </Layout>
          </div>
      }
      <PDFPreview
        open={store.isPdfPreviewModalOpen}
        title={store.pdfTitle}
        data={store.pdfData}
        headers={{}}
        fileName={store.pdfTitle}
        onClose={() => {
          store.pdfTitle = null;
          store.pdfData = null;
          store.isPdfPreviewModalOpen = false;
        }}
      />
    </>
  );
});
