import { PermIdentityOutlined, ExpandMore } from '@material-ui/icons';
import { ItemDisplay } from '../../../components/itemDisplay';
import { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { formatCurrency, formatPercentage } from '@roc/ui/utils';
import {
  IDVerificationStatus,
  SelectEntityOption,
  verifiedInvestmentPropertiesOptions,
} from '../../../utils/constants';
import {
  DetailsTable,
  DetailsTableField,
} from '../../../components/detailsTable';
import { ViewControls } from '../../../components/viewControls';
import { CreditBackgroundCard } from './creditBackgroundCard';
import { IdCard } from './idCard';
import {
  getValueOrDefaultDash,
  getBooleanDisplayValue,
} from '../../../utils/utils';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { BorrowerFormStore } from '../../../stores/borrowers/borrowerFormStore';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { isEmptyString, isNumber } from '@roc/feature-utils';

interface BorrowerInformationAccordionProps {
  store: BorrowerFormStore;
  onDeleteClick: (borrowerKey: string) => void;
  removeButtonDisabled?: boolean;
  showActions?: boolean;
  onEdit?: () => void;
  expanded?: boolean;
  onExpand: (expanded: boolean) => void;
}

export const BorrowerInformationAccordion = observer(
  ({
    store,
    onDeleteClick,
    onEdit,
    showActions = true,
    removeButtonDisabled = true,
    expanded,
    onExpand,
  }: BorrowerInformationAccordionProps) => {
    const borrower = store.getFormValues();

    const emailAddress = getValueOrDefaultDash(borrower.emailAddress);
    const borrowerDetails: DetailsTableField[] = [
      { label: 'Email', value: emailAddress, colSpan: 2 },
    ];

    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => onExpand(expanded)}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore fontSize="large" />}
          aria-controls={`borrower-information-accordion-${store.key}`}
        >
          <BorrowerInformationAccordionSummary borrowerFormStore={store} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginLeft: '58px' }}
          >
            <Grid item xs={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <CreditBackgroundCard status={borrower.creditStatus} />
                </Grid>
                <Grid item>
                  <IdCard
                    hasIdentification={
                      store.idVerificationStatus ===
                        IDVerificationStatus.ID_ON_FILE ||
                      borrower.hasIdentification
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {showActions && (
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ViewControls
                    onDeleteClick={() => onDeleteClick(store.key)}
                    onEditClick={() =>
                      onEdit ? onEdit() : store.setEditMode(true)
                    }
                    removeButtonDisabled={removeButtonDisabled}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <DetailsTable fields={borrowerDetails} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export const BorrowerInformationAccordionSummary = observer(
  ({ borrowerFormStore }) => {
    const { quoteTypeStore } = useQuoteTypeStore();
    const { borrowersStore, entityStore } = quoteTypeStore;
    const borrower = borrowerFormStore.getFormValues();
    let fullName = `${borrower.firstName} ${borrower.lastName}`.trim();
    if (fullName === '') {
      fullName = '-';
    }
    const ficoScore = borrower.ficoScore ? borrower.ficoScore : 0;
    const numberOfVerifiedInvestmentProperties =
      borrower.numberOfVerifiedInvestmentProperties;

    let experienceTier = verifiedInvestmentPropertiesOptions.findIndex(
      item => item.value === numberOfVerifiedInvestmentProperties
    );
    if (experienceTier >= 0) {
      experienceTier++;
    } else {
      experienceTier = 0;
    }
    const personalGuarantor = getBooleanDisplayValue(
      borrower.personalGuarantor
    );
    const showOwnershipPct = borrowersStore.showOwnershipPercentage;
    const percentageOfOwnership = isNumber(borrower.percentageOfOwnership)
      ? formatPercentage(borrower.percentageOfOwnership)
      : '-';

    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={5}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            <Grid item alignItems="center" style={{ height: '100%' }}>
              <RoundedBackgroundIcon
                Icon={PermIdentityOutlined}
                iconColor="#6D6D6D"
              />
            </Grid>
            <Grid item style={{ maxWidth: '100%' }}>
              <ItemDisplay
                label="Borrower Name"
                value={fullName}
                alignItems="flex-start"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <ItemDisplay label="Estimated FICO Score" value={ficoScore} />
            </Grid>
            <Grid item>
              <ItemDisplay
                label="Experience Tier"
                value={experienceTier.toString()}
              />
            </Grid>
            <Grid item>
              <ItemDisplay
                label="Personal Guarantor"
                value={personalGuarantor}
              />
            </Grid>
            {showOwnershipPct && (
              <Grid item>
                <ItemDisplay label="Owner %" value={percentageOfOwnership} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
