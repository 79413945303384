import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import {
  citizenshipStatusOptions,
  estimatedGUExperienceOptions,
  estimatedFICOOptions,
  isNotBlank,
  LoanType
} from '@roc/feature-utils';
import {
  DateField,
  FieldLabel,
  MultiSelectField,
  RadioField,
  SelectField,
  Slider, TextField,
  trueFalseOptions,
  yesNoOptions,
  CurrencyField,
  NumberFormat,
  FileUploadArea,
  FileUpload,
  InfoTooltip
} from '@roc/ui';
import { format, isValid } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { CreditReportExampleDialog } from 'libs/feature-borrower-review/src/components/utils/creditReportExampleDialog';
import { Borrower } from '@roc/feature-types';
import { CreditReportExampleImage } from 'libs/feature-borrower-review/src/components/utils/creditReportExample';

const labels: { [index: string]: string } = {
  0: 'Newbie',
  1: 'Newbie+',
  2: 'Average Investor',
  3: 'Average Investor+',
  4: 'Seasoned Investor',
  5: 'Seasoned Investor+',
};

const percentageMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const experienceMarks = [
  {
    value: 1,
    label: 'Newbie',
  },
  {
    value: 5,
    label: 'Seasoned investor',
  },
];

const ficoMarks = [
  {
    value: 600,
    label: '600',
  },
  {
    value: 800,
    label: '800',
  },
];

const borrowerExpValues = [
  { label: '0', value: '0' },
  { label: '1-2', value: '1-2' },
  { label: '3-4', value: '3-4' },
  { label: '5-9', value: '5-9' },
  { label: '10-49', value: '10-49' },
  { label: '50+', value: '50+' },
];

export const transactionRoles = [
  { label: 'Passive financial sponsor', value: 'Passive financial sponsor' },
  { label: 'Project manager', value: 'Project manager' },
  { label: 'Credit guarantor', value: 'Credit guarantor' },
  { label: 'Property disposition', value: 'Property disposition' },
  { label: 'Financial manager', value: 'Financial manager' },
  {
    label: 'Licensed Real Estate agent/broker',
    value: 'Licensed Real Estate agent/broker',
  },
  { label: 'Licensed contractor', value: 'Licensed contractor' },
  { label: 'Licensed plumber', value: 'Licensed plumber' },
  { label: 'Licensed electrician', value: 'Licensed electrician' },
  {
    label: 'Licensed architect/engineer',
    value: 'Licensed architect/engineer',
  },
  { label: 'Licensed appraiser', value: 'Licensed appraiser' },
  { label: 'Attorney', value: 'Attorney' },
];

export const transactionRolesTerm = [
  { label: 'Personal guarantor', value: 'Personal guarantor' },
  { label: 'Passive financial sponsor', value: 'Passive financial sponsor' },
  { label: 'Property managment', value: 'Property managment' },
];

export const creditBgOptions = [
  { label: 'Send the borrower Authorization Form to run a soft credit pull & background report.', value: true },
  { label: 'I already have the borrower’s information', value: false },
];

export const backgroundOptions = [
  { label: 'Send the borrower Authorization Form to run a background report.', value: true },
  { label: 'I already have the borrower’s information', value: false },
];

const useSliderStyles = makeStyles(theme =>
  createStyles({
    slider: {
      marginTop: '2.5em',
      marginLeft: '2em',
      width: '85%',
    },
    helperText: {
      marginTop: theme.spacing(2),
    },
  })
);

const useTextStyles = makeStyles(theme =>
  createStyles({
    paragraph: {
      marginLeft: '2em',
    },
    textItalic: {
      fontStyle: 'italic',
    },
  })
);

const useRadioStyles = makeStyles(theme =>
  createStyles({
    error: {
      fontWeight: 'bold',
      color: theme.palette.error.dark,
      '& .MuiSvgIcon-root': {
        color: theme.palette.error.dark,
      },
    },
  })
);

const StyledRating = withStyles(theme =>
  createStyles({
    iconFilled: {
      color: theme.palette.primary.main,
    },
  })
)(Rating);

export const FirstName = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;

    return (
      <TextField
        testId="firstName"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label="First Name"
        value={formFields.firstName.value}
        onChange={e => store.onFieldChange('firstName', e.target.value)}
        error={isNotBlank(formFields.firstName.error)}
        helperText={formFields.firstName.error}
        fullWidth
        required
      />
    );
  }
);

export const LastName = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;

    return (
      <TextField
        testId="lastName"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label="Last Name"
        value={formFields.lastName.value}
        onChange={e => store.onFieldChange('lastName', e.target.value)}
        error={isNotBlank(formFields.lastName.error)}
        helperText={formFields.lastName.error}
        fullWidth
        required
      />
    );
  }
);

export const CitizenshipStatus = observer(
  ({ store, disabled, onChange }: { store: any; disabled?: boolean, onChange?: (field, value) => void }) => {
    const formFields = store.form.fields;

    const handleChange = (value) => {
      store.onFieldChange('citizenshipStatus', value)
      if (onChange) onChange('citizenshipStatus', value);
    }

    return (
      <SelectField
        disabled={disabled}
        standaloneLabel
        label={"Borrower's Citizenship Status"}
        name={'citizenshipStatus'}
        value={formFields.citizenshipStatus.value}
        options={citizenshipStatusOptions}
        onChange={handleChange}
        error={isNotBlank(formFields.citizenshipStatus.error)}
        errorText={formFields.citizenshipStatus.error}
        variant="outlined"
        fullWidth
        testId="citizenshipStatus"
      />
    );
  }
);

export const Liquidity = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const formFields = store.form.fields;

    return (
      <CurrencyField
        testId="liquidity"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label="Liquidity"
        value={formFields.liquidity.value}
        onChange={(name, value) =>
          store.onFieldChange('liquidity', Number(value))
        }
        error={isNotBlank(formFields.liquidity.error)}
        fullWidth
        required
      />
    );
  }
);

export const Experience = observer(
  ({ store, disabled }: { store: any; disabled?: boolean }) => {
    const classes = useSliderStyles();
    const formFields = store.form.fields;
    return (
      <>
        <FieldLabel>
          Please rank the borrower's experience as a real state investor
        </FieldLabel>
        <Slider
          testId="experienceSlider"
          defaultValue={1}
          min={1}
          max={5}
          step={1}
          marks={[
            {
              value: 1,
              label: 'Newbie',
            },
            {
              value: 5,
              label: 'Seasoned investor',
            },
          ]}
          valueLabelDisplay="on"
          className={classes.slider}
          value={formFields.experience.value}
          onChangeCommitted={(event, newValue) =>
            store.onFieldChange('experience', newValue)
          }
          disabled={disabled}
        />
      </>
    );
  }
);

export const RoleInTransaction = observer(
  ({ store, options = transactionRoles, loanType, ...rest }) => {
    const formFields = store.form.fields;
    const selectedValues = toJS(formFields.roleInTransaction.value);

    if (loanType === LoanType.RESIDENTIAL_TERM) {
      options = transactionRolesTerm;
    }
    return (
      <MultiSelectField
        standaloneLabel
        label={'Role in this transaction'}
        name={'roleInTransaction'}
        options={options}
        getOptionSelected={(option, selectedValue) => {
          return option.value == selectedValue.value;
        }}
        value={selectedValues ? selectedValues : []}
        onChange={(e, value) => store.onFieldChange('roleInTransaction', value)}
        renderInput={params => (
          <TextField
            testId="roleInTransaction"
            {...params}
            variant="outlined"
          />
        )}
        fullWidth
        required
        testId="roleInTransaction"
        {...rest}
      />
    );
  }
);

export const PersonalGuarantor = observer(({
  store,
  label = 'Will this borrower be personally guaranteeing this loan?',
  ...rest
}) => {
  const classes = useRadioStyles();
  const formFields = store.form.fields;
  const error = formFields.personalGuarantor.error;

  return (
    <RadioField
      key={formFields.borrowerId.value}
      standaloneLabel
      label={label}
      value={formFields.personalGuarantor.value}
      name={'personalGuarantor'}
      options={trueFalseOptions}
      onChange={value =>
        store.onFieldChange('personalGuarantor', value === 'true')
      }
      fullWidth
      row
      className={error ? classes.error : undefined}
      testId="personalGuarantor"
      error={!!error}
      errorText={error}
      {...rest}
    />
  );
});

export const PercentOwnership = observer(({ disableEdit, store }) => {
  const formFields = store.form.fields;
  const classes = useSliderStyles();
  return (
    <>
      <FieldLabel>
        What percentage of the borrowing entity does this borrower own?
      </FieldLabel>
      <Slider
        testId="percentOwnershipSlider"
        disabled={disableEdit}
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) =>
          store.onFieldChange('pctOwnership', value)
        }
        className={classes.slider}
        value={formFields.pctOwnership.value}
        valueLabelDisplay="on"
        valueLabelFormat={value => (
          <div>{formFields.pctOwnership.value}%</div>
        )}
        marks={percentageMarks}
        step={1}
        min={0}
        max={100}
      />
    </>
  );
});

export const EmailAddressDuplicated = observer(
  ({
    store,
    disabled,
    onChanged = null,
  }: {
    store: any;
    disabled?: boolean;
    onChanged?: (name, value) => void;
  }) => {
    const formFields = store.form.fields;

    const handleChange = e => {
      store.onFieldChange('emailAddress', e.target.value);
      if (onChanged) onChanged('emailAddress', e.target.value);
    };

    return (
      <TextField
        testId="email"
        disabled={disabled}
        variant="outlined"
        standaloneLabel
        label="Email Address"
        value={formFields.emailAddress.value}
        onChange={handleChange}
        error={
          isNotBlank(formFields.emailAddress.error) ||
          isNotBlank(formFields.emailAddressDuplicated.error)
        }
        helperText={
          formFields.emailAddress.error ||
          formFields.emailAddressDuplicated.error
        }
        fullWidth
        required
      />
    );
  }
);

export const QualifyingFicoScore = observer(({ store, onChange = null, label = 'Qualifying FICO Score', ...rest }) => {
  const { tooltip, ...restProps } = rest;
  const formFields = store.form.fields;
  const classes = useSliderStyles();

  const handleChange = value => {
    store.onFieldChange('ficoProvidedAtOrigination', value);
    if (onChange) onChange('ficoProvidedAtOrigination', value);
  };

  return (
    <FormControl fullWidth error={formFields.ficoProvidedAtOrigination.error}>
      <FieldLabel tooltip={tooltip}>{label}</FieldLabel>
      <Slider
        testId="ficoProvidedAtOriginationSlider"
        disabled={false}
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) => handleChange(value)}
        className={classes.slider}
        value={formFields.ficoProvidedAtOrigination.value}
        valueLabelDisplay="on"
        marks={ficoMarks}
        step={10}
        min={600}
        max={800}
        {...restProps}
      />
      {formFields.ficoProvidedAtOrigination.error && (
        <FormHelperText className={classes.helperText}>
          {formFields.ficoProvidedAtOrigination.error}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export const QualifyingFicoScoreDropdown = observer(({ store, onChange = null, label = 'Qualifying FICO Score', ...rest }) => {
  const { tooltip, disabled, ...restProps } = rest;
  const formFields = store.form.fields;

  const handleChange = value => {
    store.onFieldChange('ficoProvidedAtOrigination', value);
    if (onChange) onChange('ficoProvidedAtOrigination', value);
  };

  const getValue = () => {
    const ficoFormValue = formFields.ficoProvidedAtOrigination.value;
    let ficoValue;

    if (ficoFormValue === '') {
      ficoValue = '';
    } else {
      ficoValue = estimatedFICOOptions[estimatedFICOOptions.length - 1].value;

      estimatedFICOOptions.some(e => {
        if (e.value !== '') {
          if (ficoFormValue >= e.value) {
            ficoValue = e.value;
            return true;
          }
        }
        return false;
      });
    }
    return ficoValue;
  };

  return (
    <SelectField
      disabled={disabled}
      standaloneLabel
      label={label}
      name={'ficoProvidedAtOrigination'}
      value={getValue().toString()}
      options={estimatedFICOOptions}
      onChange={handleChange}
      error={isNotBlank(formFields.ficoProvidedAtOrigination.error)}
      errorText={formFields.ficoProvidedAtOrigination.error}
      variant="outlined"
      fullWidth
      testId="ficoProvidedAtOrigination"
    />
  );
});

export const ExperienceLevel = observer(({ store, onChange = null, ...rest }) => {
  const formFields = store.form.fields;

  const handleChange = (value) => {
    store.onFieldChange('borrowerExp', value)
    if (onChange) onChange('borrowerExp', value);
  }

  return (
    <>
      <SelectField
        disabled={false}
        standaloneLabel
        label={'Borrower’s Experience Level'}
        name={'borrowerExp'}
        value={formFields.borrowerExp.value}
        options={borrowerExpValues}
        onChange={handleChange}
        error={isNotBlank(formFields.borrowerExp.error)}
        errorText={formFields.borrowerExp.error}
        variant="outlined"
        fullWidth
        testId="borrowerExp"
        {...rest}
      />
    </>
  );
});

export const Bankruptcy = observer(({ store, onChange = null, fieldName = 'bankruptcy', ...rest }) => {
  const formFields = store.form.fields;

  const handleChange = (value) => {
    store.onFieldChange(fieldName, value);
    if (onChange) onChange(fieldName, value);
  }

  return (
    <>
      <RadioField
        disabled={false}
        key={formFields.borrowerId.value}
        standaloneLabel
        label="Has The Borrower Previously Filed For Bankruptcy?"
        value={formFields[fieldName].value}
        name={fieldName}
        options={yesNoOptions}
        onChange={handleChange}
        fullWidth
        row
        testId={fieldName}
        {...rest}
      />
    </>
  );
});

export const BankruptcyDate = observer(({ store, onChange = null, ...rest }) => {
  const formFields = store.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('bankruptcyDate', formattedDate);
    if (onChange) onChange('bankruptcyDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="bankruptcyDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Date of Bankruptcy Filed"
      value={formFields.bankruptcyDate.value}
      onChange={date => manageDate(date)}
      error={isNotBlank(formFields.bankruptcyDate.error)}
      errorText={formFields.bankruptcyDate.error}
      disableFuture={true}
      fullWidth
      required
      {...rest}
    />
  );
});

export const Foreclosure = observer(({ store, onChange = null, fieldName = 'foreclosure', ...rest }) => {
  const formFields = store.form.fields;

  const handleChange = (value) => {
    store.onFieldChange(fieldName, value);
    if (onChange) onChange(fieldName, value);
  }

  return (
    <>
      <RadioField
        disabled={false}
        key={formFields.foreclosure.value}
        standaloneLabel
        label="Has The Borrower Previously Had A Foreclosure, Deed-in Lieu or short sale"
        value={formFields[fieldName].value}
        name={fieldName}
        options={yesNoOptions}
        onChange={handleChange}
        fullWidth
        row
        testId={fieldName}
        {...rest}
      />
    </>
  );
});

export const ForeclosureDate = observer(({ store, onChange = null, ...rest }) => {
  const formFields = store.form.fields;
  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('foreclosureDate', formattedDate);
    if (onChange) onChange('foreclosureDate', formattedDate);
  };

  return (
    <DateField
      inputVariant="outlined"
      testId="foreclosureDate"
      format="MM/dd/yyyy"
      standaloneLabel
      label="Date of Foreclosure, Deed-In Lieu or Short Sale"
      value={formFields.foreclosureDate.value}
      onChange={date => manageDate(date)}
      error={isNotBlank(formFields.foreclosureDate.error)}
      errorText={formFields.foreclosureDate.error}
      disableFuture={true}
      fullWidth
      required
      {...rest}
    />
  );
});

export const AuthorizedSignatory = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <>
      <RadioField
        key={formFields.borrowerId.value}
        standaloneLabel
        label="Is the borrower authorized signatory?"
        value={formFields.authSignatory.value}
        name={'authSignatory'}
        options={trueFalseOptions}
        onChange={value =>
          store.onFieldChange('authSignatory', value === 'true')
        }
        fullWidth
        row
        testId="authSignatory"
        {...rest}
      />
    </>
  );
});

export const BkFCwithinFourYears = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <>
      <RadioField
        key={formFields.borrowerId.value}
        standaloneLabel
        label="Has the borrower received/declared bankruptcy or foreclosure within the last 4 years?"
        value={formFields.bankruptcyOrForeclosureProvidedAtOrigination.value}
        name={'bankruptcyOrForeclosureProvidedAtOrigination'}
        options={trueFalseOptions}
        onChange={value =>
          store.onFieldChange('bankruptcyOrForeclosureProvidedAtOrigination', value === 'true')
        }
        fullWidth
        row
        testId="bankruptcyOrForeclosureProvidedAtOrigination"
        {...rest}
      />
    </>
  );
});

export const CollectCreditReport = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;
  return (
    <>
      <RadioField
        key={formFields.borrowerId.value}
        standaloneLabel
        label="Can we collect the borrower's credit report?"
        value={formFields.creditBackgroundCheck.value}
        name={'creditBackgroundCheck'}
        options={trueFalseOptions}
        onChange={value =>
          store.onFieldChange('creditBackgroundCheck', value === 'true')
        }
        fullWidth
        row
        testId="creditBackgroundCheck"
        {...rest}
      />
    </>
  );
});

export const CollectCreditReportWithMessage = observer(({
  store,
  hideDetails = false,
  label = `We will need to collect the borrower's credit.Would you like us to get it for you(free) ? `,
  ...rest
}) => {
  const formFields = store.form.fields;
  const classes = useTextStyles();
  const restrictCustomCreditReport = store.globalStore.lenderInfo.restrictCustomCreditReport;

  useEffect(() => {
    if (restrictCustomCreditReport) {
      store.onFieldChange('creditBackgroundCheck', restrictCustomCreditReport);
    }
  }, [])

  return (
    <>
      <RadioField
        key={formFields.borrowerId.value}
        standaloneLabel
        label={label}
        value={formFields.creditBackgroundCheck.value}
        name={'creditBackgroundCheck'}
        options={trueFalseOptions}
        onChange={value =>
          store.onFieldChange('creditBackgroundCheck', value === 'true')
        }
        fullWidth
        row
        testId="creditBackgroundCheck"
        disabled={restrictCustomCreditReport}
      />
      {!hideDetails && formFields.creditBackgroundCheck.value === trueFalseOptions[1].value && (
        <Box className={classes.paragraph} mt={2} px={3}>
          <Typography variant="body1">
            If you do not use the capital provider's free credit and background
            checking service you will need to provide the capital provider with
            the borrower's:
          </Typography>
          <Typography variant="body1">
            <List>
              <ul>
                <ListItem>
                  <li>ID</li>
                </ListItem>
                <ListItem>
                  <li>Personal Financial Statement</li>
                </ListItem>
                <ListItem>
                  <li>
                    Tri Merge Verifiable Credit Report (all 3 credit bureaus)
                  </li>
                </ListItem>
                <ListItem>
                  <li>
                    Background Report from TLO, LexisNexis, Clear, or Checkr
                  </li>
                </ListItem>
              </ul>
            </List>
          </Typography>
          <Typography variant="body1">
            This will extend timelines in approving the loan.
          </Typography>
        </Box>
      )}
    </>
  );
});

export const BorrowerCreditReportFields = observer(({ store, borrower }: { store: any, borrower: Borrower }) => {
  const formFields = store.form.fields;
  const theme = useTheme();
  const [openExampleModal, setOpenExampleModal] = useState(false);

  const manageDate = date => {
    let formattedDate = null;
    if (isValid(date)) {
      formattedDate = format(date, 'MM/dd/yyyy');
    }
    store.onFieldChange('dateOfBirth', formattedDate);
  };

  const closeExampleModal = () => {
    setOpenExampleModal(false);
  }
  const openCreditExampleModal = () => {
    setOpenExampleModal(true);
  }

  return (
    <>
      <CreditReportExampleDialog open={openExampleModal} onClose={closeExampleModal} />
      {!borrower?.hasDob && (


        <Box py={1}>
          <DateField
            inputVariant="outlined"
            testId="dateOfBirth"
            format="MM/dd/yyyy"
            standaloneLabel
            label="Date of Birth"
            value={formFields.dateOfBirth?.value || null}
            onChange={date => manageDate(date)}
            error={isNotBlank(formFields.dateOfBirth?.error)}
            errorText={formFields.dateOfBirth?.error}
            disableFuture={true}
            fullWidth
            required
          />
        </Box>
      )}
      {!borrower?.hasSsn && (
        <Box py={1}>
          <FieldLabel>
            Social Security Number *
          </FieldLabel>
          <NumberFormat
            customInput={TextField}
            format="###-##-####"
            mask="_"
            required={true}
            variant="outlined"
            testId="ssn"
            value={formFields.ssn?.value}
            onChange={(e) => {
              store.onFieldChange('ssn', e.target.value);
            }}
            style={{ width: '100%' }}
          />
        </Box>
      )}
      <Box py={1}>
        <Box display="flex" alignItems="center" >
          <FieldLabel>
            File Number
          </FieldLabel>
          <Tooltip title='Open Credit Report Example'>
            <span
              style={{ cursor: 'pointer', marginLeft: '8px' }}
              onClick={openCreditExampleModal}
            >
              <HelpIcon style={{ color: theme.palette.primary.main }} fontSize="small" />
            </span>
          </Tooltip>
        </Box>
        <TextField
          variant="outlined"
          label=""
          value={formFields.fileId?.value}
          onChange={(e) => {
            store.onFieldChange('fileId', e.target.value);
          }}
          fullWidth
          margin="normal"
          testId="fileNumber"
        />
      </Box>
      <Box>
        <Typography
          component="span"
          variant='subtitle2'
          style={{ display: 'inline', padding: '0px', margin: '0px' }}
          color="textSecondary"
        >
          Please upload a Credit Report (Xactus Report recommended), and enter the File #(as suggested in the
          {<span
            style={{ cursor: 'pointer', color: theme.palette.primary.main }}
            onClick={openCreditExampleModal}> image</span>}). Uploading a Xactus Report will speed up your file's processing time.
        </Typography>
      </Box>
      <Box>
        <FileUploadArea
          items={store.uploads}
          setItems={(items) => store.setUploads(items)}
          multiple={false} />
      </Box>
    </>
  );
});

export const OwnershipNotKnown = observer(({ store, ...rest }) => {
  const formFields = store.form.fields;

  return (
    <>
      <Checkbox
        checked={formFields.ownershipNotKnown.value}
        onChange={event => {
          store.onFieldChange('ownershipNotKnown', event.target.checked);
          if (event.target.checked) {
            store.onFieldChange('pctOwnership', 0);
          }
        }}
        size="small"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      Not yet known
    </>
  );
});

export const BorrowerExperienceRealState = observer(({ store, disabledEdit = false, ...rest }) => {
  const formFields = store.form.fields;
  const classes = useSliderStyles();

  return (
    <>
      <FieldLabel>
        Please rank the borrower's experience as a real state investor
      </FieldLabel>
      <Slider
        testId="experienceSlider"
        key={formFields.borrowerId.value}
        onChangeCommitted={(event, value) =>
          store.onFieldChange('experience', value)
        }
        className={classes.slider}
        value={formFields.experience.value}
        valueLabelDisplay="on"
        valueLabelFormat={value => <div>{formFields.experience.value}</div>}
        marks={experienceMarks}
        step={1}
        min={1}
        max={5}
        disabled={disabledEdit}
      />
    </>
  );
});


export const BorrowerEstimatedExperience = observer(({
  store,
  options = estimatedGUExperienceOptions,
  ...rest
}) => {
  const formFields = store.form.fields;

  const handleChange = value => {
    store.onFieldChange('experienceProvidedAtOrigination', value);
  };

  return (
    <SelectField
      disabled={rest.disabled}
      standaloneLabel
      label={'Estimated Experience*'}
      name={'experienceProvidedAtOrigination'}
      value={formFields?.experienceProvidedAtOrigination?.value}
      options={options}
      onChange={handleChange}
      error={isNotBlank(formFields?.experienceProvidedAtOrigination?.error)}
      errorText={formFields?.experienceProvidedAtOrigination?.error}
      variant="outlined"
      fullWidth
      testId="experienceProvidedAtOrigination"
    />
  );
});

export const CollectCreditReportWithMessageWithCustomOptions = observer(({
  store,
  hideDetails = false,
  label = `We will need to collect the borrower's credit.Would you like us to get it for you(free) ? `,
  isInternal = false,
  disabled = false,
  handleChange = undefined,
  fullWidth = true,
  row = true,
  ...rest
}) => {
  const formFields = store.form.fields;
  const classes = useTextStyles();
  const restrictCustomCreditReport = !isInternal ? store.globalStore.lenderInfo.restrictCustomCreditReport : false;

  useEffect(() => {
    if (restrictCustomCreditReport) {
      store.onFieldChange('creditBackgroundCheck', restrictCustomCreditReport);
    }
    if (store.globalStore?.lenderInfo?.showOnlyCafOption) {
      store.onFieldChange('creditBackgroundCheck', true);
    }
  }, [])

  return (
    <>
      {!store.globalStore?.lenderInfo?.showOnlyCafOption &&
        <RadioField
          key={formFields.borrowerId.value}
          standaloneLabel
          label={label}
          value={formFields.creditBackgroundCheck.value}
          name={'creditBackgroundCheck'}
          options={formFields.personalGuarantor.value ? creditBgOptions : backgroundOptions}
          onChange={value => {
            store.onFieldChange('creditBackgroundCheck', value === 'true');
            if (handleChange) {
              handleChange();
            }
          }}
          fullWidth
          row={row}
          testId="creditBackgroundCheck"
          disabled={restrictCustomCreditReport || disabled}
        />
      }
      {!hideDetails && formFields.creditBackgroundCheck.value === creditBgOptions[1].value && (
        <Box className={classes.paragraph} mt={2} px={3}>
          <Typography variant="body1">
            If you do not use the capital provider's free credit and background
            checking service you will need to provide the capital provider with
            the borrower's:
          </Typography>
          <Typography variant="body1">
            <List>
              <ul>
                <ListItem>
                  <li>ID</li>
                </ListItem>
                <ListItem>
                  <li>Personal Financial Statement</li>
                </ListItem>
                <ListItem>
                  <li>
                    Tri Merge Verifiable Credit Report (all 3 credit bureaus)
                  </li>
                </ListItem>
                <ListItem>
                  <li>
                    Background Report from TLO, LexisNexis, Clear, or Checkr
                  </li>
                </ListItem>
              </ul>
            </List>
          </Typography>
          <Typography variant="body1">
            This will extend timelines in approving the loan.
          </Typography>
        </Box>
      )}
    </>
  );
});