import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { Button, createCheckboxField } from '@roc/ui';
import { GetApp, MailOutline, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { QuoteTypeStore } from '../../stores/quoteTypeStore';
import { PropertyInformationCard } from '../propertyInformation/components/propertyInformationCard';
import { FixFlipPropertyInformationForm } from '../propertyInformation/forms/fixFlipPropertyInformationForm';
import { EarningsCards } from '../../components/earningsCards';
import { EconomicsSummaryCard } from './components/economicsSummaryCard';
import { BorrowerEmailDialog } from './components/borrowerEmailDialog';
import { DownloadQuoteDialog } from './components/downloadQuoteDialog';
import { QuoteStep } from '../../utils/constants';
import { BorrowerInformationCard } from '../borrowerInformation/components/borrowerInformationCard';
import { StepTitle } from '../../components/stepTitle';
import { LeverageExceptionAlert } from './components/leverageExceptionAlert';
import { SummaryActions } from './components/summaryActions';
import { LoanCommentsDialog } from './components/loanCommentsDialog';
import { useEarningsCardSticky } from '../../hooks/useEarningsCardSticky';
import { useStore } from '@roc/client-portal-shared/hooks';
import { useUserStore } from '@roc/feature-app-core';
import { EntityInformationSummaryCard } from '../entityInformation/components/entityInformationSummaryCard';
import { NoEntityAlert } from './components/noEntityAlert';
import { NoBorrowerAlert } from './components/noBorrowerAlert';
import { DownloadQuoteAlert } from './components/downloadQuoteAlert';
import { NoBorrowersDialog } from './components/noBorrowersDialog';

export interface LoanDetailsCardProps {
  store: QuoteTypeStore;
}

interface EconomicsStepProps {
  store: QuoteTypeStore;
  LoanDetailsCard: React.FC<LoanDetailsCardProps>;
}

export const SummaryStep = observer(
  ({ store, LoanDetailsCard }: EconomicsStepProps) => {
    const { earningsCardsStickyStyle } = useEarningsCardSticky();
    const [showEmailBorrowerDialog, setShowEmailBorrowerDialog] = useState(
      false
    );
    const [showDownloadQuoteDialog, setShowDownloadQuoteDialog] = useState(
      false
    );
    const { userStore } = useUserStore();

    const borrowerFormStores = store.borrowersStore.borrowerFormStores;
    const propertyFormStores = store.propertiesStore.propertyFormStores;

    const { globalStore } = useStore();
    const hasEntity = !!store.entityStore.entityFormStore.form.fields.hasEntity
      .value;

    const handleEditEconomics = () => {
      store.goToStep(QuoteStep.LOAN_ECONOMICS);
    };

    const showNoBorrowersAlert = store.summaryStore.showNoBorrowersAlert;

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StepTitle
              title={<>Quote Summary</>}
              subtitle={
                <>
                  Here is your quote, generated based on your selected borrower,
                  property, and economic data.
                </>
              }
            />
          </Grid>
          <Grid item xs={12}>
            {hasEntity ? <EntityInformationSummaryCard /> : <NoEntityAlert />}
          </Grid>
          {borrowerFormStores.map(borrowerFormStore => (
            <Grid item xs={12} key={borrowerFormStore.form.meta.key}>
              <BorrowerInformationCard
                borrowerFormStore={borrowerFormStore}
                onDeleteClick={() =>
                  store.borrowersStore.removeBorrower(
                    borrowerFormStore.form.meta.key
                  )
                }
                showActions={false}
                displayPGField={false}
              />
            </Grid>
          ))}
          {showNoBorrowersAlert && (
            <Grid item xs={12}>
              <NoBorrowerAlert />
            </Grid>
          )}
          {propertyFormStores.map(propertyFormStore => (
            <Grid item xs={12} key={propertyFormStore.form.meta.key}>
              <PropertyInformationCard
                store={store}
                propertyFormStore={propertyFormStore}
                PropertyInformationForm={FixFlipPropertyInformationForm}
                showActions={false}
              />
            </Grid>
          ))}

          {userStore.canSeeEarnings && (
            <Grid item xs={12} style={earningsCardsStickyStyle}>
              <EarningsCards store={store} />
            </Grid>
          )}
          <Grid item xs={12}>
            <LoanDetailsCard store={store} />
          </Grid>
          <Grid item xs={12}>
            <EconomicsSummaryCard store={store} />
          </Grid>
          {/* <Grid item xs={12}>
            <SummaryActions store={store} showEditEconomics />
          </Grid> */}
          <Grid item xs={12}>
            <DownloadQuoteAlert />
          </Grid>
        </Grid>
        {/* <BorrowerEmailDialog
          store={store}
          open={showEmailBorrowerDialog}
          onClose={() => setShowEmailBorrowerDialog(false)}
        />
        <DownloadQuoteDialog
          store={store}
          open={showDownloadQuoteDialog}
          onClose={() => setShowDownloadQuoteDialog(false)}
        /> */}
        <LoanCommentsDialog store={store} />
        <NoBorrowersDialog />
      </>
    );
  }
);
