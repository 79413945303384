import { makeStyles, Theme } from '@material-ui/core/styles';
import { Clear, PermIdentityOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { CustomButton } from '../../../components/customButton';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { FORM_MAX_WIDTH } from '../../../utils/constants';
import { OneToolCard } from '../../common/oneToolCard';
import { BorrowerInformationForm } from './borrowerInformationForm';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: theme.spacing(0.75),
    minWidth: 0,
  },
}));

export const BorrowerFormCard = observer(({ borrowerFormStore }) => {
  const classes = useStyles();
  const { quoteTypeStore } = useQuoteTypeStore();
  const { borrowersStore } = quoteTypeStore;
  return (
    <OneToolCard
      maxWidth={FORM_MAX_WIDTH}
      icon={PermIdentityOutlined}
      title="Add Borrower"
      actions={
        <CustomButton
          testId="remove-borrower"
          variant="contained"
          theme="blue"
          className={classes.button}
          onClick={() => borrowersStore.removeBorrower(borrowerFormStore.key)}
        >
          <Clear />
        </CustomButton>
      }
    >
      <BorrowerInformationForm
        store={borrowerFormStore}
        showActions={false}
        displayPGField
        onDeleteClick={() => null}
        showTitle={false}
      />
    </OneToolCard>
  );
});
