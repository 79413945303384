import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  notification: {
    borderRadius: 16,
    paddingRight: 24,
    paddingTop: 8,
    paddingBottom: 8,
  },
});

export const CustomAlert = (props: AlertProps) => {
  const classes = useStyles();
  return <Alert {...props} className={classes.notification} />;
};
