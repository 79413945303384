import { GlobalStore } from '@roc/feature-app-core';
import {
  DateFormat,
  formatDate,
  generateUUID,
  GENERIC_ERROR_MESSAGE,
  normalizeState,
  requiredLicenseStates,
  StateNames,
} from '@roc/feature-utils';
import { action, flow, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { PropertyFormStore } from './propertyFormStore';

export abstract class PropertiesStore {
  protected globalStore: GlobalStore;
  protected loanSubType: string;
  private oneToolService: OneToolService;

  public propertyFormStores: PropertyFormStore[];
  public uploadedLicenses = {};

  public key = generateUUID();

  abstract createPropertyForm(): PropertyFormStore;

  constructor(globalStore, loanSubType) {
    this.globalStore = globalStore;
    this.loanSubType = loanSubType;
    this.oneToolService = new OneToolService();
    makeObservable(this, {
      reset: action,
      propertyFormStores: observable,
      createPropertyForm: action,
      addNewProperty: action,
      removeProperty: action,
      removePropertyInAccordion: action,
      validateAllProperties: action,
      turnOffPropertiesEditMode: action,
      getProperties: action,
      loadProperties: action,
      uploadedLicenses: observable,
      uploadStateLicense: flow,
      removeStateLicense: action,
    });
  }

  reset() {
    this.propertyFormStores = [];
    this.addNewProperty();
  }

  addNewProperty() {
    this.propertyFormStores.push(this.createPropertyForm());
  }

  removeProperty(propertyKey: string) {
    this.propertyFormStores = this.propertyFormStores.filter(
      property => property.form.meta.key !== propertyKey
    );
  }

  removePropertyInAccordion(propertyKey: string) {
    this.propertyFormStores = this.propertyFormStores.filter(
      property => property.key !== propertyKey
    );
  }

  validateAllProperties() {
    let isValid = true;
    let missingLicense = false;

    this.propertyFormStores.forEach(store => {
      const state = store.form.fields.state.value;

      if (StateNames.NEVADA === state && !this.uploadedLicenses[state]) {
        missingLicense = true;
      }

      store.validateAndCleanupProperty();
      isValid = isValid && store.form.meta.isValid;
      store.setEditMode(!isValid || missingLicense);
    });

    if (missingLicense) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please upload the corresponding state license.',
      });
      return false;
    }

    if (!isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please complete all the required fields for the properties.',
      });
      return false;
    }

    return true;
  }

  turnOffPropertiesEditMode() {
    this.propertyFormStores.forEach(propertyFormStore => {
      propertyFormStore.form.meta.editMode = false;
    });
  }

  getProperties() {
    return this.propertyFormStores.map(form => form.getProperty());
  }

  loadProperties(quoteData) {
    const properties = quoteData.properties;
    if (properties?.length > 0) {
      this.propertyFormStores = properties?.map(borrower => {
        const form = this.createPropertyForm();
        form.loadProperty(borrower);
        form.setEditMode(false);
        return form;
      });
    }

    this.uploadedLicenses = quoteData.uiState?.uploadedLicenses || {};
  }

  *uploadStateLicense(file: File, state: string) {
    try {
      const fileType = new Blob([`State License - ${normalizeState(state)}`], {
        type: 'text/plain',
      });
      const companyId = new Blob([this.globalStore.selectedCompanyId], {
        type: 'text/plain',
      });
      const formData = new FormData();
      formData.append('file', file);
      formData.set('fileType', fileType);
      formData.set('companyId', companyId);
      yield this.oneToolService.uploadLicense(formData);

      this.uploadedLicenses = { ...this.uploadedLicenses, [state]:file.name };
    } catch (e) {
      this.globalStore.notificationStore.showErrorNotification({
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }

  removeStateLicense(state){
    delete this.uploadedLicenses[state];
  }
}
