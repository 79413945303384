import { GlobalStore } from '@roc/feature-app-core';
import { action, computed, makeObservable, observable } from 'mobx';
import { OneToolService } from '../../services/oneToolService';
import { SelectEntityOption } from '../../utils/constants';
import { QuoteTypeStore } from '../quoteTypeStore';
import { BorrowerFormStore } from './borrowerFormStore';

export class BorrowersStore {
  private globalStore: GlobalStore;
  private quoteTypeStore: QuoteTypeStore;
  private oneToolService: OneToolService;

  public borrowerFormStores: BorrowerFormStore[];

  constructor(globalStore, quoteTypeStore) {
    this.globalStore = globalStore;
    this.quoteTypeStore = quoteTypeStore;
    this.oneToolService = new OneToolService();
    makeObservable(this, {
      reset: action,
      borrowerFormStores: observable,
      createBorrowerForm: action,
      addNewBorrower: action,
      addBorrower: action,
      removeBorrower: action,
      validateAllBorrowers: action,
      turnOffBorrowersEditMode: action,
      getBorrowers: action,
      loadBorrowers: action,
      resetBorrowerFormStores: action,
      showOwnershipPercentage: computed,
    });
  }

  reset() {
    this.resetBorrowerFormStores();
    this.addNewBorrower();
  }

  resetBorrowerFormStores() {
    this.borrowerFormStores = [];
  }

  createBorrowerForm() {
    return new BorrowerFormStore(this.globalStore);
  }

  addNewBorrower() {
    this.borrowerFormStores.push(this.createBorrowerForm());
  }

  addBorrower(borrowerFormStore: BorrowerFormStore) {
    this.borrowerFormStores.push(borrowerFormStore);
  }

  removeBorrower(borrowerKey: string) {
    this.borrowerFormStores = this.borrowerFormStores.filter(
      borrower => borrower.key !== borrowerKey
    );
  }

  validateAllBorrowers() {
    let isValid = true;

    let thereIsAtLeastOnePG = false;
    this.borrowerFormStores.forEach(store => {
      thereIsAtLeastOnePG =
        thereIsAtLeastOnePG || store.form.fields.personalGuarantor.value;
      store.validateBorrower();
      isValid = isValid && store.form.meta.isValid;
      store.setEditMode(!isValid);
      store.setShowErrors(!store.form.meta.isValid);
    });

    if (!isValid) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'Please complete all the required fields for the borrowers.',
      });
    }

    if (!thereIsAtLeastOnePG) {
      this.globalStore.notificationStore.showWarningNotification({
        message: 'At least one Borrower should be Personal Guarantor.',
      });
      isValid = false;
    }

    return isValid;
  }

  turnOffBorrowersEditMode() {
    this.borrowerFormStores.forEach(borrowerFormStore => {
      borrowerFormStore.form.meta.editMode = false;
    });
  }

  getBorrowers() {
    return this.borrowerFormStores.map(form => form.getBorrower());
  }

  loadBorrowers(borrowers) {
    if (borrowers?.length > 0) {
      this.borrowerFormStores = borrowers?.map(borrower => {
        const form = this.createBorrowerForm();
        form.loadBorrower(borrower);
        form.setEditMode(false);
        return form;
      });
    }
  }

  get showOwnershipPercentage(){
    const { entityFormStore } = this.quoteTypeStore.entityStore;
    const hasEntity = entityFormStore.form.fields.hasEntity.value;
    return hasEntity && this.borrowerFormStores.length > 1;
  }
}
