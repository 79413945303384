import { useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { BooleanChip, Button, TooltipOnOverflow } from '@roc/ui';
import { useUserStore } from '@roc/feature-app-core';
import { formatCurrency } from '@roc/ui/utils';
import { observer } from 'mobx-react';
import { EditSubmissionButton } from '@roc/feature-edit-loans';
import { AddNewTaskButton } from '@roc/feature-general-tasks';
import { StandardDialog } from '@roc/ui';
import { CreateAppraisalOrderButton } from '../common/createAppraisalOrderButton';
import { EditLoanButton } from '@roc/feature-loans-shared';
import { insertIf, LoanSubType } from '@roc/feature-utils';
import LoanSummaryTabs from '../common/loanSummaryTabs'
import LoanSummaryExpandableTable from '../common/loanSummaryExpandableTable';
import { Commissions } from '../common/comissions'
import { SubmissionVsUnderwritingValues } from '../common/submissionValuesVsUnderwritingValues'
import { mapFundingEntity } from '../../utils/loanSummaryUtils';
import {
  LoanDetailsCustomCard,
  LoanDetailsInfoItem,
  useLoanDetailsInfoItemStyles,
} from '../loanDetailsCustomCard';
import { useLoanFlags } from '../../hooks/useLoanFlags';
import { useStore } from 'apps/client-portal-public/src/app/hooks';
import { isNil, isFundedOrAgreedLoan } from '@roc/feature-utils';
import {
  getValueOrDefaultDash,
  formatPercentageValue
} from 'libs/feature-one-tool/src/quote/utils/utils';
import { PropertyDetailsDialog } from '../cards/propertiesCard'
import { useLoanSummaryStore } from '@roc/feature-loan-summary';
import { useSubmissionVsUnderwritingStore } from '../../hooks/useSubmissionVsUnderwritingStore';
import { KeyDatesBridgeV2 } from '../common/keyDatesBridgeV2';

const formatPoints = value => {
  return Number((value || 0).toFixed(4));
};

type loanSummaryProps = {
  displayCreateAppraisalOrderButton: boolean,
  classes: any,
  showEditLoanButton: boolean,
  setViewDetailDialog: any,
  loanStore: any,
  loanDetails: any,
  showCreateTask: boolean
  allowLoanPricing: boolean
  lenderPoints: any,
  isInternal: boolean
  isOffHudBroker: boolean
  showEditSubmissionButton: boolean,
  isModalView?: boolean
  showPropertyDialog?: any,
  setShowPropertyDialog?: any,
  setProperty?: any,
  property?: any
  submissionVsUnderwritingStore?: any
}

const groundUpDialogTabs = ['Loan Terms', 'Brokers', 'Property Details', 'Commissions', 'SUB/UW Values', 'Key Dates']
const groundUpDialogTabsWithoutCommissions = ['Loan Terms', 'Brokers', 'Property Details', 'SUB/UW Values', 'Key Dates']

export const LoanDetailsCardGroundUp = observer(() => {
  const {
    allowLoanPricing,
    showCreateApprasialOrderButton,
    showEditLoanButton,
    showEditSubmissionButton,
  } = useLoanFlags();
  const [showViewDetailDialog, setViewDetailDialog] = useState(
    false
  );
  const { loanStore, globalStore } = useStore();
  const { userStore } = useUserStore();
  const { loanDetails } = loanStore;
  const classes = useLoanDetailsInfoItemStyles();
  const isInternal = globalStore?.lenderInfo?.isInternal;
  const showCreateTask = userStore?.isBackOfficeUser;
  const displayCreateAppraisalOrderButton = showCreateApprasialOrderButton && loanDetails?.loanSubType !== LoanSubType.FIX_AND_FLIP_PRO;
  const isOffHudBroker = loanDetails?.referralFeePct && loanDetails?.referralFeePct > 0 ? true : false;


  const lenderPoints = Math.max(
    (loanDetails?.pointsIn ?? 0) - (loanDetails?.rocPointsIn ?? 0),
    0
  );
  return (
    <LoanDetailsCustomCard contentStyle={{
      minWidth: 1100
    }} cardContentProps={{
      style: {
        overflow: 'auto'
      }
    }}>
      <CardComponent
        displayCreateAppraisalOrderButton={displayCreateAppraisalOrderButton}
        classes={classes}
        showEditLoanButton={showEditLoanButton}
        setViewDetailDialog={setViewDetailDialog}
        loanStore={loanStore}
        loanDetails={loanDetails}
        showCreateTask={showCreateTask}
        allowLoanPricing={allowLoanPricing}
        lenderPoints={lenderPoints}
        isInternal={isInternal}
        isOffHudBroker={isOffHudBroker}
        showEditSubmissionButton={showEditSubmissionButton} />
      <StandardDialog
        open={showViewDetailDialog}
        maxWidth="xl"
        fullWidth={true}
        dialogContent={<CardComponent
          setViewDetailDialog={setViewDetailDialog}
          displayCreateAppraisalOrderButton={displayCreateAppraisalOrderButton}
          classes={classes}
          isModalView={true}
          showEditLoanButton={showEditLoanButton}
          loanStore={loanStore}
          loanDetails={loanDetails}
          showCreateTask={showCreateTask}
          allowLoanPricing={allowLoanPricing}
          lenderPoints={lenderPoints}
          isInternal={isInternal}
          isOffHudBroker={isOffHudBroker}
          showEditSubmissionButton={showEditSubmissionButton} />}
        handleClose={() => setViewDetailDialog(false)}
        onClose={() => setViewDetailDialog(false)}
      />
    </LoanDetailsCustomCard>
  );
});

export const CardComponent = observer(({ displayCreateAppraisalOrderButton, classes, showEditLoanButton, loanStore, loanDetails, showCreateTask, allowLoanPricing, lenderPoints, isInternal, isOffHudBroker, showEditSubmissionButton, setViewDetailDialog, isModalView }: loanSummaryProps) => {
  const { loanSummaryStore } = useLoanSummaryStore();
  const { submissionVsUnderwritingStore } = useSubmissionVsUnderwritingStore();
  const { loanSummary } = loanSummaryStore;
  const { showCommissionsCard } = useLoanFlags();
  const [property, setProperty] = useState(null);
  const [showPropertyDialog, setShowPropertyDialog] = useState(
    false
  );
  const handleProperty = (property) => {
    setProperty(property)
    setShowPropertyDialog(true)
  }
  return (<>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 20 }}>
      <Typography variant="h5" style={{ marginTop: 8 }}>
        Loan Summary
      </Typography>
      <Box display='flex'>
        {!isModalView && displayCreateAppraisalOrderButton && <CreateAppraisalOrderButton buttonStyle={classes.button} />}
        {!isModalView && showEditLoanButton && (
          <EditLoanButton containerStyle={classes.containerStyle} loanStore={loanStore} data={loanDetails} buttonStyle={classes.button} />
        )}
        {!isModalView && showCreateTask &&
          <AddNewTaskButton buttonStyle={classes.button} loanId={loanDetails?.loanId} />
        }
        {!isModalView && <Button className={classes.button} testId='view-detail' variant="text" color="primary" onClick={() => setViewDetailDialog(true)}>
          View detail
        </Button>}
      </Box>
    </Grid>
    <Grid item xs={12} style={{ display: 'flex' }}>
      <Grid item xs={isModalView ? 12 : 3} md={3} style={{ position: 'relative', paddingRight: 24 }}>
        <Grid container spacing={0} style={{ position: 'relative', paddingTop: 0 }} className={classes.amountHighligher}>
          <Grid item xs={12}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.amount, 0)}
              label={loanDetails?.loanSubType}
              valueStyle={{
                fontSize: 34,
                fontWeight: 400
              }}
              py={0}
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                fontSize: 11,
                fontWeight: 'bold'
              }}
              labelComponent={<>
                <Typography className={classes.loanSummaryLabelStyle}>
                  {loanDetails?.loanSubType}
                </Typography>
              </>}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} height={'100%'}>
              <Box pr={2}>
                <LoanDetailsInfoItem
                  boxStyle={{
                    paddingTop: 2
                  }}
                  value={formatCurrency(loanDetails?.initialLoanAmount, 0)}
                  labelStyle={{
                    color: "#939494",
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                    fontSize: 11,
                    fontWeight: 'bold'
                  }}
                  valueStyle={{
                    fontWeight: 500
                  }}
                  label={`Initial`}
                  secondaryLabelComponent={
                    <Box fontSize={12}>
                      <span className={classes.loanSummaryLabelStyle}>
                        {`${loanDetails?.aggregateLoanToCurrentCostInitial}% of `}
                      </span>
                      <Tooltip title={`Acquisition Price`} arrow>
                        <span className={classes.loanSummaryLabelStyle} style={{
                          borderBottom: '1px dashed #bbb'
                        }}>
                          {formatCurrency(loanDetails?.calculatedLoanData?.aggregatePurchasePrice, 0)}
                        </span>
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Box pr={2} my={1} borderLeft={'1px solid #bbb'}>
              </Box>
              <Box pr={2}>
                <LoanDetailsInfoItem
                  boxStyle={{
                    paddingTop: 2
                  }}
                  value={formatCurrency(loanDetails?.constructionHoldback, 0)}
                  label={`Holdback`}
                  valueStyle={{
                    fontWeight: 500
                  }}
                  labelStyle={{
                    color: "#939494",
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                    fontSize: 11,
                    fontWeight: 'bold'
                  }}
                  secondaryLabelComponent={
                    <Box fontSize={12}>
                      <span className={classes.loanSummaryLabelStyle}>
                        {`${loanDetails?.constructionHoldbackToRenovationBudgetPercent}% of `}
                      </span>
                      <Tooltip className={classes.loanSummaryLabelStyle} title={`Total Renovation Budget`} arrow>
                        <span style={{
                          borderBottom: '1px dashed #bbb'
                        }}>
                          {formatCurrency(loanDetails?.calculatedLoanData?.aggregateRenovationBudget, 0)}
                        </span>
                      </Tooltip>
                    </Box>
                  }
                />

              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={isModalView ? 12 : 9} md={9}>
        <Grid container spacing={0} style={{
          position: 'relative'
        }}>
          {allowLoanPricing &&
            <Grid item xs={isModalView ? 12 : 3} md={3}>
              <LoanDetailsInfoItem
                value={`${formatPoints((loanDetails?.brokerPointsIn ?? 0) +
                  (lenderPoints ?? 0) +
                  (loanDetails?.rocPointsIn ?? 0) + (loanDetails?.referralFeePct ?? 0))
                  } points`}
                labelStyle={{
                  color: "#939494",
                  textTransform: 'uppercase',
                  letterSpacing: '1.5px',
                  fontSize: 11,
                  fontWeight: 'bold'
                }}
                label={
                  <>
                    {isInternal || isOffHudBroker
                      ? (isOffHudBroker
                        ? `${formatPoints(loanDetails?.referralFeePct ?? 0)} Off Hud Broker | `
                        : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} On Hud Broker | `
                      )
                      : `${formatPoints(loanDetails?.brokerPointsIn ?? 0)} Broker | `}
                    {formatPoints(lenderPoints ?? 0)} Lender <br />
                    {formatPoints(loanDetails?.rocPointsIn ?? 0)} Capital Provider
                  </>
                }
              />
            </Grid>
          }
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={formatCurrency(loanDetails?.interestReserve, 2)}
              label="Interest Reserve"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 11,
                fontWeight: 'bold'
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={formatCurrency(
                loanDetails?.properties[0]?.afterRepairValue,
                0
              )}
              label={`ARV (${loanDetails?.calculatedLoanData?.aggregateAfterRepairLoanToValue}% ARLTV)`}
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 11,
                fontWeight: 'bold'
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={`${loanDetails?.duration} Months`}
              label="Loan Term"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 11,
                fontWeight: 'bold'
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.loanPurpose ? loanDetails?.loanPurpose : '-'}
              label='Loan Purpose'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 11,
                fontWeight: 'bold'
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.rate ? `${loanDetails?.rate}%` : '-'}
              label='Interest Rate'
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 11,
                fontWeight: 'bold'
              }}
            />
          </Grid>
          <Grid item xs={isModalView ? 12 : 3} md={3}>
            <LoanDetailsInfoItem
              value={loanDetails?.pledgeOfShares ? 'Yes' : 'No'}
              label="Pledge of Shares"
              labelStyle={{
                color: "#939494",
                textTransform: 'uppercase',
                letterSpacing: '1.5px',
                fontSize: 10,
                fontWeight: 'bold'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} className={classes.loanSummaryBottomSection}>
      <Grid item xs={3} style={{ position: 'relative', textAlign: 'center' }}>
        {showEditSubmissionButton &&
          <EditSubmissionButton buttonStyle={classes.button} loanStore={loanStore} loanId={loanDetails?.loanId} />
        }
      </Grid>
      <Grid item xs={9}>
        <Grid item xs={isModalView ? 12 : 9} md={9}>
          <LoanDetailsInfoItem
            value={<TooltipOnOverflow
              title={getValueOrDefaultDash(loanDetails?.attorneyName)}
              placement="bottom"
            >
              <Typography className={classes.overflowElipsis}>
                {getValueOrDefaultDash(loanDetails?.attorneyName)}
              </Typography>
            </TooltipOnOverflow>}
            label='Attorney'
            labelStyle={{
              color: "#939494",
              textTransform: 'uppercase',
              letterSpacing: '1.5px',
              fontSize: 10,
              fontWeight: 'bold'
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    {isModalView && property && <PropertyDetailsDialog
      open={showPropertyDialog}
      onClose={() => setShowPropertyDialog(false)}
      property={property}
      loanSubType={loanDetails?.loanSubType}
    />}
    {isModalView &&
      <LoanSummaryTabs
        classes={classes}
        tabs={showCommissionsCard ? groundUpDialogTabs : groundUpDialogTabsWithoutCommissions}
        tabsContent={[
          <LoanSummaryExpandableTable
            title='Loan Terms'
            hasTwoColumns={false}
            data={[
              ...insertIf(allowLoanPricing, [{
                left: 'Capital Provider Int Rate',
                right: formatPercentageValue(loanDetails?.rocRate)
              }]),
              {
                left: 'Capital Provider Points (I|O)',
                right: `${isNil(loanDetails?.rocPointsIn) ? '0' : loanDetails?.rocPointsIn} | ${isNil(loanDetails?.rocPointsOut) ? '0' : loanDetails?.rocPointsOut}`
              },
              {
                left: 'Capital Provider Underwriting Fee',
                right: loanDetails?.underwritingFee ? formatCurrency(loanDetails?.underwritingFee) : '-'
              },
              {
                left: 'Capital Provider Processing Fee',
                right: loanDetails?.processingFee ? formatCurrency(loanDetails?.processingFee) : '-'
              },
              {
                left: 'Subordination',
                right: formatPercentageValue(loanDetails?.origLenderSubordination)
              },
              {
                left: 'Buy. Skim Red.',
                right: getValueOrDefaultDash(loanDetails?.buybackSkimReduction)
              },
              {
                left: 'Participation',
                right: getValueOrDefaultDash(loanDetails?.sellAs)
              },
              {
                left: 'Funding Entity',
                right: mapFundingEntity(loanDetails?.fundingPreference)
              },
              {
                left: 'Initial LTC',
                right: formatPercentageValue(loanDetails?.aggregateLoanToCurrentCostInitial)
              },
              {
                left: 'Construction Holdback Rate',
                right: formatPercentageValue(loanDetails?.constructionHoldbackToRenovationBudgetPercent)
              },
              ...insertIf(isFundedOrAgreedLoan(loanDetails?.status), [{
                left: 'Servicer',
                right: getValueOrDefaultDash(loanDetails?.servicer),
                subrows: [
                  { left: 'Servicer id', right: getValueOrDefaultDash(loanDetails?.servicerId) },
                  { left: 'First Payment Date', right: getValueOrDefaultDash(loanDetails?.firstServicerPaymentDueDate) }
                ]
              }]),
              {
                left: 'Total LTFC',
                right: formatPercentageValue(loanDetails?.aggregateLoanToFutureCost)
              },
              {
                left: 'MERS MIN Number',
                right: getValueOrDefaultDash(loanDetails?.minNumber)
              },
              {
                left: 'Full Princ Interest',
                right: <BooleanChip value={loanDetails?.fullPrincipalInterest}/>
              },
              {
                left: 'FPI Time',
                right: getValueOrDefaultDash(loanDetails?.fullPrincipalInterestTime)
              },
              {
                left: 'Interest Reserve (Months)',
                right: getValueOrDefaultDash(loanDetails?.interestReserveMonths)
              },
              {
                left: 'Pre Penalty (Months)',
                right: getValueOrDefaultDash(loanDetails?.prepayPenaltyMonths)
              },
              {
                left: 'Free Appraisal Promotion',
                right: <BooleanChip value={loanDetails?.freeAppraisalPromotion}/>
              },
            ]} />,
          <LoanSummaryExpandableTable hasTwoColumns={false} title='Brokers' data={[
            {
              left: 'Broker',
              right: '',
              expanded:true,
              subrows: [
                { left: 'Company Name', right: getValueOrDefaultDash(loanDetails?.broker?.company?.name) },
                { left: 'Company EIN Number', right: getValueOrDefaultDash(loanDetails?.broker?.company?.einNumber) }
              ]
            }
          ]} />,
          <LoanSummaryExpandableTable
            hasTwoColumns={false}
            title='Property Details'
            data={[
              ...loanDetails?.properties?.map((property) => {
                return ({
                  left: <>{property?.address}</>,
                  right: '',
                  subrows: [
                    {
                      left: 'Acquisition',
                      right: getValueOrDefaultDash(property?.propertyOwnership)
                    },
                    {
                      left: 'Purchase Price',
                      right: formatCurrency(property?.purchasePrice ?? 0)
                    },
                    {
                      left: 'Reno Budget',
                      right: formatCurrency(property?.renovationBudget ?? 0)
                    },
                    {
                      left: 'Purchase Date',
                      right: getValueOrDefaultDash(property?.purchaseDate)
                    },
                    {
                      left: 'Property sourcing',
                      right: getValueOrDefaultDash(property?.propertySourcing)
                    },
                    {
                      left: 'Units',
                      right: getValueOrDefaultDash(property?.units)
                    },
                    ...insertIf(property?.wholesalerPurchase, [
                      {
                        left: 'Sourcing',
                        right: getValueOrDefaultDash(property?.wholesalerPurchase)
                      }
                    ]),
                    ...insertIf(property?.propertySourcing === 'Other', [
                      {
                        left: 'Explanation',
                        right: getValueOrDefaultDash(property?.propertySourcingExplanation)
                      }
                    ]),
                    {
                      left: 'Exit Strategy',
                      right: getValueOrDefaultDash(property?.exitStrategy)
                    },
                    {
                      left: 'Completed Renovations',
                      right: formatCurrency(property?.sunkCost ?? 0)
                    },
                    {
                      left: 'Refinance Amount',
                      right: formatCurrency(property?.refiAmount ?? 0)
                    },
                    {
                      left: 'Special project type',
                      right: getValueOrDefaultDash(property?.describeRenovation)
                    },
                    {
                      left: 'Arms Length',
                      right: property?.armsLength ? 'Yes' : 'No'
                    },
                    {
                      left: 'Arms Comment',
                      right: getValueOrDefaultDash(property?.armsLengthComment)
                    },
                    {
                      left: 'WholeSale',
                      right: formatCurrency(property?.wholesaleAmount ?? 0)
                    },
                    {
                      left: 'Monthly Rent',
                      right: formatCurrency(property?.monthlyRent ?? 0)
                    },
                    {
                      left: 'As-is-Value',
                      right: formatCurrency(property?.asIsValue ?? 0)
                    },
                    {
                      left: 'Min Release',
                      right: formatCurrency(property?.releaseAmount ?? 0)
                    },
                    {
                      left: 'Ins Exp Date',
                      right: getValueOrDefaultDash(property?.insuranceExpiryDate)
                    },
                    {
                      left: 'ARV',
                      right: formatCurrency(property?.afterRepairValue ?? 0)
                    },
                    {
                      left: 'Full Net Proceeds',
                      right: property?.fullNetProceeds ? 'Yes' : 'No'
                    },
                    {
                      left: 'Release Comment',
                      right: getValueOrDefaultDash(property?.releaseComment)
                    },
                    {
                      left: 'Zillow',
                      right: formatCurrency(property?.zEstimate ?? 0)
                    },
                    {
                      left: 'Zillow Low',
                      right: formatCurrency(property?.zEstimateLow ?? 0)
                    },
                    {
                      left: 'Zillow High',
                      right: formatCurrency(property?.zEstimateHigh ?? 0)
                    },
                    {
                      left: 'Median',
                      right: formatCurrency(property?.zEstimateMedian ?? 0)
                    },
                    {
                      left: 'Additional Comments',
                      right: getValueOrDefaultDash(property?.additionalComments)
                    },
                    {
                      left: 'Lien Pos',
                      right: getValueOrDefaultDash(property?.lienPosition)
                    },
                    {
                      left: 'Sr Amt',
                      right: formatCurrency(property?.lienAmount ?? 0)
                    },
                    {
                      left: 'Flood Zone',
                      right: getValueOrDefaultDash(property?.floodZone)
                    },
                    {
                      left: 'Rural Census',
                      right: property?.ruralProperty ? 'Yes' : 'No'
                    },
                    {
                      left: 'Valuation Review',
                      right: getValueOrDefaultDash(property?.internalValuationReviewRequired)
                    },
                    {
                      left: 'Reason for Internal Valuation',
                      right: getValueOrDefaultDash(property?.internalValuationReviewRequiredReason)
                    }
                  ]
                })
              })
            ]} />,
          ...insertIf(showCommissionsCard, [
            <Commissions loanId={loanDetails?.loanId} />,
          ]),
          <SubmissionVsUnderwritingValues
            store={submissionVsUnderwritingStore}
            loanId={loanDetails?.loanId}
            isInternal={false} loanDetails={loanDetails}
          />,
          <KeyDatesBridgeV2 loanDetails={loanDetails} />
        ]}
      />}
  </>)
})
