import { Grid } from '@material-ui/core';
import { WorkOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { ItemDisplay } from '../../../components/itemDisplay';
import { RoundedBackgroundIcon } from '../../../components/roundedBackgroundIcon';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { OneToolCardBorder } from '../../common/oneToolCard';

export const EntityInformationSummaryCard = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const { entityStore } = quoteTypeStore;
  const { entityFormStore } = entityStore;
  const entity = entityFormStore.getFormValues();

  return (
    <OneToolCardBorder>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={5}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            style={{ flexWrap: 'nowrap' }}
          >
            <Grid item alignItems="center" style={{ height: '100%' }}>
              <RoundedBackgroundIcon Icon={WorkOutline} iconColor="#6D6D6D" />
            </Grid>
            <Grid item style={{ maxWidth: '100%' }}>
              <ItemDisplay
                label="Entity Name"
                value={entity.entityName || '-'}
                alignItems="flex-start"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <ItemDisplay label="Entity EIN" value={entity.entityEin || '-'} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </OneToolCardBorder>
  );
});
