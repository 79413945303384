import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { Button, StepperComponent, StepsContainerProps } from '@roc/ui';
import clsx from 'clsx';
import { OneToolStepperComponent } from './oneToolStepperComponent';
import { observer } from 'mobx-react';

const useStyle = makeStyles(theme =>
  createStyles({
    title: {
      fontSize: '34px',
    },
    stepBody: {
      minHeight: 300,
    },
    stepContainer: {
      height: '100%',
    },
    stepperComponent: {
      padding: 0,
      paddingBottom: theme.spacing(3),
      margin: theme.spacing(0, -0.5),
    },
    scrollContainer: {
      [theme.breakpoints.down('xs')]: {
        overflowX: 'auto',
        overflowY: 'hidden',
      },
    },
    scrollContent: {
      minWidth: '800px'
    }
  })
);

export const OneToolQuoteStepsContainer = observer(({
  title,
  disableNavigation,
  activeStep,
  handleStep,
  steps,
  children,
  scrollToTopOnStepChange,
}: StepsContainerProps) => {
  const classes = useStyle();
  const showBack = steps[activeStep].allowBack;
  const showNext = steps[activeStep].allowNext;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={title ? 6 : 12}>
            <Box className={classes.scrollContainer}>
              <Box className={classes.scrollContent}>
                <OneToolStepperComponent
                  activeStep={activeStep}
                  disableNavigation={disableNavigation}
                  steps={steps}
                  handleStep={handleStep}
                  className={classes.stepperComponent}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.stepBody}>
        <Box className={clsx(classes.stepContainer)}>
          <Box className={classes.scrollContainer}>
            <Box className={classes.scrollContent}>{children[activeStep]}</Box>
          </Box>
        </Box>
      </Grid>
      {(showBack || showNext) && (
        <Grid item xs={12}>
          <Box pt={3}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
              {showBack && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onBack();
                      if (scrollToTopOnStepChange) {
                        (window as any).scrollTo({
                          behavior: 'instant',
                          top: 0,
                        });
                      }
                    }}
                    testId="previous"
                  >
                    Back
                  </Button>
                </Grid>
              )}
              {showNext && (
                <Grid item xs={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      steps[activeStep].onNext();
                      if (scrollToTopOnStepChange) {
                        (window as any).scrollTo({
                          behavior: 'instant',
                          top: 0,
                        });
                      }
                    }}
                    testId={steps[activeStep].nextButtonText}
                    disabled={steps[activeStep]?.disableNextButton}
                  >
                    {steps[activeStep].nextButtonText}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
});
