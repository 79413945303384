import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useBaseStore } from '@roc/feature-app-core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useBorrowersStore } from '@roc/feature-borrowers';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useLoanStore } from '@roc/feature-loans';
import { CopyText, StandardDialog } from '@roc/ui';
import BorrowerOverview from '../borrowerOverview'
import EntityOverview from '../entityOverview';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { colors } from '../../utils/todoConstants'
import { CustomCard } from '../customCard';
import {
  formatPercentageValue,
  getValueOrDefaultDash,
} from 'libs/feature-one-tool/src/quote/utils/utils';
import WarningIcon from '@material-ui/icons/Warning';
const useStyles = makeStyles((theme: Theme) => ({
  borrowerText2: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
  borrowerCard: {
    borderRadius: theme.spacing(2),
    border: '1px solid #d5d5d5 !important',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  dialogTitle: {
    fontSize: '34px',
    marginBottom: '-16px',
    fontWeight: 400,
    marginLeft: '30px',
    marginTop: '24px',
  },
  arrowRight: {
    fontSize: '45px',
    color: colors.iron
  },
  hoverLink: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
  },
  copyText: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[200]}`,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 16,
      '&::after': {
        display: 'inherit',
      },
    },
    '&::after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
  },
}));

export const formatText = (value, type) => {
  if (value === null || value === undefined) {
    return '-';
  }
  else {
    return `${value} ${type}`
  }
};
export const BorrowerSummaryCard = observer(() => {
  const { loanStore } = useLoanStore();
  const { loanDetails } = loanStore;
  const { borrowersStore } = useBorrowersStore();
  const { borrowerSetupStore } = borrowersStore;
  const [borrower, setBorrower] = useState(null);
  const [showMoreInformationDialog, setShowMoreInformationDialog] = useState(
    false
  );
  const [type, setType] = useState('borrower');
  const entity = loanDetails?.borrowerEntity;
  const loanBorrowers = loanDetails?.borrowers;

  useEffect(() => {
    if (borrower?.borrowerId) {
      borrowerSetupStore.init(borrower, true, loanDetails.loanType)
      borrowersStore.getBorrowerExposureGrid(borrower.borrowerId);
    }
  }, [borrower?.borrowerId]);

  return (
    <CustomCard
      paperNoShadow
      paperStyle={{
        height: '100%',
      }}
      title="Borrower Summary"
    >
      <Grid container spacing={2}>
        {(entity.ein ?? entity?.company?.einNumber) && (
          <Grid item xs={12}>
            <EntityCard setShowMoreInformationDialog={setShowMoreInformationDialog} setType={setType} entity={entity} />
          </Grid>
        )}
        {loanDetails?.borrowers?.map?.(borrower => (
          <Grid item xs={12}>
            <BorrowerCard
              setShowMoreInformationDialog={setShowMoreInformationDialog}
              setType={setType}
              borrower={borrower}
              setBorrower={setBorrower}
              name={borrower?.fullName}
            />
          </Grid>
        ))}
      </Grid>
      <MoreInformationDialog
        open={showMoreInformationDialog}
        entity={entity}
        loanBorrower={borrower}
        loanBorrowers={loanBorrowers}
        currentBorrower={borrowerSetupStore?.currentBorrower}
        onClose={() => setShowMoreInformationDialog(false)}
        type={type}
      />
    </CustomCard>
  );
});

const BorrowerCard = observer(({ name, borrower, setShowMoreInformationDialog, setType, setBorrower }) => {
  const classes = useStyles();
  const { globalStore } = useBaseStore();
  const handleClick = () => {
    setBorrower(borrower);
    setType('borrower');
    setShowMoreInformationDialog(true);
  }

  const pgText: any = borrower.personalGuarantor ? 'PG' : 'Non PG';
  return (
    <Box onClick={handleClick} display="flex" alignItems="center" p={2} className={classes.borrowerCard}>
      <Box pl={2} flex={1}>
        <Grid container spacing={1} direction={'row'}>
          <Grid item>
            <Typography variant='h5'>{name}</Typography>
          </Grid>
          {borrower.exposureMonitoring && <Grid item style={{marginTop: '-10px'}}>
            <Tooltip title={<Typography variant='subtitle1'>
                {borrower.exposureMessage}
              </Typography>}>
              <IconButton>
                <WarningIcon style={{color: '#fdaf3d'}}/>
              </IconButton>
            </Tooltip>
          </Grid>}
        </Grid>
        <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
          <Typography className={classes.borrowerText2}>{pgText} • {formatText(borrower?.experience, 'exp')} • {formatText(borrower.medianScore ?? borrower.ficoProvidedAtOrigination, 'fico')} • {formatPercentageValue(borrower?.pctOwnership)}
          </Typography>
          <CopyText propStyles={classes}>
            <Typography className={`${classes.borrowerText2} ${classes.hoverLink}`}>
              {borrower?.emailAddress}
            </Typography>
          </CopyText>
        </Box>
      </Box>
      <Box>
        <KeyboardArrowRight className={classes.arrowRight} />
      </Box>
    </Box>
  );
});

const EntityCard = observer(({ entity, setShowMoreInformationDialog, setType }) => {
  const classes = useStyles();
  const handleClick = () => {
    setType('entity');
    setShowMoreInformationDialog(true);
  }
  return (
    <Box onClick={handleClick} display="flex" alignItems="center" p={2} className={classes.borrowerCard}>
      <Box pl={2} flex={1}>
        <Typography variant='h5'>{getValueOrDefaultDash(entity.company?.name)}</Typography>
        <Typography className={classes.borrowerText2}>EIN {getValueOrDefaultDash(entity.ein ?? entity?.company?.einNumber)}</Typography>
      </Box>
      <Box>
        <KeyboardArrowRight className={classes.arrowRight} />
      </Box>
    </Box>
  );
});

const MoreInformationDialog = observer(({ open, currentBorrower, onClose, type, entity, loanBorrower, loanBorrowers }) => {
  const classes = useStyles();
  const { loanStore } = useLoanStore();


  const dialogContent = type === 'entity' ?
    <EntityOverview entity={entity} loanBorrowers={loanBorrowers} /> : <BorrowerOverview loan={loanStore?.loanDetails} borrower={currentBorrower} loanBorrower={loanBorrower}
    />

  const handleClose = () => {
    loanStore.selectBorrowersStore.reset();
    loanStore.setExistingBorrowers();
    onClose();
  };
  return (
    <div>
      <StandardDialog
        title={<Typography className={classes.dialogTitle}>{type === 'entity' ? entity?.company?.name : `${loanBorrower?.firstName} ${loanBorrower?.lastName}`}</Typography>}
        open={open}
        maxWidth="xl"
        fullWidth={false}
        dialogContent={dialogContent}
        handleClose={handleClose}
        onClose={handleClose}
      /></div>
  );
});
