import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { PersonAddOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { CustomButton } from '../../../components/customButton';
import { makeStyles } from '@material-ui/styles';
import { CustomAlert } from '../../../components/customAlert';

export const AddBorrowerCard = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const { borrowersStore } = quoteTypeStore;

  return (
    <CustomAlert
      severity="info"
      variant="outlined"
      action={
        <CustomButton
          size="medium"
          theme="blue"
          startIcon={<PersonAddOutlined />}
          testId="edit-borrower"
          onClick={() => borrowersStore.addNewBorrower()}
        >
          ADD BORROWER
        </CustomButton>
      }
    >
      <AlertTitle>Add Additional Borrower(s)</AlertTitle>
      <ul>
        <li>
          Include any additional borrowers who must be presented on the loan.
        </li>
        <li>
          Any individuals listed in the entity must be included in the loan
          submission.
        </li>
      </ul>
    </CustomAlert>
  );
});
