import { GetAppOutlined, PersonAddOutlined } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { CustomAlert } from '../../../components/customAlert';
import { CustomButton } from '../../../components/customButton';
import { useQuoteTypeStore } from '../../../hooks/useQuoteTypeStore';
import { QuoteStep } from '../../../utils/constants';
import { DownloadQuoteDialog } from './downloadQuoteDialog';

export const DownloadQuoteAlert = observer(() => {
  const { quoteTypeStore } = useQuoteTypeStore();
  const [showDownloadQuoteDialog, setShowDownloadQuoteDialog] = useState(false);

  return (
    <>
      <CustomAlert
        severity="success"
        variant="outlined"
        action={
          <CustomButton
            size="medium"
            theme="green"
            startIcon={<GetAppOutlined />}
            testId="edit-borrower"
            onClick={() => setShowDownloadQuoteDialog(true)}
          >
            DOWNLOAD QUOTE
          </CustomButton>
        }
      >
        <AlertTitle>Download Quote</AlertTitle>
        <ul>
          <li>
            Please note that quotes are non-binding and subject to underwriting.
          </li>
        </ul>
      </CustomAlert>
      <DownloadQuoteDialog
        store={quoteTypeStore}
        open={showDownloadQuoteDialog}
        onClose={() => setShowDownloadQuoteDialog(false)}
      />
    </>
  );
});
